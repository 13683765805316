<div class="widget-selector">
    <div fxFlex="0 0 50%">
        <label>{{'PAGE.SECTION' | translate}}</label>
    </div>
    <div fxFlex="0 0 50%">
        <label>{{context.cellId}}</label>
    </div>
    <mat-form-field fxFlex="0 0 100%">
        <mat-select placeholder="{{'PAGE.WIDGET_TYPE' | translate}}" [(ngModel)]="context.widgetType" (selectionChange)="onWidgetTypeChanged()">
            <mat-option *ngFor="let type of widgetTypes" [value]="type.id">
                {{ type.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="0 0 100%" class="content-selection-input">
        <input disabled matInput placeholder="{{'PAGE.CELL_WIDGET' | translate}}" [(ngModel)]="context.widgetName" (change)="onValueChanged()">
        <div fxFlex="0 0 45px">
            <button mat-button class="mat-icon-button mat-elevation-z1" (click)="selectWidget()" *fuseIfOnDom aria-label="Select widget" matTooltip="{{'WIDGET.SELECT' | translate}}">
                <mat-icon>settings_applications</mat-icon>
            </button>
        </div>
        <div fxFlex="0 0 45px">
            <button mat-button class="mat-icon-button mat-elevation-z1" (click)="editAnonymousWidget()" *fuseIfOnDom aria-label="Select widget" matTooltip="{{'COMMON.EDIT' | translate}}"
                    [disabled]="context.widgetId === undefined || context.widgetId === null" [ngClass]="{'disabled': context.widgetId === undefined || context.widgetId === null}">
                <mat-icon>edit</mat-icon>
            </button>
            </div>
    </mat-form-field>

    <mat-form-field fxFlex="0 0 100%" class="content-selection-input">
        <input disabled matInput placeholder="{{'PAGE.CELL_WIDGET_AUTH' | translate}}" [(ngModel)]="context.authWidgetName" (change)="onValueChanged()">
        <div fxFlex="0 0 45px">
            <button mat-button class="mat-icon-button mat-elevation-z1" (click)="selectAuthWidget()" *fuseIfOnDom aria-label="Select widget" matTooltip="{{'WIDGET.SELECT' | translate}}">
                <mat-icon>settings_applications</mat-icon>
            </button>
        </div>
        <div fxFlex="0 0 45px">
            <button mat-button class="mat-icon-button  mat-elevation-z1" (click)="editAuthWidget()" *fuseIfOnDom aria-label="Select widget" matTooltip="{{'WIDGET.SELECT' | translate}}"
                     [disabled]="context.authWidgetId === undefined || context.authWidgetId === null" [ngClass]="{'disabled': context.authWidgetId === undefined || context.authWidgetId === null}">
                <mat-icon>edit</mat-icon>
            </button>
            </div>
    </mat-form-field>

    <mat-form-field fxFlex="0 0 50%">
        <input matInput placeholder="{{'PAGE.CELL_HEIGHT' | translate}}" matTooltip="{{'PAGE.TOOLTIP_HEIGHT' | translate}}" [(ngModel)]="context.height"
            (change)="onValueChanged()">
    </mat-form-field>

    <mat-form-field fxFlex="0 0 50%">
        <input matInput placeholder="{{'PAGE.CELL_PADDING' | translate}}" matTooltip="{{'PAGE.TOOLTIP_PADDING' | translate}}" [(ngModel)]="context.padding"
            (change)="onValueChanged()">
    </mat-form-field>

    <mat-form-field fxFlex="0 0 90%">
        <input matInput placeholder="{{'PAGE.CELL_COLOR' | translate}}" matTooltip="{{'PAGE.TOOLTIP_BACKGROUND' | translate}}" [(ngModel)]="context.color"
            (change)="onValueChanged()" />
    </mat-form-field>
    <fuse-material-color-picker [(selectedBg)]="context.color" fxFlex="10" (onValueChange)="onColorChanged()">
    </fuse-material-color-picker>
</div>