export enum NotifyType{
    Success,
    Warning,
    Error
}

export class NotifyMessage {
    index: number;
    message: string;
    type: NotifyType;

    constructor(index: number, message: string, type: NotifyType = NotifyType.Success) {
        this.index = index;
        this.message = message;
        this.type = type;
    }
}