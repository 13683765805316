import { Component, OnInit, Input } from '@angular/core';
import { LayoutRow, LayoutCell } from './layout.component';

@Component({
  selector: 'app-layoutrow',
  templateUrl: './layoutrow.component.html',
  styleUrls: ['./layoutrow.component.scss']
})

export class LayoutRowComponent implements OnInit {
  @Input('rows') rows: LayoutRow[];

  private _row: LayoutRow;

  constructor() {

  }

  ngOnInit() {

  }

  get row(): LayoutRow {
    return this._row;
  }

  @Input('row')
  set cell(value: LayoutRow) {
    this._row = value;
  }

  addRow() {
    if (!this.rows) { this.rows = []; }
    let row = new LayoutRow();
    row.width = '100';
    row.cells = [];
    let cell = new LayoutCell();
    cell.id = 'cell' + Math.round(Math.random() * 1000000).toString();
    cell.width = '100';
    row.cells.push(cell);
    row.isEdit = true;
    cell.isEdit = true;
    this.rows.push(row);
  }

  reset() {
    this._row.cells.splice(0);
  }

  delete() {
    this.rows.splice(this.rows.findIndex(x => x === this._row), 1);
    if (this.rows.length === 0) {
      this.addRow();
    }
  }
}
