import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './appconfig.service';
import { IResponse } from '../models/BaseResponse';
import { IBaseResponse } from '../models/BaseResponse';
import { Subject } from 'rxjs';
import { NotifyMessage } from '../models/NotifyMessage';
import { NotifyType } from '../models/NotifyMessage';

@Injectable()
export class NotifyService {
    private useConsole = false;
    private conflictError = -1000;
    private isBrowser = this.appConfig.isBrowser;
    private observableMessage = new Subject();

    constructor(
        private translate: TranslateService,
        private appConfig: AppConfigService
    ) {
    }

    log(msg: string) {
        this.internalLog(msg);
    }

    success(msg: string) {
        if (this.isBrowser) {
            this.translate.get(msg || 'NOTIFICATION.SUCCESS').subscribe(translate => {
                this.observableMessage.next(this.createNotifyMessage(translate, NotifyType.Success));
            });
        }
        this.internalLog(msg);
    }

    error(msg: string) {
        if (this.isBrowser) {
            this.translate.get(msg || 'NOTIFICATION.GENERICERROR').subscribe(translate => {
                this.observableMessage.next(this.createNotifyMessage(translate, NotifyType.Error));
            });
        }
        this.internalLog(msg);
    }

    warning(msg: string) {
        if (this.isBrowser) {
            this.translate.get(msg || 'NOTIFICATION.GENERICERROR').subscribe(translate => {
                this.observableMessage.next(this.createNotifyMessage(translate, NotifyType.Warning));
            });
        }
        this.internalLog(msg);
    }

    // tslint:disable-next-line:max-line-length
    handleServerResponse<T>(response: IBaseResponse<T>, callback: (data: T) => void, error?: (error: string) => void, conflict?: () => void, showMessage: boolean = true) {
        if (response) {
            if (response.isError) {
                if (response.errorCode === this.conflictError) {
                    if (conflict) {
                        conflict();
                        return;
                    }
                }
                if (error) {
                    error(response.errorMessage);
                }
                this.internalLog(response.errorMessage);
                if (showMessage) {
                    this.error(`NOTIFICATION.${response.errorMessage}`);
                }
            } else {
                callback(response.data);
            }
        }
    }

    handleResponse(response: IResponse) {
        if (response) {
            if (response.isError) {
                this.error(`NOTIFICATION.${response.errorMessage}`);
            }
        }
    }

    subscribe(next: (value: NotifyMessage) => void) {
        return this.observableMessage.subscribe(next);
    }

    private internalLog(msg: string) {
        if (this.useConsole) {
            console.log(msg);
        }
    }

    private createNotifyMessage(msg: string, type: NotifyType) {
        const index = Math.random();
        return new NotifyMessage(index, msg, type);
    }
}
