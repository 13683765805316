import { Component, OnInit, Input } from '@angular/core';
import { LayoutCell, LayoutColumn, LayoutRow } from './layout.component';

@Component({
  selector: 'app-layoutcell',
  templateUrl: './layoutcell.component.html',
  styleUrls: ['./layoutcell.component.scss']
})

export class LayoutCellComponent implements OnInit {
  @Input('cells') cells: LayoutCell[];

  private _cell: LayoutCell;

  constructor() {

  }

  ngOnInit() {

  }

  get cell(): LayoutCell {
    return this._cell;
  }

  @Input('cell')
  set cell(value: LayoutCell) {
    this._cell = value;
  }

  addCell() {
    if (this.cells === undefined) { this.cells = []; }
    let cell = new LayoutCell();
    cell.id = 'cell' + Math.round(Math.random() * 1000000).toString();
    cell.width = '100';
    cell.isEdit = true;
    this.cells.push(cell);
  }

  reset() {
    if (this._cell.columns) {
      this._cell.columns.splice(0);
    }
  }

  delete() {
    this.cells.splice(this.cells.findIndex(x => x === this._cell), 1);
    if (this.cells.length === 0) {
      this.addCell();
    }
  }
}
