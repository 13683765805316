import { ILayoutContentMapper } from '../layout/layoutcontentmapper';
import { Type } from '@angular/core';
import { UnknownDynamicComponent } from '../layout/layoutcontent.component';
import { LayoutImageComponent } from './layoutcomponents/layoutimage.component';
import { WidgetSelectorComponent } from './layoutcomponents/widgetselector.component';
import { LockedWidgetComponent } from './layoutcomponents/lockedwidget.component';
import { LayoutItemEditorComponent } from '../layout/layoutitemeditor.component';

export class LayoutContentMapperService implements ILayoutContentMapper {

    private mappings = {
        'image': LayoutImageComponent,
        'widgetselector': WidgetSelectorComponent,
        'lockedwidget': LockedWidgetComponent,
        'layouteditor': LayoutItemEditorComponent
    };

    getComponentType<T>(typeName: string): Type<T> {
        let type = this.mappings[typeName];
        return type || UnknownDynamicComponent;
    }

}
