<div class="page-layout carded fullwidth ps">
  <generic-search-filter
    [requestModel]="model"
    [panelTitle]="panelTitle"
    [panelIcon]="'insert_drive_file'"
    (onSubmit)="onSubmit()"
    (onAddItem)="add()"
  ></generic-search-filter>

  <div
    class="custom-center"
    [ngClass]="{
      'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError
    }"
  >
    <div
      class="content-card"
      [ngClass]="{
        'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError
      }"
    >
      <div *ngIf="dataSource?.isEmpty || dataSource?.isError">
        <div class="table-error-message" *ngIf="dataSource?.isEmpty">
          <span>{{ "COMMON.NO_ITEMS" | translate }}</span>
        </div>
        <div class="table-error-message" *ngIf="dataSource?.isError">
          <span>{{ "COMMON.ERROR_LOADING" | translate }}</span>
        </div>
      </div>
      <mat-table
        [ngStyle]="{
          display: dataSource?.isEmpty || dataSource?.isError ? 'none' : ''
        }"
        [dataSource]="dataSource"
        class="table-items mat-table"
        [@animateStagger]="{ value: '50' }"
        matSort
        matSortDisableClear
        matSortActive="changedAt"
        matSortDirection="desc"
        fuseperfectscrollbar
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >{{ "MENUS.NAME_COLUMN" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="link">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >{{ "MENUS.LINK" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.link }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sort">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >{{ "MENUS.SORT" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.sort }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isRight">
          <mat-header-cell *matHeaderCellDef
            >{{ "MENUS.ISRIGHT" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon [ngClass]="{ check: row.isRight, clear: !row.isRight }">
              {{ row.isRight ? "check" : "clear" }}
            </mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="userType">
          <mat-header-cell *matHeaderCellDef>PVR</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon
              [ngClass]="{ check: row.userType == 1, clear: !row.isRight }"
            >
              {{ row.userType == 1 ? "check" : "clear" }}
            </mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="authenticated">
          <mat-header-cell *matHeaderCellDef
            >{{ "MENUS.AUTHENTICATED" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon
              [ngClass]="{
                check: row.authenticated,
                clear: !row.authenticated
              }"
            >
              {{ row.authenticated ? "check" : "clear" }}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="icon">
          <mat-header-cell *matHeaderCellDef
            >{{ "MENUS.ICON" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.icon }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="changedAt">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >{{ "COMMON.CHANGED_COLUMN" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.changedFormated }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="changedBy">
          <mat-header-cell *matHeaderCellDef
            >{{ "COMMON.CHANGEDBY_COLUMN" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.changedBy }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>
            <span>{{ "MEDIA.ACTION" | translate }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div fxLayoutAlign="end">
              <button
                mat-icon-button
                *ngIf="userAccess.canEdit"
                (click)="edit(row, $event)"
                name="editButton"
                matTooltip="{{ 'COMMON.EDIT' | translate }}"
              >
                <mat-icon class="edit">edit</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="userAccess.canDelete"
                (click)="delete(row, $event)"
                name="deleteButton"
                matTooltip="{{ 'COMMON.DELETE' | translate }}"
              >
                <mat-icon class="delete-icon">delete</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="userAccess.canInsert"
                (click)="clone(row, $event)"
                name="cloneButton"
                matTooltip="{{ 'COMMON.CLONE' | translate }}"
              >
                <mat-icon class="content-copy">content_copy</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="edit(row)"
        ></mat-row>
      </mat-table>
      <div class="media-loading-shade" *ngIf="dataSource?.isLoadingResults">
        <mat-spinner [color]="'accent'"></mat-spinner>
      </div>
      <mat-paginator
        [ngStyle]="{
          display: dataSource?.isEmpty || dataSource?.isError ? 'none' : ''
        }"
        [length]="dataSource?.resultsLength"
        [pageSize]="dataSource?.pageSize"
      >
      </mat-paginator>
    </div>
  </div>
</div>
