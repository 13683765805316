import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ORIGIN_URL} from './constants/baseurl.constants';

@Injectable()
export class AccountService {
    constructor(
        private http: HttpClient, // Use for everything else
        @Inject(ORIGIN_URL) private baseUrl: string) {
    }

    logout() {
        this.http.post(`${this.baseUrl}/Account/Logout`, {}).subscribe(res => {
            location.replace('/');
        }, error => {
        });
    }

    setLanguage(languageCode: string) {
        this.http.post(`${this.baseUrl}/api/DataServices/SetLanguage?languageCode=${languageCode}`, {}).subscribe(res => {
            location.replace('/');
        }, error => {
        });
    }
}
