export const locale = {
  lang: "it-IT",
  data: {
    NOTIFICATION: {
      UNAUTHORIZED: "Non autoSrizzato",
      "-1000": "Dati non validi. Aggiornare i dati dal server",
      "-1001": "Richiesta non valida",
      "1000": "Elemento non trovato",
      "1001": "Impossibile cancellare un elemento pubblicato.",
      "2001": "Pagina con indirizzo essistente",
      "2002": "Non è possibile cambiare skin",
      "4000": "Link seo esistente",
      "4001": "Link seo non valido",
      "6000": "Nome contenuto non valido. Deve essere univoco",
      "7000":
        "Dati invalidi per gico Netent. Tipo gioco AAMS deve essere numerico, famiglia netent e tipo non devono essere vuoti. Inoltre il campo 'gameId' deve essere univoco",
      "7001":
        "Dati invalidi per gico Capecod. Tipo gioco AAMS e codice AAMS devono essere numerici, tipo non deve essere vuoto. Inoltre il campo 'gameId' deve essere univoco",
      "7002":
        "Dati invalidi per gico GreenTube. Tipo gioco AAMS e codice AAMS devono essere numerici, tipo non deve essere vuoto. Inoltre il campo 'gameId' deve essere univoco",
      "7003":
        "Dati invalidi per gico Novomatic. Tipo gioco AAMS, codice AAMS e id gioco devono essere numerici. Inoltre il campo 'gameId' deve essere univoco",
      "7004": "Dati invalidi per gico Extreme",
      "7005": "Dati invalidi per gico Evolution",
      "8000": "La cartella contiene elementi, non può essere cancellata",
      "8001": "Nome cartella non valido",
      "8002": "Cartella media non trovata",
      "9000": "Scommessa già insertia.",
      "9001": "Seleziona la disciplina",
      "9002": "Scommessa duplicata",
      "10001": "Gruppo già presente",
      "10002": "Disciplina esitente",
      "10003": "Tutti i gruppi sono già configurati",
      SUCCESS: "Operazione eseguita",
      ERRROR: "Errore",
      CONFLICT:
        "L'elemento e stato cambiato da un altro utente. Sovrascrivere i dati?",
      NOT_IMPLEMENTED: "Non implementato",
      GENERICERROR: "Errore generico",
      NULLREQUESTERROR: "Richiesta non valida",
      ITEM_NOT_FOUND_ERROR: "Elemento non trovato",
      INVALID_REQUEST: "Richiesta non valida",
      ERROR_WIDGET_SETAUTHORANONYM:
        "Impostare la visibilità anonimo o/e autenticato",
      INVALID_DATA: "Dati non validi",
      MEDIA_NAME_ERROR: "Inserire il nome",
      MEDIA_FOLDER_DELETE_ERROR:
        "La cartella contiene elementi, non può essere cancellata",
      MEDIA_FOLDER_SAVE_ERROR: "Errore nel salvataggio della cartella",
      MEDIA_FOLDER_MOVE_ERROR: "Errore nello spostamento della cartella",
      MEDIA_ITEMS_MOVE_ERROR: "Errore nello spostamento degli elementi",
      ERROR_SAVING_DATA: "Errore nel salvataggio",
      MEDIA_FILE_NOTSUPPORTED_ERROR: "File nullo o non supportato",
    },
    COMMON: {
      SEARCH_TITLE: "Cerca",
      SEARCH_FRONTEND_LABEL: "Prodotto",
      SEARCH_CREATION_FOLDER: "Cartella",
      SEARCH_WIDTH: "Larghezza",
      SEARCH_HEIGHT: "Altezza",
      SEARCH_SKIN_LABEL: "Skin",
      SEARCH_NAME_LABEL: "Nome",
      SEARCH_META_LABEL: "Meta",
      SEARCH_TAGS_LABEL: "Tags",
      SEARCH_TYPE_LABEL: "Tipo",
      SEARCH_PAGE_LABEL: "Nome",
      SEARCH_TITLE_LABEL: "Titolo",
      SEARCH_CREATION_DATE: "Data creazione",
      SEARCH_START_LABEL: "Da",
      SEARCH_END_LABEL: "A",
      SEARCH_ANONYMOUS_LABEL: "Anonimo",
      SEARCH_AUTHENTICATED_LABEL: "Autenticato",
      SEARCH_FRONTEND: "Prodotto",
      SEARCH_PLATFORM_LABEL: "Piattaforma",
      SEARCH_STARTDATE_LABEL: "Data Inizio",
      SEARCH_ENDDATE_LABEL: "Data fine",
      SEARCH_NAME: "Cerca per nome",
      SEARCH_ENABLED_LABEL: "abilitato",
      SEARCH_ALLFOLDERS: "Tutte le cartelle",
      RESET_LABEL: "Azzera",
      SUBMIT_LABEL: "Invia",
      ADDITEM_LABEL: "Nuovo",
      SAVE: "Salva",
      CLOSE: "Chiudi",
      EDIT: "Modifica",
      MOVE: "Sposta",
      DELETE: "Cancella",
      DELETE_BUTTON: "Rimuovi",
      HIDE: "Cancella",
      CONFIRM_TITLE: "Conferma",
      CONFIRM_YES_BUTTON: "Si",
      CONFIRM_NO_BUTTON: "No",
      DELETEMESSAGE: "Cancellare l'elemento selezionato? ",
      DELETEMESSAGE_SECTION:
        "Cancellare l'elemento selezionato? Saranno cancellati anche tutti i figli.",
      DELETE_PUBLISHED_MESSAGE:
        "Attenzione! L'elemento selezionato e pubblicato. Continuare con la cancellazione?",
      NO_ITEMS: "Nessun elemento trovato",
      ERROR_LOADING: "Errore nel caricamento dei dati",
      CANCELEDIT: "Modifiche non salvate. Chiudere lo stesso?",
      CONFIRM_DELETE:
        "Cliccando sì, si elimineranno il seguente nodo e tutti i nodi sottostanti",
      INVALIDDATA: "Compilare tutti i campi richiesti",
      REQUIRED_ERROR: "Campo richiesto",
      REQUIRED_MAXLEN: "Lunghezza massima",
      SEARCH_PLACEHOLDER: "Cerca",
      PUBLISH: "Pubblica",
      CREATE: "Crea nuovo",
      WIDGET_VISIBILITY_ERROR:
        "Impostare la visibilità anonimo o/e autenticato",
      FRONTEND: "Prodotto",
      CLONE: "Copia",
      WIDGET_COMPONENT_ERROR:
        "Il componente dynamico richiede sia prodotto che nome componente",
      SELECT_MEDIA: "Seleziona media",
      ACTION: "Azioni",
      RESET: "Azzera",
      REMOVE_MEDIA: "Rimuovi media",
      ADD_MEDIA: "Aggiungi/cambia media",
      ADD_FRONT_MEDIA: "Aggiungi/cambia front media",
      PUBLISHED: "Pubblicato",
      SELECT: "Seleziona",
      DIFFERENT_FIELD: "Campo diverso dalla pubblicazione",
      ADD_BUTTON: "Aggiungi bottone",
      ADD_ITEM: "Aggiungi elemento",
      SELECT_CONTENT: "Seleziona contenuto",
      ISVISIBLE: "Si",
      ISNOTVISIBLE: "No",
      INVALID_LINK: "valore non valido",
      MOVE_UP: "Sposta in alto",
      MOVE_DOWN: "Sposta in basso",
      CHANGED_COLUMN: "Ultima modifica",
      CHANGEDBY_COLUMN: "Modificato da",
      SEARCH_FIXEDWIDGET_LABEL: "Widget noti",
      SUCCESS_COPY: "Copiato con successo negli appunti",
    },
    SEARCH: {
      PAGES: "Pagine",
      WIDGETS: "Widgets",
      MEDIA: "Immagini",
      TITLE: "Ricerca",
      TYPE: "Tipo",
    },
    WIDGETS: {
      TITLE: "Widgets",
      FILTER: "Cerca",
      NAME_COLUMN: "Nome",
      TYPE_COLUMN: "Tipo",
      NOTES_COLUMN: "Note ",
      PUBLISHED_COLUMN: "Pubblicato",
      FRONTEND_COLUMN: "Prodotto",
      ADD_BANNER: "Aggiungi un banner",
      TOOLTIP_FRONTEND: "seleziona a quale sezione del sito deve appartenere",
      TOOLTIP_TYPE: "seleziona il tipo del widget",
      TOOLTIP_NAME: "nome identificativo del widget",
      TOOLTIP_START_DATE: "data di inizio del widget",
      TOOLTIP_END_DATE: "data di scadenxa del widget",
      TOOLTIP_NOTE: "note o informazioni aggiuntive",
      TOOLTIP_BANNER_ANIM: "scegliere un animazione",
      TOOLTIP_BANNER_TITLE: "Titolo del banner",
      TOOLTIP_BANNER_BACK_COLOR_TITLE:
        "possibilità di impostare un colore di sfondo per il titolo",
      TOOLTIP_BANNER_COLOR_TITLE:
        "possibilità di impostare un colore al titolo",
      TOOLTIP_BANNER_SUBTITLE: "Sottotitolo del banner",
      TOOLTIP_BANNER_BACK_COLOR_SUBTITLE:
        "possibiltà di impostare un colore di sfondo al sottotitolo",
      TOOLTIP_BANNER_COLOR_SUBTITLE:
        "possibiità di impostare un colore al titolo",
      TOOLTIP_BANNER_TEXT: "descrizione del banner",
      TOOLTIP_BANNER_BUTTON_TEXT: "test nel bottone del banner",
      TOOLTIP_BANNER_BUTTON_CONTENT: "contetnuto del bottone",
      TOOLTIP_BANNER_BUTTON_LINK: "link da impostare al bottone",
      TOOLTIP_BANNER_BUTTON_FUNCTION:
        "possibile funzione js da CMS (stili e script)",
      TOOLTIP_BANNER_RATIO: "Proporzioni banner (se vuoto, predefinito 16%)",
    },
    FILTER: {
      TITLE: "Filtra",
    },
    PAGES: {
      FILTER: "Cerca",
      SELECT_PAGE_LABEL: "Seleziona una pagina",
      TITLE: "Pagine",
      NAME_COLUMN: "Nome",
      META_COLUMN: "Meta Tags",
      TAGS_COLUMN: "Meta Links",
      LAYOUT_COLUMN: "Layout",
      FRONTEND_COLUMN: "Prodotto",
      SKIN_COLUMN: "Skin",
      EDIT: "Modifica",
      LINK: "Indirizzo",
      TITLE_COLUMN: "Titolo",
      PUBLISHED_COLUMN: "Pubblicata",
      PUBLISHED_DATE_COLUMN: "Pubblicata il",
      SELECTOR_TITLE: "Seleziona pagina",
      SELECT: "Seleziona",
    },
    MEDIA: {
      FILTER: "Cerca",
      TITLE: "Media",
      DESCRIPTION_COLUMN: "Descrizione",
      EXT_COLUMN: "Tipo",
      FILE_LANGUAGE: "Lingua",
      FILE_DESCRIPTION: "Descrizione Media",
      FILE_INPUT: "Seleziona File",
      FILE_INPUT_SELECTED: "Nome File",
      NAME_COLUMN: "Nome",
      FRONTEND_COLUMN: "Prodotto",
      EDIT: "Modifica",
      FILE_NAME: "Nome Media",
      ACTION: "Azione",
      PREVIEW: "Anteprima",
      WIDTH_COLUMN: "Larghezza",
      HEIGHT_COLUMN: "Altezza",
      FILE_WIDTH: "Larghezza",
      FILE_HEIGHT: "Altezza",
      FILE_FRONTEND: "Prodotto",
      LANGUAGE_SELECTOR: "Aggiungi Media in altra lingua (opzionale)",
      DIALOG_EDIT: "Modifica Media",
      DIALOG_ADD: "Aggiungi Media",
      ALT: "Alt Media",
      THUMNBNAIL_COLUMN: "",
      TOOLTIP_PRODUCT: "selezionare a che sezione indirizzare un immagine",
      TOOLTIP_NAME: "nome identificativo",
      TOOLTIP_DESCRIPTION: "piccola descrizione (opzionale)",
      TOOLTIP_ALT_MEDIA: "alt per tag html",
      TOOLTIP_LANGUAGE: "tipo di lingua",
      FILE_CDNURL: "Link assoluto",
      FILE_PUBLICURL: "Link relativo",
    },
    PAGE: {
      NEW_TITLE: "Nuova pagina",
      EDIT_TITLE: "Modifica pagina",
      NAME: "Nome",
      TAGS: "Aggiungi Meta Links",
      META: "Aggiungi Meta Tags",
      TAGS_ADDED: "Meta Links",
      META_ADDED: "Meta Tags",
      LAYOUT: "Layout",
      ISFULLSCREEN: "Tutto schermo",
      PROMOTIONAL_LANDING_PAGE: "Landing Page Promozionali",
      LANDING_PAGE: "Landing Page",
      LINK: "Indirizzo",
      PAGE_ID: "Id pagina",
      ADDZONE: "Nuova sezione",
      ZONES: "Sezioni",
      PREVIEW: "Anteprima",
      REMOTE_ID: "Id remoto",
      TITLE: "Titolo",
      DETAILS: "Dettagli",
      WIDGET_TYPE: "Seleziona tipo widget",
      FRONTEND: "Prodotto",
      SELECT_WIDGET_TYPE: "Seleziona il tipo di widget",
      SELECT_LAYOUT_TYPE: "Seleziona il tipo di layout",
      RESET_PUBLISHED: "Ripristina da pubblicazione",
      RESET_PUBLISHED_CONFIRM:
        "Ripristinare la pagina dall'ultima pubblicazione?",
      SECTION: "Sezione",
      CELL_HEIGHT: "Altezza",
      CELL_WIDGET: "Widget",
      CELL_LAYOUT: "Layout",
      CELL_WIDGET_AUTH: "Widget autenticato",
      CELL_PADDING: "Padding",
      CELL_COLOR: "Colore sfondo",
      KEY: "Chiave",
      VALUE: "Valore",
      ADD_META_TAG: "Aggiungi meta tag",
      REMOVE_META_TAG: "Rimuovi meta tag",
      ADD_META_LINK: "Aggiungi meta link",
      REMOVE_META_LINK: "Rimuovi meta link",
      SKIN: "Skin",
      ISSYSTEM: "Pagina di Sistema",
      LINKURL: "Indirizzo",
      TOOLTIP_SKIN: "selezionare una skin per la page",
      TOOLTIP_PRODUCT: "selezionare a quale sezione è indirizzata",
      TOOLTIP_ID:
        "inserire il link della pagina (se la pagina non è di sistema verra sempre preceduto dalla directory virtuale pages/)",
      TOOLTIP_NAME: "nome identificativo della pagina",
      TOOLTIP_TITLE: "titolo visualizzato sulla scheda html",
      TOOLTIP_ADDRESS: "indirizzo di url della pagina",
      TOOLTIP_METATAG_KEY:
        "questo campo corrsiponde al valore name del metatag",
      TOOLTIP_METATAG_VALUE:
        "questo campo corrsiponde al valore content del metatag",
      TOOLTIP_METALINK_KEY:
        "questo campo corrsiponde al valore name del metalink",
      TOOLTIP_METALINK_VALUE:
        "questo campo corrsiponde al valore content del metalink",
      TOOLTIP_HEIGHT: "inserisci laltezza del contenitore",
      TOOLTIP_PADDING:
        "insersci il padding del contenitore (la distanza tra i bordi del contenitore e il contenuto interno)",
      TOOLTIP_BACKGROUND: "scegli un colore di sfondo per il box",
    },
    WIDGET: {
      NEW_TITLE: "Nuovo Widget",
      EDIT_TITLE: "Modifica Widget",
      NAME: "Nome",
      TYPE: "Tipo",
      STARTDATE: "Inizio",
      ENDDATE: "Fine",
      SHOWANONYMOUS: "Anonimo",
      SHOWAUTHENTICATED: "Autenticato",
      SHOW_SHADOW: "Ombra",
      HIDE_ON_APP: "Nascondi su app",
      CONTENT: "Contenuto",
      DETAILS: "Dettagli",
      DATES_ERROR: "Data fine non valida",
      LINK: "Link",
      WIDTH: "Larghezza",
      HEIGHT: "Altezza",
      ANOUNYMOUS_WIDGETS: "Widget anonimi",
      AUTHENTICATED_WIDGETS: "Widget autenticati",
      FRONTEND: "Prodotto",
      DYNAMIC_COMPONENT: "Componente dinamico",
      SELECT_IMAGE_MESSAGE: "Seleziona media",
      ANIMATION: "Animazione",
      NOTES: "Note",
      SELECT: "Seleziona Widget",
      VISIBLE: "Visible",
      CONFIRMTYPECHANGED:
        "ATTENZIONE! Modificando il tipo del widget il contenuto sarà perso. Proseguire?",
    },
    HELP: {
      SKIN: "Skin",
      EDITITEM_LABEL: "Modifica",
      ADDITEM_LABEL: "Aggiungi a",
      DISPLAY: "Nome nodo",
      NEW_NODE: "Nuovo nodo",
      DEFAULT_NODE_VALUE:
        '<h2 style="color:#ff0000">Aggiungere contenuto al seguente nodo</h2>',
      SELECTION_WARNING: "Selezionare una voce",
      TOOLTIP_SKIN: "Skin",
      SORT: "Ordina elementi",
      CONFIRM_SORT:
        "Sei sicuro di voler ordinare gli elementi alfabeticamente?",
    },
    STYLES: {
      EDIT_TITLE: "Modifica stili",
      CONTENT: "Contenuto",
      STYLE: "Stili CSS",
      SCRIPT: "Script",
    },
    LAYOUT: {
      NAME_COLUMN: "Nome",
      EDIT_TITLE: "Modifica layout",
      NEW_TITLE: "Nuovo layout",
      ADD_COLUMN: "Aggiungi colonna",
      REFRESH_ROWS: "Elimina righe",
      ADD_ROW: "Aggiungi riga",
      REFRESH_CELLS: "Elimina celle",
      ADD_CELL: "Aggiungi cella",
      DELETE: "Cancella",
      ISPUBLISHED: " - Layout pubblicato",
      CONFIRMSAVE:
        "ATTENZIONE! Il layout è collegato a pagine pubblicate. Le modifiche possono resettare i contenuti della pagina. Proseguire?",
    },
    LAYOUTS: {
      TITLE: "Layouts",
    },
    MEDIASELECTOR: {
      TITLE: "Seleziona media",
    },
    LAYOUTSELECTOR: {
      TITLE: "Seleziona layout",
    },
    WIDGETSELECTOR: {
      TITLE: "Seleziona widget",
    },
    BANNER: {
      BACK_ANIMATION: "Seleziona animazione sfondo",
      FRONT_ANIMATION: "Seleziona animazione primo piano",
      LINK: "Collegamento",
      TITLE: "Titolo",
      SUBTITLE: "Sottotitolo",
      BUTTON_TEXT: "Testo",
      BUTTON_TYPE: "Tipo",
      IMAGES_FRONT_SUBTITLE: "Seleziona Immagine primo piano",
      IMAGES_SECTION: "Seleziona Immagini",
      IMAGES_SECTION_SUBTITLE: "Seleziona Immagine sfondo",
      IMAGES_SECTION_RATIO: "Carica un'immagine con Proporzione min 1072x364",
      DETAILS: "Dettagli",
      TIMER: "Abilita timer",
      TITLE_COLOR: "Colore titolo",
      SUBTITLE_COLOR: "Colore sottotitolo",
      TITLE_BACK_COLOR: "Colore sfondo titolo",
      SUBTITLE_BACK_COLOR: "Colore sfondo sottotitolo",
      TEXT: "Testo",
      TITLE_ANIMATION: "Titolo animato",
      TIMER_ONSTART: "Timer data inizio",
      ADD_BUTTON: "Aggiungi Bottone",
      BUTTON_ISCONTENT: "Contenuto",
      BUTTON_ISPOPUP: "Apri popup",
      BUTTON_CONTENT: "Contenuto",
      BUTTON_ISPAGE: "Pagina",
      BUTTON_PAGE: "Pagina",
      BUTTON_ISLINK: "Link",
      BUTTON_LINK: "Link",
      BUTTON_ISFUNCTION: "Funzione JS",
      BUTTON_FUNCTION: "Funzione JS",
      SELECT_CONTENT: "Seleziona conetunto",
      SELECT_PAGE: "Seleziona pagina",
      RATIO: "Proporzioni",
    },
    CONTENTS: {
      TITLE: "Contenuti",
      SELECTOR_TITLE: "Seleziona contenuto",
    },
    CONTENT: {
      NEW_TITLE: "Modifica contenuto",
      EDIT_TITLE: "Modifica contenuto",
      NAME: "Nome",
      TITLE: "Titolo",
      WIDGET: "Widget",
      WIDGET_COLUMN: "Widget",
      SKIN: "Skin",
      URL: "Url contenuto",
    },
    SLIDER: {
      ITEM_NAME: "Widget",
    },
    SECTION: {
      TITLE: "Sotto-menu",
      NAME: "Nome",
      SKIN: "Skin",
      ITEM_TITLE: "Titolo",
      SORT: "Ordinamento",
      CONTENT: "Contenuto",
      ISPOPUP: "Apri popup",
      ISVISIBLE: "Visibile",
      FRONTEND: "Prodotto",
      SELECT_ITEM: "Seleziona un elemento",
      ITEM_LINK: "Url Seo",
      INVALID_LINK: "Link seo non valido",
      PAGE: "Pagina",
      SELECT_PAGE: "Seleziona pagina",
    },
    PROMOTIONS: {
      TITLE: "Promozioni",
      NAME_COLUMN: "Titolo",
      THUMNBNAIL_COLUMN: "",
      SKIN_COLUMN: "Skin",
      ISVISIBLE: "Visibile",
      FRONTENDS_COLUMN: "Prodotto",
      STARTDATE_COLUMN: "Data inizio",
      ENDDATE_COLUMN: "Data fine",
      EDIT: "Modifica",
      PUBLISHED_COLUMN: "Pubblicata",
      SORT: "Ordinamento",
    },
    PROMO: {
      SKIN: "Skin",
      NEW_TITLE: "Nuova promozione",
      EDIT_TITLE: "Modifica promozione",
      BACK_COLOR: "Colore sfondo",
      NAME: "Titolo Promozione",
      ADD_BUTTON: "Aggiungi bottone",
      SHORTDETAILS: "Testo breve",
      DESKTOPIMAGE: "Immagine Desktop",
      PORTRAIT_IMAGE: "Carica un'immagine 390x420",
      LANDSCAPE_IMAGE: "Carica un'immagine 720x400",
      BIGIMAGE: "Landscape",
      SMALLIMAGE: "Portrait",
      MOBILEIMAGE: "Immagine Mobile",
      SHOWDATA: "Data visibile",
      BUTTON_IS_AUTHENTICATED: "Autenticato",
      BUTTON_IS_ANONYMOUS: "Anonimo",
      ENABLE_DATE: "Data abilitata",
      ENABLE_TIMER: "Timer abilitato",
      ENABLE_START_TIMER: "Timer data inizio",
      STARTDATE: "Inizio",
      ENDDATE: "Fine",
      NOTES: "Note",
      HTML: "Html",
      SHOWINCOMING: "Visibile IN ARRIVO",
      ISVISIBLE: "Visibile",
      JACKPOT: "Montepremi",
      SORT: "Ordinamento",
      BUTTON_TEXT: "Testo",
      BUTTON_TYPE: "Tipo",
      SELECT_BACKIMAGE: "Sfondo",
      SELECT_FRONTIMAGE: "Immagine",
      PLATFORM: "Piattaforma",
      PRODUCTS: "Prodotti",
      LANDSCAPE: "Portrait/Landscape",
      BUTTON_ISCONTENT: "Contenuto",
      BUTTON_ISPOPUP: "Apri popup",
      BUTTON_CONTENT: "Contenuto",
      BUTTON_ISPAGE: "Pagina",
      BUTTON_PAGE: "Pagina",
      BUTTON_ISLINK: "Link",
      BUTTON_LINK: "Link",
      BUTTON_ISFUNCTION: "Funzione JS",
      BUTTON_FUNCTION: "Funzione JS",
      SELECT_CONTENT: "Seleziona contenuto",
      COMINGSOON_BUTTONS: "Bottoni in arrivo",
      BUTTONS: "Bottoni",
      LINK: "Link Promozione",
      SHOW_AFTER_REGISTER: "Vedi dopo registrazione",
      BADGE_ICON: "Icona badge",
      TYPE: "Tipologia promozione",
      SELECT_TYPE: "Seleziona tipologia",
      TOOLTIP_SKIN: "Selezionare la tipologia di skin",
      TOOLTIP_TITLE_PROMO: "Titolo visualizzato nella card",
      TOOLTIP_DESCRIPTION_PROMO:
        "Descrizione visualizzata nelle promo in arrivo e terminate",
      TOOLTIP_LINK_PROMO:
        "link di puntamento della promo sia assoluto che relativo",
      TOOLTIP_ICON_PROMO: "Selezionare il badge visibile dulle card",
      TOOLTIP_IMG_LANDSCAPE_DESKTOP:
        "Immagine visibile su in arrivo e terminate (desktop)",
      TOOLTIP_IMG_PORTRAIT_DESKTOP: "Immagine visibile su attive (desktop)",
      TOOLTIP_IMG_LANDSCAPE_MOBILE:
        "Immagine visibile su in arrivo e terminate (mobile)",
      TOOLTIP_IMG_PORTRAIT_MOBILE: "Immagine visibile su attive (mobile)",
      TOOLTIP_START_DATE: "Data di inzio promozione",
      TOOLTIP_END_DATE: "Data di fine promozione",
      TOOLTIP_START_HOUR: "Ora di inzio promozione",
      TOOLTIP_END_HOUR: "Ora di fine promozione",
      TOOLTIP_NOTE: "note aggiuntive o informazione per promo",
      TOOLTIP_JACKPOT: "montepremi per la promo",
      TOOLTIP_BUTTON_TEXT: "testo del bottone",
      TOOLTIP_BUTTON_CONTENT:
        "tipo di contenuto visibile sul click del bottone",
      TOOLTIP_BUTTON_LINK: "tipo di contenuto visibile sul click del bottone",
      TOOLTIP_BUTTON_FUNCTION: "funzione js del CMS (stili e script)",
      PROMOID: "Id promozione",
      TOOLTIP_PROMOID: "Id promozione",
      ISWELCOME: "Promozione di benvenuto",
      SORT_DESCRIPTION_PROMO: "Ordinamento",
      LANDSCAPE_DESCRIPTION_PROMO: "Portrait/Landscape (predefinito portrait)",
      LANDSCAPE_RATIO: "Orizzontale",
      PORTRAIT_RATIO: "Verticale",
    },
    MENUS: {
      TITLE: "Menu",
      NAME_COLUMN: "Nome",
      LINK: "Indirizzo",
      SORT: "Ordine",
      ISRIGHT: "A destra",
      AUTHENTICATED: "Autenticato",
      ICON: "Icona",
    },
    MENU: {
      NEW_TITLE: "Nuovo",
      EDIT_TITLE: "Modifica",
      SKIN: "Skin",
      LINK: "Indirizzo",
      NAME: "Nome",
      SORT: "Ordine",
      ISRIGHT: "A destra",
      AUTHENTICATED: "Autenticato",
      ICON: "Icona",
      PARENT: "Padre",
      ENABLED: "Abilitato",
    },
    MEDIAFOLDERS: {
      TITLE: "Crea Nuova Cartella",
      NOITEMS: "Nessuna cartella",
      EDITTITLE: "Modifica Cartella",
      NAME: "Nome cartella",
      ACTIONS_MOVE_FOLDER: "Sposta Cartella in...",
      ACTIONS_MOVE_ITEMS:
        'Sposta Media<span style="color: #efad1a">(*)</span> in...',
    },
    SKINS: {
      TITLE: "Skins Editor",
      ITEMS: "Skins",
      NAME: "Nome",
      GOOGLESEARCH: "Google Search Console Desktop",
      GOOGLETAG: "Google Tag Manager Desktop",
      GOOGLETAG_HEADSCRIPT: "Google Tag Manager Header Script",
      GOOGLETAG_BODYSCRIPT: "Google Tag Manager Body Script",
      GOOGLESEARCHMOBILE: "Google Search Console Mobile",
      GOOGLETAGMOBILE: "Google Tag Manager Mobile",
      ISMAINTENANCE: "Desktop Manutenzione",
      ISMOBILEMAINTENANCE: "Mobile Manutezione",
    },
    MOBILE: {
      TITLE: "Contenuti Mobile",
      NAME_COLUMN: "Nome",
      NAME: "Nome",
      SKIN: "Skin",
      PAGE: "Pagina",
      PUBLISHED_COLUMN: "Pubblicata",
      PUBLISHED_DATE_COLUMN: "Data pubblicazione",
      NEW_TITLE: "Nuovo",
      EDIT_TITLE: "Modifica contenuto mobile",
      SORT: "Ordinamento",
      ENABLED: "Abilitato",
      STARTDATE: "Inizio",
      TOOLTIP_START_DATE: "Inizio",
      TOOLTIP_END_DATE: "Fine",
      ENDDATE: "Fine",
    },
    NATIVEAPP: {
      TITLE: "Applicazioni native",
      NAME: "Piattaforma",
      LINK: "Link",
      RELEASEVERSION: "Versione",
      CURRENTFILE: "File corrente",
      MAINTENANCE: "Manutenzione",
      DISABLE_OUT_OF_ITALY: "Blocca App fuori dall' Italia",
    },
    GAMES: {
      TITLE: "Anagrafica giochi",
      NAME_COLUMN: "Nome",
      SEARCH_PROVIDER_LABEL: "Fornitore",
      PROVIDER_COLUMN: "Fornitore",
      ISENABLED_COLUMN: "Abilitato",
      ISVISIBLE_COLUMN: "Visibile",
      ISJACKPOT_COLUMN: "Jackpot",
      ISMOBILE_COLUMN: "Mobile",
      ISDESKTOP_COLUMN: "Desktop",
      ISDEMO_COLUMN: "Demo",
      ORDER_COLUMN: "Ordinamento",
      SYSTEMDATE_COLUMN: "Data modifica",
      SEARCH_GAMEID_LABEL: "GameId",
      THUMNBNAIL_COLUMN: "Thumbnail",
      SEARCH_ADM_LABEL: "Codice ADM",
      SEARCH_CHANNEL_LABEL: "Canale",
      SEARCH_JACKPOT_LABEL: "Jackpot",
      SEARCH_VISIBLE_LABEL: "Visibile",
      SEARCH_ENABLED_LABEL: "Abilitato",
      SEARCH_ISNEW_LABEL: "Nuovo",
      SEARCH_ROOM_LABEL: "Sala",
    },
    GAMESLIVE: {
      TITLE: "Anagrafica giochi live",
      NAME_COLUMN: "Nome",
      TABLETYPENAME_COLUMN: "Tavolo",
      SEARCH_PROVIDER_LABEL: "Fornitore",
      PROVIDER_COLUMN: "Fornitore",
      ISENABLED_COLUMN: "Abilitato",
      ISVISIBLE_COLUMN: "Visibile",
      ISJACKPOT_COLUMN: "Jackpot",
      ISMOBILE_COLUMN: "Mobile",
      ISDESKTOP_COLUMN: "Desktop",
      ISDEMO_COLUMN: "Demo",
      ORDER_COLUMN: "Ordinamento",
      SYSTEMDATE_COLUMN: "Data modifica",
      SEARCH_GAMEID_LABEL: "GameId",
    },
    GAME: {
      DETAILS: "Dettagli",
      ATTRIBUTES: "Attributi",
      NEW_TITLE: "Nuovo Gioco",
      EDIT_TITLE: "Modifica Gioco",
      NAME: "Nome",
      PROVIDER: "Fornitore",
      TOOLTIP_PROVIDER: "Fornitore",
      GAMEID: "Game id",
      FULLNAME: "Nome completo",
      UNIQUENAME: "Nome univoco",
      TITLE: "Titolo",
      NAMESEO: "Nome SEO",
      AAMSCODE: "Codice AAMS",
      AAMSGAMETYPE: "Tipo gioco AAMS",
      ISENABLED: "Abilitato",
      ISVISIBLE: "Visibile",
      ISJACKPOT: "Jackpot",
      ISDEMO: "Demo",
      ISDESKTOP: "Desktop",
      ISMOBILE: "Mobile",
      ORDER: "Ordinamento",
      FFP: "FFP",
      COEFFICIENTE: "Coefficiente",
      ISMULTIBET: "Multibet",
      HEIGHT: "Altezza",
      WIDTH: "Larghezza",
      RPT: "RPT",
      IMAGEBACKGROUND: "Immagine sfondo",
      IMAGEMINI: "Immagine mini",
      IMAGESPLASHDESKTOP: "Immagine Splash Desktop",
      IMAGEMINIPREVIEW: "Immagine Anteprima",
      IMAGEICONMOBILE: "Icona Mobile",
      IMAGESPLASHMOBILE: "Immagine Splash Mobile",
      HELP: "Aiuto",
      ISNEW: "Nuovo",
      SHORTDESCRIPTION: "Descrizione breve",
      LONGDESCRIPTION: "Descrizione completa",
      FAMILY: "Famiglia",
      ATTRIBUTE: "Attributo",
      ADDATTRIBUTE: "Nuovo attributo",
      DESKTOPORMOBILE: "Desktop/Mobile",
      NETENTGAMETYPE: "Netent Tipo Gioco",
      TOOLTIP_GAMETYPE: "Tipo gioco fornitore Netent",
      SELECTIMAGE: "Seleziona immagine",
      NETENTGAMEFAMILY: "Netent famiglia gioco",
      CAPECODEGAMETYPE: "Capecode tipo gioco",
      CAPECODECASH: "Capecode disponibile RealMoney",
      CAPECODEISFUN: "Capecode is fun",
      GREENTUBEGAMETYPE: "GreenTube tipo gioco",
      GREENTUBECASH: "GreenTube disponibile RealMoney",
      GREENTUBEISFUN: "GreenTube is fun",
      "SELECT-IMAGE": "Seleziona immagine",
      NOVOMATICGAMETYPE: "Novomatic tipo gioco",
      TAGKEYWORDS: "Tag parole chiave",
      TAGTDESCRIPTION: "Tag descrizione",
      TAGTITLE: "Tag titolo",
      ISFOREGROUND: "Primo piano",
      GOLIVEDATE: "Data lancio",
      ISITA: "Tavolo in Italiano",
      GROUPGAME: "Gruppo",
    },
    GAMELIVE: {
      NEW_TITLE: "Nuovo Gioco",
      EDIT_TITLE: "Modifica Gioco",
      DISPLAYNAME: "Nome",
      PROVIDER: "Fornitore",
      TOOLTIP_PROVIDER: "Fornitore",
      TABLENAME: "Nome Tavolo",
      TABLEID: "Id Tavolo",
      TABLETYPE: "Tipo Tavolo",
      TABLEVIRTUAL: "Tavolo Virtuale",
      TABLETYPENAME: "Nome Tipo Tavolo",
      URLSEO: "URL SEO",
      TABLEPIATTAFORMA: "Piattaforma Tavolo",
      TABLEOPEN: "Apertura",
      TABLCLOSE: "Chiusura",
      ORDER: "Ordinamento",
      ISOPEN: "Aperto",
      ISENABLED: "Abilitato",
      TIPOGIOCO: "Tipo Gioco",
      CODICEAAMS: "Codice AAMS",
      TABLEIMAGE: "Immagine",
      FPP: "FPP",
      RPT: "RPT",
      PAGEID: "Id Pagina",
      LOBBY: "Lobby",
      COEFICINTE: "Coeficiente",
      TAGKEYWORDS: "Tag parole chiave",
      TAGTDESCRIPTION: "Tag descrizione",
      TAGTITLE: "Tag titolo",
      ISFOREGROUND: "Primo piano",
      GOLIVEDATE: "Data lancio",
      ISITA: "Tavolo in Italiano",
      GROUPGAME: "Gruppo",
    },
    BETTINGGROUPS: {
      TITLE: "Gruppi Scommesse",
      NEW_TITLE: "Nuovo gruppo scommessa",
      EDIT_TITLE: "Modifica gruppo scommessa",
      NAME: "Nome gruppo",
      NAME_COLUMN: "Nome gruppo",
      SPORT_COLUMN: "Disciplina",
      ORDER_COLUMN: "Ordinamento",
      SYSTEMDATE_COLUMN: "Data modifica",
      ADDOUTCOME: "Aggiungi scommessa",
      SPORT: "Disciplina",
      SPORT_SELECT: "Seleziona discilina",
      OUTCOMES_TITLE: "Scommesse",
      OUTCOME_BET: "Scommessa",
      OUTCOME_ID: "Esito",
      SEARCH_SPORT_LABEL: "Disciplina",
      CREATEBETOUTCOME_TITLE: "Nuovo esito",
      BET_SELECT: "Seleziona scommessa",
      ORDER: "Ordinamento",
      BET_ORDER: "Ordinamento",
    },
    BETTINGSPORTS: {
      TITLE: "Discipline",
      NEW_TITLE: "Nuovo disciplina",
      EDIT_TITLE: "Modifica disciplina",
      SPORT: "Disciplina",
      DESCRIPTION: "Nome disciplina",
      DESKTOPIMAGE: "Immagine desktop",
      MOBILEIMAGE: "Immagine mobile",
      SEARCH_SPORT_LABEL: "Disciplina",
      BETTINGSPORTS: "Gruppi Scommesse",
      BETGROUPS_TITLE: "Gruppi Modello Scommesse",
      BET_GROUPENABLED: "Abilitato",
      GROUP_SELECT: "Seleziona Gruppo",
    },
    BONUS: {
      TITLE: "Bonus",
      NAME: "Nome",
      NAME_COLUMN: "Nome",
      DESCRIPTION: "Descrizione",
      SPECIAL_BONUS: "Bonus Speciale",
      CUMULATIVE: "Cumulativo",
      STARTDATE: "Inizio",
      ENDDATE: "Fine",
      NOTES: "Note",
      FAKE_MONEY: "Soldi Finti",
      BONUS_TYPE: "Tipo Bonus",
      GAME_CODE: "Codice Gioco",
      TRANCHE: "Tranche",
      BONUS: "Bonus",
      POINT_CODE: "Codice Punto",
      WAGERING: "Scommesse",
      DAYS: "Durata Giorni",
      CAP: "Cap",
      COMMENT: "Commento",
      NEW_TITLE: "Aggiungi Bonus",
      EDIT_TITLE: "Modifica Bonus",
      WEEK: "Numero settimane",
      ADD_RULES: "Aggiungi Regola",
      FROM: "Da",
      AT: "A",
      PRECENTAGE: "Precentuale",
      BETTING_TITLE: "Bonus Scommesse",
      MINIMMUMEVENTS: "Numero minimo eventi",
      MINIMUMODDVALUE: "Valore minimo quota",
      TOTALODDVALUE: "Valore totale quota",
      BETTING_BONUSTYPE: "Tipo bonus",
      BETTING_BONUSAMOUNT: "Abilitato con importo bonus",
      BETTING_SPORTS: "Discipline",
      SPORTINCLUSIONTYPE: "Incluso/escluso",
      BETTING_TOURNAMENTS: "Manifestazioni",
      BETTING_EVENTS: "Eventi",
      TOURNAMENTINCLUSIONTYPE: "Incluso/escluso",
      EVENTINCLUSIONTYPE: "Incluso/escluso",
      INCLUDEDSPORTS: "Discipline incluse",
      EXCLUDEDSPORTS: "Discipline escluse",
      INCLUDEDTOURMANETS: "Manifestazioni incluse",
      EXCLUDEDTOURNAMENTS: "Manifestazioni escluse",
      INCLUDEDEVENTS: "Eventi inclusi",
      EXCLUDEDEVENTS: "Eventi esclusi",
      ADDSPORT: "Includi disciplina",
      ADDTOURNAMENT: "Includi manifestazione",
      ADDEVENT: "Includi evento",
      EXCLUDESPORT: "Escludi disciplina",
      EXCLUDETOURNAMENT: "Escludi manifestazione",
      EXCLUDEEVENT: "Escludi evento",
      SPORTSELECTOR: "Seleziona disciplina",
      SPORTSELECTOR_NAMECOLUMN: "Disciplina",
      TOURNAMENTTSELECTOR: "Seleziona manifestazione",
      TOURNAMENTSELECTOR_NAMECOLUMN: "Manifestazione",
      EVENTSELECTOR: "Seleziona evento",
      EVENTSELECTOR_NAMECOLUMN: "Evento",
      MAX_BONUS: "Bonus Massimo",
      MIN_REFILL: "Ricarica Minima",
      ACTIVE: "Attivo",
      STARTDATE_COLUMN: "Data inizio",
      ENDDATE_COLUMN: "Data fine",
      SYSTEMDATE_COLUMN: "Data modifica",
      TRANCHE_SECTION_NOT_VALID: "Compilare correttamente sezione Tranche",
      IN_CONFIGURATION: "In Configurazione",
      PERCENTAGE_SECTION_NOT_VALID:
        "Compilare correttamente i campi relativi alla percentuale",
      BONUS_SECTION_NOT_VALID:
        "Compilare correttamente i campi relativi al bonus",
      FAKE_MONEY_SECTION_NOT_VALID:
        "Compilare correttamente i campi relativi alla sezione Soldi Finti",
      VIEW_TITLE: "Visualizza Bonus",
      EDIT: "Modifica",
      VIEW: "Visualizza",
      FILTER_TOURNAMENTS: "Cerca manifestazioni",
      FILTER_EVENTS: "Cerca avvenimenti",
      VERIFIED_ACCOUNT: "Conto Verificato",
      BONUSTYPE: "Tipo Bonus",
      STARTDATE_FILTER: "Data inizio",
      ENDDATE_FILTER: "Data fine",
      MINIMUMAMOUNT: "Importo minimo",
      BETTING_WINONLY: "Solo vincità",
    },
    PROMOTIONSPOINTCODE: {
      TITLE: "Promozioni",
      PROMO_CODE: "Codice Promozione",
      POINT_CODE: "Codice Punto",
      ACTIVE: "Attivo",
      NEW_TITLE: "Nuova Promozione",
      EDIT_TITLE: "Modifica Promozione",
      MULTIPLE_CODES: "Codici Multipli",
      MULTIPLE_BONUS: "Bonus Multipli",
      MAX_INSERT: "Numero Massimo Inserimenti",
      INSERT_COUNTER: "Contatore Inserimenti",
      STARTDATE_COLUMN: "Data inizio",
      ENDDATE_COLUMN: "Data fine",
      SYSTEMDATE_COLUMN: "Data modifica",
      STARTDATE: "Inizio",
      ENDDATE: "Fine",
      BONUS: "Bonus",
      POINT_CODE_SECTION_NOT_VALID: "Inserire un codice punto valido",
    },
  },
};
