/* tslint:disable:typedef */
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Injectable, NgZone } from "@angular/core";
import { UrlHelper } from "./urlhelper";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CasinoService {
  constructor(
    private urlHelper: UrlHelper,
    private http: HttpClient,
    public router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) {}
  mockVisible = new Subject();

  //=========================
  getGamesFromProviders() {
    const url = this.urlHelper.getUrl("/casino/list-games");
    return this.http.get<any>(url);
  }

  getGamesBySkin(skinId: string) {
    const url = this.urlHelper.getUrl("/casino/games?skinIds=" + skinId);
    return this.http.get<any>(url);
  }

  getGames(skinId: string, categoryId: string) {
    const url = this.urlHelper.getUrl(
      "/casino/skins/" + skinId + "/category/" + categoryId + "/games"
    );
    return this.http.get<any>(url);
  }

  addGame(req: any) {
    const url = this.urlHelper.getUrl("/casino/games/");
    return this.http.post<any>(url, req);
  }

  addGamesToCategory(
    // id: any, categoryId: any
    associations: any[]
  ) {
    const url = this.urlHelper.getUrl("/casino/game-category/");
    return this.http.post<any>(url, associations);
  }

  editGame(id: string, req: any) {
    const url = this.urlHelper.getUrl("/casino/games/" + id);
    return this.http.put<any>(url, req);
  }

  deleteGameAssociation(id: string, categoryId: string) {
    const url = this.urlHelper.getUrl(
      "/casino/categories/" + categoryId + "/game/" + id
    );
    return this.http.delete<any>(url);
  }

  deleteGame(id: string) {
    const url = this.urlHelper.getUrl("/casino/games/" + id);
    return this.http.delete<any>(url);
  }
  goToGames(
    skinId: string,
    groupId: string,
    groupName: string,
    categoryId: string,
    categoryName: string
  ) {
    const url = `game-pritech/${skinId}/${groupId}/${groupName}/${categoryId}/${categoryName}/games`;
    this.router.navigate([url]);
  }

  getOmegaGames() {
    return this.http.get<any>("https://int-ps.omegasys.eu/ps/ips/getGameInfo");
  }

  sortGames(categoryId: string, newGames: any) {
    const url = this.urlHelper.getUrl("/casino/game-categories/" + categoryId);
    return this.http.put<any>(url, newGames);
  }
  // ======================================================

  getCategoriesGroups(skinId: string) {
    const url = this.urlHelper.getUrl(
      "/casino/skins/" + skinId + "/category-groups"
    );
    return this.http.get<any>(url);
  }

  addCategoryGroup(req: any) {
    const url = this.urlHelper.getUrl("/casino/category-groups/");
    return this.http.post<any>(url, req);
  }

  deleteCategoryGroup(id: string) {
    const url = this.urlHelper.getUrl("/casino/category-proups/" + id);
    return this.http.delete(url);
  }

  editCategoryGroup(
    id: string,
    newName: string,
    newSkinId: string,
    willBeVisible: boolean,
    newIcon: string
  ) {
    const url = this.urlHelper.getUrl("/casino/category-groups/" + id);
    return this.http.put<any>(url, {
      name: newName,
      skinId: newSkinId,
      isVisible: willBeVisible,
      icon: newIcon,
    });
  }

  sortGroups(req: any) {
    const url = this.urlHelper.getUrl("/casino/category-groups");
    return this.http.put<any>(url, req);
  }
  // ======================================================

  getCategories(skinId: string, groupId: string) {
    const url = this.urlHelper.getUrl(
      "/casino/skins/" + skinId + "/groups/" + groupId + "/categories"
    );
    return this.http.get<any>(url);
  }

  goToCategories(skinId: string, groupId: string, groupName: string) {
    const url = `game-pritech/${skinId}/${groupId}/${groupName}/categories`;
    this.router.navigate([url]);
  }

  addCategory(req: any) {
    const url = this.urlHelper.getUrl("/casino/categories/");
    return this.http.post<any>(url, req);
  }

  deleteCategory(id: string) {
    const url = this.urlHelper.getUrl("/casino/categories/" + id);
    return this.http.delete(url);
  }

  editCategory(id: string, req: any) {
    const url = this.urlHelper.getUrl("/casino/categories/" + id);
    return this.http.put<any>(url, req);
  }

  //api/casino/category-group/{categoryGroupId}/categories
  sortCategories(groupId: string, newCategories: any) {
    const url = this.urlHelper.getUrl(
      "/casino/category-group/" + groupId + "/categories"
    );
    return this.http.put<any>(url, newCategories);
  }

  // ==============================================================

  getProviders(skinId: string) {
    const url = this.urlHelper.getUrl("/casino/skins/" + skinId + "/providers");
    return this.http.get<any>(url);
  }

  addProvider(req: any) {
    const url = this.urlHelper.getUrl("/casino/providers/");
    return this.http.post<any>(url, req);
  }

  editProvider(id: string, req: any) {
    const url = this.urlHelper.getUrl("/casino/providers/" + id);
    return this.http.put<any>(url, req);
  }

  deleteProvider(id: string) {
    const url = this.urlHelper.getUrl("/casino/providers/" + id);
    return this.http.delete(url);
  }

  // ==============================================================
  addSkinProvider(req: any) {
    const url = this.urlHelper.getUrl("/casino/skin-providers/");
    return this.http.post<any>(url, req);
  }

  editSkinProvider(skinId: string, providerId: string, req: any) {
    const url = this.urlHelper.getUrl(
      "/api/casino/skins/" + skinId + "/providers/" + providerId
    );
    return this.http.put<any>(url, req);
  }

  deleteSkinProvider(skinId: string, providerId: string) {
    const url = this.urlHelper.getUrl(
      "/api/casino/skins/" + skinId + "/providers/" + providerId
    );
    return this.http.delete(url);
  }

  // ==============================================================
  showMobileURL = false;
  showDesktopURL = false;
  showVideoURL = false;
  showBackgroundURL = false;
  showVerticalURL = false;
  showImageURL = false;
  currentlySelecting: string;
  desktopImageURL: string;
  mobileImageURL: string;
  videoURL: string;
  backgroundURL: string;
  verticalURL: string;
  imageURL: string;
  dialogHandler = new Subject();

  getMediaFromId(id: string) {
    const url = this.urlHelper.getUrl("/MediaService/GetMediaContent?id=" + id);
    return this.http.get(url);
  }
  // ==============================================================
  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, undefined, configuration));
  }

  dismiss() {
    this.zone.run(() => this.snackBar.dismiss());
  }

  success(message: string, duration: number = 3000) {
    this.show(message, {
      duration,
      panelClass: "success-notification-overlay",
    });
  }

  error(message: string, duration: number = 3000) {
    if (message) {
      this.show(message, {
        duration,
        panelClass: "error-notification-overlay",
      });
    }
  }

  selectedMediaFolder: string;
}
