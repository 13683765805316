export class HelpModelItemEdit {
    constructor(id: number, value: string, display: string, skinId: string) {
        this.id = id;
        this.value = value;
        this.display = display;
        this.skinId = skinId;
    }

    id: number;
    value: string;
    display: string;
    skinId: string;
}

export class HelpItem {
    parentId: number;
    display: string;
    value: string;
}