import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GetPromoRequest} from '../../models/DataModels/Cms/Request';
import {Observable, Subscription} from 'rxjs';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {ApisService} from 'src/app/shared/apis.service';
import {NotifyService} from 'src/app/shared/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {GetItemsBaseResponse, PromotionsPointCodeModel, UserAccess} from '../../models/DataModels';
import {DataBaseRequestField, ModelRequest} from '../../models/DataModels/TableDataSource/ModelRequest';
import {HttpDao} from '../../models/DataModels/TableDataSource/HttpDao';
import {FuseConfirmDialogComponent} from '../../core/components/confirm-dialog/confirm-dialog.component';
import {fuseAnimations} from '../../core/animations';
import {map} from 'rxjs/operators';

@Component({
    selector: 'promo',
    templateUrl: './promo.component.html',
    styleUrls: ['./promo.component.scss'],
    animations: fuseAnimations
})
export class PromoComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    panelTitle = 'PROMOTIONSPOINTCODE.TITLE';
    dataSource: TableDataSource<GetPromoRequest, GetItemsBaseResponse<PromotionsPointCodeModel>>;
    displayedColumns = ['action', 'promoCode', 'pointCode', 'attivo', 'startDate', 'endDate', 'systemDate'];
    model: ModelRequest<GetPromoRequest>;

    protected request: GetPromoRequest = new GetPromoRequest();

    private httpDao: PromotionHttpDao;
    private firstLoad: Subscription;

    constructor(
        @Inject(AppConfigService) private appConfig: AppConfigService,
        @Inject(NotifyService) private notifyService,
        private translateService: TranslateService,
        public dialog: MatDialog,
        private apisService: ApisService,
        private route: ActivatedRoute,
        private router: Router,
        private cd : ChangeDetectorRef
    ) {
        this.request.includeAccess = true;

    }

    ngOnInit() {
        this.httpDao = new PromotionHttpDao(this.apisService);
        this.firstLoad = this.httpDao.firstLoad.subscribe((userAccess: UserAccess) => {
            this.request.includeAccess = false;
            // this.userAccess = userAccess;
            this.model.updateShowAdd(this.userAccess.canInsert);
        });
        this.model = new ModelRequest<GetPromoRequest>(this.request);
        this.model.showAddItem = true;

        let tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(new DataBaseRequestField('promoCode', 'PROMOTIONSPOINTCODE.PROMO_CODE', 'string'),
            new DataBaseRequestField('startDate', 'COMMON.SEARCH_STARTDATE_LABEL', 'date'),
            new DataBaseRequestField('endDate', 'COMMON.SEARCH_ENDDATE_LABEL', 'date'));
        this.model.items = tmpItems;


    }

    ngAfterViewInit() {
        this.dataSource = new TableDataSource<GetPromoRequest, GetItemsBaseResponse<PromotionsPointCodeModel>>(this.httpDao!, this.paginator, this.sort, this.model);
        this.cd.detectChanges();
    }


    ngOnDestroy() {
        this.dataSource.disconnect();
        if (this.firstLoad) {
            this.firstLoad.unsubscribe();
            this.firstLoad = undefined;
        }
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    edit(item?: PromotionsPointCodeModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canEdit) {
            return;
        }
        this.appConfig.temporaryData = {'internal': true};
        if (item !== undefined) {
            this.appConfig._temporaryData = {
                internal: true,
                data: this.request
            };
            this.router.navigate(['item', item.id], {
                relativeTo: this.route
            });
        }

    }

    add(): void {
        if (!this.userAccess.canInsert) {
            return;
        }
        this.router.navigate(['add'], {
            relativeTo: this.route
        });
    }

    delete(item?: PromotionsPointCodeModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canDelete || item === undefined) {
            return;
        }
        this.translateService.get('COMMON.DELETEMESSAGE').subscribe(translate => {
            translate += item.promoCode + '?';
            let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {

                    this.dataSource.isLoadingResults = true;
                    this.apisService.deletePromo(item.id).subscribe(result => {
                        this.dataSource.isLoadingResults = false;
                        this.notifyService.handleServerResponse(result, res => {
                            this.model.reset();
                        });
                    });
                }
            });
        });
    }
}


export class PromotionHttpDao extends HttpDao<GetPromoRequest, GetItemsBaseResponse<PromotionsPointCodeModel>> {
    constructor(public apisService: ApisService) {
        super(apisService);
    }

    public getData(request: GetPromoRequest): Observable<GetItemsBaseResponse<PromotionsPointCodeModel>> {
        return this.apisService.getPromos(request)
            .pipe(map(res => {
                if (res.isError) {
                    return <GetItemsBaseResponse<PromotionsPointCodeModel>> {
                        total: 0,
                        items: []
                    };
                } else {
                    if (request.includeAccess && res.data.userAccess) {
                        this.firstLoad.next(res.data.userAccess);
                    }
                    return res.data;
                }
            }));
    }
}
