import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ApisService } from 'src/app/shared/apis.service';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/shared/notify.service';
import { HelpTreeItem } from '../../models/DataModels/Help/Response';
import { fuseAnimations } from '../../core/animations';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserAccess } from '../../models/DataModels';

@Component({
    selector: 'select-sections',
    templateUrl: './select.section.component.html',
    styleUrls: ['./sections.component.scss'],
    animations: fuseAnimations
})

// tslint:disable-next-line:component-class-suffix
export class SelectSections implements OnInit, OnDestroy {

    itemSelected = false;
    isLoading = false;
    items: HelpTreeItem[];
    itemsSelected = new Array<HelpTreeItem>();
    itemId?: number;
    skinId: string;

    public userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    private _subscribers: Subscription[];
    private _tmpItems: HelpTreeItem[];

    constructor(
        @Inject(NotifyService) private notifyService,
        public dialog: MatDialog,
        private apisService: ApisService,
        @Inject(MAT_DIALOG_DATA) public input: any,
        public dialogRef: MatDialogRef<SelectSections>
    ) {
        this._subscribers = new Array<Subscription>();
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.apisService.getTree()
            .subscribe(result => {
                this.notifyService.handleServerResponse(result, (res) => {
                    // this.userAccess = result.userAccess;
                    this.items = res.items;
                    this._tmpItems = res.items;
                    this.itemsSelected.push(new HelpTreeItem(this._tmpItems));
                    this.isLoading = false;
                });
            });
    }

    ngOnDestroy(): void {
        this._subscribers.forEach(t => t.unsubscribe());
    }

    loadItems(): void {
        this.isLoading = true;
        this.apisService.getTree()
            .subscribe(result => {
                this.notifyService.handleServerResponse(result, (res) => {
                    this._tmpItems = res.items;
                    let itemsQuery = res.items;
                    this.itemsSelected[this.itemsSelected.length - 1].items = itemsQuery;
                    this.items = itemsQuery;
                    this.itemSelected = false;
                    this.isLoading = false;
                });
            });
    }

    selectItem(item: HelpTreeItem): void {
        this.itemId = item.id;
        this.items = item.items;
        this.itemSelected = false;
        this.items.forEach(item => item.selected = false);
        let itemFound = this.itemsSelected.find(elem => elem.id === item.id);
        if (itemFound) {
            let index = this.itemsSelected.findIndex(elem => elem.id === item.id);
            for (let i = ++index; i < this.itemsSelected.length; i++) {
                this.itemsSelected[i] = undefined;
            }
            this.itemsSelected = this.itemsSelected.filter(elem => elem !== undefined);
            this.items = index > 0 ? this.itemsSelected[--index].items : this._tmpItems;
        } else {
            this.itemsSelected.push(item);
        }
    }

    getItem(item: HelpTreeItem, $event: any): void {
        $event.preventDefault();
        if (item !== undefined) {
            this.itemId = item.id;
            let index = this.itemsSelected.findIndex(elem => elem.parentId === item.parentId);
            if (index > 0) {
                this.itemsSelected[index] = item;
            } else {
                this.itemsSelected.push(item);
            }
            if ((item.items && item.items.length > 0) /*|| item.parentId === null*/) {
                this.selectItem(item);
                this.itemSelected = true;
            } else {
                this.items.forEach(item => item.selected = false);
                item.selected = true;
            }
        }
    }

    select(id: string, $event: any) {
        $event.preventDefault();
        this.dialogRef.close({
            result: true,
            id: id
        });
    }
}
