import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GetLayoutsRequest, SaveLayoutRequest} from '../../models/DataModels/Cms/Request';
import {Observable, Subscription} from 'rxjs';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {ApisService} from 'src/app/shared/apis.service';
import {NotifyService} from 'src/app/shared/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {GetItemsBaseResponse, LayoutModel, UserAccess} from '../../models/DataModels';
import {DataBaseRequestField, ModelRequest} from '../../models/DataModels/TableDataSource/ModelRequest';
import {HttpDao} from '../../models/DataModels/TableDataSource/HttpDao';
import {FuseConfirmDialogComponent} from '../../core/components/confirm-dialog/confirm-dialog.component';
import {fuseAnimations} from '../../core/animations';
import {map} from 'rxjs/operators';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    animations: fuseAnimations
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    public userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    public panelTitle = 'LAYOUTS.TITLE';
    protected request = new GetLayoutsRequest();
    public dataSource: TableDataSource<GetLayoutsRequest, GetItemsBaseResponse<LayoutModel>>;
    public displayedColumns = ['action', 'name', 'changedBy', 'changedAt'];
    public model: ModelRequest<GetLayoutsRequest>;
    private httpDao: LayoutHttpDao;
    private firstLoad: Subscription;


    constructor(
        @Inject(AppConfigService) private appConfig,
        @Inject(NotifyService) private notifyService,
        private translateService: TranslateService,
        public dialog: MatDialog,
        private apisService: ApisService,
        private route: ActivatedRoute,
        private router: Router,
        private cd : ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.httpDao = new LayoutHttpDao(this.apisService);
        this.firstLoad = this.httpDao.firstLoad.subscribe((userAccess: UserAccess) => {
            this.request.includeAccess = false;
            // this.userAccess = userAccess;
            this.model.updateShowAdd(this.userAccess.canInsert);
        });
        this.model = new ModelRequest<GetLayoutsRequest>(this.request);
        this.model.showAddItem = true;

        let tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string')
        );
        this.model.items = tmpItems;

    }

    ngAfterViewInit() {
        this.dataSource = new TableDataSource<GetLayoutsRequest, GetItemsBaseResponse<LayoutModel>>(this.httpDao!, this.paginator, this.sort, this.model);
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        this.dataSource.disconnect();
        if (this.firstLoad) {
            this.firstLoad.unsubscribe();
            this.firstLoad = undefined;
        }
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    edit(item?: LayoutModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (item !== undefined) {
            this.router.navigate(['layout', item.id], {
                relativeTo: this.route
            });
        }
    }

    add(): void {
        this.router.navigate(['addlayout'], {
            relativeTo: this.route
        });
    }

    delete(item?: LayoutModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (item === undefined) {
            return;
        }
        this.translateService.get('COMMON.DELETEMESSAGE').subscribe(translate => {
            translate += `${item.name}?`;
            const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {
                    this.dataSource.isLoadingResults = true;
                    this.apisService.deleteLayout(item.id).subscribe(result => {
                        this.dataSource.isLoadingResults = false;
                        this.notifyService.handleServerResponse(result, res => {
                            this.model.reset();
                        });
                    });
                }
            });
        });
    }

    clone(item?: LayoutModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (item === undefined) {
            return;
        }
        this.dataSource.isLoadingResults = true;
        const request = new SaveLayoutRequest();
        request.id = item.id;
        request.copy = true;
        this.apisService.saveLayout(request).subscribe(response => {
            this.dataSource.isLoadingResults = false;
            this.notifyService.handleServerResponse(response, result => {
                this.model.reset();
            });
        });
    }
}

export class LayoutHttpDao extends HttpDao<GetLayoutsRequest, GetItemsBaseResponse<LayoutModel>> {
    constructor(public apisService: ApisService) {
        super(apisService);
    }

    public getData(request: GetLayoutsRequest): Observable<GetItemsBaseResponse<LayoutModel>> {
        return this.apisService.getLayoutsData(request)
            .pipe(map(res => {
                if (res.isError) {
                    return <GetItemsBaseResponse<LayoutModel>> {
                        total: 0,
                        items: []
                    };
                } else {
                    if (request.includeAccess && res.data.userAccess) {
                        this.firstLoad.next(res.data.userAccess);
                    }
                    this.itemsChangedAt(res.data);
                    return res.data;
                }
            }));
    }
}


