<div class="dialog-container">
  <div class="page-layout carded fullwidth ps dialog" fuseperfectscrollbar>
    <div class="dialog-modal">
      <div id="header">
        <div class="header p-sm-24">
          <div class="header-content" fxLayoutAlign="space-between">
            <div
              class="header-top"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayout.xs="column"
            >
              <div
                class="logo mb-16 mb-sm-0"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <button
                  mat-button
                  class="mat-icon-button"
                  *fuseIfOnDom
                  aria-label="close"
                  (click)="onNoClick()"
                >
                  <mat-icon
                    class="logo-icon"
                    *fuseIfOnDom
                    [@animate]="{
                      value: '*',
                      params: { delay: '50ms', scale: '0.2' }
                    }"
                    >close</mat-icon
                  >
                </button>
                <mat-icon
                  class="logo-icon"
                  *fuseIfOnDom
                  [@animate]="{
                    value: '*',
                    params: { delay: '50ms', scale: '0.2' }
                  }"
                  >image</mat-icon
                >
                <span
                  class="logo-text"
                  *fuseIfOnDom
                  [@animate]="{
                    value: '*',
                    params: { delay: '100ms', x: '-25px' }
                  }"
                  >{{
                    (isEdit ? "MEDIA.DIALOG_EDIT" : "MEDIA.DIALOG_ADD")
                      | translate
                  }}</span
                >
                <div
                  class="toolbar"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  *ngIf="currentFilePath === undefined"
                >
                  <button
                    mat-button
                    class="mat-icon-button expander"
                    *fuseIfOnDom
                    aria-label="Filter"
                    (click)="sendFile()"
                    matTooltip="{{ 'COMMON.SAVE' | translate }}"
                  >
                    <mat-icon>save</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container" *ngIf="currentFilePath === undefined">
        <div #uploadForm>
          <div
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayout.gt-md="row"
          >
            <div
              class="full-width px-24 pt-24"
              fxLayout="column"
              fxLayoutAlign="start"
              fxFlex="1 0 auto"
            >
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 100">
                <!-- <mat-form-field fxFlex="50">
                  <mat-select
                    placeholder="{{ 'MEDIA.FILE_FRONTEND' | translate }}"
                    matTooltip="{{ 'MEDIA.TOOLTIP_PRODUCT' | translate }}"
                    [(ngModel)]="uploadMediaRequest.frontend"
                  >
                    <mat-option
                      *ngFor="let frontend of frontends"
                      [value]="frontend.id"
                      (click)="onFrontendChanged(frontend)"
                    >
                      {{ frontend.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
                <mat-form-field fxFlex="50">
                  <input
                    matInput
                    placeholder="{{ 'MEDIA.FILE_NAME' | translate }}"
                    matTooltip="{{ 'MEDIA.TOOLTIP_NAME' | translate }}"
                    name="mediaNameField"
                    [(ngModel)]="uploadMediaRequest.name"
                  />
                </mat-form-field>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
              >
                <mat-form-field fxFlex="50">
                  <input
                    matInput
                    placeholder="{{ 'MEDIA.FILE_DESCRIPTION' | translate }}"
                    matTooltip="{{ 'MEDIA.TOOLTIP_DESCRIPTION' | translate }}"
                    name="mediaDescriptionField"
                    [(ngModel)]="uploadMediaRequest.description"
                  />
                </mat-form-field>
                <mat-form-field fxFlex="50">
                  <input
                    matInput
                    placeholder="{{ 'MEDIA.ALT' | translate }}"
                    matTooltip="{{ 'MEDIA.TOOLTIP_ALT_MEDIA' | translate }}"
                    name="mediaAltField"
                    [(ngModel)]="uploadMediaRequest.alt"
                  />
                </mat-form-field>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="1 0 auto"
                *ngIf="!uploadMediaRequest.isDocument"
              >
                <mat-form-field
                  fxFlex="15"
                  class="image-ratio"
                  *ngIf="uploadMediaRequest.width && uploadMediaRequest.height"
                >
                  <input
                    matInput
                    placeholder="{{ 'MEDIA.FILE_WIDTH' | translate }}"
                    name="width"
                    [(ngModel)]="uploadMediaRequest.width"
                    [readOnly]="true"
                  />
                </mat-form-field>
                <mat-form-field
                  fxFlex="15"
                  class="image-ratio"
                  *ngIf="uploadMediaRequest.width && uploadMediaRequest.height"
                >
                  <input
                    matInput
                    placeholder="{{ 'MEDIA.FILE_HEIGHT' | translate }}"
                    name="height"
                    [(ngModel)]="uploadMediaRequest.height"
                    [readOnly]="true"
                  />
                </mat-form-field>
              </div>

              <div *ngFor="let item of filterItems()">
                <div
                  class="media-item-row"
                  fxLayout="row"
                  fxLayoutAlign="start stretch"
                  fxFlex="100"
                >
                  <mat-form-field fxFlex="40">
                    <input
                      matInput
                      class="label-disabled"
                      [name]="item.language"
                      [value]="item.language"
                      disabled
                    />
                  </mat-form-field>

                  <input
                    id="fileInput"
                    name="fileInput"
                    type="file"
                    #fileInput
                    (change)="fileChange($event, item, fileSelected)"
                  />

                  <mat-form-field fxFlex="40" class="content-selection-input">
                    <input
                      matInput
                      placeholder="{{
                        (fileSelected.value
                          ? 'MEDIA.FILE_INPUT_SELECTED'
                          : 'MEDIA.FILE_INPUT'
                        ) | translate
                      }}"
                      name="fileSelected"
                      disabled
                      value=""
                      #fileSelected
                    />
                    <button mat-button fxFlex="0 0 10%" class="mat-icon-button">
                      <span class="custom-icon-container">
                        <mat-icon
                          class="material-icons mat-icon"
                          role="img"
                          aria-hidden="true"
                          (click)="view(item)"
                          *ngIf="hasFile(item)"
                          >remove_red_eye</mat-icon
                        >
                      </span>
                    </button>
                    <button
                      mat-button
                      fxFlex="0 0 10%"
                      class="mat-icon-button"
                      name="filePickerOpen"
                      (click)="fileInput.click()"
                    >
                      <mat-icon
                        class="material-icons mat-icon"
                        role="img"
                        aria-hidden="true"
                      >
                        settings_applications</mat-icon
                      >

                      <div
                        class="
                          mat-button-ripple mat-ripple mat-button-ripple-round
                        "
                      ></div>
                      <div class="mat-button-focus-overlay"></div>
                    </button>
                    <button
                      mat-button
                      fxFlex="0 0 10%"
                      class="mat-icon-button"
                      name="delete"
                      (click)="removeItem(item)"
                    >
                      <span class="custom-icon-container">
                        <mat-icon
                          class="material-icons mat-icon"
                          role="img"
                          aria-hidden="true"
                        >
                          delete</mat-icon
                        >
                      </span>
                      <div
                        class="
                          mat-button-ripple mat-ripple mat-button-ripple-round
                        "
                      ></div>
                      <div class="mat-button-focus-overlay"></div>
                    </button>
                  </mat-form-field>
                  <img
                    [attr.src]="newFilePreview"
                    class="big-preview-img"
                    alt=""
                  />
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 100"
                    *ngIf="item.publicUrl"
                  >
                    <mat-form-field fxFlex="90">
                      <input
                        #publicUrlElement
                        matInput
                        placeholder="{{ 'MEDIA.FILE_PUBLICURL' | translate }}"
                        name="publicUrl"
                        [(ngModel)]="item.publicUrl"
                        [readOnly]="true"
                      />
                    </mat-form-field>
                    <button mat-button fxFlex="10" class="mat-icon-button">
                      <span>
                        <mat-icon (click)="copy(publicUrlElement)">
                          filter_none
                        </mat-icon>
                      </span>
                    </button>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 100"
                    *ngIf="item.fileUrl"
                  >
                    <mat-form-field fxFlex="90">
                      <input
                        #cdnUrlElement
                        matInput
                        placeholder="{{ 'MEDIA.FILE_CDNURL' | translate }}"
                        name="fileUrl"
                        [(ngModel)]="item.fileUrl"
                        [readOnly]="true"
                      />
                    </mat-form-field>
                    <button mat-button fxFlex="10" class="mat-icon-button">
                      <span>
                        <mat-icon (click)="copy(cdnUrlElement)">
                          filter_none
                        </mat-icon>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                fxLayout="column"
                fxFlex="1 0 auto"
                *ngIf="languagesSelect.length > 0"
              >
                <h4 class="description-subtitle">
                  {{ "MEDIA.LANGUAGE_SELECTOR" | translate }}
                </h4>

                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="1 0 auto"
                >
                  <mat-form-field fxFlex="50">
                    <mat-select
                      placeholder="{{ 'MEDIA.FILE_LANGUAGE' | translate }}"
                      matTooltip="{{ 'MEDIA.TOOLTIP_LANGUAGE' | translate }}"
                    >
                      <mat-option
                        *ngFor="let language of languagesSelect"
                        [value]="language"
                        (click)="changeLanguage(language)"
                      >
                        {{ language.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="media-loading-shade" *ngIf="isBusy">
            <mat-spinner [color]="'accent'"></mat-spinner>
          </div>
        </div>
      </div>
    </div>

    <div class="image-preview-container" *ngIf="currentFilePath !== undefined">
      <div class="options-icon isVisible go-back">
        <button
          mat-button
          class="mat-icon-button"
          name="closeview"
          aria-label="Close"
          (click)="closeview()"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <img [src]="currentFilePath" />
    </div>
  </div>
</div>
