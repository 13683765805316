import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { AppConfigService } from "src/app/shared/appconfig.service";
import { NotifyService } from "src/app/shared/notify.service";
import { ApisService } from "src/app/shared/apis.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { fuseAnimations } from "../../core/animations";
import { SaveCmhHtmlRequest } from "../../models/DataModels/Cms/Request";
import { BaseEditorComponent } from "../../components/baseeditor.component";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { BaseModel } from "../../models/DataModels";
import { FuseConfirmDialogComponent } from "../../core/components/confirm-dialog/confirm-dialog.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-styleeditor",
  templateUrl: "./styleeditor.component.html",
  styleUrls: ["./styleeditor.component.scss"],
  animations: fuseAnimations,
})
export class StyleEditorComponent
  extends BaseEditorComponent
  implements OnInit, OnDestroy
{
  subscribers: Subscription[];
  skins: BaseModel[];
  skinId: string;
  currentSkinId: string;
  pageForm: FormGroup;
  formErrors: any;
  isLoading = false;

  cssEditor = {
    touched: false,
    theme: "vs-dark",
    codeModel: {
      language: "css",
      uri: "styles-editor.css",
      value: "",
    },
    options: {
      contextmenu: true,
      minimap: {
        enabled: true,
      },
    },
  };
  onCssChange(value) {
    console.log({ css: value });
    this.pageForm.patchValue({ style: value });
    this.cssEditor.touched = true;
  }
  scriptEditor = {
    touched: false,
    theme: "vs-dark",
    codeModel: {
      language: "js",
      uri: "script-editor.js",
      value: "",
    },
    options: {
      contextmenu: true,
      minimap: {
        enabled: true,
      },
    },
  };
  onJsChange(value) {
    console.log({ script: value });
    this.pageForm.patchValue({ script: value });
    this.scriptEditor.touched = true;
  }

  constructor(
    protected translateService: TranslateService,
    @Inject(NotifyService) protected notifyService,
    protected dialog: MatDialog,
    @Inject(AppConfigService) private appConfig,
    private location: Location,
    private router: Router,
    private apisService: ApisService,
    private formBuilder: FormBuilder
  ) {
    super();
    this.subscribers = new Array<Subscription>();
    this.formErrors = {
      skinId: {},
      style: {},
      script: {},
    };
  }

  ngOnInit() {
    this.createForm();
    this.loadPage();
  }

  onFormValuesChanged(data) {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  ngOnDestroy() {
    this.subscribers.forEach((t) => t.unsubscribe());
  }

  onSkinChanged() {
    if (this.skinId === this.currentSkinId) return;
    if (this.pageForm.touched && this.pageForm.dirty) {
      this.translateService.get("COMMON.CANCELEDIT").subscribe((translate) => {
        let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          width: "50%",
        });
        dialogRef.componentInstance.confirmMessage = translate;
        dialogRef.afterClosed().subscribe((confirm) => {
          if (confirm === true) {
            this.currentSkinId = this.skinId;
            this.pageForm.reset();
            this.loadPage();
          } else {
            this.pageForm.patchValue({
              skinId: this.currentSkinId,
            });
          }
        });
      });
    } else {
      this.currentSkinId = this.skinId;
      this.pageForm.reset();
      this.loadPage();
    }
  }

  loadPage(): void {
    this.isLoading = true;
    this.subscribers.push(
      this.apisService.getStyle(this.currentSkinId).subscribe((response) => {
        this.isLoading = false;
        this.notifyService.handleServerResponse(response, (result) => {
          this.id = result.id;
          this.skinId = result.skinId;
          if (this.skins === undefined && result.skins) {
            this.skins = result.skins;
          }
          this.cssEditor.codeModel.value = result.style;
          this.scriptEditor.codeModel.value = result.script;
          this.pageForm.patchValue(result);
          this.pageForm.markAsPristine();
          this.pageForm.markAsUntouched();
        });
      })
    );
  }

  save(publish?: boolean) {
    if (
      this.pageForm.dirty ||
      this.cssEditor.touched ||
      this.scriptEditor.touched
    ) {
      if (!this.pageForm.invalid) {
        this.cssEditor.touched = false;
        this.scriptEditor.touched = false;
        this.isLoading = true;
        let request = <SaveCmhHtmlRequest>{
          publish: publish,
        };
        Object.assign(request, this.pageForm.value);
        this.subscribers.push(
          this.apisService.saveStyle(request).subscribe((response) => {
            this.isLoading = false;
            this.notifyService.handleServerResponse(response, (result) => {
              this.pageForm.markAsPristine();
              // this.internalClose();
            });
          })
        );
      } else {
        this.notifyService.warning("COMMON.INVALIDDATA");
      }
    } else {
      // this.internalClose();
    }
  }

  close(): void {
    let data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(["cms/pages"]);
    }
  }

  private createForm() {
    this.pageForm = this.formBuilder.group({
      style: [""],
      script: [""],
      skinId: ["", Validators.required],
      version: [],
    });

    this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    });
  }
}
