<div class="page-layout carded fullwidth ps">
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div
                    class="header-content"
                    fxLayout="column"
                    fxLayoutAlign="space-between"
            >
                <div
                        class="header-top"
                        fxLayout="row"
                        fxLayoutAlign="stretch"
                        fxLayout.xs="column"
                >
                    <div
                            class="logo mb-16 mb-sm-0"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                    >
                        <div class="options-icon isVisible">
                            <button
                                    mat-button
                                    class="mat-icon-button close"
                                    [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }"
                                    *fuseIfOnDom
                                    aria-label="Close"
                                    matTooltip="{{ 'COMMON.CLOSE' | translate }}"
                                    (click)="close()"
                            >
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span
                                class="logo-text"
                                *fuseIfOnDom
                                [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' }
              }"
                        >{{
                            (isNew ? "PROMO.NEW_TITLE" : "PROMO.EDIT_TITLE") | translate
                            }}</span
                        >
                    </div>
                    <div
                            class="toolbar"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            *ngIf="userAccess.canEdit"
                    >
                        <div class="options-icon isVisible">
                            <button
                                    mat-button
                                    class="mat-icon-button"
                                    [ngClass]="{ disabled: !pageForm.touched || pageForm.invalid }"
                                    [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }"
                                    [disabled]="!pageForm.touched || pageForm.invalid"
                                    (click)="save()"
                                    *fuseIfOnDom
                                    aria-label="Save"
                                    matTooltip="{{ 'COMMON.SAVE' | translate }}"
                            >
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <div class="options-icon isVisible">
                            <button
                                    mat-button
                                    class="mat-icon-button"
                                    [ngClass]="{ disabled: pageForm.invalid }"
                                    [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }"
                                    [disabled]="pageForm.invalid"
                                    (click)="publish()"
                                    *fuseIfOnDom
                                    aria-label="Save"
                                    matTooltip="{{ 'COMMON.PUBLISH' | translate }}"
                            >
                                <mat-icon>publish</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div class="page-form" fxLayout="column">
                <div
                        name="pageForm"
                        [formGroup]="pageForm"
                        fxLayout="column"
                        fxFlex="1 0 auto"
                >
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="50">
                            <mat-select
                                    matInput
                                    placeholder="{{ 'PROMO.SKIN' | translate }}"
                                    matTooltip="{{ 'PROMO.TOOLTIP_SKIN' | translate }}"
                                    [(ngModel)]="skinId"
                                    formControlName="skinId"
                                    (selectionChange)="onSkinChanged()"
                            >
                                <mat-option *ngFor="let skin of skins" [value]="skin.id">
                                    {{ skin.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formErrors.skinId.required">
                                {{ "COMMON.REQUIRED_ERROR" | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input
                                    matInput
                                    placeholder="{{ 'PROMO.NAME' | translate }}"
                                    matTooltip="{{ 'PROMO.TOOLTIP_TITLE_PROMO' | translate }}"
                                    formControlName="name"
                            />
                            <mat-error *ngIf="formErrors.name.required">
                                {{ "COMMON.REQUIRED_ERROR" | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="50">
                            <input
                                    matInput
                                    placeholder="{{ 'PROMO.SHORTDETAILS' | translate }}"
                                    matTooltip="{{ 'PROMO.TOOLTIP_DESCRIPTION_PROMO' | translate }}"
                                    formControlName="shortDetails"
                            />
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input
                                    matInput
                                    placeholder="{{ 'PROMO.LINK' | translate }}"
                                    matTooltip="{{ 'PROMO.TOOLTIP_LINK_PROMO' | translate }}"
                                    formControlName="link"
                            />
                            <mat-error *ngIf="formErrors.link.required">
                                {{ "COMMON.REQUIRED_ERROR" | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            class="promo-form-item-row"
                    >
                        <div fxFlex="100">
                            <h2 class="description-title">
                                {{ "PROMO.PLATFORM" | translate }}
                            </h2>
                            <mat-checkbox
                                    matInput
                                    *ngFor="let p of platforms"
                                    [(checked)]="p.selected"
                                    (change)="onPlatformChange(p, $event.checked)"
                            >{{ p.value }}</mat-checkbox
                            >
                        </div>
                    </div>
                    <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            class="promo-form-item-row"
                    >
                        <div fxFlex="100">
                            <h2 class="description-title">
                                {{ "PROMO.PRODUCTS" | translate }}
                            </h2>
                            <mat-checkbox
                                    matInput
                                    *ngFor="let f of frontends"
                                    [(checked)]="f.selected"
                                    (change)="onFrontendChange(f, $event.checked)"
                            >{{ f.name }}</mat-checkbox
                            >
                        </div>
                    </div>
                    <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            class="promo-form-item-row"
                    >
                        <div fxFlex="50">
                            <h2 class="description-title">{{ "PROMO.TYPE" | translate }}</h2>
                            <mat-form-field class="badge-icon-select">
                                <mat-select
                                        matInput
                                        placeholder="{{ 'PROMO.SELECT_TYPE' | translate }}"
                                        matTooltip="{{ 'PROMO.TOOLTIP_ICON_PROMO' | translate }}"
                                        [(ngModel)]="icon"
                                        formControlName="icon"
                                >
                                    <mat-option *ngFor="let icon of icons" [value]="icon.id">
                                        {{ icon.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="25">
                            <h2 class="description-title">{{ "PROMO.SORT" | translate }}</h2>
                            <mat-form-field>
                                <input
                                        type="number"
                                        matInput
                                        placeholder="{{ 'PROMO.SORT' | translate }}"
                                        matTooltip="{{ 'PROMO.SORT_DESCRIPTION_PROMO' | translate }}"
                                        formControlName="sort"
                                />
                            </mat-form-field>
                        </div>
                        <div fxFlex="25">
                            <h2 class="description-title">
                                {{ "PROMO.LANDSCAPE" | translate }}
                            </h2>
                            <mat-checkbox
                                    matInput
                                    formControlName="landscape"
                                    (click)="enableDateOrTime(0)"
                                    matTooltip="{{
                  'PROMO.LANDSCAPE_DESCRIPTION_PROMO' | translate
                }}"
                            >
                                {{ "PROMO.LANDSCAPE" | translate }}</mat-checkbox
                            >
                        </div>
                    </div>
                    <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            class="promo-form-item-row"
                    >
                        <div fxFlex="100">
                            <h2 class="description-title">
                                {{ "PROMO.DESKTOPIMAGE" | translate }}
                            </h2>
                        </div>
                    </div>
                    <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            class="promo-form-item-row"
                    >
                        <div fxFlex="50">
                            <h3 class="description-subtitle">
                                {{ "PROMO.BIGIMAGE" | translate }}
                            </h3>
                            <h4
                                    class="description-subtitle"
                                    matTooltip="{{
                  'PROMO.TOOLTIP_IMG_LANDSCAPE_DESKTOP' | translate
                }}"
                            >
                                {{ "PROMO.LANDSCAPE_IMAGE" | translate }}
                            </h4>
                            <input hidden formControlName="desktopMediaId"/>
                            <input hidden formControlName="desktopMediaName"/>
                            <figure>
                                <img
                                        *ngIf="pageForm.get('desktopMediaName').value"
                                        class="promo-image big"
                                        [src]="pageForm.get('desktopMediaName').value"
                                />
                                <img
                                        *ngIf="!pageForm.get('desktopMediaName').value"
                                        class="promo-image big"
                                />
                                <figcaption
                                        [ngClass]="{
                    '': pageForm.get('desktopMediaName').value,
                    'no-content-box': !pageForm.get('desktopMediaName').value
                  }"
                                >
                                    <div class="media-buttons-container">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="selectImage(false, false)"
                                                *fuseIfOnDom
                                                aria-label="Add Media"
                                                matTooltip="{{ 'COMMON.ADD_MEDIA' | translate }}"
                                        >
                                            <mat-icon>image</mat-icon>
                                        </button>
                                        <button
                                                mat-button
                                                [ngStyle]="{
                        display: !pageForm.get('desktopMediaName').value
                          ? 'none'
                          : ''
                      }"
                                                class="mat-icon-button"
                                                (click)="removeImage(pageForm.get('desktopMediaName'))"
                                                *fuseIfOnDom
                                                aria-label="Remove Media"
                                                matTooltip="{{ 'COMMON.REMOVE_MEDIA' | translate }}"
                                        >
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </figcaption>
                            </figure>
                            <mat-error *ngIf="formErrors.desktopMediaId.imageError">
                                {{ "COMMON.REQUIRED_ERROR" | translate }}
                            </mat-error>
                        </div>
                        <div fxFlex="50">
                            <h3 class="description-subtitle">
                                {{ "PROMO.SMALLIMAGE" | translate }}
                            </h3>
                            <h4
                                    class="description-subtitle"
                                    matTooltip="{{
                  'PROMO.TOOLTIP_IMG_PORTRAIT_DESKTOP' | translate
                }}"
                            >
                                {{ "PROMO.PORTRAIT_IMAGE" | translate }}
                            </h4>
                            <input hidden formControlName="desktopSmallMediaId"/>
                            <input hidden formControlName="desktopSmallMediaName"/>
                            <figure>
                                <img
                                        *ngIf="pageForm.get('desktopSmallMediaName').value"
                                        class="promo-image small"
                                        [src]="pageForm.get('desktopSmallMediaName').value"
                                />
                                <img
                                        *ngIf="!pageForm.get('desktopSmallMediaName').value"
                                        class="promo-image small"
                                />
                                <figcaption
                                        [ngClass]="{
                    '': pageForm.get('desktopSmallMediaName').value,
                    'no-content-box': !pageForm.get('desktopSmallMediaName')
                      .value
                  }"
                                >
                                    <div class="media-buttons-container">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="selectImage(false, true)"
                                                *fuseIfOnDom
                                                aria-label="Add Media"
                                                matTooltip="{{ 'COMMON.ADD_MEDIA' | translate }}"
                                        >
                                            <mat-icon>image</mat-icon>
                                        </button>
                                        <button
                                                mat-button
                                                [ngStyle]="{
                        display: !pageForm.get('desktopSmallMediaName').value
                          ? 'none'
                          : ''
                      }"
                                                class="mat-icon-button"
                                                (click)="
                        removeImage(pageForm.get('desktopSmallMediaName'))
                      "
                                                *fuseIfOnDom
                                                aria-label="Remove Media"
                                                matTooltip="{{ 'COMMON.REMOVE_MEDIA' | translate }}"
                                        >
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </figcaption>
                            </figure>
                            <mat-error *ngIf="formErrors.desktopSmallMediaId.imageError">
                                {{ "COMMON.REQUIRED_ERROR" | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            class="promo-form-item-row"
                    >
                        <div fxFlex="100">
                            <h2 class="description-title">
                                {{ "PROMO.MOBILEIMAGE" | translate }}
                            </h2>
                        </div>
                    </div>
                    <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            class="promo-form-item-row"
                    >
                        <div fxFlex="50">
                            <h3 class="description-subtitle">
                                {{ "PROMO.BIGIMAGE" | translate }}
                            </h3>
                            <h4
                                    class="description-subtitle"
                                    matTooltip="{{
                  'PROMO.TOOLTIP_IMG_LANDSCAPE_MOBILE' | translate
                }}"
                            >
                                {{ "PROMO.LANDSCAPE_IMAGE" | translate }}
                            </h4>
                            <input hidden formControlName="mobileMediaId"/>
                            <input hidden formControlName="mobileMediaName"/>
                            <figure>
                                <img
                                        *ngIf="pageForm.get('mobileMediaName').value"
                                        class="promo-image big"
                                        [src]="pageForm.get('mobileMediaName').value"
                                />
                                <img
                                        *ngIf="!pageForm.get('mobileMediaName').value"
                                        class="promo-image big"
                                />
                                <figcaption
                                        [ngClass]="{
                    '': pageForm.get('mobileMediaName').value,
                    'no-content-box': !pageForm.get('mobileMediaName').value
                  }"
                                >
                                    <div class="media-buttons-container">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="selectImage(true, false)"
                                                *fuseIfOnDom
                                                aria-label="Add Media"
                                                matTooltip="{{ 'COMMON.ADD_MEDIA' | translate }}"
                                        >
                                            <mat-icon>image</mat-icon>
                                        </button>
                                        <button
                                                mat-button
                                                [ngStyle]="{
                        display: !pageForm.get('mobileMediaName').value
                          ? 'none'
                          : ''
                      }"
                                                class="mat-icon-button"
                                                (click)="removeImage(pageForm.get('mobileMediaName'))"
                                                *fuseIfOnDom
                                                aria-label="Remove Media"
                                                matTooltip="{{ 'COMMON.REMOVE_MEDIA' | translate }}"
                                        >
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </figcaption>
                            </figure>
                            <mat-error *ngIf="formErrors.mobileMediaId.imageError">
                                {{ "COMMON.REQUIRED_ERROR" | translate }}
                            </mat-error>
                        </div>
                        <div fxFlex="50">
                            <h3 class="description-subtitle">
                                {{ "PROMO.SMALLIMAGE" | translate }}
                            </h3>
                            <h4
                                    class="description-subtitle"
                                    matTooltip="{{
                  'PROMO.TOOLTIP_IMG_PORTRAIT_MOBILE' | translate
                }}"
                            >
                                {{ "PROMO.PORTRAIT_IMAGE" | translate }}
                            </h4>
                            <input hidden formControlName="mobileSmallMediaId"/>
                            <input hidden formControlName="mobileSmallMediaName"/>
                            <figure>
                                <img
                                        *ngIf="pageForm.get('mobileSmallMediaName').value"
                                        class="promo-image small"
                                        [src]="pageForm.get('mobileSmallMediaName').value"
                                />
                                <img
                                        *ngIf="!pageForm.get('mobileSmallMediaName').value"
                                        class="promo-image small"
                                />
                                <figcaption
                                        [ngClass]="{
                    '': pageForm.get('mobileSmallMediaName').value,
                    'no-content-box': !pageForm.get('mobileSmallMediaName')
                      .value
                  }"
                                >
                                    <div class="media-buttons-container">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="selectImage(true, true)"
                                                *fuseIfOnDom
                                                aria-label="Add Media"
                                                matTooltip="{{ 'COMMON.ADD_MEDIA' | translate }}"
                                        >
                                            <mat-icon>image</mat-icon>
                                        </button>
                                        <button
                                                mat-button
                                                [ngStyle]="{
                        display: !pageForm.get('mobileSmallMediaName').value
                          ? 'none'
                          : ''
                      }"
                                                class="mat-icon-button"
                                                (click)="
                        removeImage(pageForm.get('mobileSmallMediaName'))
                      "
                                                *fuseIfOnDom
                                                aria-label="Remove Media"
                                                matTooltip="{{ 'COMMON.REMOVE_MEDIA' | translate }}"
                                        >
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </figcaption>
                            </figure>
                            <mat-error *ngIf="formErrors.mobileSmallMediaId.imageError">
                                {{ "COMMON.REQUIRED_ERROR" | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="50">
                            <input
                                    matInput
                                    [matDatepicker]="startDatePicker"
                                    placeholder="{{ 'PROMO.STARTDATE' | translate }}"
                                    matTooltip="{{ 'PROMO.TOOLTIP_START_DATE' | translate }}"
                                    formControlName="startDate"
                            />
                            <mat-datepicker-toggle
                                    matSuffix
                                    [for]="startDatePicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error *ngIf="formErrors.startDate.required">
                                {{ "COMMON.REQUIRED_ERROR" | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input
                                    matInput
                                    [matDatepicker]="endDatePicker"
                                    placeholder="{{ 'PROMO.ENDDATE' | translate }}"
                                    matTooltip="{{ 'PROMO.TOOLTIP_END_DATE' | translate }}"
                                    formControlName="endDate"
                            />
                            <mat-datepicker-toggle
                                    matSuffix
                                    [for]="endDatePicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                            <mat-error *ngIf="formErrors.endDate">
                                {{ "WIDGET.DATES_ERROR" | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="50">
                            <div class="timepicker">
                                <div class="timepicker-value">
                                    <input
                                            matInput
                                            type="time"
                                            [(ngModel)]="startTime"
                                            matTooltip="{{ 'PROMO.TOOLTIP_START_HOUR' | translate }}"
                                            formControlName="startTime"
                                    />
                                </div>
                                <div class="timepicker-button">
                                    <button
                                            mat-button
                                            class="mat-icon-button"
                                            (click)="openStartTimePicker()"
                                            *fuseIfOnDom
                                            aria-label="Open start time picker"
                                    >
                                        <mat-icon>schedule</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <div class="timepicker">
                                <div class="timepicker-value">
                                    <input
                                            matInput
                                            type="time"
                                            [(ngModel)]="endTime"
                                            matTooltip="{{ 'PROMO.TOOLTIP_END_HOUR' | translate }}"
                                            formControlName="endTime"
                                    />
                                </div>
                                <div class="timepicker-button">
                                    <button
                                            mat-button
                                            class="mat-icon-button"
                                            (click)="openEndTimePicker()"
                                            *fuseIfOnDom
                                            aria-label="Open end time picker"
                                    >
                                        <mat-icon>schedule</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox
                                    matInput
                                    formControlName="enableTimer"
                                    (click)="enableDateOrTime(0)"
                            >
                                {{ "PROMO.ENABLE_TIMER" | translate }}</mat-checkbox
                            >
                        </div>
                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox
                                    matInput
                                    formControlName="enableDate"
                                    (click)="enableDateOrTime(1)"
                            >
                                {{ "PROMO.ENABLE_DATE" | translate }}</mat-checkbox
                            >
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox matInput formControlName="showIncoming"
                            >
                                Is Visible
                            </mat-checkbox>
                        </div>
                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox
                                    matInput
                                    formControlName="enableStartTimer"
                                    (click)="enableDateOrTime(0)"
                            >
                                {{ "PROMO.ENABLE_START_TIMER" | translate }}</mat-checkbox
                            >
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="50">
                            <input
                                    matInput
                                    placeholder="{{ 'PROMO.NOTES' | translate }}"
                                    matTooltip="{{ 'PROMO.TOOLTIP_NOTE' | translate }}"
                                    formControlName="notes"
                            />
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input
                                    matInput
                                    type="number"
                                    placeholder="{{ 'PROMO.JACKPOT' | translate }}"
                                    matTooltip="{{ 'PROMO.TOOLTIP_JACKPOT' | translate }}"
                                    formControlName="jackpot"
                            />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="50">
                            <input
                                    matInput
                                    placeholder="{{ 'PROMO.PROMOID' | translate }}"
                                    matTooltip="{{ 'PROMO.TOOLTIP_PROMOID' | translate }}"
                                    formControlName="promotionId"
                            />
                        </mat-form-field>
                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox
                                    matInput
                                    formControlName="isWelcome"
                                    (click)="enableDateOrTime(1)"
                            >
                                {{ "PROMO.ISWELCOME" | translate }}</mat-checkbox
                            >
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 0 auto">
                    <div class="promo-form-button-row">
                        <div>
                            <h2 class="description-title" fxFlex="100">
                                {{ "PROMO.BUTTONS" | translate }}
                            </h2>
                            <div
                                    class="button-action add"
                                    *ngIf="buttons && buttons.length < 2"
                            >
                                <button
                                        mat-button
                                        class="mat-icon-button"
                                        (click)="addButton()"
                                        *fuseIfOnDom
                                        aria-label="Add Media"
                                        matTooltip="{{ 'COMMON.ADD_BUTTON' | translate }}"
                                >
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div
                                *ngFor="let button of buttons"
                                class="promo-form-button-row buttons-container"
                        >
                            <div fxFlex="100">
                <span class="button-type">{{
                    "PROMO.BUTTON_TYPE" | translate
                    }}</span>
                                <mat-radio-group
                                        matInput
                                        [(ngModel)]="button.type"
                                        (change)="onButtonTypeChanged(button)"
                                >
                                    <mat-radio-button
                                            *ngFor="let item of buttontypes"
                                            [value]="item.value"
                                    >
                                        {{ item.display | translate }}</mat-radio-button
                                    >
                                </mat-radio-group>
                            </div>
                            <div fxFlex="100">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-form-field fxFlex="30">
                                        <input
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_TEXT' | translate }}"
                                                matTooltip="{{ 'PROMO.TOOLTIP_BUTTON_TEXT' | translate }}"
                                                [(ngModel)]="button.text"
                                                (change)="emitChange()"
                                        />
                                    </mat-form-field>
                                    <div class="button-action">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="deleteButton(button)"
                                                *fuseIfOnDom
                                                matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}"
                                        >
                                            <mat-icon>remove_circle</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="100" class="actions-container">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-checkbox
                                            matInput
                                            [(ngModel)]="button.isAnonymous"
                                            (change)="emitChange()"
                                    >
                                        {{ "PROMO.BUTTON_IS_ANONYMOUS" | translate }}</mat-checkbox
                                    >
                                    <mat-checkbox
                                            matInput
                                            [(ngModel)]="button.isAuthenticated"
                                            (change)="emitChange()"
                                    >
                                        {{
                                        "PROMO.BUTTON_IS_AUTHENTICATED" | translate
                                        }}</mat-checkbox
                                    >
                                </div>
                            </div>
                            <div fxFlex="100" *ngIf="button.type === 0">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-form-field fxFlex="30" class="content-selection-input">
                                        <input
                                                fxFlex="0 0 100%"
                                                class="layout-selected"
                                                disabled
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_CONTENT' | translate }}"
                                                matTooltip="{{
                        'PROMO.TOOLTIP_BUTTON_CONTENT' | translate
                      }}"
                                                [ngModelOptions]="{ standalone: true }"
                                                [(ngModel)]="button.contentName"
                                                (change)="emitChange()"
                                        />
                                    </mat-form-field>
                                    <div class="button-action">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="selectContent(button)"
                                                *fuseIfOnDom
                                                aria-label="Select content"
                                                matTooltip="{{ 'BANNER.SELECT_CONTENT' | translate }}"
                                        >
                                            <mat-icon>settings_applications</mat-icon>
                                        </button>
                                    </div>
                                    <div class="button-action popup">
                                        <mat-checkbox
                                                matInput
                                                [(ngModel)]="button.isPopup"
                                                (change)="emitChange()"
                                        >
                                            {{ "PROMO.BUTTON_ISPOPUP" | translate }}</mat-checkbox
                                        >
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="100" *ngIf="button.type === 1">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-form-field fxFlex="30">
                                        <input
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_PAGE' | translate }}"
                                                [(ngModel)]="button.pageLink"
                                                (change)="emitChange()"
                                        />
                                    </mat-form-field>
                                    <div class="button-action">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="selectPage(button)"
                                                *fuseIfOnDom
                                                aria-label="Select Page"
                                                matTooltip="{{ 'BANNER.SELECT_PAGE' | translate }}"
                                        >
                                            <mat-icon>find_in_page</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="100" *ngIf="button.type === 2">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-form-field fxFlex="30">
                                        <input
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_LINK' | translate }}"
                                                matTooltip="{{ 'PROMO.TOOLTIP_BUTTON_LINK' | translate }}"
                                                [(ngModel)]="button.link"
                                                (change)="emitChange()"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxFlex="100" *ngIf="button.type === 3">
                                <div fxLayout="column" fxLayoutAlign="start stretch">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_FUNCTION' | translate }}"
                                                matTooltip="{{
                        'PROMO.TOOLTIP_BUTTON_FUNCTION' | translate
                      }}"
                                                [(ngModel)]="button.customFunction"
                                                (change)="emitChange()"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 0 auto">
                    <div class="promo-form-button-row">
                        <div>
                            <h2 class="description-title" fxFlex="100">
                                {{ "PROMO.COMINGSOON_BUTTONS" | translate }}
                            </h2>
                            <div
                                    class="button-action add"
                                    *ngIf="comingSoonButtons && comingSoonButtons.length < 2"
                            >
                                <button
                                        mat-button
                                        class="mat-icon-button"
                                        (click)="addComingSoonButton()"
                                        *fuseIfOnDom
                                        aria-label="Add Media"
                                        matTooltip="{{ 'COMMON.ADD_BUTTON' | translate }}"
                                >
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div
                                *ngFor="let button of comingSoonButtons"
                                class="promo-form-button-row buttons-container"
                        >
                            <div fxFlex="100">
                <span class="button-type">{{
                    "PROMO.BUTTON_TYPE" | translate
                    }}</span>
                                <mat-radio-group
                                        matInput
                                        [(ngModel)]="button.type"
                                        (change)="onButtonTypeChanged(button)"
                                >
                                    <mat-radio-button
                                            *ngFor="let item of buttontypes"
                                            [value]="item.value"
                                    >
                                        {{ item.display | translate }}</mat-radio-button
                                    >
                                </mat-radio-group>
                            </div>
                            <div fxFlex="100">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-form-field fxFlex="30">
                                        <input
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_TEXT' | translate }}"
                                                matTooltip="{{ 'PROMO.TOOLTIP_BUTTON_TEXT' | translate }}"
                                                [(ngModel)]="button.text"
                                                (change)="emitComingSoonChange()"
                                        />
                                    </mat-form-field>
                                    <div class="button-action">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="deleteComingSoonButton(button)"
                                                *fuseIfOnDom
                                                matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}"
                                        >
                                            <mat-icon>remove_circle</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="100" class="actions-container">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-checkbox
                                            matInput
                                            [(ngModel)]="button.isAnonymous"
                                            (change)="emitComingSoonChange()"
                                    >
                                        {{ "PROMO.BUTTON_IS_ANONYMOUS" | translate }}</mat-checkbox
                                    >
                                    <mat-checkbox
                                            matInput
                                            [(ngModel)]="button.isAuthenticated"
                                            (change)="emitComingSoonChange()"
                                    >
                                        {{ "PROMO.BUTTON_IS_AUTHENTICATED" | translate }}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" *ngIf="button.type === 0">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-form-field fxFlex="30" class="content-selection-input">
                                        <input
                                                fxFlex="0 0 100%"
                                                class="layout-selected"
                                                disabled
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_CONTENT' | translate }}"
                                                matTooltip="{{
                        'PROMO.TOOLTIP_BUTTON_CONTENT' | translate
                      }}"
                                                [ngModelOptions]="{ standalone: true }"
                                                [(ngModel)]="button.contentName"
                                                (change)="emitComingSoonChange()"
                                        />
                                    </mat-form-field>
                                    <div class="button-action">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="selectContent(button)"
                                                *fuseIfOnDom
                                                aria-label="Select content"
                                                matTooltip="{{ 'BANNER.SELECT_CONTENT' | translate }}"
                                        >
                                            <mat-icon>settings_applications</mat-icon>
                                        </button>
                                    </div>
                                    <div class="button-action popup">
                                        <mat-checkbox
                                                matInput
                                                [(ngModel)]="button.isPopup"
                                                (change)="emitComingSoonChange()"
                                        >
                                            {{ "PROMO.BUTTON_ISPOPUP" | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="100" *ngIf="button.type === 1">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-form-field fxFlex="30">
                                        <input
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_PAGE' | translate }}"
                                                [(ngModel)]="button.pageLink"
                                                (change)="emitComingSoonChange()"
                                        />
                                    </mat-form-field>
                                    <div class="button-action">
                                        <button
                                                mat-button
                                                class="mat-icon-button"
                                                (click)="selectPage(button)"
                                                *fuseIfOnDom
                                                aria-label="Select Page"
                                                matTooltip="{{ 'BANNER.SELECT_PAGE' | translate }}"
                                        >
                                            <mat-icon>find_in_page</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="100" *ngIf="button.type === 2">
                                <div fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-form-field fxFlex="30">
                                        <input
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_LINK' | translate }}"
                                                matTooltip="{{ 'PROMO.TOOLTIP_BUTTON_LINK' | translate }}"
                                                [(ngModel)]="button.link"
                                                (change)="emitComingSoonChange()"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxFlex="100" *ngIf="button.type === 3">
                                <div fxLayout="column" fxLayoutAlign="start stretch">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                placeholder="{{ 'PROMO.BUTTON_FUNCTION' | translate }}"
                                                matTooltip="{{
                        'PROMO.TOOLTIP_BUTTON_FUNCTION' | translate
                      }}"
                                                [(ngModel)]="button.customFunction"
                                                (change)="emitComingSoonChange()"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 0 auto">
                    <div fxFlex="100" class="timyMce-wrapper">
                        <h2 class="description-title">{{ "PROMO.HTML" | translate }}</h2>
                        <!--
                        <app-tinymce [(ngModel)]="htmlText" [config]="editorConfig">
                        </app-tinymce>
                        -->
                        <mat-form-field style="width: 100%">
                            <mat-label>HTML</mat-label>
                            <textarea [(ngModel)]="htmlText" matInput></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>
