<div class="page-layout carded fullwidth ps">
  <div id="header">
    <div class="header p-16 p-sm-24">
      <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
        <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
          <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
            <div class="options-icon isVisible">
              <button mat-button class="mat-icon-button close" [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }" *fuseIfOnDom aria-label="Close" matTooltip="{{ 'COMMON.CLOSE' | translate }}" (click)="close()">
                <mat-icon>arrow_back</mat-icon>
              </button>
            </div>
            <span class="logo-text" *fuseIfOnDom [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' }
              }">{{ "STYLES.EDIT_TITLE" | translate }}</span>
          </div>
          <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
            <div class="options-icon isVisible">
              <button mat-button class="mat-icon-button" [ngClass]="{ disabled: pageForm.invalid }" [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }" [disabled]="pageForm.invalid" (click)="save()" *fuseIfOnDom aria-label="Save" matTooltip="{{ 'COMMON.SAVE' | translate }}">
                <mat-icon>save</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-center">
    <div class="content-card">
      <div name="pageForm" [formGroup]="pageForm" fxLayout="column">
        <div fxFlex="100">
          <mat-form-field fxFlex="50">
            <mat-select matInput placeholder="{{ 'PAGE.SKIN' | translate }}" matTooltip="{{ 'PAGE.TOOLTIP_SKIN' | translate }}" [(ngModel)]="skinId" formControlName="skinId" (selectionChange)="onSkinChanged()">
              <mat-option *ngFor="let skin of skins" [value]="skin.id">
                {{ skin.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formErrors.skinId.required">
              {{ "COMMON.REQUIRED_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <h2>{{ "STYLES.STYLE" | translate }}</h2>
        <code fxFlex="100">
          <textarea rows="20" formControlName="style" fxFlex="100"></textarea>
        </code>
        <!-- <ngs-code-editor
          fxFlex="100"
          style="height: 500px"
          *ngIf="cssEditor.codeModel.value"
          [theme]="cssEditor.theme"
          [codeModel]="cssEditor.codeModel"
          (valueChanged)="onCssChange($event)"
          [options]="cssEditor.options"
        >
        </ngs-code-editor> -->
        <h2>{{ "STYLES.SCRIPT" | translate }}</h2>
        <code fxFlex="100">
          <textarea rows="20" formControlName="script" fxFlex="100"></textarea>
        </code>
        <!-- <ngs-code-editor
          fxFlex="100"
          style="height: 500px"
          *ngIf="scriptEditor.codeModel.value"
          [theme]="scriptEditor.theme"
          [codeModel]="scriptEditor.codeModel"
          (valueChanged)="onScriptEditorChange($event)"
          [options]="scriptEditor.options"
        >
        </ngs-code-editor> -->
      </div>
    </div>
  </div>

  <div class="media-loading-shade full-height" *ngIf="isLoading">
    <mat-spinner [color]="'accent'"></mat-spinner>
  </div>
</div>