import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApisService } from 'src/app/shared/apis.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from 'src/app/shared/notify.service';
import { AppConfigService } from 'src/app/shared/appconfig.service';
import { UserAccess, GbetSelectItem, GameAttributeLinkModel, GameAttributeModel, AttributeFamilyModel, GetMaxOrderGamesModel } from '../../models/DataModels';
import { SaveGameRequest } from '../../models/DataModels/Cms/Request';
import { fuseAnimations } from '../../core/animations';
import { BaseEditorComponent } from '../../components/baseeditor.component';
import { MediaSelectorComponent } from '../../cms/mediaselector/mediaselector.component';

@Component({
  selector: 'app-gameeditor',
  templateUrl: './gameeditor.component.html',
  styleUrls: ['./gameeditor.component.scss'],
  animations: fuseAnimations
})

export class GameEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  id: string;
  formErrors: any;
  isNew: boolean = false;
  subscribers: Subscription[];
  isLoading = false;
  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };

  canEdit: boolean = true;
  providers: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  allAttributes: Array<GameAttributeModel> = new Array<GameAttributeModel>();
  allFamilies: Array<AttributeFamilyModel> = new Array<AttributeFamilyModel>();
  netentGameTypes: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  netentGameFamilies: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  capecodeGameTypes: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  greenTubeGameTypes: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  novomaticGameTypes: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  selectedTab: number = 0;
  removedAttributes: Array<number> = new Array<number>();
  isNetent = false;
  isCapecode = false;
  isGreenTube = false;
  isNovomatic = false;
  idFornitore: string;
  netentGameFamily: string;
  netentGameType: string;
  capecodeGameType: string;
  greenTubeGameType: string;
  novomaticGameType: string;
  attributes: FormArray;
  gameMaxOrder: GetMaxOrderGamesModel;

  private novomatic = 1;
  private capecode = 2;
  private netEnt = 3;
  private greenTube = 6;
  private multiSelectFamily = [1, 2, 10];
  private imagesPath = [
    { key: 'imageBackground', value: 'imagebackground' },
    { key: 'imageMini', value: 'imagemini' },
    { key: 'imageSplashMobile', value: 'imagesplashmobile' },
    { key: 'imageSplashDesktop', value: 'imagesplashdesktop' },
    { key: 'imageMiniPreview', value: 'imageminipreview' },
    { key: 'imageIconMobile', value: 'imageiconmobile' }
  ];

  constructor(
    protected translateService: TranslateService,
    @Inject(NotifyService) protected notifyService: NotifyService,
    protected dialog: MatDialog,
    @Inject(AppConfigService) private appConfig: AppConfigService,
    private location: Location,
    private apisService: ApisService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    super();
    this.createForm();
    this.subscribers = new Array<Subscription>();
    this.formErrors = {
      gameId: {},
      name: {},
      idFornitore: {},
      fullName: {},
      nomeUnivoco: {},
      title: {},
      nameSEO: {},
      aamsCode: {},
      aamsTipoGioco: {},
      isEnabled: {},
      fpp: {},
      order: {},
      coefficiente: {},
      height: {},
      width: {},
      rpt: {},
      imageBackground: {},
      imageMini: {},
      imageSplashMobile: {},
      imageSplashDesktop: {},
      imageMiniPreview: {},
      imageIconMobile: {},
      help: {},
      shortDescription: {},
      longDescription: {}
    };
  }

  ngOnInit() {
    this.subscribers.push(this.route.params.subscribe(params => {
      this.id = params.id;
      this.isNew = this.id === undefined;
      this.loadPage(this.id);
    }));
  }

  ngOnDestroy() {
    this.subscribers.forEach(t => t.unsubscribe());
  }

  loadPage(id: string): void {

    this.removedAttributes = new Array<number>();
    this.isLoading = true;
    if (this.isNew) {
      const getMaxOrderLoader = this.apisService.getMaxOrder().subscribe(response => {
        if (!response.isError) {
          this.gameMaxOrder = response.data;
        }
        getMaxOrderLoader.unsubscribe();
      });
    }

    this.subscribers.push(this.apisService.getGame(id).subscribe(response => {
      if (response.isError) {
        this.isLoading = false;
      }
      this.notifyService.handleServerResponse(response, result => {
        let markAsDirty = false;
        this.netEnt = result.netEntId;
        this.capecode = result.capecodeId;
        this.novomatic = result.novomaticId;
        this.greenTube = result.greenTubeId;
        this.capecode = result.capecodeId;
        // this.userAccess = response.userAccess;
        this.providers = result.providers;
        this.allAttributes = result.attributes;
        this.allFamilies = result.attributeFamilies;
        this.netentGameTypes = result.netentGameTypes;
        this.netentGameFamilies = result.netentGameFamilies;
        this.capecodeGameTypes = result.capecodeGameTypes;
        this.greenTubeGameTypes = result.greenTubeGameTypes;
        this.novomaticGameTypes = result.novomaticGameTypes;
        if (result.item) {
          this.updateProviderControls(result.item.idFornitore);
          this.pageForm.patchValue(result.item);
          let checkAttribureCapeCod = this.addClientAttributeCapecod(result.links, result.item.idFornitore);

          if (checkAttribureCapeCod.check) {
            const link = {
              id: -1,
              gameId: result.item.id,
              attributeId: checkAttribureCapeCod.htmlVal.id
            };
            this.attributes.push(this.patchLink(link));
            markAsDirty = true;

          }

          result.links.forEach(link => {
            this.attributes.push(this.patchLink(link));
          });
          this.updateEnabledFields();
          if (markAsDirty)
            this.pageForm.markAsDirty();
        }
        this.isLoading = false;
      });
    }));
  }

  onFormValuesChanged(data) {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
        console.log(field, 'not valid');
      }
    }
  }

  save(copy?: boolean) {

    if (!this.userAccess.canEdit) return;
    if (this.pageForm.dirty) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new SaveGameRequest();
        Object.assign(request, this.pageForm.getRawValue());
        //if (request.idFornitore === this.netEnt) {
        //  request.isDesktop = request.isMobile === false;
        //}
        request.deletedAttributes = this.removedAttributes;
        if (request.attributes) {
          request.attributes.forEach(attribute => {
            delete attribute['families'];
            delete attribute['attributes'];
          });
        }
        request.id = +this.id;
        if (this.isNew) {
          this.checkOrder(request);
        }

        if (request.idFornitore === this.netEnt) {
          if (request.netentGameType === undefined || request.netentGameFamily === undefined
            || request.aamsTipoGioco === undefined
            || isNaN(+request.aamsTipoGioco)) {
            this.isLoading = false;
            this.notifyService.error('NOTIFICATION.7000');
            return;
          }
          this.setClientRequestField(request);
        } else if (request.idFornitore === this.capecode) {
          if (
            // request.capecodeCashAvailable === undefined
            // || request.capecodeIsFun === undefined
            request.capecodeGameType === undefined
            || request.aamsTipoGioco === undefined
            || isNaN(+request.aamsTipoGioco)
            || request.aamsCode === undefined
            || isNaN(+request.aamsCode)) {
            this.isLoading = false;
            this.notifyService.error('NOTIFICATION.7001');
            return;
          }
          this.setClientRequestField(request);
        } else if (request.idFornitore === this.greenTube) {
          if (request.greenTubeCashAvailable === undefined
            || request.greenTubeIsFun === undefined
            || request.greenTubeGameType === undefined
            || request.aamsTipoGioco === undefined
            || isNaN(+request.aamsTipoGioco)
            || request.aamsCode === undefined
            || isNaN(+request.aamsCode)) {
            this.isLoading = false;
            this.notifyService.error('NOTIFICATION.7002');
            return;
          }
        } else if (request.idFornitore === this.novomatic) {
          if (request.novomaticGameType === undefined
            || request.gameId === undefined
            || isNaN(+request.gameId)
            || request.aamsTipoGioco === undefined
            || isNaN(+request.aamsTipoGioco)
            || request.aamsCode === undefined
            || isNaN(+request.aamsCode)) {
            this.isLoading = false;
            this.notifyService.error('NOTIFICATION.7003');
            return;
          }
        }

        this.subscribers.push(this.apisService.saveGame(request).subscribe(response => {
          this.isLoading = false;
          this.notifyService.handleServerResponse(response, result => {
            const attributes = <FormArray>this.pageForm.controls.attributes;
            while (attributes.length > 0) {
              attributes.removeAt(0);
            }
            this.pageForm.reset();
            if (this.id === String(result)) {
              this.loadPage(this.id);
            } else {
              this.router.navigate(['/gbet/giochi/gioco/', result]);
            }
          });
        }));
      } else {
        this.notifyService.warning('COMMON.INVALIDDATA');
      }
    }
  }

  addAttribute() {
    if (this.pageForm.invalid) return;
    const attributesForm = <FormArray>this.pageForm.controls.attributes;
    const emptyFamily = attributesForm.controls.find(x => x.value.idFamigliaAttributi === undefined);
    if (emptyFamily) {
      return;
    }
    const item = <GameAttributeLinkModel>{
      attributeId: undefined,
      id: -1,
      gameId: +this.id
    };
    attributesForm.push(this.patchLink(item));
  }

  deleteAttribute(i: number) {
    const attributesForm = <FormArray>this.pageForm.controls.attributes;
    if (i >= 0 && i < attributesForm.length) {
      const ctrl = attributesForm.controls[i];
      if (ctrl.value.id && ctrl.value.id > 0) {
        this.removedAttributes.push(ctrl.value.id);
      }
      attributesForm.removeAt(i);
      this.pageForm.markAsDirty();
      this.updateItemsFamilies(ctrl);
    }
  }

  onFamilyChanged(ctrl: any, idFamigliaAttributi: any) {
    if (idFamigliaAttributi) {
      if (idFamigliaAttributi !== ctrl.value.idFamigliaAttributi) {
        const family = this.allFamilies.find(x => x.id === idFamigliaAttributi);
        const itemAttributes = family !== undefined ? this.allAttributes.filter(x => x.idFamigliaAttributi === family.id) : undefined;
        if (!ctrl.value.attributes) {
          ctrl.patchValue({
            attributes: itemAttributes,
            attributeId: undefined
          });
        } else {
          for (let index = 0; index < ctrl.value.attributes.length; index++) {
            const e = ctrl.value.attributes[index];
            if (itemAttributes.find(x => x.id === e.id) === undefined) {
              ctrl.patchValue({
                attributes: itemAttributes,
                attributeId: undefined
              });
              break;
            }
          }
        }
      }
    } else {
      ctrl.patchValue({
        attributes: undefined,
        attributeId: undefined
      });
    }
    this.updateItemsFamilies(ctrl);
  }

  onIdFornitoreChnaged() {
    this.updateProviderControls(this.pageForm.value.idFornitore);
  }

  showMediaSelector(imageProp: string) {
    if (imageProp === undefined || imageProp === null || this.canEdit === false) return;

    let dialogRef = this.dialog.open(MediaSelectorComponent, {
      width: '100%',
      data: {
        isMultiSelect: false,
        folder: this.getImageFolder(imageProp),
        ignoreFolder: false
      },
      height: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.items && result.items.length > 0) {
        let item = result.items[0];
        const control = this.pageForm.get(imageProp);
        if (control) {
          control.patchValue(item.url);
        }
        this.pageForm.markAsDirty();
      }
    });
  }

  ddlOpenedChanged(value) {
    if (value) {
      const t = setTimeout(() => {
        //hack
        const selectPanels = document.getElementsByClassName('mat-select-panel');
        if (selectPanels.length > 0) {
          const overlay = selectPanels[0].parentElement;
          const documentSizes = document.body.getBoundingClientRect();
          var overlayPosition = overlay.getBoundingClientRect();
          if (documentSizes.height - overlayPosition.top - overlayPosition.height - 50 < 0) {
            overlay.style.removeProperty('top');
            overlay.style.bottom = '50px';
          }
        }
        clearTimeout(t);
      }, 10);
    }
  }

  protected internalClose() {
    let data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(['gbet/giochi']);
    }
  }

  private getImageFolder(imagePath: string): string {
    let result = 'games';
    if (!imagePath) return result;
    const provider = this.pageForm.getRawValue().idFornitore;
    if (provider) {
      const selected = this.providers.find(x => x.id === provider);
      if (selected) {
        result += '/' + selected.value;
      } else {
        result += '/' + provider;
      }
    }
    const path = this.imagesPath.find(x => x.key === imagePath);
    result += '/' + path.value.toLowerCase();
    return result.toLowerCase();
  }

  private updateItemsFamilies(ctrl: any) {
    const attributesForm = <FormArray>this.pageForm.controls.attributes;
    const selectedFamilies = attributesForm.controls.map(x => {
      return x.value.idFamigliaAttributi;
    }).filter(x => {
      if (x === undefined || x === null) return false;
      return this.multiSelectFamily.findIndex(y => y === x) < 0;
    });
    attributesForm.controls.forEach(item => {
      if (item !== ctrl) {
        const families = this.allFamilies.filter(x => x.id === item.value.idFamigliaAttributi || selectedFamilies.findIndex(y => y === x.id) < 0);
        item.patchValue({
          families: families
        });
      }
    });
  }

  private patchLink(item: GameAttributeLinkModel) {
    const attributesForm = <FormArray>this.pageForm.controls.attributes;
    const attribute = this.allAttributes.find(x => x.id === item.attributeId);
    const family = attribute !== undefined ? this.allFamilies.find(x => x.id === attribute.idFamigliaAttributi) : undefined;
    const attributes = family !== undefined ? this.allAttributes.filter(x => x.idFamigliaAttributi === family.id) : undefined;
    const selectedFamilies = attributesForm.controls.map(x => {
      return x.value.idFamigliaAttributi;
    }).filter(x => {
      if (x === undefined || x === null) return false;
      return this.multiSelectFamily.findIndex(y => y === x) < 0;
    });
    const families = this.allFamilies.filter(x => selectedFamilies.findIndex(y => y === x.id) < 0);
    const control = this.formBuilder.group({
      attributeId: [item.attributeId, [Validators.required]],
      idFamigliaAttributi: [family !== undefined ? family.id : undefined, Validators.required],
      families: [families],
      attributes: [attributes],
      id: [item.id]
    });
    const familyAttribute = control.controls['idFamigliaAttributi'];
    this.subscribers.push(familyAttribute.valueChanges.subscribe((data) => {
      this.onFamilyChanged(control, data);
    }));
    return control;
  }

  private createForm() {
    this.attributes = this.formBuilder.array([]);

    this.pageForm = this.formBuilder.group({
      gameId: [undefined, [Validators.required, Validators.maxLength(255)]],
      name: [undefined, [Validators.required, Validators.maxLength(255)]],
      idFornitore: [undefined, [Validators.required]],
      fullName: [, [Validators.required]],
      nomeUnivoco: [, [Validators.required]],
      title: [, [Validators.required]],
      nameSEO: [, [Validators.required]],
      aamsCode: [, [Validators.required]],
      aamsTipoGioco: [, [Validators.required]],
      isEnabled: [],
      isVisible: [],
      isJackpot: [],
      isMobile: [],
      isDesktop: [],
      isDemo: [],
      order: [, [Validators.required]],
      fpp: [, [Validators.required]],
      coefficiente: [, [Validators.required]],
      isMultybet: [],
      height: [1140, [Validators.required]],
      width: [640, [Validators.required]],
      imageBackground: [, [Validators.required]],
      imageMini: [, [Validators.required]],
      imageSplashMobile: [, [Validators.required]],
      imageSplashDesktop: [, [Validators.required]],
      imageMiniPreview: [, [Validators.required]],
      imageIconMobile: [, [Validators.required]],
      // help: [, [Validators.required]],
      help: [],
      shortDescription: [, Validators.maxLength(149)],
      longDescription: [, Validators.maxLength(1999)],
      isNew: [],
      rpt: [, [Validators.required]],
      netentGameType: [],
      netentGameFamily: [],
      capecodeGameType: [],
      // capecodeIsFun: [],
      // capecodeCashAvailable: [],
      greenTubeGameType: [],
      greenTubeIsFun: [],
      greenTubeCashAvailable: [],
      novomaticGameType: [],
      attributes: this.attributes,
      tagKeywords: [],
      tagDescription: [],
      tagTitle: [],
      isForeground: [],
      goLiveDate: []
    });

    this.subscribers.push(this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    }));
  }

  private updateProviderControls(idFornitore: number): void {
    this.isNetent = false;
    this.pageForm.controls.netentGameType.enable();
    this.pageForm.controls.netentGameFamily.disable();
    this.isCapecode = false;
    this.pageForm.controls.capecodeGameType.disable();
    this.isGreenTube = true;
    this.isGreenTube = false;
    this.pageForm.controls.greenTubeGameType.disable();
    this.isNovomatic = false;
    this.pageForm.controls.novomaticGameType.disable();

    if (idFornitore === this.netEnt) {
      this.isNetent = true;
      this.pageForm.controls.netentGameType.enable();
      this.pageForm.controls.netentGameFamily.enable();
    } else if (idFornitore === this.capecode) {
      this.isCapecode = true;
      this.pageForm.controls.capecodeGameType.enable();
    } else if (idFornitore === this.greenTube) {
      this.isGreenTube = true;
      this.pageForm.controls.greenTubeGameType.enable();
    } else if (idFornitore === this.novomatic) {
      this.isNovomatic = true;
      this.pageForm.controls.novomaticGameType.enable();
    }
  }

  private updateEnabledFields(): void {
    for (const key in this.pageForm.controls) {
      if (this.pageForm.controls.hasOwnProperty(key)) {
        const ctrl = this.pageForm.controls[key];
        ctrl.enable();
      }
    }

    if (this.id) {
      this.pageForm.controls.idFornitore.disable();
    } else {
      this.pageForm.controls.idFornitore.enable();
    }
  }

  private addClientAttributeCapecod(links: GameAttributeLinkModel[], idFornitore: number): any {
    if (idFornitore === this.capecode) {
      const familyId = this.allFamilies.find(x => x.famiglia === 'Client');
      if (familyId !== undefined) {
        const clientsVal = this.allAttributes.filter(x => x.idFamigliaAttributi === familyId.id);
        let htmlVal = clientsVal.find(x => x.descrizione.toLowerCase() === 'html5');
        let found = false;
        clientsVal.forEach(clientVal => {
          const containClientAttribute = links.find(x => x.attributeId === clientVal.id);
          if (containClientAttribute !== undefined)
            found = true;
        });

        if (found)
          return {
            check: false
          };
        else
          return {
            check: true,
            familyId: familyId.id,
            htmlVal: htmlVal
          };
      }
    }

    return {
      check: false
    };
  }

  private setClientRequestField(req: SaveGameRequest) {
    const familyId = this.allFamilies.find(x => x.famiglia === 'Client');
    if (familyId !== undefined) {
      const clientsVal = this.allAttributes.filter(x => x.idFamigliaAttributi === familyId.id);
      clientsVal.forEach(clientVal => {

        if (req.attributes.find(x => x.attributeId === clientVal.id) !== undefined) {
          req.capeCodeClientType = clientVal.descrizione;
        }
      });
    }

  }

  private checkOrder(req: SaveGameRequest) {
    if (this.gameMaxOrder !== undefined) {
      if (req.order === 0) {
        if (req.isDesktop)
          req.order = this.gameMaxOrder.desktop + 1;
        else if (req.isMobile)
          req.order = this.gameMaxOrder.mobile + 1;
      }
    }
  }

}
