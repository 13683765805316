<div class="slider-widget-item" fxLayout="row" fxLayoutAlign="start center" *ngFor="let item of items">
    <mat-form-field fxFlex="50">
        <input matInput placeholder="{{'SLIDER.ITEM_NAME' | translate}}" [(ngModel)]="item.name" />
    </mat-form-field>
    <div fxFlex="50">
        <button mat-button class="mat-icon-button" (click)="editItem(item)" *fuseIfOnDom aria-label="Remove" matTooltip="{{'COMMON.EDIT' | translate}}">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-button class="mat-icon-button" (click)="removeItem(item)" *fuseIfOnDom aria-label="Remove" matTooltip="{{'COMMON.DELETE' | translate}}">
            <mat-icon class="delete-icon">delete</mat-icon>
        </button>
        <button mat-button class="mat-icon-button" (click)="moveUp(item)" *fuseIfOnDom aria-label="Move up" matTooltip="{{'COMMON.MOVE_UP' | translate}}">
            <mat-icon>arrow_upward</mat-icon>
        </button>
        <button mat-button class="mat-icon-button" (click)="moveDown(item)" *fuseIfOnDom aria-label="Move down" matTooltip="{{'COMMON.MOVE_DOWN' | translate}}">
            <mat-icon>arrow_downward</mat-icon>
        </button>
    </div>
</div>