import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NotifyService } from "./notify.service";
import { environment } from "src/environments/environment";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotifyService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        let errors: any[];
        errors = [];
        const errorText = err.error
          ? err.error.error_description || err.statusText
          : "";

        if (err.status === 401 || err.status === 403) {
          localStorage.clear();
          location.href = environment.boHomeUrl;
        }
        if (err.status === 400) {
          return throwError(errorText);
        }
        if (err.status === 500) {
          return throwError(errors);
        }

        this.notificationService.error("Error");
        return throwError(err);
      })
    );
  }
}
