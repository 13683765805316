import { Component, Input } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BaseWidgetContentComponent } from "./baseWidgetContent.component";
import { AngularEditorConfig } from "@kolkov/angular-editor";



@Component({
    selector: 'app-htmlWidget',
    templateUrl: './htmlWidget.component.html',
    styleUrls: ['./htmlWidget.component.scss'],
})
export class HtmlWidgetComponent extends BaseWidgetContentComponent {

  //#region Angular Editor Configuration parameters:
  //   editable: true,
  //   spellcheck: true,
  //   height: 'auto',
  //   minHeight: '0',
  //   maxHeight: 'auto',
  //   width: 'auto',
  //   minWidth: '0',
  //   translate: 'yes',
  //   enableToolbar: true,
  //   showToolbar: true,
  //   placeholder: 'Enter text here...',
  //   defaultParagraphSeparator: '',
  //   defaultFontName: '',
  //   defaultFontSize: '',
  //   fonts: [
  //     {class: 'arial', name: 'Arial'},
  //     {class: 'times-new-roman', name: 'Times New Roman'},
  //     {class: 'calibri', name: 'Calibri'},
  //     {class: 'comic-sans-ms', name: 'Comic Sans MS'}
  //   ],
  //   customClasses: [
  //   {
  //     name: 'quote',
  //     class: 'quote',
  //   },
  //   {
  //     name: 'redText',
  //     class: 'redText'
  //   },
  //   {
  //     name: 'titleText',
  //     class: 'titleText',
  //     tag: 'h1',
  //   },
  // ],
  // uploadUrl: 'v1/image',
  // upload: (file: File) => { ... }
  // uploadWithCredentials: false,
  // sanitize: true,
  // toolbarPosition: 'top',
  // toolbarHiddenButtons: [
  //   ['bold', 'italic'],
  //   ['fontSize']
  // ]
  //#endregion
      editConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        sanitize: true,
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [
            ['bold'],
            ['customClasses']
          ],
        customClasses: [
          {
              name: "quote",
              class: "quote",
          },
          {
              name: 'redText',
              class: 'redText'
          },
          {
              name: "titleText",
              class: "titleText",
              tag: "h1",
          },
        ]
      }
  constructor(
    protected formBuilder: FormBuilder
  ) {
    super(formBuilder);
  }

    createForm(value: any) {
        this.contentForm = this.formBuilder.group({
            text: ['', Validators.required],
        });
        this.contentForm.patchValue({
            text: value,
        });
        this.contentForm.markAsPristine();
    }

    public getContent(): any {
        return this.contentForm.value.text;
    }

    get text() {
        return this.contentForm.get('text').value;
    }

    @Input('text') set text(value: string) {
        this.contentForm.patchValue({
            text: value,
        });
    }
}
