export class NavigationModel {
    public model: any[];

    constructor() {
        this.model = [];
        /*this.model = [
            {
                'id': 'CSM',
                'title': 'CMS Editor',
                'type': 'group',
                'children': [
                    {
                        'id': 'layouts',
                        'title': 'Layouts',
                        'type': 'item',
                        'icon': 'grid_on',
                        'url': '/cms/layouts'
                    },
                    {
                        'id': 'pages',
                        'title': 'Pagine',
                        'type': 'item',
                        'icon': 'insert_drive_file',
                        'url': '/cms/pages'
                    },
                    {
                        'id': 'media',
                        'title': 'Media',
                        'type': 'item',
                        'icon': 'image',
                        'url': '/cms/media'
                    },
                    {
                        'id': 'widget',
                        'title': 'Widgets',
                        'type': 'item',
                        'icon': 'widgets',
                        'url': '/cms/widgets'
                    },
                    {
                        'id': 'promotions',
                        'title': 'Promozioni',
                        'type': 'item',
                        'icon': 'card_giftcard',
                        'url': '/cms/promotions'
                    },
                    {
                        'id': 'contens',
                        'title': 'Contenuti',
                        'type': 'item',
                        'icon': 'content_paste',
                        'url': '/cms/contents'
                    },
                    {
                        'id': 'menu',
                        'title': 'Menu',
                        'type': 'item',
                        'icon': 'menu',
                        'url': '/cms/menus'
                    },
                    {
                        'id': 'sections',
                        'title': 'Sezioni',
                        'type': 'item',
                        'icon': 'collections_bookmark',
                        'url': '/cms/sections'
                    },
                    {
                        'id': 'styles',
                        'title': 'Stili & Script',
                        'type': 'item',
                        'icon': 'brush',
                        'url': '/cms/styles'
                    }
                ]
            },
            {
                'id': 'Help',
                'title': 'Help Editor',
                'type': 'group',
                'children': [
                    {
                        'id': 'sections',
                        'title': 'Sezioni',
                        'type': 'item',
                        'icon': 'help_outline',
                        'url': '/help/sections'
                    }
                ]
            }
        ];*/
    }
}
