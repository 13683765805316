export class BonusDetailModel {
    id: number;
    idBonus: number;
    bonus: number;
    percentage: number;
    isPercentage: boolean;

    constructor(isPercentage: boolean, bonus: number, percentage: number) {
        this.isPercentage = isPercentage;
        this.bonus = bonus;
        this.percentage = percentage;
        this.id = 0;
        this.idBonus = 0;
    }
}

export class BonusFunCasinoModel {
    id: number;
    idBonus: number;
    wagering: number;
    durataGG: number;
    cap: number;
    comment: string;

    constructor(wagering: number, cap: number, comment: string, durataGG: number) {
        this.id = 0;
        this.idBonus = 0;
        this.wagering = wagering;
        this.cap = cap;
        this.comment = comment;
        this.durataGG = durataGG;
    }
}

export class BonusTranche {
    week: number;
    rulesTranche: BonusRules[];
}

export class BonusRules {
    constructor(public from: Array<any>, public at: Array<any>, public bonus: Array<any>) { }
}
