<div class="page-layout carded fullwidth ps">
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button close"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" *fuseIfOnDom
                                aria-label="Close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                            {{ (isNew ? 'GAME.NEW_TITLE' : 'GAME.EDIT_TITLE') | translate}}
                        </span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="pageForm.invalid"
                                (click)="save()" *fuseIfOnDom aria-label="Save"
                                matTooltip="{{'COMMON.SAVE' | translate}}">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <div class="options-icon isVisible" *ngIf="selectedTab === 1 && canEdit">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="isLoading"
                                (click)="addAttribute()" *fuseIfOnDom aria-label="add"
                                matTooltip="{{'GAME.ADDATTRIBUTE' | translate}}">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div name="pageForm" [formGroup]="pageForm" fxLayout="column">
                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="{{'GAME.DETAILS'| translate}}">
                        <div class="form-editor-host">
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.GAMEID' | translate}}" formControlName="gameId">
                                <mat-error *ngIf="formErrors.gameId.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.NAME' | translate}}" formControlName="name">
                                <mat-error *ngIf="formErrors.name.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex="50">
                                <mat-select matInput placeholder="{{'GAME.PROVIDER' | translate}}"
                                    matTooltip="{{'GAME.TOOLTIP_PROVIDER' | translate}}" [(ngModel)]="idFornitore"
                                    formControlName="idFornitore" (selectionChange)="onIdFornitoreChnaged()">
                                    <mat-option *ngFor="let provider of providers" [value]="provider.id">
                                        {{ provider.value }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formErrors.idFornitore.invalid">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex="25" [class.hidden]="!isNetent">
                                <mat-select matInput placeholder="{{'GAME.NETENTGAMEFAMILY' | translate}}"
                                    [(ngModel)]="netentGameFamily" formControlName="netentGameFamily">
                                    <mat-option *ngFor="let g of netentGameFamilies" [value]="g.id">
                                        {{ g.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field fxFlex="25" [class.hidden]="!isNetent">
                                <mat-select matInput placeholder="{{'GAME.NETENTGAMETYPE' | translate}}"
                                    [(ngModel)]="netentGameType" formControlName="netentGameType">
                                    <mat-option *ngFor="let g of netentGameTypes" [value]="g.id">
                                        {{ g.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field fxFlex="50" [class.hidden]="!isCapecode">
                                <mat-select matInput placeholder="{{'GAME.CAPECODEGAMETYPE' | translate}}"
                                    [(ngModel)]="capecodeGameType" formControlName="capecodeGameType">
                                    <mat-option *ngFor="let g of capecodeGameTypes" [value]="g.id">
                                        {{ g.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- <div fxFlex="50" class="mat-form-field" [class.hidden]="!isCapecode">
        <mat-checkbox matInput formControlName="capecodeIsFun">{{'GAME.CAPECODEISFUN' | translate}}</mat-checkbox>
    </div>
    <div fxFlex="50" class="mat-form-field" [class.hidden]="!isCapecode">
        <mat-checkbox matInput formControlName="capecodeCashAvailable">{{'GAME.CAPECODECASH' | translate}}</mat-checkbox>
    </div> -->

                            <mat-form-field fxFlex="50" [class.hidden]="!isGreenTube">
                                <mat-select matInput placeholder="{{'GAME.GREENTUBEGAMETYPE' | translate}}"
                                    [(ngModel)]="greenTubeGameType" formControlName="greenTubeGameType">
                                    <mat-option *ngFor="let g of greenTubeGameTypes" [value]="g.id">
                                        {{ g.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div fxFlex="50" class="mat-form-field" [class.hidden]="!isGreenTube">
                                <mat-checkbox matInput formControlName="greenTubeIsFun">
                                    {{'GAME.GREENTUBEISFUN' | translate}}</mat-checkbox>
                            </div>
                            <div fxFlex="50" class="mat-form-field" [class.hidden]="!isGreenTube">
                                <mat-checkbox matInput formControlName="greenTubeCashAvailable">
                                    {{'GAME.GREENTUBECASH' | translate}}</mat-checkbox>
                            </div>

                            <mat-form-field fxFlex="50" [class.hidden]="!isNovomatic">
                                <mat-select matInput placeholder="{{'GAME.NOVOMATICGAMETYPE' | translate}}"
                                    [(ngModel)]="novomaticGameType" formControlName="novomaticGameType">
                                    <mat-option *ngFor="let g of novomaticGameTypes" [value]="g.id">
                                        {{ g.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div fxFlex="50" [class.hidden]="isNetent || isCapecode || isGreenTube || isNovomatic">

                            </div>

                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.FULLNAME' | translate}}"
                                    formControlName="fullName">
                                <mat-error *ngIf="formErrors.fullName.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.UNIQUENAME' | translate}}"
                                    formControlName="nomeUnivoco">
                                <mat-error *ngIf="formErrors.nomeUnivoco.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.TITLE' | translate}}" formControlName="title">
                                <mat-error *ngIf="formErrors.title.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.NAMESEO' | translate}}" formControlName="nameSEO">
                                <mat-error *ngIf="formErrors.nameSEO.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>


                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.TAGTITLE' | translate}}"
                                    formControlName="tagTitle">
                            </mat-form-field>

                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.TAGTDESCRIPTION' | translate}}"
                                    formControlName="tagDescription">
                            </mat-form-field>

                            <mat-form-field fxFlex="100">
                                <input matInput placeholder="{{'GAME.TAGKEYWORDS' | translate}}"
                                    formControlName="tagKeywords">
                            </mat-form-field>

                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.AAMSCODE' | translate}}"
                                    formControlName="aamsCode">
                                <mat-error *ngIf="formErrors.aamsCode.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.AAMSGAMETYPE' | translate}}"
                                    formControlName="aamsTipoGioco">
                                <mat-error *ngIf="formErrors.aamsTipoGioco.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>

                            <div fxFlex="25" class="mat-form-field">
                                <mat-slide-toggle matInput formControlName="isVisible">{{'GAME.ISVISIBLE' | translate}}
                                </mat-slide-toggle>
                            </div>
                            <div fxFlex="25" class="mat-form-field">
                                <mat-slide-toggle matInput formControlName="isEnabled">{{'GAME.ISENABLED' | translate}}
                                </mat-slide-toggle>
                            </div>

                            <div fxFlex="25" class="mat-form-field">
                                <mat-slide-toggle matInput formControlName="isJackpot">{{'GAME.ISJACKPOT' | translate}}
                                </mat-slide-toggle>
                            </div>
                            <div fxFlex="25" class="mat-form-field">
                                <mat-slide-toggle matInput formControlName="isDemo">{{'GAME.ISDEMO' | translate}}
                                </mat-slide-toggle>
                            </div>
                            <div fxFlex="25" class="mat-form-field">
                                <mat-slide-toggle matInput formControlName="isNew">{{'GAME.ISNEW' | translate}}
                                </mat-slide-toggle>
                            </div>
                            <div fxFlex="25" class="mat-form-field">
                                <mat-slide-toggle matInput formControlName="isMultybet">
                                    {{'GAME.ISMULTIBET' | translate}}</mat-slide-toggle>
                            </div>

                            <div fxFlex="25" class="mat-form-field" >
                                <mat-slide-toggle matInput formControlName="isDesktop">{{'GAME.ISDESKTOP' | translate}}
                                </mat-slide-toggle>
                            </div>
                            <div fxFlex="25" class="mat-form-field">
                                <mat-slide-toggle matInput formControlName="isMobile">{{'GAME.ISMOBILE' | translate}}
                                </mat-slide-toggle>
                            </div>

                            <!--<div fxFlex="25" class="mat-form-field" *ngIf="isNetent">
                                <mat-slide-toggle matInput formControlName="isMobile">
                                    {{'GAME.DESKTOPORMOBILE' | translate}}</mat-slide-toggle>
                            </div>-->

                            <div fxFlex="100" class="mat-form-field">
                                <mat-slide-toggle matInput formControlName="isForeground">
                                    {{'GAME.ISFOREGROUND' | translate}}</mat-slide-toggle>
                            </div>

                            <mat-form-field fxFlex="25">
                                <input type="number" matInput placeholder="{{'GAME.ORDER' | translate}}"
                                    formControlName="order">
                                <mat-error *ngIf="formErrors.order.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <input type="number" matInput placeholder="{{'GAME.FFP' | translate}}"
                                    formControlName="fpp">
                                <mat-error *ngIf="formErrors.fpp.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <input type="number" matInput placeholder="{{'GAME.COEFFICIENTE' | translate}}"
                                    formControlName="coefficiente">
                                <mat-error *ngIf="formErrors.coefficiente.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <input matInput [matDatepicker]="goLiveDatePicker"
                                    placeholder="{{'GAME.GOLIVEDATE' | translate}}"
                                    matTooltip="{{'GAME.GOLIVEDATE' | translate}}" formControlName="goLiveDate" />
                                <mat-datepicker-toggle matSuffix [for]="goLiveDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #goLiveDatePicker></mat-datepicker>
                            </mat-form-field>


                            <mat-form-field fxFlex="25">
                                <input type="number" matInput placeholder="{{'GAME.HEIGHT' | translate}}"
                                    formControlName="height">
                                <mat-error *ngIf="formErrors.height.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <input type="number" matInput placeholder="{{'GAME.WIDTH' | translate}}"
                                    formControlName="width">
                                <mat-error *ngIf="formErrors.width.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <input type="number" matInput placeholder="{{'GAME.RPT' | translate}}"
                                    formControlName="rpt">
                                <mat-error *ngIf="formErrors.rpt.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>

                            <div fxFlex="50" class="game-image-host">
                                <div>
                                    <h3 class="description-subtitle" fxFlex="100">{{'GAME.IMAGEBACKGROUND' | translate}}
                                    </h3>
                                </div>
                                <div *ngIf="canEdit">
                                    <mat-form-field fxFlex="100">
                                        <input matInput formControlName="imageBackground"
                                            placeholder="{{'GAME.IMAGEBACKGROUND' | translate}}">
                                    </mat-form-field>
                                </div>

                                <figure class="game-image-figure" (click)="showMediaSelector('imageBackground')">
                                    <img *ngIf="pageForm.value.imageBackground" class="game-image"
                                        [(src)]="pageForm.value.imageBackground">
                                    <img *ngIf="!pageForm.value.imageBackground" class="game-noimage">
                                    <figcaption *ngIf="canEdit"
                                        [ngClass]="{'': pageForm.value.imageBackground, 'no-content-box': !pageForm.value.imageBackground}">
                                        <div class="media-buttons-container">
                                            <button mat-button class="mat-icon-button" *fuseIfOnDom
                                                aria-label="Add Media" matTooltip="{{'COMMON.ADD_MEDIA' | translate}}">
                                                <mat-icon>image</mat-icon>
                                            </button>
                                        </div>
                                    </figcaption>
                                </figure>
                                <mat-error *ngIf="formErrors.imageBackground.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </div>

                            <div fxFlex="50" class="game-image-host">
                                <div>
                                    <h3 class="description-subtitle" fxFlex="100">{{'GAME.IMAGEMINI' | translate}}</h3>
                                </div>
                                <div *ngIf="canEdit">
                                    <mat-form-field fxFlex="100">
                                        <input matInput formControlName="imageMini"
                                            placeholder="{{'GAME.IMAGEMINI' | translate}}">
                                    </mat-form-field>
                                </div>

                                <figure class="game-image-figure" (click)="showMediaSelector('imageMini')">
                                    <img *ngIf="pageForm.value.imageMini" class="game-image"
                                        [(src)]="pageForm.value.imageMini">
                                    <img *ngIf="!pageForm.value.imageMini" class="game-noimage">
                                    <figcaption *ngIf="canEdit"
                                        [ngClass]="{'': pageForm.value.imageMini, 'no-content-box': !pageForm.value.imageMini}">
                                        <div class="media-buttons-container">
                                            <button mat-button class="mat-icon-button" *fuseIfOnDom
                                                aria-label="Add Media" matTooltip="{{'COMMON.ADD_MEDIA' | translate}}">
                                                <mat-icon>image</mat-icon>
                                            </button>
                                        </div>
                                    </figcaption>
                                </figure>
                                <mat-error *ngIf="formErrors.imageMini.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </div>

                            <div fxFlex="50" class="game-image-host">
                                <div>
                                    <h3 class="description-subtitle" fxFlex="100">
                                        {{'GAME.IMAGESPLASHDESKTOP' | translate}}</h3>
                                </div>
                                <div *ngIf="canEdit">
                                    <mat-form-field fxFlex="100">
                                        <input matInput formControlName="imageSplashDesktop"
                                            placeholder="{{'GAME.IMAGESPLASHDESKTOP' | translate}}">
                                    </mat-form-field>
                                </div>

                                <figure class="game-image-figure" (click)="showMediaSelector('imageSplashDesktop')">
                                    <img *ngIf="pageForm.value.imageSplashDesktop" class="game-image"
                                        [(src)]="pageForm.value.imageSplashDesktop">
                                    <img *ngIf="!pageForm.value.imageSplashDesktop" class="game-noimage">
                                    <figcaption *ngIf="canEdit"
                                        [ngClass]="{'': pageForm.value.imageSplashDesktop, 'no-content-box': !pageForm.value.imageSplashDesktop}">
                                        <div class="media-buttons-container">
                                            <button mat-button class="mat-icon-button" *fuseIfOnDom
                                                aria-label="Add Media" matTooltip="{{'COMMON.ADD_MEDIA' | translate}}">
                                                <mat-icon>image</mat-icon>
                                            </button>
                                        </div>
                                    </figcaption>
                                </figure>
                                <mat-error *ngIf="formErrors.imageSplashDesktop.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </div>

                            <div fxFlex="50" class="game-image-host">
                                <div>
                                    <h3 class="description-subtitle" fxFlex="100">
                                        {{'GAME.IMAGEMINIPREVIEW' | translate}}</h3>
                                </div>
                                <div *ngIf="canEdit">
                                    <mat-form-field fxFlex="100">
                                        <input matInput formControlName="imageMiniPreview"
                                            placeholder="{{'GAME.IMAGEMINIPREVIEW' | translate}}">
                                    </mat-form-field>
                                </div>

                                <figure class="game-image-figure" (click)="showMediaSelector('imageMiniPreview')">
                                    <img *ngIf="pageForm.value.imageMiniPreview" class="game-image"
                                        [(src)]="pageForm.value.imageMiniPreview">
                                    <img *ngIf="!pageForm.value.imageMiniPreview" class="game-noimage">
                                    <figcaption *ngIf="canEdit"
                                        [ngClass]="{'': pageForm.value.imageMiniPreview, 'no-content-box': !pageForm.value.imageMiniPreview}">
                                        <div class="media-buttons-container">
                                            <button mat-button class="mat-icon-button" *fuseIfOnDom
                                                aria-label="Add Media" matTooltip="{{'COMMON.ADD_MEDIA' | translate}}">
                                                <mat-icon>image</mat-icon>
                                            </button>
                                        </div>
                                    </figcaption>
                                </figure>
                                <mat-error *ngIf="formErrors.imageMiniPreview.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </div>


                            <div fxFlex="50" class="game-image-host">
                                <div>
                                    <h3 class="description-subtitle" fxFlex="100">
                                        {{'GAME.IMAGESPLASHMOBILE' | translate}}</h3>
                                </div>
                                <div *ngIf="canEdit">
                                    <mat-form-field fxFlex="100">
                                        <input matInput formControlName="imageSplashMobile"
                                            placeholder="{{'GAME.IMAGESPLASHMOBILE' | translate}}">
                                    </mat-form-field>
                                </div>

                                <figure class="game-image-figure" (click)="showMediaSelector('imageSplashMobile')">
                                    <img *ngIf="pageForm.value.imageSplashMobile" class="game-image"
                                        [(src)]="pageForm.value.imageSplashMobile">
                                    <img *ngIf="!pageForm.value.imageSplashMobile" class="game-noimage">
                                    <figcaption *ngIf="canEdit"
                                        [ngClass]="{'': pageForm.value.imageSplashMobile, 'no-content-box': !pageForm.value.imageSplashMobile}">
                                        <div class="media-buttons-container">
                                            <button mat-button class="mat-icon-button" *fuseIfOnDom
                                                aria-label="Add Media" matTooltip="{{'COMMON.ADD_MEDIA' | translate}}">
                                                <mat-icon>image</mat-icon>
                                            </button>
                                        </div>
                                    </figcaption>
                                </figure>
                                <mat-error *ngIf="formErrors.imageSplashMobile.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </div>

                            <div fxFlex="50" class="game-image-host">
                                <div>
                                    <h3 class="description-subtitle" fxFlex="100">{{'GAME.IMAGEICONMOBILE' | translate}}
                                    </h3>
                                </div>
                                <div *ngIf="canEdit">
                                    <mat-form-field fxFlex="100">
                                        <input matInput formControlName="imageIconMobile"
                                            placeholder="{{'GAME.IMAGEICONMOBILE' | translate}}">
                                    </mat-form-field>
                                </div>

                                <figure class="game-image-figure" (click)="showMediaSelector('imageIconMobile')">
                                    <img *ngIf="pageForm.value.imageIconMobile" class="game-image"
                                        [(src)]="pageForm.value.imageIconMobile">
                                    <img *ngIf="!pageForm.value.imageIconMobile" class="game-noimage">
                                    <figcaption *ngIf="canEdit"
                                        [ngClass]="{'': pageForm.value.imageMiniPreview, 'no-content-box': !pageForm.value.imageIconMobile}">
                                        <div class="media-buttons-container">
                                            <button mat-button class="mat-icon-button" *fuseIfOnDom
                                                aria-label="Add Media" matTooltip="{{'COMMON.ADD_MEDIA' | translate}}">
                                                <mat-icon>image</mat-icon>
                                            </button>
                                        </div>
                                    </figcaption>
                                </figure>
                                <mat-error *ngIf="formErrors.imageIconMobile.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </div>

                            <!-- <mat-form-field fxFlex="100">
        <input matInput placeholder="{{'GAME.HELP' | translate}}" formControlName="help">
        <mat-error *ngIf="formErrors.help.required">
            {{'COMMON.REQUIRED_ERROR' | translate}}
        </mat-error>
    </mat-form-field> -->
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.SHORTDESCRIPTION' | translate}}"
                                    formControlName="shortDescription">
                                <mat-error *ngIf="formErrors.shortDescription.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'GAME.LONGDESCRIPTION' | translate}}"
                                    formControlName="longDescription">
                                <mat-error *ngIf="formErrors.longDescription.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{'GAME.ATTRIBUTES'| translate}}">
                        <div class="form-editor-host" formArrayName="attributes">
                            <div *ngFor="let attr of attributes.controls; let i=index;" fxFlex="100">
                                <div [formGroupName]="i">
                                    <mat-form-field fxFlex="50">
                                        <mat-select matInput placeholder="{{'GAME.FAMILY' | translate}}" 
                                            formControlName="idFamigliaAttributi" (openedChange)="ddlOpenedChanged($event)">
                                            <mat-option *ngFor="let f of attr.value.families" [value]="f.id">
                                                {{ f.famiglia }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="50">
                                        <mat-select matInput placeholder="{{'GAME.ATTRIBUTE' | translate}}"
                                            formControlName="attributeId"  (openedChange)="ddlOpenedChanged($event)">
                                            <mat-option *ngFor="let a of attr.value.attributes" [value]="a.id">
                                                {{ a.descrizione }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <button mat-button class="mat-icon-button fixed" (click)="deleteAttribute(i)"
                                        aria-label="Delete" matTooltip="{{'COMMON.DELETE' | translate}}" fxFlex="10"
                                        *ngIf="canEdit">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>