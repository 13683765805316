import { Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApisService } from 'src/app/shared/apis.service';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/shared/notify.service';
import { AppConfigService } from 'src/app/shared/appconfig.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseConfirmDialogComponent } from '../../core/components/confirm-dialog/confirm-dialog.component';
import { SaveWidgetRequest } from '../../models/DataModels/Cms/Request';
import { FrontendModel, UserAccess, WidgetType } from '../../models/DataModels';
import { BaseWidgetContentComponent } from './baseWidgetContent.component';
import { TextWidgetComponent } from './textWidget.component';
import { BannerWidgetComponent } from './bannerWidget.component';
import { SlideWidgetComponent } from './slideWidget.component';
import { HtmlWidgetComponent } from './htmlWidget.component';
import { fuseAnimations } from '../../core/animations';
import { BaseEditorComponent } from '../../components/baseeditor.component';

@Component({
    selector: 'app-widgeteditor',
    templateUrl: './widgeteditor.component.html',
    styleUrls: ['./widgeteditor.component.scss'],
    animations: fuseAnimations
})

export class WidgetEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
    frontends: FrontendModel[];
    frontend: FrontendModel;
    showSelectMedia: boolean = false;
    types: string[];
    id: string;
    isPublished: boolean = false;
    subscribers: Subscription[];
    isLoading = false;
    pageForm: FormGroup;
    formErrors: any;
    isNew: boolean = false;
    type: string;
    currentType: string;
    showLink = false;
    showAddItem = false;
    isDynamic = false;
    contentInvalid: boolean;
    isFixedWidget: boolean = false;

    public userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    @ViewChild('contentViewContainer', { read: ViewContainerRef, static: false }) contentView;
    private contentEditor: BaseWidgetContentComponent;

    constructor(
        protected translateService: TranslateService,
        @Inject(NotifyService) protected notifyService,
        protected dialog: MatDialog,
        @Inject(AppConfigService) private appConfig,
        private location: Location,
        private apisService: ApisService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private componentResolver: ComponentFactoryResolver
    ) {
        super();
        this.subscribers = new Array<Subscription>();
        this.formErrors = {
            name: {},
            type: {},
            startDate: {},
            endDate: {},
            showAnonymous: {},
            showAuthenticated: {},
            content: {},
            frontend: {},
            component: {},
            visible: {}
        };
    }

    ngOnInit() {
        this.createForm();
        this.subscribers.push(this.route.params.subscribe(params => {
            this.id = params.id;
            this.isNew = this.id === undefined;
            this.loadPage(this.id);
        }));
    }

    ngOnDestroy() {
        this.subscribers.forEach(t => t.unsubscribe());
    }

    loadPage(id: string): void {
        this.isLoading = true;
        this.subscribers.push(this.apisService.getWidget(id).subscribe(response => {
            this.isLoading = false;
            this.notifyService.handleServerResponse(response, result => {
                // this.userAccess = response.userAccess;
                if (result.widget) {
                    if (result.widget.startDate) {
                        result.widget.startDate = new Date(result.widget.startDate);
                    } else {
                        result.widget.startDate = new Date();
                    }
                    if (result.widget.endDate) {
                        result.widget.endDate = new Date(result.widget.endDate);
                    }
                    this.type = result.widget.type;
                    this.isDynamic = this.type && (this.type === WidgetType[WidgetType.Dynamic]);
                    this.isFixedWidget = result.widget.isFixedWidget;
                }
                this.frontends = result.frontends;
                this.types = result.types;
                this.pageForm.patchValue(result.widget);
                this.pageForm.patchValue(result);
                this.isPublished = result.widget !== undefined && result.widget.published;
                this.updateContent();
                this.pageForm.markAsPristine();
                this.currentType = this.pageForm.value.type;
            });
        }));
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.pageForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
        let endDateControl = this.pageForm.get('endDate');
        let startDateControl = this.pageForm.get('startDate');
        // tslint:disable-next-line:no-null-keyword
        if (endDateControl.value === undefined || endDateControl.value == null) {
            endDateControl.setErrors(undefined);
        } else {
            let endDateValue = new Date(endDateControl.value);
            let startDateValue = new Date(startDateControl.value);
            if (startDateValue > endDateValue) {
                endDateControl.setErrors(['dateError', ('Invalid start/end dates')]);
            } else {
                endDateControl.setErrors(undefined);
            }
        }
        this.formErrors['endDate'] = endDateControl.errors;

        let anonymousControl = this.pageForm.get('showAnonymous');
        let authControl = this.pageForm.get('showAuthenticated');
        if (!anonymousControl.value && !authControl.value) {
            anonymousControl.setErrors(['anonError', ('selectvisibily')]);
            authControl.setErrors(['authError', ('selectvisibily')]);
        } else {
            anonymousControl.setErrors(undefined);
            authControl.setErrors(undefined);
        }
        this.formErrors['visibilityError'] = anonymousControl.errors;

        let type = this.pageForm.get('type');
        let frontend = this.pageForm.get('frontend');
        let component = this.pageForm.get('dynamicComponent');
        if (type.value === WidgetType[WidgetType.Dynamic]) {
            if (frontend.value === undefined || component.value === undefined || frontend.value === '' || component.value === '' || frontend.value === null || component.value === null) {
                component.setValidators(Validators.required);
                // frontend.setErrors(['invalid', 'invalid']);
                // this.formErrors['component'].setErrors(Validators.required);
            } else {
                component.clearValidators();
                // frontend.setErrors(undefined);
                // this.formErrors['component'] = {};
            }
        } else {
            frontend.setErrors(undefined);
            component.clearValidators();
            // component.setErrors(undefined);
            this.formErrors['component'] = {};
            let content = this.pageForm.get('content');
            if (content.value === '' || content.value === undefined || content.value === null) {
                content.setErrors(['required', true]);
                this.formErrors['content'] = { required: true };
            } else {
                content.setErrors(undefined);
                this.formErrors['content'] = {};
            }
        }
    }

    typeChanged(event: any): void {
        this.type = event.value;
        if (this.currentType) {
            if (this.currentType !== this.type) {
                this.translateService.get('WIDGET.CONFIRMTYPECHANGED').subscribe(translate => {
                    let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                        width: '50%'
                    });
                    dialogRef.componentInstance.confirmMessage = translate;
                    dialogRef.afterClosed().subscribe(confirm => {
                        if (confirm === true) {
                            this.pageForm.patchValue({
                                content: '',
                                frontend: undefined,
                                dynamicComponent: undefined
                            });
                            this.currentType = this.type;
                            this.pageForm.markAsDirty();
                            this.isDynamic = this.type && (this.type === WidgetType[WidgetType.Dynamic]);
                            this.updateContent();
                        } else {
                            this.type = this.currentType;
                            this.pageForm.patchValue({
                                type: this.currentType
                            });
                        }
                    });
                });
            }
        } else {
            this.pageForm.patchValue({
                content: '',
                frontend: undefined,
                dynamicComponent: undefined
            });
            this.currentType = this.type;
            this.pageForm.markAsDirty();
            this.isDynamic = this.type && (this.type === WidgetType[WidgetType.Dynamic]);
            this.updateContent();
        }

    }

    save(publish?: boolean, overwrite?: boolean) {
        if (this.pageForm.dirty || (this.contentEditor !== undefined && this.contentEditor.dirty)) {
            if (!this.pageForm.invalid) {
                this.isLoading = true;
                let request = new SaveWidgetRequest();
                // Object.assign(request, this.pageForm.value);
                request = { ...this.pageForm.value };
                request.id = this.id;
                request.overwrite = overwrite;
                request.type = this.type;
                request.publish = publish;
                this.subscribers.push(this.apisService.saveWidget(request).subscribe(response => {
                    this.isLoading = false;
                    this.handleServerResponse(response, result => {
                        this.pageForm.reset();
                        if (this.id === result) {
                            this.loadPage(this.id);
                        } else {
                            this.router.navigate(['/cms/widgets/widget', result]);
                        }
                    }, publish);
                }));
            } else {
                this.notifyService.warning('COMMON.INVALIDDATA');
            }
        }
    }

    clone() {
        if (this.pageForm.invalid) {
            this.notifyService.error('WIDGET.INVALID');
        } else {
            this.pageForm.markAsDirty();
            this.id = undefined;
            this.pageForm.patchValue({
                name: this.pageForm.value.name + ' - copia'
            });
            this.save();
        }
    }

    selectMedia() {
        if (this.contentEditor === undefined) {
            return;
        }
        this.contentEditor.showMediaSelector();
    }

    addItem() {
        if (this.contentEditor === undefined) {
            return;
        }
        this.contentEditor.addItem();
    }

    publish() {
        if (this.pageForm.invalid) {
            this.notifyService.error('COMMON.INVALIDPUBLISH');
        } else {
            this.pageForm.markAsDirty();
            this.save(true);
        }
    }

    protected internalClose() {
        let tempZoneEditor = this.appConfig.tempZoneEditor;
        if (tempZoneEditor) {
            this.router.navigate(['cms/pages/page', tempZoneEditor.pageId]);
        } else {
            let data = this.appConfig.temporaryData;
            if (data && data.internal) {
                this.location.back();
            } else {
                this.router.navigate(['cms/widgets']);
            }
        }
    }

    private createForm() {
        this.pageForm = this.formBuilder.group({
            name: ['', Validators.required],
            type: ['', Validators.required],
            startDate: [undefined, Validators.required],
            endDate: [undefined],
            showAnonymous: [undefined, Validators.required],
            showAuthenticated: [undefined, Validators.required],
            showShadow: [undefined],
            hideOnApp: [false],
            content: [undefined],
            frontend: [''],
            dynamicComponent: [undefined],
            notes: [undefined],
            visible: [false, Validators.required],
            version: []
        });

        this.pageForm.valueChanges.subscribe((changes) => {
            this.onFormValuesChanged();
        });
    }

    private updateContent() {
        if (this.contentEditor !== undefined) {
            this.contentEditor.contentChanged.unsubscribe();
        }
        this.contentView.clear();
        this.contentEditor = undefined;
        if (this.type.toLocaleLowerCase() === WidgetType[WidgetType.Text].toLocaleLowerCase()) {
            this.contentEditor = (this.contentView.createComponent(this.componentResolver.resolveComponentFactory(TextWidgetComponent))).instance;
        } else if (this.type.toLocaleLowerCase() === WidgetType[WidgetType.Banner].toLocaleLowerCase()) {
            this.contentEditor = (this.contentView.createComponent(this.componentResolver.resolveComponentFactory(BannerWidgetComponent))).instance;
        } else if (this.type.toLocaleLowerCase() === WidgetType[WidgetType.Slider].toLocaleLowerCase()) {
            this.contentEditor = (this.contentView.createComponent(this.componentResolver.resolveComponentFactory(SlideWidgetComponent))).instance;
        } else if (this.type.toLocaleLowerCase() === WidgetType[WidgetType.Html].toLocaleLowerCase()) {
            this.contentEditor = (this.contentView.createComponent(this.componentResolver.resolveComponentFactory(HtmlWidgetComponent))).instance;
        } else if (this.type.toLocaleLowerCase() === WidgetType[WidgetType.Dynamic].toLocaleLowerCase()) {
            this.contentEditor = undefined;
        }
        if (this.contentEditor !== undefined) {
            this.contentEditor.setContent = (sender) => {
                sender.createForm(this.pageForm.value.content);
                this.contentEditor.contentChanged.subscribe(val => {
                    let content = this.contentEditor.getContent();
                    if (content !== this.pageForm.value.content) {
                        this.pageForm.patchValue({ content: content });
                        this.contentInvalid = !this.contentEditor.isValid;
                        this.pageForm.markAsDirty();
                    }
                });
            };
        }
        this.showSelectMedia = this.type !== undefined && this.type.toLocaleLowerCase() === WidgetType[WidgetType.Banner].toLocaleLowerCase();
        this.showAddItem = this.type !== undefined && this.type.toLocaleLowerCase() === WidgetType[WidgetType.Slider].toLocaleLowerCase();
    }
}
