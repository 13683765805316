import {EventEmitter, Injectable, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

@Injectable()
export abstract class BaseWidgetContentComponent implements OnInit, OnDestroy {
    pageFormChanged: Subscription;
    isValid: boolean = true;
    dirty: boolean = false;
    contentForm: FormGroup;
    formErrors: {};

    setContent?: (sender: BaseWidgetContentComponent) => void;
    public contentChanged: EventEmitter<any> = new EventEmitter();

    constructor(
        protected formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        if (this.setContent) {
            this.setContent(this);
            let timer = setTimeout(() => {
                this.attachChanges();
                clearTimeout(timer);
            }, 200);
        }
    }

    ngOnDestroy() {
        if (this.pageFormChanged) {
            this.pageFormChanged.unsubscribe();
        }
    }

    // public setEndDate(value?: Date) {
    //     this.contentForm.patchValue({
    //         endDate: value
    //     });
    // }

    public attachChanges() {
        if (this.pageFormChanged) {
            this.pageFormChanged.unsubscribe();
        }
        if (this.contentForm) {
            this.pageFormChanged = this.contentForm.valueChanges.subscribe((changes) => {
                this.onFormValuesChanged();
            });
        }
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.contentForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
        this.isValid = !this.contentForm.invalid;
        this.contentChanged.emit(this.getContent());
    }

    abstract createForm(value: any);

    public abstract getContent(): any;

    public showMediaSelector(isFrontEnd?: boolean) {

    }

    public addItem() {

    }
}
