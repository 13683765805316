import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GetPromotionsRequest, SavePromotionRequest} from '../../models/DataModels/Cms/Request';
import {Observable, Subscription} from 'rxjs';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {ApisService} from 'src/app/shared/apis.service';
import {NotifyService} from 'src/app/shared/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {GetItemsBaseResponse, PromotionsModel, UserAccess} from '../../models/DataModels';
import {DataBaseRequestField, ModelRequest, SelectItem} from '../../models/DataModels/TableDataSource/ModelRequest';
import {HttpDao} from '../../models/DataModels/TableDataSource/HttpDao';
import {FuseConfirmDialogComponent} from '../../core/components/confirm-dialog/confirm-dialog.component';
import {fuseAnimations} from '../../core/animations';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-promotions',
    templateUrl: './promotions.component.html',
    styleUrls: ['./promotions.component.scss'],
    animations: fuseAnimations
})

export class PromotionsComponent implements OnInit, OnDestroy,AfterViewInit {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    public userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    public panelTitle = 'PROMOTIONS.TITLE';
    protected request = new GetPromotionsRequest();
    public dataSource: TableDataSource<GetPromotionsRequest, GetItemsBaseResponse<PromotionsModel>>;
    public displayedColumns = ['action', 'thumbnailUrl', 'name', 'frontends', 'skin', 'isVisible', 'sort', 'startDateStr',
        'endDateStr', 'published', 'notes', 'changedAt', 'changedBy'];
    public model: ModelRequest<GetPromotionsRequest>;

    private httpDao: PromotionsHttpDao;
    private skins = new DataBaseRequestField('skin', 'COMMON.SEARCH_SKIN_LABEL', 'select', false, new Array<SelectItem>());
    private frontends = new DataBaseRequestField('frontend', 'COMMON.SEARCH_FRONTEND_LABEL', 'select', false, new Array<SelectItem>());
    private firstLoad: Subscription;

    constructor(
        @Inject(AppConfigService) private appConfig,
        @Inject(NotifyService) private notifyService,
        private translateService: TranslateService,
        public dialog: MatDialog,
        private apisService: ApisService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.httpDao = new PromotionsHttpDao(this.apisService);
        this.model = new ModelRequest<GetPromotionsRequest>(this.request);
        // this.model.showAddItem = true;

        this.model.showAddItem = true;

        const platforms = [
            {
                id: '-1',
                value: '---'
            } as SelectItem,
            {
                id: '32',
                value: 'Tutte'
            } as SelectItem,
            {
                id: '1',
                value: 'Desktop'
            } as SelectItem,
            {
                id: '2',
                value: 'Mobile'
            } as SelectItem,
            {
                id: '4',
                value: 'Android'
            } as SelectItem,
            {
                id: '8',
                value: 'Ios'
            } as SelectItem,
            {
                id: '16',
                value: 'Windows'
            } as SelectItem
        ];

        const tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string'),
            this.skins,
            this.frontends,
            new DataBaseRequestField('platform', 'COMMON.SEARCH_PLATFORM_LABEL', 'select', false, platforms),
            new DataBaseRequestField('startDate', 'COMMON.SEARCH_STARTDATE_LABEL', 'date'),
            new DataBaseRequestField('endDate', 'COMMON.SEARCH_ENDDATE_LABEL', 'date')
        );
        this.model.items = tmpItems;


        const loader = this.apisService.getSkinData().subscribe(response => {
            if (!response.isError) {
                this.skins.selectItems = response.data.skins;
                this.frontends.selectItems = response.data.frontends;
            }
            loader.unsubscribe();
        });

        this.firstLoad = this.httpDao.firstLoad.subscribe((userAccess: UserAccess) => {
            this.request.includeAccess = false;
            // this.userAccess = userAccess;
            this.model.updateShowAdd(this.userAccess.canInsert);
        });

    }

    ngAfterViewInit() {
        this.dataSource = new TableDataSource<GetPromotionsRequest, GetItemsBaseResponse<PromotionsModel>>(this.httpDao!, this.paginator, this.sort, this.model);
    }

    ngOnDestroy() {
        this.dataSource.disconnect();
        if (this.firstLoad) {
            this.firstLoad.unsubscribe();
            this.firstLoad = undefined;
        }
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    edit(item?: PromotionsModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canEdit) {
            return;
        }
        this.appConfig.temporaryData = {'internal': true};
        if (item !== undefined) {
            this.router.navigate(['promotion', item.id], {
                relativeTo: this.route
            });
        }
    }

    add(): void {
        if (!this.userAccess.canInsert) {
            return;
        }
        this.router.navigate(['addpromotion'], {
            relativeTo: this.route
        });
    }

    delete(item?: PromotionsModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canDelete) {
            return;
        }
        if (item === undefined) {
            return;
        }
        this.translateService.get('COMMON.DELETEMESSAGE').subscribe(translate => {
            translate += `${item.name}?`;
            const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {
                    this.dataSource.isLoadingResults = true;
                    this.apisService.deletePromotion(item.id).subscribe(result => {
                        this.dataSource.isLoadingResults = false;
                        this.notifyService.handleServerResponse(result, res => {
                            this.model.reset();
                        });
                    });
                }
            });
        });
    }

    clone(item: PromotionsModel, event?: any) {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canEdit || !this.userAccess.canInsert) {
            return;
        }
        if (item === undefined) {
            return;
        }
        this.dataSource.isLoadingResults = true;
        const request = new SavePromotionRequest();
        request.id = item.id;
        request.copy = true;
        this.apisService.savePromotion(request).subscribe(response => {
            this.dataSource.isLoadingResults = false;
            this.notifyService.handleServerResponse(response, result => {
                this.model.reset();
            });
        });
    }
}

export class PromotionsHttpDao extends HttpDao<GetPromotionsRequest, GetItemsBaseResponse<PromotionsModel>> {
    constructor(public apisService: ApisService) {
        super(apisService);
    }

    public getData(request: GetPromotionsRequest): Observable<GetItemsBaseResponse<PromotionsModel>> {
        return this.apisService.getPromotions(request)
            .pipe(map(res => {
                if (res.isError) {
                    return {
                        total: 0,
                        items: []
                    } as GetItemsBaseResponse<PromotionsModel>;
                } else {
                    if (res.data && res.data.items && res.data.items.length > 0) {
                        res.data.items.forEach(x => {
                            if (x.startDate) {
                                const start = new Date(x.startDate);
                                x.startDateStr = start.toLocaleDateString();
                            }
                            if (x.endDate) {
                                const end = new Date(x.endDate);
                                x.endDateStr = end.toLocaleDateString();
                            }
                        });
                    }
                    if (request.includeAccess && res.userAccess) {
                        this.firstLoad.next(res.userAccess);
                    }

                    this.itemsChangedAt(res.data);
                    return res.data;
                }
            }));
    }
}
