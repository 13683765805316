import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { LayoutContentComponent, UnknownDynamicComponent } from './layoutcontent.component';
import { LayoutCellComponent } from './layoutcell.component';
import { LayoutRowComponent } from './layoutrow.component';
import { LayoutColumnComponent } from './layoutcolumn.component';
import { LayoutItemEditorComponent } from './layoutitemeditor.component';
import { MaterialModule } from '../core/modules/material.module';
import { SharedModule } from '../core/modules/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        LayoutComponent,
        LayoutContentComponent,
        LayoutColumnComponent,
        LayoutRowComponent,
        LayoutCellComponent,
        UnknownDynamicComponent,
        LayoutItemEditorComponent
    ],
    exports: [
        LayoutComponent,
        LayoutContentComponent,
        LayoutColumnComponent,
        LayoutRowComponent,
        LayoutCellComponent,
        UnknownDynamicComponent,
        LayoutItemEditorComponent
    ],
    entryComponents: [
        UnknownDynamicComponent,
        LayoutItemEditorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslateModule
    ]
})


export class CmsLayoutModule {
}
