import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "./material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ColorPickerModule } from "ngx-color-picker";

import {
  FuseMatSidenavHelperDirective,
  FuseMatSidenavTogglerDirective,
} from "../directives/mat-sidenav-helper/mat-sidenav-helper.directive";
import { FusePipesModule } from "../pipes/pipes.module";
import { FuseConfirmDialogComponent } from "../components/confirm-dialog/confirm-dialog.component";
import { FuseCountdownComponent } from "../components/countdown/countdown.component";
import { FuseMatchMedia } from "../services/match-media.service";
import { FuseNavbarVerticalService } from "../../main/navbar/vertical/navbar-vertical.service";
import { FuseMatSidenavHelperService } from "../directives/mat-sidenav-helper/mat-sidenav-helper.service";
// import { FuseHljsComponent } from '../components/hljs/hljs.component';
import { FusePerfectScrollbarDirective } from "../directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseIfOnDomDirective } from "../directives/fuse-if-on-dom/fuse-if-on-dom.directive";
import { FuseMaterialColorPickerComponent } from "../components/material-color-picker/material-color-picker.component";
import { FuseTranslationLoaderService } from "../services/translation-loader.service";
import { CookieService } from "ngx-cookie-service";
import { TranslateModule } from "@ngx-translate/core";
import { CmsLayoutModule } from "../../layout/layout.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { GenericSearchFilter } from "../../components/genericSearchFilterComponent/generic.search.filter.component";
import { HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "src/app/shared/auth-token-interceptor";
import { ErrorHandlerInterceptor } from "src/app/shared/http-error.interceptor";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TableSearchComponent } from "src/app/shared/components/table-search.component";

@NgModule({
  declarations: [
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FuseConfirmDialogComponent,
    FuseCountdownComponent,
    // FuseHljsComponent,
    FuseIfOnDomDirective,
    FusePerfectScrollbarDirective,
    FuseMaterialColorPickerComponent,
    GenericSearchFilter,
    TableSearchComponent,
  ],
  imports: [
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    FusePipesModule,
    ReactiveFormsModule,
    ColorPickerModule,
    CmsLayoutModule,
  ],
  exports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FusePipesModule,
    FuseCountdownComponent,
    FusePerfectScrollbarDirective,
    ReactiveFormsModule,
    ColorPickerModule,
    FuseIfOnDomDirective,
    FuseMaterialColorPickerComponent,
    TranslateModule,
    CmsLayoutModule,
    GenericSearchFilter,
    HttpClientModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    TableSearchComponent,
  ],
  entryComponents: [FuseConfirmDialogComponent],
  providers: [
    CookieService,
    FuseMatchMedia,
    FuseNavbarVerticalService,
    FuseMatSidenavHelperService,
    FuseTranslationLoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
