<div id="header">
  <div class="header p-sm-24">
    <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
      <div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
        <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon" *fuseIfOnDom [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }">
            {{ panelIcon }}</mat-icon>
          <span class="logo-text" *fuseIfOnDom [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">{{ panelTitle | translate }}</span>
          <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
            <div *ngIf="showSaveBtn">
              <button mat-button class="mat-icon-button cmd-buttons" *ngFor="let cmd of model.commands" (click)="commandClick(cmd)" [name]="cmd.label" matTooltip="{{ cmd.tooltip | translate }}">
                <mat-icon>{{ cmd.icon }}</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
          <button mat-button class="mat-icon-button expander" *ngIf="showMoveItem" aria-label="Move" (click)="move()" matTooltip="{{ 'COMMON.MOVE' | translate }}">
            <svg viewBox="0 0 24 24">
              <path d="M14,18V15H10V11H14V8L19,13M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z" />
            </svg>
          </button>
          <button mat-button class="mat-icon-button expander mat-elevation-z1" *fuseIfOnDom aria-label="Filter" (click)="openOrClose()" matTooltip="{{ 'FILTER.TITLE' | translate }}">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
      </div>
      <div id="filter-panel" [ngClass]="{
          'fadein-filter-panel': panelExpanded,
          'fadeout-filter-panel': !panelExpanded
        }">
        <h2 class="description-title">{{ "FILTER.TITLE" | translate }}</h2>
        <div class="container">
          <div *ngFor="let item of model.items" fxFlex="{{ 100 / columns }}">
            <mat-form-field *ngIf="
                item.propertyType === 'string' || item.propertyType === 'number'
              ">
              <input matInput (keypress)="onKeyPress($event)" placeholder="{{ item.placeholder | translate }}" name="{{ item.property }}" [(ngModel)]="model.request[item.property]" />
            </mat-form-field>
            <mat-form-field *ngIf="item.propertyType === 'date'">
              <input matInput (keypress)="onKeyPress($event)" [matDatepicker]="datePicker" [(ngModel)]="model.request[item.property]" name="{{ item.property }}" placeholder="{{ item.placeholder | translate }}" />
              <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
            <div *ngIf="item.propertyType === 'boolean'">
              <mat-checkbox [(ngModel)]="model.request[item.property]" [indeterminate]="true" name="{{ item.property }}">
                {{ item.placeholder | translate }}</mat-checkbox>
            </div>
            <div *ngIf="item.propertyType === 'toggle'">
              <mat-slide-toggle [(ngModel)]="model.request[item.property]" name="{{ item.property }}">
                {{ item.placeholder | translate }}</mat-slide-toggle>
              <!-- <mat-checkbox [(ngModel)]="model.request[item.property]" [indeterminate]="true" name="{{item.property}}">
                {{item.placeholder | translate}}</mat-checkbox> -->
            </div>
            <mat-form-field *ngIf="
                item.propertyType === 'select' && item.selectItems !== undefined
              ">
              <mat-select matInput placeholder="{{ item.placeholder | translate }}" [(ngModel)]="model.request[item.property]" (selectionChange)="selectFilterChanged(item.property, $event)">
                <mat-option *ngFor="let selectItem of item.selectItems" [value]="selectItem.id">
                  {{ selectItem.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="actions-container">
            <div class="options-icon" [ngClass]="{ isVisible: panelExpanded }" *ngIf="model.showReset">
              <button mat-button class="mat-icon-button mat-elevation-z1" (click)="reset()" *fuseIfOnDom aria-label="Reset" matTooltip="{{ 'COMMON.RESET_LABEL' | translate }}">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
            <div class="options-icon" [ngClass]="{ isVisible: panelExpanded }" *ngIf="model.showSearch">
              <button mat-button class="mat-icon-button mat-elevation-z1" *fuseIfOnDom aria-label="Search" matTooltip="{{ 'COMMON.SUBMIT_LABEL' | translate }}" (click)="submit()">
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showAddItem" class="add-event-button-host" fxLayout="row" fxLayoutAlign="end center">
      <button mat-fab class="add-event-button" *fuseIfOnDom (click)="addItem()" aria-label="Add item" matTooltip="{{ 'COMMON.ADDITEM_LABEL' | translate }}" [@animate]="{ value: '*', params: { delay: '300ms', scale: '0.2' } }">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>