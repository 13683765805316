import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ORIGIN_URL } from "src/app/shared/constants/baseurl.constants";
import { DATA } from "src/app/shared/constants/request";

import "hammerjs";
import { SharedModule } from "./core/modules/shared.module";
import { FuseMainModule } from "./main/main.module";
import { FuseSplashScreenService } from "./core/services/splash-screen.service";
import { FuseConfigService } from "./core/services/config.service";
import { FuseNavigationService } from "./core/components/navigation/navigation.service";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { CmsModule } from "./cms/cms.module";
import { HelpModule } from "./help/help.module";
import { GbetModule } from "./gbet/gbet.module";
import { BettingModule } from "./betting/betting.module";
import { PromotionModule } from "./promotions/promotion.module";

import { AppComponent } from "./app.component";
import { UnauthorizedComponent } from "./components/unauthorized.component";

import { ApisService } from "src/app/shared/apis.service";
import { UrlHelper } from "src/app/shared/urlhelper";
import { NotifyService } from "src/app/shared/notify.service";
import { AppConfigService } from "src/app/shared/appconfig.service";
import { AccountService } from "src/app/shared/account.service";

import { ITransferData } from "./models/TransferDataModel";

import { routes } from "./app.routes";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { AmazingTimePickerModule } from "amazing-time-picker";
import { AmazingTimePickerService } from "amazing-time-picker";
import { environment } from "src/environments/environment";
import { GenericCreationFormComponent } from "./components/generic-creation-form/generic-creation-form.component";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { LoginAuthGuard } from "./components/login-auth.guard";
import { LoginComponent } from "./components/login/login.component";
// import { CodeEditorModule } from "@ngstack/code-editor";
import { SkinsSettingsComponent } from "./afriplay/skins-settings/skins-settings.component";
import { AppInitializerService } from "./core/services/app-initializer.service";

export function init_app(appInitializerService: AppInitializerService) {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      appInitializerService.loadConfigJson().then(() => {
        appInitializerService.token = localStorage.getItem("token");
        resolve(true);
      });
    });
  };
}

export function getOriginUrl() {
  return environment.apiUrl;
}

export function getDateTimeZone() {
  const data = getTransferData();
  return data ? data.Language : "it-IT";
}

export function getTransferData() {
  const cacheData = window["TRANSFER_CACHE"];
  if (cacheData !== undefined) {
    return cacheData;
  }
  return {
    avatar: "",
    isAuthenticated: false,
    nick: "",
    pageSize: 20,
    language: "it-IT",
  } as ITransferData;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// @fix-by-migration (angular 10)
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    GenericCreationFormComponent,
    LoginComponent,
    SkinsSettingsComponent,
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    FuseMainModule,
    AmazingTimePickerModule,
    RouterModule.forRoot(routes, {
      //useHash: true,
      // preloadingStrategy: PreloadAllModules,
      // initialNavigation: 'enabled',
      enableTracing: false,
    }),
    TranslateModule.forRoot(),
    CmsModule,
    HelpModule,
    GbetModule,
    BettingModule,
    PromotionModule,
    // CodeEditorModule.forRoot(),
  ],
  providers: [
    FuseSplashScreenService,
    FuseConfigService,
    FuseNavigationService,
    UrlHelper,
    ApisService,
    NotifyService,
    AppConfigService,
    AccountService,
    AmazingTimePickerService,
    LoginAuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: ORIGIN_URL,
      useFactory: getOriginUrl,
    },
    {
      provide: DATA,
      useFactory: getTransferData,
    },
    { provide: MAT_DATE_LOCALE, useFactory: getDateTimeZone },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
