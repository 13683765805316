import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApisService } from 'src/app/shared/apis.service';
import { CheckItem } from '../../models/DataModels/TableDataSource/ModelRequest';
import { fuseAnimations } from '../../core/animations';
import { Subject } from 'rxjs';
import { of } from 'rxjs';
import { Subscription } from 'rxjs';
import { startWith, debounceTime, flatMap, delay, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'bonus-tournamentselector',
  templateUrl: './tournamentselector.component.html',
  animations: fuseAnimations
})
export class TournamentSelectorComponent implements OnInit, OnDestroy {

  displayedColumns = ['action', 'value'];
  allItems: CheckItem[] = [];
  items: CheckItem[] = [];
  currentPage: CheckItem[] = [];
  isBusy = false;

  filter = new Subject<string>();

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  private excluded: string[] = [];
  private includedSport: string[] = [];
  private subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public input: any,
    public dialogRef: MatDialogRef<TournamentSelectorComponent>,
    private apisService: ApisService
  ) {
    this.excluded = input.excluded || [];
    this.includedSport = input.includedSports || [];
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  ngOnInit() {
    this.isBusy = true;
    this.apisService.getTournaments(this.includedSport).subscribe(response => {
      if (!response.isError) {
        this.allItems = this.items = response.data.filter(x => this.excluded.indexOf(x.id) < 0)
          .map(x => <CheckItem>{
            id: x.id,
            value: x.value,
            selected: false
          });
        this.currentPage = this.items.slice(0, 20);
      }
      this.isBusy = false;
    });

    this.subscription = this.filter
      .pipe(debounceTime(300), distinctUntilChanged(), flatMap(search => of(search).pipe(delay(200))))
      .subscribe((data: any) => {
        const filterData = data ? data.toLowerCase() : '';
        if (filterData !== '') {
          this.items = this.allItems.filter(x => x.value.toLowerCase().indexOf(filterData) >= 0);
        } else {
          this.items = this.allItems;
        }
        if (this.paginator.pageIndex > 0) {
          this.paginator.pageIndex = 0;
        } else {
          this.loadPage(0, this.paginator.pageSize);
        }
      });
  }

  close() {
    this.dialogRef.close();
  }


  save() {
    this.dialogRef.close({
      items: this.items.filter(x => x.selected)
    });
  }

  select(item) {
    item.selected = !item.selected;
  }

  pageChange(event: PageEvent) {
    this.loadPage(event.pageIndex, event.pageSize);
  }

  private loadPage(pageIndex: number, pageSize: number) {
    this.isBusy = true;
    const current = pageIndex * pageSize;
    const next = current + pageSize;
    this.currentPage = this.items.slice(current, next);
    this.isBusy = false;
  }
}
// tslint:disable-next-line:max-line-length
function as(arg0: (x: import("../../models/DataModels/TableDataSource/ModelRequest").SelectItem) => void, as: any, CheckItem): CheckItem[] {
  throw new Error('Function not implemented.');
}

