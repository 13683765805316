import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ApisService} from 'src/app/shared/apis.service';
import {NotifyService} from 'src/app/shared/notify.service';
import {Observable, Subscription} from 'rxjs';
import {GetSearchRequest, SearchItemModel} from '../../models/DataModels/Cms/Request';
import {GetItemsBaseResponse} from '../../models/DataModels';
import {HttpDao} from '../../models/DataModels/TableDataSource/HttpDao';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {DataBaseRequestField, ModelRequest} from '../../models/DataModels/TableDataSource/ModelRequest';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '../../core/animations';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    animations: fuseAnimations
})

export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {

    subscribers: Subscription[] = new Array<Subscription>();

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    public panelTitle = 'SEARCH.TITLE';
    protected request = new GetSearchRequest();
    public dataSource: TableDataSource<GetSearchRequest, GetItemsBaseResponse<SearchItemModel>>;
    public displayedColumns = ['action', 'name', 'typeLabel'];
    public model: ModelRequest<GetSearchRequest>;
    private httpDao: SearchHttpDao;

    constructor(
        @Inject(ApisService) private apisService,
        @Inject(NotifyService) private notifyService,
        @Inject(AppConfigService) private appConfig,
        private location: Location,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router
    ) {

    }

    ngOnInit() {
        this.httpDao = new SearchHttpDao(this.apisService);
        this.model = new ModelRequest<GetSearchRequest>(this.request);
        let tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string'),
            new DataBaseRequestField('startDate', 'COMMON.SEARCH_START_LABEL', 'date'),
            new DataBaseRequestField('endDate', 'COMMON.SEARCH_END_LABEL', 'date'));
        this.model.items = tmpItems;
        this.model.showAddItem = true;

        this.appConfig.onSearchRequest.subscribe(r => {
            this.model.request.name = r;
            this.onSubmit();
        });
    }

    ngAfterViewInit() {
        this.model.request.reset();
        this.dataSource = new TableDataSource<GetSearchRequest, GetItemsBaseResponse<SearchItemModel>>(this.httpDao, this.paginator, this.sort, this.model);
        this.subscribers.push(this.route.params.subscribe(params => {
            this.model.request.name = params.input;
            this.onSubmit();
        }));
    }

    ngOnDestroy(): void {
        this.subscribers.forEach(t => t.unsubscribe());
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    edit(item: SearchItemModel) {
        if (item === undefined) {
            return;
        }
        switch (item.type) {
            case 0:
                this.router.navigate(['/cms/pages/page', item.id]);
                break;
            case 1:
                this.router.navigate(['/cms/widgets/widget', item.id]);
                break;
            case 2:
                this.router.navigate(['/cms/media', item.name]);
                break;
            default:
                break;
        }
    }
}

export class SearchHttpDao extends HttpDao<GetSearchRequest, GetItemsBaseResponse<SearchItemModel>> {
    constructor(public apisService: ApisService) {
        super(apisService);
    }

    public getData(request: GetSearchRequest): Observable<GetItemsBaseResponse<SearchItemModel>> {
        return this.apisService.searchData(request)
            .pipe(map(res => {
                if (res.isError) {
                    return <GetItemsBaseResponse<SearchItemModel>> {
                        total: 0,
                        items: []
                    };
                } else {
                    return res.data;
                }
            }));
    }
}
