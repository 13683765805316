<div class="dialog-container">
    <div class="page-layout carded fullwidth ps dialog">
        <div class="toolbar" fxLayout="row" fxLayoutAlign="start stretch">
            <h2>{{'MEDIAFOLDERS.EDITTITLE' | translate}}</h2>
            <div class="options-icon isVisible">
                <button mat-button class="mat-icon-button mat-elevation-z1" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                    (click)="save()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.SAVE' | translate}}">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start stretch">
            <mat-form-field fxFlex="100">
                <input fxFlex="100" matInput [(ngModel)]="model.value" class="layout-selected" placeholder="{{'MEDIAFOLDERS.NAME' | translate}}">
            </mat-form-field>
        </div>
    </div>
</div>