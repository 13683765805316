import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CasinoService } from "src/app/shared/casino.service";

@Component({
  selector: "app-add-media-modal",
  templateUrl: "./add-media-modal.component.html",
  styleUrls: ["./add-media-modal.component.scss"],
})
export class AddMediaModalComponent {
  folderId: string;

  constructor(
    public dialogRef: MatDialogRef<AddMediaModalComponent>,
    public casinoService: CasinoService
  ) {}

  close() {
    this.dialogRef.close();
  }

  logFolderId(folder) {
    this.casinoService.selectedMediaFolder = folder;
  }
}
