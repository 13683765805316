import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { UserAccess, SkinSettingsModel } from "../../models/DataModels";
import { NotifyService } from "src/app/shared/notify.service";
import { ApisService } from "src/app/shared/apis.service";
import { fuseAnimations } from "../../core/animations";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { BaseEditorComponent } from "../../components/baseeditor.component";
import { SaveSkinRequest } from "../../models/DataModels/Cms/Request";

@Component({
  selector: "app-skineditor",
  templateUrl: "./skineditor.component.html",
  styleUrls: ["./skineditor.component.scss"],
  animations: fuseAnimations,
})
export class SkinEditorComponent
  extends BaseEditorComponent
  implements OnInit, OnDestroy
{
  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true,
  };

  isLoading = false;
  pageForm: FormGroup;
  selectedItem: string;
  formErrors: any;
  skins = new Array<SkinSettingsModel>();

  constructor(
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    @Inject(NotifyService) protected notifyService: NotifyService,
    private apisService: ApisService,
    private formBuilder: FormBuilder
  ) {
    super();
    this.formErrors = {
      name: {},
    };
  }

  ngOnInit() {
    this.createForm();
    this.loadPage();
  }

  ngOnDestroy() {}

  select(item: SkinSettingsModel) {
    this.isLoading = true;
    if (item === undefined) {
      this.selectedItem = undefined;
      this.pageForm.reset();
      this.isLoading = false;
      return;
    }
    this.pageForm.reset();
    this.selectedItem = item.id;
    this.pageForm.patchValue({
      name: item.name,
      googleSearchConsole: item.googleSearchConsole,
      googleTagManager: item.googleTagManager,
      googleTagManagerHead: item.googleTagManagerHead,
      googleTagManagerBody: item.googleTagManagerBody,
      googleSearchConsoleMobile: item.googleSearchConsoleMobile,
      googleTagManagerMobile: item.googleTagManagerMobile,
      isMaintenance: item.isMaintenance,
      isMobileMaintenance: item.isMaintenance,
    });
    this.pageForm.markAsPristine();
    this.isLoading = false;
  }

  onSkinChanged() {
    let selected = this.skins.find((x) => x.id === this.selectedItem);
    this.select(selected);
  }

  public save(publish?: boolean, overwrite?: boolean) {
    if (!this.userAccess.canEdit) return;
    if (this.pageForm.dirty) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new SaveSkinRequest();
        Object.assign(request, this.pageForm.getRawValue());
        request.overwrite = overwrite;
        request.id = this.selectedItem;
        console.log("richiesta:", request);
        this.subscribers.push(
          this.apisService.saveSkin(request).subscribe((response) => {
            this.isLoading = false;
            this.handleServerResponse(
              response,
              (result) => {
                this.pageForm.reset();
                this.loadPage();
              },
              publish
            );
          })
        );
      } else {
        this.notifyService.warning("COMMON.INVALIDDATA");
      }
    }
  }

  protected loadPage(): void {
    if (this.isLoading) return;
    this.isLoading = true;
    let loader = this.apisService.getSkinSettings().subscribe((response) => {
      if (response.isError) this.isLoading = false;
      this.notifyService.handleServerResponse(response, (res) => {
        // this.userAccess = response.userAccess;
        this.skins = res || new Array<SkinSettingsModel>();
        console.log(res);
        if (this.skins.length > 0) {
          this.select(this.skins[0]);
        }
        this.isLoading = false;
        loader.unsubscribe();
      });
    });
  }

  private createForm(): void {
    this.pageForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(255)]],
      googleSearchConsole: [],
      googleTagManager: [],
      googleTagManagerHead: [],
      googleTagManagerBody: [],
      googleSearchConsoleMobile: [],
      googleTagManagerMobile: [],
      isMaintenance: [false],
      isMobileMaintenance: [false],
    });
    this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    });
  }

  private onFormValuesChanged(data): void {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }
}
