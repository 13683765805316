import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FuseConfigService } from '../../services/config.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class FuseSearchBarComponent implements OnInit {
    collapsed: boolean;
    toolbarColor: string;
    @Output() onInput: EventEmitter<any> = new EventEmitter();
    onSettingsChanged: Subscription;

    constructor(
        private fuseConfig: FuseConfigService
    ) {
        this.collapsed = true;
        this.onSettingsChanged =
            this.fuseConfig.onSettingsChanged
                .subscribe(
                    (newSettings) => {
                        this.toolbarColor = newSettings.colorClasses.toolbar;
                    }
                );
    }

    ngOnInit() {

    }

    collapse() {
        this.collapsed = true;
    }

    expand() {
        this.collapsed = false;
    }

    search(event) {
        if (event.key == 'Enter') {
            const value = event.target.value;
            this.onInput.emit(value);
            this.collapse();
            event.target.value = '';
        }
    }

}
