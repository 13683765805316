<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button close" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                *fuseIfOnDom aria-label="Close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{
                            (isNew ? 'PROMOTIONSPOINTCODE.NEW_TITLE' : 'PROMOTIONSPOINTCODE.EDIT_TITLE') | translate}}</span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': !pageForm.touched || pageForm.invalid}"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="!pageForm.touched || pageForm.invalid"
                                (click)="save()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.SAVE' | translate}}">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <!-- <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid}"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="pageForm.invalid"
                                (click)="publish()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.PUBLISH' | translate}}">
                                <mat-icon>publish</mat-icon>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div class="page-form" fxLayout="column">
                <div name="pageForm" [formGroup]="pageForm" fxLayout="column" fxFlex="1 0 auto">
                   <div fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="50">
                            <input matInput placeholder="{{'PROMOTIONSPOINTCODE.PROMO_CODE' | translate}}" matTooltip="{{'PROMOTIONSPOINTCODE.PROMO_CODE' | translate}}"
                                formControlName="promoCode" />
                            <mat-error *ngIf="formErrors.promoCode.required">
                                {{'COMMON.REQUIRED_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>
                        

                        <mat-form-field fxFlex="50">
                            <input matInput placeholder="{{'PROMOTIONSPOINTCODE.POINT_CODE' | translate}}" formControlName="pointCodeDescr"
                                [matAutocomplete]="pointCodeOption">
                            <mat-autocomplete #pointCodeOption="matAutocomplete">
                                <mat-option *ngFor="let pc of filteredOptions | async" [value]="pc.value">
                                    {{ pc.value }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="50">
                            <input matInput [matDatepicker]="startDatePicker" placeholder="{{'PROMOTIONSPOINTCODE.STARTDATE' | translate}}"
                                matTooltip="{{'PROMOTIONSPOINTCODE.STARTDATE' | translate}}" formControlName="startDate" />
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error *ngIf="formErrors.startDate.required">
                                {{'COMMON.REQUIRED_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input matInput [matDatepicker]="endDatePicker" placeholder="{{'PROMOTIONSPOINTCODE.ENDDATE' | translate}}"
                                matTooltip="{{'PROMOTIONSPOINTCODE.ENDDATE' | translate}}" formControlName="endDate" />
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                            <!-- <mat-error *ngIf="formErrors.endDate">
                                {{'WIDGET.DATES_ERROR' | translate}}
                            </mat-error> -->
                        </mat-form-field>
                      
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start">
                       

                        <mat-form-field fxFlex="50">
                            <input matInput placeholder="{{'PROMOTIONSPOINTCODE.BONUS' | translate}}" formControlName="bonus"
                                [matAutocomplete]="bonusOption">
                            <mat-autocomplete #bonusOption="matAutocomplete">
                                <mat-option *ngFor="let pc of filteredBonus | async" [value]="pc.value">
                                    {{ pc.value }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="50">
                            <input matInput type="number" placeholder="{{'PROMOTIONSPOINTCODE.MAX_INSERT' | translate}}" matTooltip="{{'PROMOTIONSPOINTCODE.MAX_INSERT' | translate}}"
                                formControlName="maxNumberInsert" />
                                <mat-error *ngIf="formErrors.maxNumberInsert.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="50">
                            <input matInput type="number" placeholder="{{'PROMOTIONSPOINTCODE.INSERT_COUNTER' | translate}}" matTooltip="{{'PROMOTIONSPOINTCODE.INSERT_COUNTER' | translate}}"
                                formControlName="insertCounter" />
                                <mat-error *ngIf="formErrors.insertCounter.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                        </mat-form-field>

                       
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start">

                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox class="attiva" matInput formControlName="attivo">{{'PROMOTIONSPOINTCODE.ACTIVE' | translate}}</mat-checkbox>
                        </div>
                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox matInput formControlName="multipleCode">{{'PROMOTIONSPOINTCODE.MULTIPLE_CODES' | translate}}</mat-checkbox>
                        </div>
                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox matInput formControlName="multipleBonus" >{{'PROMOTIONSPOINTCODE.MULTIPLE_BONUS'
                                | translate}}</mat-checkbox>
                        </div>
                       
                    </div>


                  

                    
                </div>
            </div>
        </div>
    </div>
    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>
