import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApisService } from 'src/app/shared/apis.service';
import { Subscription, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from 'src/app/shared/notify.service';
import { AppConfigService } from 'src/app/shared/appconfig.service';
import { UserAccess, OutcomeModel } from '../../models/DataModels';
import { SaveBettingGroupRequest } from '../../models/DataModels/Cms/Request';
import { fuseAnimations } from '../../core/animations';
import { BaseEditorComponent } from '../../components/baseeditor.component';
import { SelectItem, TreeItem } from '../../models/DataModels/TableDataSource/ModelRequest';
import { map, startWith } from 'rxjs/operators';
import { CreateOutcomeComponent } from './createOutcome.component';

@Component({
  selector: 'app-groupseditor',
  templateUrl: './groupseditor.component.html',
  styleUrls: ['./groupseditor.component.scss'],
  animations: fuseAnimations
})

export class GroupsEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  id: string;
  formErrors: any;
  isNew: boolean = false;
  subscribers: Subscription[];
  isLoading = false;
  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };

  canEdit: boolean = true;
  sports: Array<SelectItem>;
  filteredSports: Observable<SelectItem[]>;
  outcomes: FormArray;

  betOutcomes: TreeItem[];

  private removedOutcomes: number[];

  constructor(
    protected translateService: TranslateService,
    @Inject(NotifyService) protected notifyService: NotifyService,
    protected dialog: MatDialog,
    @Inject(AppConfigService) private appConfig: AppConfigService,
    private location: Location,
    private apisService: ApisService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    super();
    this.createForm();
    this.subscribers = new Array<Subscription>();
    this.formErrors = {
      id: {},
      description: {},
      sportId: {},
      sportDescription: {}
    };
    this.filteredSports = this.pageForm.get('sportDescription').valueChanges.pipe(
      startWith(''),
      map(value => this.filterSports(value))
    );
  }

  ngOnInit() {
    this.subscribers.push(this.route.params.subscribe(params => {
      this.id = params.id;
      this.isNew = this.id === undefined;
      this.loadPage(this.id);
    }));
  }

  ngOnDestroy() {
    this.subscribers.forEach(t => t.unsubscribe());
  }

  loadPage(id: string): void {
    this.removedOutcomes = [];
    this.outcomes.controls.splice(0);
    this.isLoading = true;
    this.subscribers.push(this.apisService.getBettingGroup(id).subscribe(response => {
      if (response.isError) {
        this.isLoading = false;
      }
      this.notifyService.handleServerResponse(response, result => {
        // this.userAccess = response.userAccess;
        this.sports = result.sports;
        this.betOutcomes = result.betOutcomes;
        if (result) {
          if (result.item) {
            this.pageForm.patchValue(result.item);
          }
          if (result.children) {
            result.children.forEach(o => {
              this.outcomes.push(this.patchLink(o));
            });
          }
        }

        const isOld = result && result.item && result.item.id > 0;
        const sportControl = this.pageForm.get('sportDescription');
        if (isOld) {
          sportControl.disable();
        } else {
          sportControl.enable();
        }
        this.isLoading = false;
      });
    }));
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
        console.log(field, 'not valid');
      }
    }
  }

  save(copy?: boolean) {
    if (!this.userAccess.canEdit) return;
    if (this.pageForm.dirty) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new SaveBettingGroupRequest();
        Object.assign(request, this.pageForm.getRawValue());
        request.removedOutcomes = this.removedOutcomes;
        request.id = +this.id;
        this.subscribers.push(this.apisService.saveBettingGroup(request).subscribe(response => {
          this.isLoading = false;
          this.notifyService.handleServerResponse(response, result => {
            this.pageForm.reset();
            if (this.id === String(result)) {
              this.loadPage(this.id);
            } else {
              this.router.navigate(['/betting/groups/group/', result]);
            }
          });
        }));
      } else {
        this.notifyService.warning('COMMON.INVALIDDATA');
      }
    }
  }

  addOutcome() {
    if (!this.userAccess.canEdit) {
      return;
    }
    if (!this.pageForm.getRawValue().sportId) {
      this.notifyService.error('NOTIFICATION.9001');
      return;
    }
    let dialogRef = this.dialog.open(CreateOutcomeComponent, {
      width: '30%',
      data: {
        betOutcomes: this.betOutcomes
      },
      height: '30%'
    });

    const dialogSub = dialogRef.afterClosed().subscribe(result => {
      if (result && result.item) {
        const item = <OutcomeModel>{
          id: undefined,
          betId: result.item.betId,
          outcomeId: result.item.outcomeId,
          betDescription: result.item.betDescription,
          outcomeDescription: result.item.outcomeDescription,
          order: result.item.order
        };
        const old = this.outcomes.controls.map(x => {
          return {
            betId: x.value.betId,
            outcomeId: x.value.outcmeId
          };
        });
        if (old.find(x => x.betId === item.betId && x.outcomeId === item.outcomeId)) {
          this.notifyService.error('NOTIFICATION.9000');
        } else {
          this.outcomes.push(this.patchLink(item));
          this.pageForm.markAsDirty();
        }
      }
      dialogSub.unsubscribe();
    });
  }

  deleteOutcome(i: number) {
    if (this.userAccess.canEdit && this.outcomes) {
      if (i >= 0 && i < this.outcomes.length) {
        const ctrl = this.outcomes.controls[i];
        if (ctrl.value.id && ctrl.value.id > 0) {
          this.removedOutcomes.push(ctrl.value.id);
        }
        this.outcomes.removeAt(i);
        this.pageForm.markAsDirty();
      }
    }
  }

  protected internalClose() {
    let data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(['betting/groups']);
    }
  }


  private createForm() {
    this.outcomes = this.formBuilder.array([]);

    this.pageForm = this.formBuilder.group({
      description: [undefined, [Validators.required, Validators.maxLength(255)]],
      sportId: [undefined, [Validators.required, Validators.maxLength(255)]],
      sportDescription: [undefined, [Validators.required, Validators.maxLength(255)]],
      order: [undefined],
      outcomes: this.outcomes
    });

    this.subscribers.push(this.pageForm.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    }));

    this.subscribers.push(this.pageForm.get('sportDescription').valueChanges.subscribe((data) => {
      if (this.sports) {
        const selected = this.sports.find(x => x.value === data);
        if (selected) {
          this.pageForm.patchValue({
            sportId: selected.id
          });
          this.loadBetOutcomes();
        } else {
          this.pageForm.patchValue({
            sportId: undefined
          });
          this.betOutcomes = [];
        }
      } else {
        this.pageForm.patchValue({
          sportId: undefined
        });
        this.betOutcomes = [];
      }
    }));
  }

  private patchLink(item: OutcomeModel) {
    const control = this.formBuilder.group({
      id: [item.id],
      betId: [item.betId, [Validators.required]],
      order: [item.order],
      outcomeId: [item.outcomeId, [Validators.required]],
      betDescription: [item.betDescription],
      outcomeDescription: [item.outcomeDescription]
    });
    control.disable();
    return control;
  }

  private filterSports(value: string): SelectItem[] {
    if (!this.sports || !value) return [];
    const filterValue = value.toLowerCase();
    return this.sports.filter(option => option.value.toLowerCase().indexOf(filterValue) === 0);
  }

  private loadBetOutcomes() {
    if (!this.userAccess.canEdit) return;
    const sportId = this.pageForm.getRawValue().sportId;
    if (sportId) {
      this.isLoading = true;
      this.subscribers.push(this.apisService.getBetOutcomeBySport(sportId).subscribe(response => {
        if (response.isError) {
          this.isLoading = false;
        }
        this.notifyService.handleServerResponse(response, result => {
          this.betOutcomes = result;
          this.isLoading = false;
        });
      }));
    } else {
      this.betOutcomes = [];
    }
  }
}
