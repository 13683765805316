import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApisService } from 'src/app/shared/apis.service';
import { CheckItem } from '../../models/DataModels/TableDataSource/ModelRequest';
import { fuseAnimations } from '../../core/animations';

@Component({
    selector: 'bonus-sportselector',
    templateUrl: './sportselector.component.html',
    animations: fuseAnimations
})
export class SportSelectorComponent implements OnInit {

    displayedColumns = ['action', 'value'];
    sports: CheckItem[] = [];
    currentPage: CheckItem[] = [];
    isBusy: boolean = false;

    private excluded: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public input: any,
        public dialogRef: MatDialogRef<SportSelectorComponent>,
        private apisService: ApisService
    ) {
        this.excluded = input.excluded || [];
    }

    ngOnInit() {
        this.isBusy = true;
        this.apisService.getSports().subscribe(response => {
            if (!response.isError) {
                this.sports = response.data.filter(x => this.excluded.indexOf(x.id) < 0)
                    .map(x => <CheckItem>{
                        id: x.id,
                        value: x.value,
                        selected: false
                    });
                this.currentPage = this.sports.slice(0, 20);
            }
            this.isBusy = false;
        });
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close({
            items: this.sports.filter(x => x.selected)
        });
    }

    select(item) {
        item.selected = !item.selected;
    }

    pageChange(event: PageEvent) {
        let current = event.pageIndex * event.pageSize;
        let next = current + event.pageSize;
        this.currentPage = this.sports.slice(current, next);
    }
}
