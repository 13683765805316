import {
  Component,
  OnDestroy,
  ViewChild,
  Input,
  AfterViewInit,
  Output,
} from "@angular/core";
import {
  GetExternalMediaResponseItem,
  BaseGetMediaItem,
} from "../../models/DataModels";
import { CasinoService } from "src/app/shared/casino.service";
import { DataBaseRequest } from "../../models/DataModels/TableDataSource/DataBaseRequest";
import { ExternalMediaResponse } from "../../models/DataModels/TableDataSource/DataBaseResponse";
import { HttpDao } from "../../models/DataModels/TableDataSource/HttpDao";
import { ModelRequest } from "../../models/DataModels/TableDataSource/ModelRequest";
import { TableDataSource } from "../../models/DataModels/TableDataSource/TableDataSource";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Subscription } from "rxjs";
import { EventEmitter } from "@angular/core";

export class GalleryItem {
  isSelected: boolean = false;
  id: string;
  title: string;
}

@Component({
  selector: "generic-gallery",
  templateUrl: "./generic.gallery.component.html",
  styleUrls: ["./generic.gallery.component.scss"],
})
export class GenericGalleryComponent<
  R extends DataBaseRequest,
  T extends ExternalMediaResponse
> implements OnDestroy, AfterViewInit
{
  protected columns = 5;
  protected total: number;
  public images = new Array<any>();
  protected imagesSelected = new Array<string>();
  selectedChanged: EventEmitter<string | string[]> = new EventEmitter();
  selectedItemsChanged: EventEmitter<T[]> = new EventEmitter();

  private _sort: MatSort;
  private _subscription: Subscription;
  private _multiSelection = true;
  private _dataSource: TableDataSource<R, T>;
  private iconUrl: string;

  @ViewChild("paginator", { static: false }) private _paginator: MatPaginator;
  @ViewChild("previewContent", { static: false }) private _previewContent: any;
  @Output() closeDialogEmitter = new EventEmitter<any>();
  @Input() gamesForm: boolean;
  @Input() categoriesForm: boolean;
  @Input() groupsForm: boolean;
  constructor(public _dialog: MatDialog, private casino: CasinoService) {}

  closeDialog() {
    if (this.categoriesForm || this.groupsForm) {
      console.log('EMITTING AN ICON URL', this.iconUrl);
      this.closeDialogEmitter.emit(this.iconUrl);
    }
    else {
      this.closeDialogEmitter.emit(true);
      if (this.casino.currentlySelecting == "desktop") {
        this.casino.showDesktopURL = true;
      } else if (this.casino.currentlySelecting == "mobile") {
        this.casino.showMobileURL = true;
      } else if(this.casino.currentlySelecting == "video") {this.casino.showVideoURL = true} 
      else if(this.casino.currentlySelecting == "background") {this.casino.showBackgroundURL = true}
      else if(this.casino.currentlySelecting == "vertical") {this.casino.showVerticalURL = true}
      else if(this.casino.currentlySelecting == "image") {this.casino.showImageURL = true}
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.disconnectSource();
  }

  public create(
    httpDao: HttpDao<R, T>,
    model: ModelRequest<R>,
    selected?: string | string[]
  ): void {
    this._dataSource = new TableDataSource<R, T>(
      httpDao,
      this._paginator,
      this._sort,
      model
    );
    this.updateSource();
  }

  getImageData(image) {
    if (this.casino.currentlySelecting == "desktop") {
      this.casino.desktopImageURL = image.url;
    } else if (this.casino.currentlySelecting == "mobile") {
      this.casino.mobileImageURL = image.url;
    } else if (this.casino.currentlySelecting == "video") {
      //to remove?
      this.casino.videoURL = image.url.replace("images", "documents");
    } else if (this.casino.currentlySelecting == "background") {
      this.casino.backgroundURL = image.url;
    } else if (this.casino.currentlySelecting == "vertical") {
      this.casino.verticalURL = image.url;
    } else if (this.casino.currentlySelecting == "image") {
      this.casino.imageURL = image.url;
    }
     else if (this.categoriesForm){
      this.iconUrl = image.url;
    } else if (this.groupsForm){
      this.iconUrl = image.url;
    }
  }

  public addOrRemoveImage(id: string): void {
    if (this.contains(id)) {
      this.imagesSelected = this.imagesSelected.filter(
        (imageId) => imageId !== id
      );
    } else {
      if (!this._multiSelection) this.imagesSelected = [];
      this.imagesSelected.push(id);
    }
    this.selectedChanged.emit(this.imagesSelected);
  }

  public contains(id: string): boolean {
    const result = this.imagesSelected.indexOf(id) > -1;
    return result;
  }

  public getSelected(): string | string[] {
    return this.imagesSelected;
  }

  public updateSelected(input: string | string[]): void {
    if (input !== undefined) {
      this.imagesSelected = new Array<string>();
      if (this._multiSelection) {
        if (input instanceof Array) {
          this.imagesSelected = input;
        } else {
          this.imagesSelected.push(input);
        }
      } else {
        if (input instanceof Array) {
          if (input.length > 0) {
            this.imagesSelected.push(input[0]);
          }
        } else {
          this.imagesSelected.push(input);
        }
      }
    }
  }

  public getSelectedItems(): Array<T> {
    return this.images.filter((x) => this.contains(x.id));
  }

  get dataSource() {
    return this._dataSource;
  }

  get multiSelection() {
    return this._multiSelection;
  }

  @Input("multiSelection")
  set multiSelection(value: boolean) {
    if (value === this._multiSelection) {
      return;
    }
    this._multiSelection = value;
  }

  get galleryColumns() {
    return this.columns;
  }

  @Input("galleryColumns")
  set galleryColumns(value: number) {
    if (value === this.columns) {
      return;
    }
    this.columns = value;
  }

  private disconnectSource(): void {
    if (this._subscription !== undefined) {
      this._subscription.unsubscribe();
    }
    if (this._dataSource !== undefined) {
      this._dataSource.disconnect();
    }
  }

  private connectSource(): void {
    if (this._dataSource !== undefined) {
      if (this._subscription !== undefined) {
        this._subscription.unsubscribe();
      }
      this._subscription = this._dataSource.connect().subscribe((response) => {
        this.images.slice(0);
        if (response && response.length > 0) {
          this.images = response;
        }
      });
    }
  }

  private updateSource(): void {
    if (this._paginator === undefined) {
      return;
    }
    this.disconnectSource();
    this.connectSource();
  }
}
