<div class="page-layout carded fullwidth ps container">
    <div class="media-loading-shade" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>

    <div class="help-container">
        <div class="help-breadcrumb">
            <p class="help-step" *ngFor="let itemSelected of itemsSelected; let i = index"
                (click)="selectItem(itemSelected)">
                <span class="display">{{itemSelected.display}}</span>
                <span class="icon" [hidden]="i === itemsSelected.length - 1">
                    <mat-icon class="mat-icon material-icons">chevron_right</mat-icon>
                </span>
            </p>
        </div>
    </div>

    <div class="flex-container">
        <div class="content ps items-container" fuseperfectscrollbar>
            <div class="nav">
                <div aria-label="inbox" class="nav-item" *ngFor="let item of items">
                    <a class="nav-link mat-ripple help-item" [ngClass]="{'selected': item.selected}" matripple
                        (click)="getItem(item, $event)">
                        <span class="title">{{item.display}}</span>
                        <mat-icon class="delete-button" (click)="select(item.id, $event)">save</mat-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>