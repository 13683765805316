<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button close" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" *fuseIfOnDom
                                aria-label="Close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                            {{ (isNew ? 'LAYOUT.NEW_TITLE' : 'LAYOUT.EDIT_TITLE') | translate}} {{isPublished ? ('LAYOUT.ISPUBLISHED' | translate): ''}}
                        </span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                [disabled]="pageForm.invalid" (click)="save()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.SAVE' | translate}}">
                                <mat-icon>save</mat-icon>
                            </button>
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                [disabled]="pageForm.invalid" (click)="copy()" *fuseIfOnDom aria-label="Copy" matTooltip="{{'COMMON.CLONE' | translate}}">
                                <mat-icon>content_copy</mat-icon>
                            </button>
                            <button mat-button class="mat-icon-button" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" (click)="reset()" *fuseIfOnDom
                                aria-label="Reset" matTooltip="{{'COMMON.RESET' | translate}}">
                                <mat-icon>cached</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div name="pageForm" [formGroup]="pageForm" fxLayout="column">
                <div class="inline">
                    <div>
                        <mat-form-field fxFlex="50">
                            <input matInput placeholder="{{'PAGE.NAME' | translate}}" formControlName="name">
                            <mat-error *ngIf="formErrors.name.required">
                                {{'COMMON.REQUIRED_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <app-layout [config]="layoutConfig"></app-layout>
            </div>
        </div>
    </div>

    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>