import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../core/modules/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AuthService } from '../shared/auth.service';
import { PageEditorGuard } from '../components/editorguard';
import { PageCanActivateGuard } from '../components/activateguard';
// guards

import { routes } from './gbet.routes';

import { GamesComponent } from './games/games.component';
import { GameEditorComponent } from './games/gameeditor.component';
import { GamesLiveComponent } from './gameslive/gameslive.component';
import { GameLiveEditorComponent } from './gameslive/gameeditorlive.component';



@NgModule({
    declarations: [
        GamesComponent,
        GameEditorComponent,
        GamesLiveComponent,
        GameLiveEditorComponent
    ],
    entryComponents: [

    ],
    imports: [
        BrowserAnimationsModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    providers: [
        AuthService,
        PageEditorGuard,
        PageCanActivateGuard
    ]
})

export class GbetModule {
}
