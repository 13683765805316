import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseWidgetContentComponent } from './baseWidgetContent.component';
import { ModelRequest } from '../../models/DataModels/TableDataSource/ModelRequest';
import { GetExternalMediaRequest } from '../../models/DataModels/Cms/Request';
import { ApisService } from 'src/app/shared/apis.service';
import { GetItemsBaseResponse, GetExternalMediaResponseItem, BaseGetMediaItem, BannerWidgetModel, WidgetType, BaseModel }
  from '../../models/DataModels';
import { GenericGalleryComponent } from '../../components/genericGallery/generic.gallery.component';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WidgetSelectorDialogComponent } from '../widgetselectordialog/widgetselectordialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slideWidget',
  templateUrl: './slideWidget.component.html',
  styleUrls: ['./slideWidget.component.scss']
})

export class SlideWidgetComponent extends BaseWidgetContentComponent {

  items: Array<BaseModel> = new Array<BaseModel>();
  private _request = new GetExternalMediaRequest();

  constructor(private apisService: ApisService,
    private dialog: MatDialog,
    protected formBuilder: FormBuilder,
    private router: Router
  ) {
    super(formBuilder);

    this._request.searchById = true;
    this._request.mediaIds = [];
  }


  public getContent() {
    return JSON.stringify(this.items);
  }

  createForm(value: any) {
    this.contentForm = this.formBuilder.group({
    });
    this.items.slice(0);
    if (value !== undefined && value !== '') {
      try {
        this.items = JSON.parse(value) || [];
      } catch (error) {
      }
    } else {
      // this.addItem();
    }

  }

  addItem() {
    this.addMedia();
  }

  addMedia() {
    let excludeId = this.items.map(x => x.id);

    let dialogRef = this.dialog.open(WidgetSelectorDialogComponent, {
      width: '100%',
      data: {
        excludeTypes: [WidgetType[WidgetType.Slider], WidgetType[WidgetType.Dynamic]],
        exclude: excludeId
      },
      height: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result) {
        this.items.push({
          id: result.widgetId,
          name: result.widgetName
        });
      }
      this.contentChanged.emit(this.items);
    });
  }

  moveUp(item: BaseModel) {
    let index = this.items.indexOf(item);
    if (index > 0 && index < this.items.length) {
      this.items.splice(index, 1);
      this.items.splice(--index, 0, item);
    }
    this.contentChanged.emit(this.items);
  }

  moveDown(item: BaseModel) {
    let index = this.items.indexOf(item);
    if (index >= 0 && index < this.items.length - 1) {
      this.items.splice(index, 1);
      this.items.splice(++index, 0, item);
    }
    this.contentChanged.emit(this.items);
  }

  removeItem(item: BaseModel) {
    let index = this.items.indexOf(item);
    if (index >= 0 && index < this.items.length) {
      this.items.splice(index, 1);
    }
    this.contentChanged.emit(this.items);
  }

  editItem(item: BaseModel) {
    if (item === undefined) return;
    this.router.navigate(['/cms/widgets/widget', item.id]);
  }
}
