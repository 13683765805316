import { Injectable } from '@angular/core';
import { WebStorageStateStore } from 'oidc-client';

@Injectable({
  providedIn: 'root',
})
export class IdentityConfig {
  private config: any;

  constructor() {}

  getStsConfiguration(): any {
    if (this.config) {
      return this.config;
    }
    this.config = {};
    Object.assign(this.config, window['stsconfig']);
    this.config.userStore = new WebStorageStateStore({
      store: window.localStorage,
    });
    return this.config;
  }
}
