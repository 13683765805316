import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { FuseConfigService } from "../../core/services/config.service";
import { Subscription } from "rxjs";
import { AppConfigService } from "src/app/shared/appconfig.service";
import { AccountService } from "src/app/shared/account.service";
import { LanguageModel } from "../../models/LanguageModel";
import { FuseSplashScreenService } from "../../core/services/splash-screen.service";
import { AuthService } from "../../shared/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "fuse-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class FuseToolbarComponent implements OnInit, OnDestroy {
  // userStatusOptions: any[];
  @Input() languages: LanguageModel[];
  @Input() selectedLanguage: LanguageModel;
  showLoadingBar: boolean;
  horizontalNav: boolean;
  avatar: string;
  nick: string;
  env = environment;
  // accountActions: any;
  action = {
    icon: "exit_to_app",
    display: "Logout",
    actionId: 2,
  };
  private subscribers: Subscription[] = new Array<Subscription>();

  constructor(
    @Inject(AppConfigService) private appConfig,
    private accountService: AccountService,
    private authService: AuthService,
    private router: Router,
    private fuseConfig: FuseConfigService,
    private splashService: FuseSplashScreenService
  ) {
    // this.accountActions = [
    //     {
    //         icon: 'account_circle',
    //         display: 'Profile',
    //         actionId: 1
    //     },
    //     {
    //         icon: 'account_circle',
    //         display: 'Logout',
    //         actionId: 2
    //     }
    // ];

    // this.action = this.accountActions[1];

    // this.userStatusOptions = [
    //     {
    //         'title': 'Online',
    //         'icon': 'icon-checkbox-marked-circle',
    //         'color': '#4CAF50'
    //     },
    //     {
    //         'title': 'Away',
    //         'icon': 'icon-clock',
    //         'color': '#FFC107'
    //     },
    //     {
    //         'title': 'Do not Disturb',
    //         'icon': 'icon-minus-circle',
    //         'color': '#F44336'
    //     },
    //     {
    //         'title': 'Invisible',
    //         'icon': 'icon-checkbox-blank-circle-outline',
    //         'color': '#BDBDBD'
    //     },
    //     {
    //         'title': 'Offline',
    //         'icon': 'icon-checkbox-blank-circle-outline',
    //         'color': '#616161'
    //     }
    // ];

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showLoadingBar = true;
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.showLoadingBar = false;
      }
    });

    this.fuseConfig.onSettingsChanged.subscribe((settings) => {
      this.horizontalNav = settings.layout.navigation === "top";
      this.languages = settings.languages;
      this.selectedLanguage = settings.selectedLanguage;
    });

    if (appConfig.isAuthenticated()) {
      this.avatar = `/assets/images/avatars/${appConfig.data.avatar}`;
      this.nick = appConfig.data.Nick;
    }
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscribers.forEach((t) => t.unsubscribe());
  }

  search(value) {
    this.appConfig.showSearchDialog(value);
  }

  setLanguage(lang) {
    this.splashService.show();
    setTimeout(() => {
      this.accountService.setLanguage(lang.code);
    }, 500);
  }

  actionAccountClick(item: any) {
    this.authService.startAuthentication();
    // if (item.actionId === 2) {
    //     // logout
    //     this.splashService.show();
    //     setTimeout(() => {
    //         this.accountService.logout();
    //     }, 500);
    // }
  }
}
