import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { ApisService } from 'src/app/shared/apis.service';
import { CheckItem } from '../../models/DataModels/TableDataSource/ModelRequest';
import { fuseAnimations } from '../../core/animations';
import { Subject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'bonus-eventselector',
  templateUrl: './eventselector.component.html',
  animations: fuseAnimations
})
export class EventSelectorComponent implements OnInit, OnDestroy {

  displayedColumns = ['action', 'value'];
  items: CheckItem[] = [];
  allItems: CheckItem[] = [];
  currentPage: CheckItem[] = [];
  isBusy = false;

  filter = new Subject<string>();

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  private excluded: string[] = [];
  private included: string[] = [];
  private subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public input: any,
    public dialogRef: MatDialogRef<EventSelectorComponent>,
    private apisService: ApisService
  ) {
    this.excluded = input.excluded || [];
    this.included = input.included || [];
  }



  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  ngOnInit() {
    this.isBusy = true;
    this.apisService.getEvents(this.included).subscribe(response => {
      if (!response.isError) {
        this.allItems = this.items = response.data.filter(x => this.excluded.indexOf(x.id) < 0)
          .map(x => <CheckItem>{
            id: x.id,
            value: x.value,
            selected: false
          });
        this.currentPage = this.items.slice(0, 20);
      }
      this.isBusy = false;
    });

    // @fix-by-migration
    /*
    this.subscription = this.filter
      .debounceTime(300)
      .distinctUntilChanged()
      .flatMap(search => Observable.of(search).delay(200))
      .subscribe((data) => {
        const filterData = data ? data.toLowerCase() : '';
        if (filterData !== '') {
          this.items = this.allItems.filter(x => x.value.toLowerCase().indexOf(filterData) >= 0);
        } else {
          this.items = this.allItems;
        }
        if (this.paginator.pageIndex > 0) {
          this.paginator.pageIndex = 0;
        } else {
          this.loadPage(0, this.paginator.pageSize);
        }
      });*/
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close({
      items: this.items.filter(x => x.selected)
    });
  }

  select(item) {
    item.selected = !item.selected;
  }


  pageChange(event: PageEvent) {
    this.loadPage(event.pageIndex, event.pageSize);
  }

  private loadPage(pageIndex: number, pageSize: number) {
    this.isBusy = true;
    const current = pageIndex * pageSize;
    const next = current + pageSize;
    this.currentPage = this.items.slice(current, next);
    this.isBusy = false;
  }
}
