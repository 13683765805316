import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FuseConfigService } from "../../core/services/config.service";
import { ApisService } from "src/app/shared/apis.service";
import { NotifyService } from "src/app/shared/notify.service";
import { Subscription } from "rxjs";
import { LanguageModel } from "../../models/LanguageModel";
import {
  MediaRequestType,
  UploadMediaRequest,
  UploadMediaRequestItem,
} from "../../models/DataModels/Cms/Request";
import { FrontendModel } from "../../models/DataModels";
import { fuseAnimations } from "../../core/animations";
import { CasinoService } from "src/app/shared/casino.service";

@Component({
  selector: "app-editmedia",
  templateUrl: "./editmedia.component.html",
  styleUrls: ["./editmedia.component.scss"],
  animations: fuseAnimations,
})
export class EditMediaComponent implements OnInit, OnDestroy {
  //Utilizzato solo nel popup dalla galleria
  @Input() mediaFolder: string;
  @ViewChild("uploadForm", { static: false }) uploadForm;
  frontends: FrontendModel[];
  languages: LanguageModel[];
  languagesSelect = new Array<LanguageModel>();
  uploadMediaRequest = new UploadMediaRequest();
  isBusy = false;
  isEdit = false;
  currentFilePath: string;
  newFilePreview: string;

  private subscribers: Subscription[];

  constructor(
    @Inject(NotifyService) private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public input: any,
    public dialogRef: MatDialogRef<EditMediaComponent>,
    public casinoService: CasinoService,
    private apisService: ApisService,
    private fuseConfig: FuseConfigService
  ) {
    this.subscribers = new Array<Subscription>();
    this.languages = this.fuseConfig.settings.languages;
  }

  ngOnInit(): void {
    this.languagesSelect = this.languages.slice(0);
    this.uploadMediaRequest.id = this.input.id;
    this.uploadMediaRequest.folder = this.input.folder?this.input.folder:this.mediaFolder;

    this.isEdit = this.uploadMediaRequest.id !== null;
    this.isBusy = true;
    this.subscribers.push(
      this.apisService.getMediaContent(this.input.id).subscribe((result) => {
        this.notifyService.handleServerResponse(result, (res) => {
          this.frontends = res.frontends;
          this.uploadMediaRequest.name = res.name;
          this.uploadMediaRequest.description = res.description;
          this.uploadMediaRequest.width = res.width;
          this.uploadMediaRequest.height = res.height;
          this.uploadMediaRequest.frontend =
            "4095cebf-38bb-4a33-ab37-79be6337305a";
          this.uploadMediaRequest.alt = res.alt;
          this.uploadMediaRequest.isDocument = res.isDocument;
          if (res.items && res.items.length > 0) {
            res.items.forEach((element) => {
              let lang = this.languages.find(
                (x) => x.code === element.language
              );
              const langItem = new LanguageModel();
              Object.assign(langItem, lang);
              const item = new UploadMediaRequestItem();
              item.language = langItem.name;
              item.languageCode = langItem.code;
              item.languageId = langItem.id;
              item.fileUrl = element.path;
              item.publicUrl = element.publicUrl;
              this.uploadMediaRequest.items.push(item);
            });
          }
          this.languagesSelect = this.languages.filter(
            (x) =>
              this.uploadMediaRequest.items.find(
                (y) => y.languageId === x.id
              ) === undefined
          );

          if (this.input.gameMedia) {
            if (!this.uploadMediaRequest.id) {
              if (!this.uploadMediaRequest.frontend) {
                this.uploadMediaRequest.frontend = this.frontends.find(
                  (x) => x.name === "Slot & Casinò"
                )
                  ? this.frontends.find((x) => x.name === "Slot & Casinò").id
                  : "";
              }

              if (this.uploadMediaRequest.items.length == 0) {
                const item = new UploadMediaRequestItem();
                Object.assign(item, {
                  fileUrl: undefined,
                  changed: true,
                  languageCode: this.languagesSelect[0].code,
                  language: this.languagesSelect[0].name,
                  action: MediaRequestType.Inserted,
                  file: undefined,
                  languageId: this.languagesSelect[0].id,
                  publicUrl: undefined,
                });
                this.uploadMediaRequest.items.push(item);
              }
            }
          }
        });
        this.isBusy = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscribers.forEach((t) => t.unsubscribe());
  }

  changeLanguage(language: LanguageModel): void {
    this.languagesSelect.splice(this.languagesSelect.indexOf(language), 1);
    let item = new UploadMediaRequestItem();
    item.language = language.name;
    item.languageCode = language.code;
    item.languageId = language.id;
    item.action = MediaRequestType.Inserted;
    this.uploadMediaRequest.items.push(item);
  }

  onFrontendChanged(frontend: FrontendModel): void {
    this.uploadMediaRequest.frontend = frontend.id;
  }

  sendFile(): void {
    if (this.uploadMediaRequest.name === null) {
      this.notifyService.error("NOTIFICATION.MEDIA_NAME_ERROR");
      return;
    }
    this.isBusy = true;
    if (
      this.uploadMediaRequest.items.some(
        (item) =>
          ((item.isNew() || item.changed) && item.file === undefined) ||
          item.file === null
      )
    ) {
      this.notifyService.error("NOTIFICATION.MEDIA_FILE_NOTSUPPORTED_ERROR");
      return;
    } else {
      let filesExtension = new Array<string>();
      this.uploadMediaRequest.items
        .filter((x) => x.isNew() || x.changed)
        .forEach((item) => filesExtension.push(item.file.name));
      let request = Object.assign({}, this.uploadMediaRequest);
      this.apisService.uploadFile(request.folder?request:{...request, folder: this.casinoService.selectedMediaFolder, frontend:'4095cebf-38bb-4a33-ab37-79be6337305a'}).subscribe(
        (res) => {
          if (!res.isError) {
            // console.log(this.input.folder)
            this.casinoService.dialogHandler.next();
            this.dialogRef.close(true);
          } else {
            this.notifyService.error(res.errorMessage || "NOTIFICATION.ERRROR");
          }
          this.isBusy = false;
        },
        (error) => {
          this.notifyService.error(error.statusText);
        }
      );
    }
  }

  fileChange(
    event: Event,
    item: UploadMediaRequestItem,
    fileSelected: any
  ): void {
    item.file = (<any>event.currentTarget).files[0];
    fileSelected.value = item.file.name;
    item.changed = true;
    if (!item.isNew()) {
      item.action = MediaRequestType.Updated;
    }
    //logica preview nuova immagine
    const eventTarget: any = event.target;
    const file = eventTarget.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.newFilePreview = reader.result as string;
    };
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  reset(): void {
    this.uploadForm.nativeElement.reset();
  }

  removeItem(item: UploadMediaRequestItem): void {
    let lang = this.languages.find((x) => x.id === item.languageId);
    this.languagesSelect.push(lang);
    if (item.isNew()) {
      this.uploadMediaRequest.items.splice(
        this.uploadMediaRequest.items.indexOf(item, 1)
      );
    } else {
      item.action = MediaRequestType.Removed;
    }
  }

  hasFile(item: UploadMediaRequestItem): boolean {
    return item !== undefined && item.fileUrl !== undefined;
  }

  view(item: UploadMediaRequestItem): void {
    if (this.hasFile(item)) {
      this.currentFilePath = item.fileUrl;
    }
  }

  filterItems(): UploadMediaRequestItem[] {
    return this.uploadMediaRequest.items.filter(
      (x) => x.action !== MediaRequestType.Removed
    );
  }

  closeview(): void {
    this.currentFilePath = undefined;
  }

  copy($element: any) {
    if ($element) {
      $element.select();
      document.execCommand("copy");
      this.notifyService.success("COMMON.SUCCESS_COPY");
    }
  }

  private internalClose(result?: boolean) {
    this.dialogRef.close(result);
  }
}
