<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button close" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                *fuseIfOnDom aria-label="Close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{
                            (isNew ? 'BONUS.NEW_TITLE' : 'BONUS.EDIT_TITLE') | translate}}</span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': !pageForm.touched || pageForm.invalid}"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="!pageForm.touched || pageForm.invalid"
                                (click)="save()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.SAVE' | translate}}">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <div class="options-icon isVisible" *ngIf="!isPublished">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid}"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="pageForm.invalid"
                                (click)="publish()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.PUBLISH' | translate}}">
                                <mat-icon>publish</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div class="page-form" fxLayout="column">
                <div name="pageForm" [formGroup]="pageForm" fxLayout="column" fxFlex="1 0 auto">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="50">
                            <input matInput placeholder="{{'BONUS.NAME' | translate}}" matTooltip="{{'BONUS.NAME' | translate}}"
                                formControlName="name" />
                            <mat-error *ngIf="formErrors.name.required">
                                {{'COMMON.REQUIRED_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input matInput placeholder="{{'BONUS.DESCRIPTION' | translate}}" matTooltip="{{'BONUS.DESCRIPTION' | translate}}"
                                formControlName="description" />
                            <mat-error *ngIf="formErrors.description.required">
                                {{'COMMON.REQUIRED_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="50">
                            <input type="number" matInput min="0" placeholder="{{'BONUS.MIN_REFILL' | translate}}"
                                matTooltip="{{'BONUS.MIN_REFILL' | translate}}" formControlName="minRefill" />

                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input matInput min="0" placeholder="{{'BONUS.MAX_BONUS' | translate}}" matTooltip="{{'BONUS.MAX_BONUS' | translate}}"
                                formControlName="maxBonus" />

                        </mat-form-field>

                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox matInput formControlName="contoVerificato">{{'BONUS.VERIFIED_ACCOUNT' |
                                translate}}</mat-checkbox>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="50">
                            <mat-select matInput placeholder="{{'BONUS.BONUS_TYPE' | translate}}" matTooltip="{{'BONUS.BONUS_TYPE' | translate}}"
                                formControlName="idBonusType">
                                <mat-option *ngFor="let bt of bonusTypes" [value]="bt.id">
                                    {{ bt.value }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formErrors.idBonusType.invalid">
                                {{'COMMON.REQUIRED_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="50">


                            <input matInput placeholder="{{'BONUS.POINT_CODE' | translate}}" formControlName="pointCode"
                                [matAutocomplete]="pointCodeOption">
                            <mat-autocomplete #pointCodeOption="matAutocomplete">
                                <mat-option *ngFor="let pc of filteredOptions | async" [value]="pc.value">
                                    {{ pc.value }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>


                        <mat-form-field fxFlex="50">
                            <mat-select matInput placeholder="{{'BONUS.GAME_CODE' | translate}}" matTooltip="{{'BONUS.GAME_CODE' | translate}}"
                                formControlName="gameCode">
                                <mat-option *ngFor="let gc of gameCodes" [value]="gc.id">
                                    {{ gc.value }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formErrors.gameCode.invalid">
                                {{'COMMON.REQUIRED_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="50">
                            <input matInput [matDatepicker]="startDatePicker" placeholder="{{'BONUS.STARTDATE' | translate}}"
                                matTooltip="{{'BONUS.STARTDATE' | translate}}" formControlName="startDate" />
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error *ngIf="formErrors.startDate.required">
                                {{'COMMON.REQUIRED_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input matInput [matDatepicker]="endDatePicker" placeholder="{{'BONUS.ENDDATE' | translate}}"
                                matTooltip="{{'BONUS.ENDDATE' | translate}}" formControlName="endDate" />
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                            <mat-error *ngIf="formErrors.endDate">
                                {{'WIDGET.DATES_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input matInput placeholder="{{'BONUS.NOTES' | translate}}" matTooltip="{{'BONUS.NOTES' | translate}}"
                                formControlName="notes" />
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start">

                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox matInput formControlName="specialBonus">{{'BONUS.SPECIAL_BONUS' | translate}}</mat-checkbox>
                        </div>
                        <!-- <div fxFlex="25" class="mat-form-field">
                            <mat-checkbox matInput formControlName="cumulative">{{'BONUS.CUMULATIVE' | translate}}</mat-checkbox>
                        </div> -->
                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox matInput formControlName="fakeMoney" (change)="fakeMoneyChange($event)">{{'BONUS.FAKE_MONEY'
                                | translate}}</mat-checkbox>
                        </div>
                        <div fxFlex="50" class="mat-form-field">
                            <mat-checkbox matInput formControlName="isTranche" (change)="tranceChange($event)">{{'BONUS.TRANCHE'
                                | translate}}</mat-checkbox>
                        </div>
                    </div>

                    <div fxLayout="row" *ngIf="fakeMoneySection" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="50">
                            <input matInput type="number" placeholder="{{'BONUS.WAGERING' | translate}}" matTooltip="{{'BONUS.WAGERING' | translate}}"
                                formControlName="wagering" />
                        </mat-form-field>

                        <mat-form-field fxFlex="50">
                            <input matInput type="number" placeholder="{{'BONUS.DAYS' | translate}}" matTooltip="{{'BONUS.DAYS' | translate}}"
                                formControlName="durataGG" />
                        </mat-form-field>

                        <mat-form-field fxFlex="50">
                            <input matInput type="number" placeholder="{{'BONUS.CAP' | translate}}" matTooltip="{{'BONUS.CAP' | translate}}"
                                formControlName="cap" />
                        </mat-form-field>

                        <mat-form-field fxFlex="50">
                            <input matInput placeholder="{{'BONUS.COMMENT' | translate}}" matTooltip="{{'BONUS.COMMENT' | translate}}"
                                formControlName="comment" />
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" *ngIf="!tranceSection" fxLayoutAlign="start start">
                        <div fxFlex="25" class="mat-form-field">
                            <mat-slide-toggle matInput formControlName="isPercentage" (change)="percentageChange($event)">{{'BONUS.PRECENTAGE'
                                | translate}}</mat-slide-toggle>
                        </div>
                        <mat-form-field fxFlex="50" *ngIf="!isPercentage">
                            <input matInput type="number" min="0" placeholder="{{'BONUS.BONUS' | translate}}"
                                matTooltip="{{'BONUS.BONUS' | translate}}" formControlName="bonus" />
                        </mat-form-field>

                        <mat-form-field fxFlex="50" *ngIf="isPercentage">
                            <input matInput type="number" min="0" placeholder="{{'BONUS.PRECENTAGE' | translate}}"
                                matTooltip="{{'BONUS.PRECENTAGE' | translate}}" formControlName="percentage" />
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" *ngIf="tranceSection" fxLayoutAlign="start start">
                        <mat-form-field fxFlex="50">
                            <input matInput type="number" min="1" placeholder="{{'BONUS.WEEK' | translate}}" matTooltip="{{'BONUS.WEEK' | translate}}"
                                formControlName="week" />
                        </mat-form-field>

                        <button mat-button class="mat-icon-button" *fuseIfOnDom aria-label="Add Rules" (click)="addRules()"
                            matTooltip="{{'BONUS.ADD_RULES' | translate}}" [disabled]="isPublished">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </div>

                    <div *ngIf="tranceSection" fxLayout="column" formArrayName="rulesForm">
                        <div *ngFor="let rule of rulesForm.controls; let i = index" fxFlex="100">
                            <div [formGroupName]="i">
                                <mat-form-field fxFlex="50">
                                    <input matInput type="number" placeholder="{{'BONUS.FROM' | translate}}" matTooltip="{{'BONUS.FROM' | translate}}"
                                        formControlName="from" />
                                </mat-form-field>

                                <mat-form-field fxFlex="50">
                                    <input matInput type="number" placeholder="{{'BONUS.AT' | translate}}" matTooltip="{{'BONUS.AT' | translate}}"
                                        formControlName="at" />
                                </mat-form-field>

                                <mat-form-field fxFlex="50">
                                    <input matInput type="number" placeholder="{{'BONUS.BONUS' | translate}}"
                                        matTooltip="{{'BONUS.BONUS' | translate}}" formControlName="bonus" />
                                </mat-form-field>
                                <div class="button-container">
                                    <button mat-icon-button color="warn" (click)="removeBonusRulesItem(i)" matTooltip="{{'PAGE.REMOVE_META_TAG' | translate}}"
                                        [disabled]="isPublished">
                                        <mat-icon class="mat-24 delete-icon" aria-label="Remove">delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container #bonusDetail></ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>