import { Component, OnInit } from "@angular/core";
import { DynamicComponent } from "./layoutcontent.component";

@Component({
  selector: "app-layoutitemeditor",
  templateUrl: "./layoutitemeditor.component.html",
  styleUrls: ["./layoutitemeditor.component.scss"]
})

export class LayoutItemEditorComponent extends DynamicComponent {

  constructor() {
    super();
  }

  ngOnInit() {

  }

  ngOnDestroy() {
  }
}
