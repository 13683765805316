<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button close" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                *fuseIfOnDom aria-label="Close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                            {{ (isNew ? 'BETTINGGROUPS.NEW_TITLE' : 'BETTINGGROUPS.EDIT_TITLE') | translate}}
                        </span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="pageForm.invalid"
                                (click)="save()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.SAVE' | translate}}">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <div class="options-icon isVisible" *ngIf="userAccess.canEdit">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" (click)="addOutcome()"
                                *fuseIfOnDom aria-label="Add" [disabled]="pageForm.invalid" matTooltip="{{'BETTINGGROUPS.ADDOUTCOME' | translate}}">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div name="pageForm" [formGroup]="pageForm" fxLayout="column">
                <div class="form-editor-host">
                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'BETTINGGROUPS.NAME' | translate}}" formControlName="description">
                        <mat-error *ngIf="formErrors.description.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <input hidden formControlName="sportId">
                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'BETTINGGROUPS.SPORT_SELECT' | translate}}" formControlName="sportDescription"
                            [matAutocomplete]="sportOptions">
                        <mat-error *ngIf="formErrors.sportDescription.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-autocomplete #sportOptions="matAutocomplete">
                            <mat-option *ngFor="let sport of filteredSports | async" [value]="sport.value">
                                {{ sport.value }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <input matInput type="number" placeholder="{{'BETTINGGROUPS.ORDER' | translate}}"
                            formControlName="order">
                    </mat-form-field>
                    <div class="description-title-host" fxFlex="100">
                        <span class="description-title">{{'BETTINGGROUPS.OUTCOMES_TITLE' | translate}}</span>
                    </div>
                    <div formArrayName="outcomes" fxFlex="100">
                        <div class="form-editor-host">
                            <div *ngFor="let outcome of outcomes.controls; let i=index;" fxFlex="50">
                                <div [formGroupName]="i" fxLayout="row" fxLayoutAlign="start stretch">
                                    <mat-form-field fxFlex="33.33">
                                        <input matInput formControlName="betDescription">
                                    </mat-form-field>
                                    <mat-form-field fxFlex="33.33">
                                        <input matInput formControlName="outcomeDescription">
                                    </mat-form-field>
                                    <mat-form-field fxFlex="33.33">
                                        <input matInput formControlName="order">
                                    </mat-form-field>
                                    <button mat-button class="mat-icon-button fixed" (click)="deleteOutcome(i)" aria-label="Delete"
                                        matTooltip="{{'COMMON.DELETE' | translate}}" *ngIf="canEdit">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>