<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button close" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" *fuseIfOnDom
                                aria-label="Close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{ (isNew ? 'MOBILE.NEW_TITLE' : 'MOBILE.EDIT_TITLE') | translate}}</span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                [disabled]="!pageForm.touched && pageForm.invalid" (click)="save()" *fuseIfOnDom aria-label="Save"
                                matTooltip="{{'COMMON.SAVE' | translate}}">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                [disabled]="pageForm.invalid" (click)="publish()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.PUBLISH' | translate}}">
                                <mat-icon>publish</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div name="pageForm" [formGroup]="pageForm" class="page-form">
                <div fxLayout="row" fxLayoutAlign="start stretch">
                    <mat-form-field fxFlex="50">
                        <mat-select matInput placeholder="{{'MENU.SKIN' | translate}}" [(ngModel)]="skinId" formControlName="skinId" (selectionChange)="onSkinChanged()">
                            <mat-option *ngFor="let skin of skins" [value]="skin.id">
                                {{ skin.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.skinId.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <mat-select matInput placeholder="{{'MOBILE.PAGE' | translate}}" [(ngModel)]="pageId" formControlName="pageId">
                            <mat-option *ngFor="let page of pages" [value]="page.id">
                                {{ page.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.pageId.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start stretch">
                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'MOBILE.NAME' | translate}}" formControlName="name">
                        <mat-error *ngIf="formErrors.name.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.name.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <input type="number" matInput placeholder="{{'MOBILE.SORT' | translate}}" formControlName="sort">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start stretch">
                    <mat-form-field fxFlex="50">
                        <input matInput [matDatepicker]="startDatePicker" placeholder="{{'MOBILE.STARTDATE' | translate}}" matTooltip="{{'MOBILE.TOOLTIP_START_DATE' | translate}}"
                            formControlName="startDate" />
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                        <mat-error *ngIf="formErrors.startDate.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <input matInput [matDatepicker]="endDatePicker" placeholder="{{'MOBILE.ENDDATE' | translate}}" matTooltip="{{'MOBILE.TOOLTIP_END_DATE' | translate}}"
                            formControlName="endDate" />
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start stretch">
                    <div fxFlex="50" class="mat-form-field">
                        <mat-checkbox formControlName="authenticated">{{'MENU.AUTHENTICATED' | translate}}
                        </mat-checkbox>
                    </div>
                    <div fxFlex="50" class="mat-form-field">
                        <mat-checkbox formControlName="enabled">{{'MENU.ENABLED' | translate}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>