import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ApisService} from 'src/app/shared/apis.service';
import {Subscription} from 'rxjs';
import {NotifyService} from 'src/app/shared/notify.service';
import {HelpModelItemEdit} from '../../models/DataModels/Help/Request';
import {HelpTreeItem} from '../../models/DataModels/Help/Response';
import {fuseAnimations} from '../../core/animations';
import {SectionsEditor} from './sectionseditor.component';
import {MatDialog} from '@angular/material/dialog';
import {TinymceOptions} from '../../tinymce/angular2-tinymce.config.interface';
import {DATA} from 'src/app/shared/constants/request';
import {TranslateService} from '@ngx-translate/core';
import {FuseConfirmDialogComponent} from '../../core/components/confirm-dialog/confirm-dialog.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseModel, UserAccess} from '../../models/DataModels';
import {SelectSections} from './select.section.component';
import {AngularEditorConfig} from '@kolkov/angular-editor';

@Component({
    selector: 'sections',
    templateUrl: './sections.component.html',
    styleUrls: ['./sections.component.scss'],
    animations: fuseAnimations
})

// tslint:disable-next-line:component-class-suffix
export class Sections implements OnInit, OnDestroy {

    pageForm: FormGroup;
    formErrors: any;
    content: any;
    itemSelected = false;
    isLoading = false;
    items: HelpTreeItem[];
    itemsSelected = new Array<HelpTreeItem>();
    itemId?: number;
    skins = new Array<BaseModel>();
    skinId: string;

    editConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        sanitize: true,
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [
            ['bold'],
            ['customClasses']
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ]
    };

    public userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    private _subscribers: Subscription[];
    private _tmpItems: HelpTreeItem[];

    constructor(
        @Inject(NotifyService) private notifyService,
        @Inject('TINYMCE_CONFIG') public editorConfig: TinymceOptions,
        @Inject(DATA) private tinyMceGallery,
        public dialog: MatDialog,
        private apisService: ApisService,
        private translateService: TranslateService,
        private formBuilder: FormBuilder
    ) {
        this._subscribers = new Array<Subscription>();
        this.editorConfig.plugins.push('gallery');
        this.editorConfig.plugins.push('documents');
        this.formErrors = {content: {}, display: {}, skinId: {}};
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.skins.splice(0);
        this.skins.push(<BaseModel> {});
        this.apisService.getTree()
            .subscribe(result => {
                this.notifyService.handleServerResponse(result, (res) => {
                    // this.userAccess = result.userAccess;
                    res.skins.forEach(x => this.skins.push(x));
                    this.items = res.items;
                    this._tmpItems = res.items;
                    this.itemsSelected.push(new HelpTreeItem(this._tmpItems));
                    this.isLoading = false;
                });
            });

        this.pageForm = this.formBuilder.group({
            content: [''],
            display: ['', Validators.required],
            skinId: []
        });

        this.pageForm.valueChanges.subscribe((res) => {
            console.log(res)
            this.onFormValuesChanged();
        });
    }

    ngOnDestroy(): void {
        this._subscribers.forEach(t => t.unsubscribe());
    }

    getTreeItem(item: HelpTreeItem): void {
        this.pageForm.reset();
        this.pageForm.markAsPristine();
        if (item.parentId) {
            this.pageForm.controls.skinId.disable();
        } else {
            this.pageForm.controls.skinId.enable();
        }
        this.itemSelected = true;
        this.isLoading = true;
        this.pageForm.patchValue({
            display: item.display,
            skinId: item.skinId
        });
        this.pageForm.controls.skinId.setValue(item.skinId);
        this.skinId = item.skinId;
        this.apisService.getTreeItem(item.id)
            .subscribe(result => {
                this.notifyService.handleServerResponse(result, (res) => {
                    this.content = this.cleanHtml(res.result);
                    this.pageForm.patchValue({
                        content: this.content
                    });
                    this.pageForm.markAsPristine();
                    this.isLoading = false;
                });
            });
    }

    getDescription(item: HelpTreeItem): void {
        if (this.pageForm.dirty) {
            this.translateService.get('COMMON.CANCELEDIT').subscribe(translate => {
                let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    width: '50%'
                });
                dialogRef.componentInstance.confirmMessage = translate;
                dialogRef.afterClosed().subscribe(confirm => {
                    if (confirm === true) {
                        this.getItem(item);
                    }
                });
            });
        } else {
            this.getItem(item);
        }
    }

    selectItem(item: HelpTreeItem): void {
        if (this.pageForm.dirty) {
            this.translateService.get('COMMON.CANCELEDIT').subscribe(translate => {
                let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    width: '50%'
                });
                dialogRef.componentInstance.confirmMessage = translate;
                dialogRef.afterClosed().subscribe(confirm => {
                    if (confirm === true) {
                        this.pageForm.reset();
                        this.pageForm.markAsUntouched();
                        this.addItem(item);
                    }
                });
            });
        } else {
            this.addItem(item);
        }
    }

    loadItems(): void {
        this.isLoading = true;
        this.apisService.getTree()
            .subscribe(result => {
                this.notifyService.handleServerResponse(result, (res) => {
                    this._tmpItems = res.items;
                    let itemsQuery = res.items;
                    for (let i = 1; i < this.itemsSelected.length; i++) {
                        let id = this.itemsSelected[i].id;
                        let query = itemsQuery.find(elem => elem.id === id);
                        if (query) {
                            itemsQuery = query.items || new Array<HelpTreeItem>();
                        }
                    }
                    this.itemsSelected[this.itemsSelected.length - 1].items = itemsQuery;
                    this.items = itemsQuery;
                    this.itemSelected = false;
                    this.isLoading = false;
                });
            });
    }

    save(): void {
        if (!this.userAccess.canEdit) {
            return;
        }
        if (!this.pageForm.valid) {
            return;
        }
        if (this.itemId && this.content) {
            this.isLoading = true;
            let request = new HelpModelItemEdit(this.itemId, this.pageForm.value.content, this.pageForm.value.display, this.pageForm.value.skinId);
            this.apisService.editTreeItemValue(request)
                .subscribe(result => {
                    this.notifyService.handleServerResponse(result, (res) => {
                        if (res === true) {
                            this.pageForm.markAsPristine();
                            const selected = this.itemsSelected.find(x => x.id === this.itemId);
                            if (selected) {
                                selected.skinId = this.pageForm.value.skinId;
                            }
                        } else {
                            this.notifyService.error();
                        }
                        this.isLoading = false;
                    });
                });
        }
    }

    add(): void {
        if (!this.userAccess.canInsert) {
            return;
        }
        this.dialog.open(SectionsEditor, {
            width: '30%',
            data: {reference: this},
            height: '30%'
        });
    }

    sort(): void {
        if (!this.userAccess.canEdit || this.isLoading) {
            return;
        }
        this.translateService.get('HELP.CONFIRM_SORT').subscribe(translate => {
            let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {
                    this.apisService.sortTreeItem(this.itemId)
                        .subscribe(result => {
                            this.isLoading = false;
                            this.notifyService.handleServerResponse(result, (res) => {
                                if (res !== true) {
                                    this.notifyService.error();
                                }
                                this.loadItems();
                            });
                        });
                }
            });
        });
    }

    delete(id: number, $event: any): void {
        $event.preventDefault();
        if (!this.userAccess.canDelete) {
            return;
        }
        this.translateService.get('COMMON.CONFIRM_DELETE').subscribe(translate => {
            let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {
                    this.isLoading = true;
                    this.apisService.deleteTreeItem(id)
                        .subscribe(result => {
                            this.isLoading = false;
                            this.notifyService.handleServerResponse(result, (res) => {
                                if (res !== true) {
                                    this.notifyService.error();
                                }
                                this.loadItems();
                            });
                        });
                }
            });
        });
    }

    movedown(id: number, $event: any) {
        $event.preventDefault();
        if (!this.userAccess.canEdit) {
            return;
        }
        if (this.items && id) {
            const index = this.items.findIndex(x => x.id === id);
            if (index >= this.items.length) {
                return;
            }
            this.isLoading = true;
            this.apisService.moveTreeItem(id, 1)
                .subscribe(result => {
                    this.isLoading = false;
                    this.notifyService.handleServerResponse(result, (res) => {
                        if (res !== true) {
                            this.notifyService.error();
                        }
                        this.loadItems();
                    });
                });
        }

    }

    moveup(id: number, $event: any) {
        $event.preventDefault();
        if (!this.userAccess.canEdit) {
            return;
        }
        if (this.items && id) {
            const index = this.items.findIndex(x => x.id === id);
            if (index <= 0) {
                return;
            }
            this.isLoading = true;
            this.apisService.moveTreeItem(id, -1)
                .subscribe(result => {
                    this.isLoading = false;
                    this.notifyService.handleServerResponse(result, (res) => {
                        if (res !== true) {
                            this.notifyService.error();
                        }
                        this.loadItems();
                    });
                });
        }
    }

    move(id: number, $event: any) {
        $event.preventDefault();
        if (!this.userAccess.canEdit) {
            return;
        }
        if (this.items && id) {
            const item = this.items.find(x => x.id === id);
            if (item) {
                let dialogRef = this.dialog.open(SelectSections, {
                    width: '40%',
                    height: '90%',
                    data: {
                        id: item.id,
                        skinId: item.skinId
                    }
                });

                dialogRef.afterClosed().subscribe(close => {
                    if (close && close.result && close.id) {
                        this.isLoading = true;
                        this.apisService.moveTreeItem(id, 0, close.id)
                            .subscribe(result => {
                                this.isLoading = false;
                                this.notifyService.handleServerResponse(result, (res) => {
                                    if (res !== true) {
                                        this.notifyService.error();
                                    } else {
                                        this.content = undefined;
                                        this.itemId = undefined;
                                        this.itemSelected = false;
                                        this.pageForm.markAsPristine();
                                        this.pageForm.patchValue({});
                                        this.itemsSelected.splice(1);
                                        this.loadItems();
                                    }
                                });
                            });
                    }
                });
            }
        }
    }

    private getItem(item: HelpTreeItem): void {
        if (item !== undefined) {
            this.itemId = item.id;
            let index = this.itemsSelected.findIndex(elem => elem.parentId === item.parentId);
            if (index > 0) {
                this.itemsSelected[index] = item;
            } else {
                this.itemsSelected.push(item);
            }
            if ((item.items && item.items.length > 0) /*|| item.parentId === null*/) {
                this.selectItem(item);
                this.itemSelected = true;
                this.getTreeItem(item);
            } else {
                this.items.forEach(item => item.selected = false);
                item.selected = true;
                this.getTreeItem(item);
            }
        }
    }

    private addItem(item: HelpTreeItem): void {
        this.pageForm.reset();
        this.pageForm.markAsUntouched();
        this.itemId = item.id;
        this.items = item.items;
        this.itemSelected = false;
        this.items.forEach(item => item.selected = false);
        let itemFound = this.itemsSelected.find(elem => elem.id === item.id);
        if (itemFound) {
            let index = this.itemsSelected.findIndex(elem => elem.id === item.id);
            for (let i = ++index; i < this.itemsSelected.length; i++) {
                this.itemsSelected[i] = undefined;
            }
            this.itemsSelected = this.itemsSelected.filter(elem => elem !== undefined);
            this.items = index > 0 ? this.itemsSelected[--index].items : this._tmpItems;
        } else {
            this.itemsSelected.push(item);
        }
    }

    private cleanHtml(html: string): string {
        if (html) {
            return html
                .replace(new RegExp('\>[ ]+\<', 'g'), '><')
                .replace(/&nbsp;/gi, '')
                .replace(/>\s+</g, '><');
        }
        return '';
    }

    private onFormValuesChanged(): void {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.pageForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }
}
