import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ApisService } from "src/app/shared/apis.service";
import { Subscription } from "rxjs";
import { NotifyService } from "src/app/shared/notify.service";
import { fuseAnimations } from "../../core/animations";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { FuseConfirmDialogComponent } from "../../core/components/confirm-dialog/confirm-dialog.component";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  SectionItemModel,
  SectionModel,
  UserAccess,
  WidgetType,
} from "../../models/DataModels";
import {
  BaseSaveRequest,
  SaveSectionRequest,
} from "../../models/DataModels/Cms/Request";
import { ContentSelectorDialogComponent } from "../contents/contentselectordialog.component";
import { PageSelectorDialogComponent } from "../pages/pageselectordialog.component";

@Component({
  selector: "app-sections",
  templateUrl: "./sections.component.html",
  styleUrls: ["./sections.component.scss"],
  animations: fuseAnimations,
})
export class SectionsComponent implements OnInit, OnDestroy {
  pageForm: FormGroup;
  formErrors: any;
  isLoading = false;
  items: SectionItemModel[];
  itemsSelected: string[];
  selectedSkin: SectionItemModel;
  selectedMenu: SectionItemModel;
  selectedSubmenu: SectionItemModel;

  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true,
  };

  private subscribers: Subscription[];

  constructor(
    @Inject(NotifyService) private notifyService,
    public dialog: MatDialog,
    private apisService: ApisService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder
  ) {
    this.subscribers = new Array<Subscription>();
    this.formErrors = {
      name: {},
      title: {},
      parent: {},
      isVisible: {},
      isPopup: {},
      contentId: {},
      pageId: {},
      link: {},
    };
    this.itemsSelected = new Array<string>();
  }

  ngOnInit() {
    this.pageForm = this.formBuilder.group({
      id: [],
      name: ["", [Validators.required, Validators.maxLength(255)]],
      title: ["", [Validators.required, Validators.maxLength(255)]],
      link: [
        "",
        [
          Validators.required,
          Validators.maxLength(255),
          sectionLinkValidator(/^[a-zA-Z0-9_/-]*$/),
        ],
      ],
      isVisible: [true, [Validators.required]],
      isPopup: [false],
      content: [
        {
          value: "",
          disabled: true,
        },
      ],
      contentId: [""],
      sort: [0],
      pageId: [],
      pageName: [],
      version: [],
    });
    this.pageForm.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });
    this.pageForm.get("content").valueChanges.subscribe((data) => {
      if (!data) {
        this.pageForm.patchValue({
          contentId: undefined,
        });
      }
    });
    this.pageForm.get("pageName").valueChanges.subscribe((data) => {
      if (!data) {
        this.pageForm.patchValue({
          pageId: undefined,
        });
      }
    });
    this.load();
  }

  ngOnDestroy() {
    this.subscribers.forEach((t) => t.unsubscribe());
  }

  cancel() {
    this.checkDirty(() => {
      this.loadItem(this.selectedSubmenu.id);
    });
  }

  selectContent() {
    let dialogRef = this.dialog.open(ContentSelectorDialogComponent, {
      width: "70%",
      data: {
        type: WidgetType[WidgetType.Html],
      },
      height: "70%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.result) {
        this.pageForm.patchValue({
          pageId: undefined,
          pageName: undefined,
          content: result.widgetName,
          contentId: result.widgetId,
        });
        this.pageForm.markAsDirty();
      }
    });
  }

  selectPage() {
    let dialogRef = this.dialog.open(PageSelectorDialogComponent, {
      width: "70%",
      data: {
        skin: this.selectedSkin.value,
        hideSkin: true,
        hideFrontend: true,
      },
      height: "70%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.result) {
        this.pageForm.patchValue({
          pageId: result.id,
          pageName: result.name,
          content: undefined,
          contentId: undefined,
        });
        this.pageForm.markAsDirty();
      }
    });
  }

  save(overwrite?: boolean) {
    if (!this.pageForm.dirty) {
      return;
    }
    if (
      !this.userAccess.canEdit ||
      this.selectedMenu === undefined ||
      this.selectedSkin === undefined
    )
      return;
    this.isLoading = true;
    let request = <SaveSectionRequest>{
      skinId: this.selectedSkin.id,
      menuId: this.selectedMenu.id,
    };
    Object.assign(request, this.pageForm.value);
    request.overwrite = overwrite;
    let subs = this.apisService.saveSection(request).subscribe((result) => {
      this.isLoading = false;
      subs.unsubscribe();
      this.notifyService.handleServerResponse(
        result,
        (res) => {
          this.isLoading = false;
          this.load();
        },
        undefined,
        () => {
          this.isLoading = false;
          this.translateService
            .get("NOTIFICATION.CONFLICT")
            .subscribe((msg) => {
              let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: "50%",
              });
              dialogRef.componentInstance.confirmMessage = msg;
              dialogRef.afterClosed().subscribe((confirm) => {
                if (confirm === true) {
                  this.save(true);
                }
              });
            })
            .unsubscribe();
        }
      );
    });
  }

  delete() {
    if (!this.userAccess.canDelete || this.selectedSubmenu === undefined)
      return;
    this.checkDirty(() => {
      if (this.selectedSubmenu.id === undefined) {
        this.pageForm.reset();
        this.pageForm.markAsUntouched();
        this.selectedSubmenu = undefined;
        this.updateBreadcrumb();
        return;
      }
      let item = this.selectedSubmenu;
      this.translateService
        .get("COMMON.DELETEMESSAGE_SECTION")
        .subscribe((translate) => {
          let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            width: "50%",
          });
          dialogRef.componentInstance.confirmMessage = translate;
          dialogRef.afterClosed().subscribe((confirm) => {
            if (confirm === true) {
              this.isLoading = true;
              let request = <BaseSaveRequest>{
                id: item.id,
              };
              request.overwrite = true;
              let subs = this.apisService
                .deleteSection(request)
                .subscribe((result) => {
                  subs.unsubscribe();
                  this.notifyService.handleServerResponse(
                    result,
                    (res) => {
                      this.isLoading = false;
                      this.pageForm.reset();
                      this.pageForm.markAsUntouched();
                      this.load();
                    },
                    undefined,
                    () => {
                      this.isLoading = false;
                      this.translateService
                        .get("NOTIFICATION.CONFLICT")
                        .subscribe((msg) => {
                          let dialogRef = this.dialog.open(
                            FuseConfirmDialogComponent,
                            {
                              width: "50%",
                            }
                          );
                          dialogRef.componentInstance.confirmMessage = msg;
                          dialogRef.afterClosed().subscribe((confirm) => {
                            if (confirm === true) {
                              this.delete();
                            }
                          });
                        })
                        .unsubscribe();
                    }
                  );
                });
            }
          });
        });
    });
  }

  loadItem(id: string) {
    this.checkDirty(() => {
      this.pageForm.reset();
      this.pageForm.reset();
      this.pageForm.markAsUntouched();
      this.isLoading = true;
      this.apisService.getSection(id).subscribe((result) => {
        this.notifyService.handleServerResponse(result, (res) => {
          if (res) {
            this.pageForm.patchValue(res);
          }
        });
        this.isLoading = false;
      });
    });
  }

  add() {
    if (this.selectedMenu === undefined) return;
    this.checkDirty(() => {
      this.pageForm.reset();
      this.pageForm.markAsUntouched();
      if (this.selectedSubmenu !== undefined) {
        this.selectedMenu.selected = false;
      }
      this.selectedSubmenu = <SectionItemModel>{
        parentId: this.selectedMenu.id,
        value: "nuovo",
        selected: true,
      };
      let item = <SectionModel>{
        parentId: this.selectedMenu.id,
        isPopup: false,
        isVisible: true,
      };
      this.pageForm.patchValue(item);
      this.pageForm.markAsDirty();
      this.updateBreadcrumb();
    });
  }

  expandSkin(item: SectionItemModel) {
    if (item === undefined) return;
    this.checkDirty(() => {
      item.expanded = !item.expanded;
      this.pageForm.reset();
      if (item === this.selectedSkin) {
        this.selectedSkin.expanded = false;
        if (this.selectedMenu) {
          this.selectedMenu.expanded = false;
          this.selectedMenu = undefined;
        }
        if (this.selectedSubmenu) {
          this.selectedSubmenu.selected = false;
          this.selectedSubmenu = undefined;
        }
        this.selectedSkin = undefined;
      } else {
        if (this.selectedSkin) {
          this.selectedSkin.expanded = false;
        }
        if (this.selectedMenu) {
          this.selectedMenu.expanded = false;
          this.selectedMenu = undefined;
        }
        if (this.selectedSubmenu) {
          this.selectedSubmenu.selected = false;
          this.selectedSubmenu = undefined;
        }
        this.selectedSkin = item;
      }

      this.updateBreadcrumb();
    });
  }

  expandMenu(item: SectionItemModel) {
    if (item === undefined || item === this.selectedMenu) return;
    this.checkDirty(() => {
      item.expanded = !item.expanded;
      if (this.selectedMenu) {
        this.selectedMenu.expanded = false;
      }
      if (this.selectedSubmenu) {
        this.selectedSubmenu.selected = false;
        this.selectedSubmenu = undefined;
      }
      this.pageForm.reset();
      this.selectedMenu = item;
      this.updateBreadcrumb();
    });
  }

  selectSubmenu(item: SectionItemModel) {
    if (item === undefined || item === this.selectedSubmenu) return;
    this.checkDirty(() => {
      item.selected = true;
      if (this.selectedSubmenu) {
        this.selectedSubmenu.selected = false;
      }
      this.pageForm.reset();
      this.selectedSubmenu = item;
      this.loadItem(this.selectedSubmenu.id);
      this.updateBreadcrumb();
    });
  }

  private updateBreadcrumb() {
    this.itemsSelected.splice(0);
    if (this.selectedSkin) this.itemsSelected.push(this.selectedSkin.value);
    if (this.selectedMenu) this.itemsSelected.push(this.selectedMenu.value);
    if (this.selectedSubmenu)
      this.itemsSelected.push(this.selectedSubmenu.value);
  }

  private load() {
    let tmpskin = this.selectedSkin;
    let tmpmenu = this.selectedMenu;
    let tmpsubmenu = this.selectedSubmenu;

    this.pageForm.reset();
    this.pageForm.markAsUntouched();
    this.selectedMenu = this.selectedSubmenu = this.selectedSkin = undefined;
    this.updateBreadcrumb();
    this.isLoading = true;
    this.apisService.getSectionEditor().subscribe((result) => {
      this.notifyService.handleServerResponse(result, (res) => {
        // this.userAccess = result.userAccess;
        this.items = result.data.items;
        this.isLoading = false;
        this.reselectItems(tmpskin, tmpmenu, tmpsubmenu);
      });
    });
  }

  private reselectItems(
    skin: SectionItemModel,
    menu: SectionItemModel,
    submenu: SectionItemModel
  ) {
    if (skin !== undefined) {
      let skinToSelect = this.items.find((x) => x.id === skin.id);
      if (skinToSelect !== undefined) {
        this.selectedSkin = skinToSelect;
        this.selectedSkin.expanded = true;
        if (menu !== undefined && this.selectedSkin.children) {
          let menuToSelect = this.selectedSkin.children.find(
            (x) => x.id === menu.id
          );
          if (menuToSelect !== undefined) {
            this.selectedMenu = menuToSelect;
            this.selectedMenu.expanded = true;
            if (submenu && this.selectedMenu.children) {
              let submenutoselect = this.selectedMenu.children.find(
                (x) => x.id === submenu.id
              );
              if (submenutoselect !== undefined) {
                this.selectedSubmenu = submenutoselect;
                this.selectedSubmenu.selected = true;
                this.loadItem(submenutoselect.id);
              }
            }
          }
        }
      }
      this.updateBreadcrumb();
    }
  }

  private onFormValuesChanged(): void {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  private checkDirty(action: () => void) {
    if (this.pageForm.dirty) {
      this.translateService.get("COMMON.CANCELEDIT").subscribe((translate) => {
        let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          width: "50%",
        });
        dialogRef.componentInstance.confirmMessage = translate;
        dialogRef.afterClosed().subscribe((confirm) => {
          if (confirm === true) {
            this.pageForm.markAsPristine();
            action();
          }
        });
      });
    } else {
      action();
    }
  }
}

export function sectionLinkValidator(pattern: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const valid = pattern.test(control.value);
    return valid ? undefined : { invalidLink: { value: control.value } };
  };
}
