import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../core/modules/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AuthService } from "../shared/auth.service";

// guards
import { PageEditorGuard } from "../components/editorguard";
import { PageCanActivateGuard } from "../components/activateguard";

import { TinymceModule } from "../tinymce/angular2-tinymce.module";
import { TinymceDefaultOptions } from "../tinymce/angular2-tinymce.default";

import { routes } from "./cms.routes";
import { PagesComponent } from "./pages/pages.component";
import { PageSelectorDialogComponent } from "./pages/pageselectordialog.component";
import { LayoutComponent } from "./layout/layout.component";
import { LayoutEditorComponent } from "./layout/layouteditor.component";
import { WidgetsComponent } from "./widgets/widgets.component";
import { MediaComponent } from "./media/media.component";
import { PageEditorComponent } from "./pageeditor/pageeditor.component";
import { EditMediaComponent } from "./media/editmedia.component";
import { WidgetEditorComponent } from "./widgets/widgeteditor.component";
import { TextWidgetComponent } from "./widgets/textWidget.component";
import { BannerWidgetComponent } from "./widgets/bannerWidget.component";
import { SlideWidgetComponent } from "./widgets/slideWidget.component";
import { HtmlWidgetComponent } from "./widgets/htmlWidget.component";
import { GenericGalleryComponent } from "../components/genericGallery/generic.gallery.component";
import { GalleryItemComponent } from "../components/genericGallery/galleryitem.component";
import { SearchComponent } from "./search/search.component";
import { MediaSelectorComponent } from "./mediaselector/mediaselector.component";
import { StyleEditorComponent } from "./styles/styleeditor.component";
import { ContentsComponent } from "./contents/contents.component";
import { ContentEditorComponent } from "./contents/contenteditor.component";
import { ContentSelectorDialogComponent } from "./contents/contentselectordialog.component";
import { LayoutContentMapperService } from "./layoutcontentmapper.service";
import { LayoutContentMapper } from "../layout/layoutcontentmapper";
import { WidgetSelectorDialogComponent } from "./widgetselectordialog/widgetselectordialog.component";
import { LayoutSelectorDialogComponent } from "./layoutselectordialog/layoutselectordialog.component";

import { PromotionsComponent } from "./promotions/promotions.component";
import { PromotionEditorComponent } from "./promotions/promotioneditor.component";
import { SectionsComponent } from "./sections/sections.component";

import { LayoutImageComponent } from "./layoutcomponents/layoutimage.component";

import { WidgetSelectorComponent } from "./layoutcomponents/widgetselector.component";
import { LockedWidgetComponent } from "./layoutcomponents/lockedwidget.component";

import { MenuComponent } from "./menu/menu.component";
import { MenuEditorComponent } from "./menu/menueditor.component";

import { MediaService } from "src/app/shared/media.service";
import { MediaFolderComponent } from "./media/mediafolder.component";
import { EditMediaFolderComponent } from "./media/editmediafolder.component";
import { MoveMediaFolderComponent } from "./media/movemediafolder.component";
import { SkinEditorComponent } from "./skin/skineditor.component";

import { SafeHtmlPipe } from "../pipes/safeHtml.pipe";
import { MobileContentComponent } from "./mobilecontent/mobilecontent.component";
import { MobileContentEditorComponent } from "./mobilecontent/mobilecontenteditor.component";

import { NativeAppEditorComponent } from "./nativeapp/nativeappeditor.component";

import { AngularEditorModule } from "@kolkov/angular-editor";
import { AuthCallbackComponent } from "../components/auth-callback/auth-callback.component";
import { LoginAuthGuard } from "../components/login-auth.guard";
// import { CodeEditorComponent, CodeEditorModule } from "@ngstack/code-editor";
import { AddMediaModalComponent } from "./add-media-modal/add-media-modal.component";

const defaultTinyMceConfig = {
  plugins: [
    "link",
    "paste",
    "table",
    "advlist",
    "autoresize",
    "lists",
    "code",
    "colorpicker",
    "textcolor",
  ],
  toolbar: [
    // tslint:disable-next-line:max-line-length
    "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
  ],
  skin_url: "./assets/tinymce/skins/lightgray",
  baseURL: "./assets/tinymce",
  allow_script_urls: true,
  auto_focus: true,
  paste_as_text: true,
};

const layoutContentMapper = new LayoutContentMapperService();

@NgModule({
  declarations: [
    PagesComponent,
    PageSelectorDialogComponent,
    LayoutComponent,
    WidgetsComponent,
    MediaComponent,
    MediaFolderComponent,
    EditMediaFolderComponent,
    MoveMediaFolderComponent,
    PageEditorComponent,
    EditMediaComponent,
    WidgetEditorComponent,
    TextWidgetComponent,
    BannerWidgetComponent,
    SlideWidgetComponent,
    HtmlWidgetComponent,
    GenericGalleryComponent,
    GalleryItemComponent,
    SearchComponent,
    LayoutImageComponent,
    WidgetSelectorComponent,
    LockedWidgetComponent,
    WidgetSelectorDialogComponent,
    LayoutSelectorDialogComponent,
    MediaSelectorComponent,
    StyleEditorComponent,
    LayoutEditorComponent,
    ContentsComponent,
    ContentEditorComponent,
    ContentSelectorDialogComponent,
    PromotionsComponent,
    PromotionEditorComponent,
    SectionsComponent,
    MenuComponent,
    MenuEditorComponent,
    SkinEditorComponent,
    SafeHtmlPipe,
    MobileContentComponent,
    MobileContentEditorComponent,
    NativeAppEditorComponent,
    AuthCallbackComponent,
    AddMediaModalComponent,
  ],
  entryComponents: [
    EditMediaComponent,
    EditMediaFolderComponent,
    MoveMediaFolderComponent,
    PageEditorComponent,
    WidgetEditorComponent,
    TextWidgetComponent,
    BannerWidgetComponent,
    SlideWidgetComponent,
    HtmlWidgetComponent,
    SearchComponent,
    LayoutImageComponent,
    WidgetSelectorComponent,
    LockedWidgetComponent,
    WidgetSelectorDialogComponent,
    LayoutSelectorDialogComponent,
    MediaSelectorComponent,
    ContentEditorComponent,
    ContentSelectorDialogComponent,
    PageSelectorDialogComponent,
    AddMediaModalComponent,
    // CodeEditorComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    //EditorModule,
    AngularEditorModule,
    SharedModule,
    RouterModule.forChild(routes),
    TinymceModule.withConfig(defaultTinyMceConfig),
    // CodeEditorModule.forChild(),
  ],
  providers: [
    //{provide: 'TINYMCE_CONFIG', useClass: TinymceDefaultOptions},
    // { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: LayoutContentMapper, useValue: layoutContentMapper },
    AuthService,
    MediaService,
    LoginAuthGuard,
    PageEditorGuard,
    PageCanActivateGuard,
  ],
})
export class CmsModule {}
