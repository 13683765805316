import { Injectable, OnInit, EventEmitter } from '@angular/core';
import { SaveBonusRequest } from '../../models/DataModels/Cms/Request';
import { BonusModel } from '../../models/DataModels';

@Injectable()
export abstract class BaseBonusDetailComponent implements OnInit {

  public type: number;

  public valueChanges: EventEmitter<BonusDetailResult> = new EventEmitter<BonusDetailResult>();

  // tslint:disable-next-line:contextual-lifecycle
  ngOnInit() {
    this.init();
  }

  protected abstract init(): void;

  public abstract load(input: BonusModel, canEdit: boolean);

  public abstract save(request: SaveBonusRequest);
}


export class BonusDetailResult {
  data: any;
  invalid: boolean;
}
