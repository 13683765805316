import {Component, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {FuseNavigationService} from './navigation.service';
import {Subscription} from 'rxjs';
import {ApisService} from '../../../shared/apis.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseNavigationComponent implements OnDestroy {
    navigationModel: any[];
    navigationModelChangeSubscription: Subscription;

    @Input('layout') layout = 'vertical';

    constructor(private fuseNavigationService: FuseNavigationService,
                private apiService: ApisService) {
        // this.navigationModelChangeSubscription =
        //     this.fuseNavigationService.onNavigationModelChange
        //         .subscribe((navigationModel) => {
        //             console.log(navigationModel)
        //             this.navigationModel = navigationModel;
        //             this.navigationModel.forEach(x => x.function = (item) => {
        //             });
        //         });

        this.apiService.getNavigationMenu().subscribe(res => {
            console.log({navigationMenu: res});
            this.navigationModel = res;
            // this.navigationModel[0].children = this.navigationModel[0].children.filter(c => c.id === 'promotions');
        });
    }

    ngOnDestroy() {
        this.navigationModelChangeSubscription.unsubscribe();
    }

}
