import { Routes } from '@angular/router';
import { Sections } from '../help/sections/sections.component';

export const routes: Routes = [
    {
        path: 'help',
        children: [
            {
                path: 'sections',
                component: Sections,
                data: {
                    title: 'Sections',
                    meta: [{ name: 'description', content: '' }]
                }
            }
        ]
    }
];
