import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})

export class UnauthorizedComponent implements OnInit {

  time = 5;

  constructor() {

  }

  ngOnInit() {
    console.log('UnauthorizedComponent ngOnInit');
    interval(1000).subscribe((i) => {
      this.time--;
      console.log(this.time);
      if (this.time <= 0) {
        document.location.href = '/';
      }
    });
  }
}
