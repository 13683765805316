<div class="sectionseditordialog" name="pageForm" [formGroup]="pageForm">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'HELP.NEW_NODE' | translate}}</span>
            <button mat-button class="mat-icon-button close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <div class="p-24 m-0" fusePerfectScrollbar>
        <div class="media-loading-shade" *ngIf="isLoading">
            <mat-spinner [color]="'accent'"></mat-spinner>
        </div>
        <div fxLayout="column" fxFlex>
            <mat-form-field class="w-100-p">
                <input matInput placeholder="{{'HELP.DISPLAY' | translate}}" formControlName="display">
                <mat-error *ngIf="formErrors.display.required">
                    {{'COMMON.REQUIRED_ERROR' | translate}}
                </mat-error>
            </mat-form-field>

        </div>
    </div>
</div>

<div class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
    <button [disabled]="pageForm.invalid" mat-raised-button (click)="save()" class="save-button mat-accent">
        {{'COMMON.SAVE' | translate}}
    </button>
</div>