import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NotifyService } from 'src/app/shared/notify.service';
import { ApisService } from 'src/app/shared/apis.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { fuseAnimations } from '../../core/animations';
import { UploadMediaRequestItem, UploadNativeAppSettingRequest } from '../../models/DataModels/Cms/Request';
import { BaseEditorComponent } from '../../components/baseeditor.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { UserAccess, NativeAppSetting } from '../../models/DataModels';


@Component({
  selector: 'native-app-editor',
  templateUrl: './nativeappeditor.component.html',
  styleUrls: ['./nativeappeditor.component.scss'],
  animations: fuseAnimations
})

export class NativeAppEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };


  isBusy = false;
  isEdit = false;

  isLoading = false;
  pageForm: FormGroup;
  selectedItem: string;
  formErrors: any;
  nativeAppSettings = new Array<NativeAppSetting>();

  uploadNativeAppSetting = new UploadNativeAppSettingRequest();

  @ViewChild('fileInput', { static: false }) fileInputControl: ElementRef;
  @ViewChild('filePicker', { static: false }) filePickerControl: ElementRef;

  constructor(
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    @Inject(NotifyService) protected notifyService: NotifyService,
    private apisService: ApisService,
    private formBuilder: FormBuilder
  ) {
    super();
    this.formErrors = {
      name: {}
    };
  }

  ngOnInit() {
    this.createForm();
    this.loadPage();
  }

  ngOnDestroy() {

  }

  select(item: NativeAppSetting) {
    this.isLoading = true;
    if (this.fileInputControl && this.fileInputControl.nativeElement) {
      this.fileInputControl.nativeElement.value = '';
    }
    if (this.filePickerControl && this.filePickerControl.nativeElement) {
      this.fileInputControl.nativeElement.value = '';
    }
    this.uploadNativeAppSetting = new UploadNativeAppSettingRequest();
    if (item === undefined) {
      this.selectedItem = undefined;
      this.pageForm.reset();
      this.isLoading = false;
      return;
    }
    this.pageForm.reset();
    this.selectedItem = item.id;
    this.uploadNativeAppSetting.releaseVersion = item.releaseVersion;
    this.uploadNativeAppSetting.id = item.id;
    this.uploadNativeAppSetting.isMaintenance = item.isMaintenance;
    this.uploadNativeAppSetting.isAppDisabledOutsideOfItaly = item.isAppDisabledOutsideOfItaly;
    this.pageForm.patchValue({
      link: item.link,
      releaseVersion: item.releaseVersion,
      isAppDisabledOutsideOfItaly: item.isAppDisabledOutsideOfItaly,
      isMaintenance: item.isMaintenance
    });
    this.pageForm.markAsPristine();
    this.isLoading = false;

  }

  onPlatformChanged() {
    const selected = this.nativeAppSettings.find(x => x.id === this.selectedItem);
    this.select(selected);
  }


  fileChange(event: Event, fileSelected: any): void {
    this.uploadNativeAppSetting.file = (event.currentTarget as any).files[0];
    fileSelected.value = this.uploadNativeAppSetting.file.name;
  }

  // removeItem(item: UploadMediaRequestItem): void {
  //   this.uploadNativeAppSetting.file = undefined;
  // }

  public save() {
    if (this.selectedItem === null) {
      this.notifyService.error('NOTIFICATION.MEDIA_NAME_ERROR');
      return;
    }
    this.isBusy = true;
    // if (this.uploadNativeAppSetting.file === undefined || this.uploadNativeAppSetting.file === null) {
    //   this.notifyService.error('NOTIFICATION.MEDIA_FILE_NOTSUPPORTED_ERROR');
    //   return;
    // } else {
    const formData = this.pageForm.getRawValue();
    this.uploadNativeAppSetting.releaseVersion = formData.releaseVersion;
    this.uploadNativeAppSetting.isMaintenance = formData.isMaintenance;
    this.uploadNativeAppSetting.isAppDisabledOutsideOfItaly = formData.isAppDisabledOutsideOfItaly;

    const request = Object.assign({}, this.uploadNativeAppSetting);
    this.apisService
      .uploadNativeAppSetting(request)
      .subscribe(res => {
        if (!res.isError) {
        } else {
          this.notifyService.error(res.errorMessage || 'NOTIFICATION.ERRROR');
        }
        this.isBusy = false;
        this.reloadAppSettings(this.selectedItem);
      }, error => {
        this.notifyService.error(error.statusText);
      });

  }

  private reloadAppSettings(id: string) {
    if (this.isLoading) { return; }
    this.isLoading = true;
    this.apisService.getNativeAppSettings().subscribe(response => {
      if (response.isError) { this.isLoading = false; }
      this.notifyService.handleServerResponse(response, res => {
        // this.userAccess = response.userAccess;
        this.nativeAppSettings = res || new Array<NativeAppSetting>();
        if (this.nativeAppSettings.length > 0) {
          const foundSetting = this.nativeAppSettings.find(s => s.id === id);
          this.select(foundSetting);
        }
        this.isLoading = false;
      });
    });
  }

  protected loadPage(): void {
    if (this.isLoading) { return; }
    this.isLoading = true;
    this.apisService.getNativeAppSettings().subscribe(response => {
      if (response.isError) { this.isLoading = false; }
      this.notifyService.handleServerResponse(response, res => {
        // this.userAccess = response.userAccess;
        this.nativeAppSettings = res || new Array<NativeAppSetting>();
        if (this.nativeAppSettings.length > 0) {
          this.select(this.nativeAppSettings[0]);
        }
        this.isLoading = false;
      });
    });
  }

  private createForm(): void {
    this.pageForm = this.formBuilder.group({
      link: [{
        value: undefined,
        disabled: true
      }],
      releaseVersion: [],
      isMaintenance: [],
      isAppDisabledOutsideOfItaly: []
    });
    this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    });
  }

  private onFormValuesChanged(data): void {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

}
