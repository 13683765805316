import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ApisService } from 'src/app/shared/apis.service';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/shared/notify.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseConfirmDialogComponent } from '../../core/components/confirm-dialog/confirm-dialog.component';
import { HelpItem } from '../../models/DataModels/Help/Request';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sections-editor',
  templateUrl: './sectionseditor.component.html',
  styleUrls: ['./sectionseditor.component.scss']
})

// tslint:disable-next-line:component-class-suffix
export class SectionsEditor implements OnInit, OnDestroy {

  pageForm: FormGroup;
  formErrors: any;
  isLoading = false;

  private _subscribers: Subscription[];

  constructor(
    @Inject(NotifyService) private notifyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SectionsEditor>,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private apisService: ApisService,
    private formBuilder: FormBuilder
  ) {
    this._subscribers = new Array<Subscription>();
    this.formErrors = {
      display: {}
    };
  }

  ngOnInit(): void {
    this.pageForm = this.formBuilder.group({
      display: ['', Validators.required]
    });

    this.pageForm.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });
  }

  ngOnDestroy(): void {
    this._subscribers.forEach(t => t.unsubscribe());
  }

  save(): void {
    if (this.pageForm.dirty) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new HelpItem();
        Object.assign(request, this.pageForm.value);
        request.parentId = this.data.reference.itemId === undefined ? undefined : this.data.reference.itemId;
        this.translateService.get('HELP.DEFAULT_NODE_VALUE').subscribe(result => request.value = result);
        this._subscribers.push(this.apisService.addTreeItem(request).subscribe(response => {
          this.isLoading = false;
          this.notifyService.handleServerResponse(response, result => {
            if (result === true) {
              this.notifyService.success();
            } else {
              this.notifyService.error();
            }
            this.internalClose(true);
            this.data.reference.loadItems();
          });
        }));
      } else {
        this.notifyService.warning('COMMON.INVALIDDATA');
      }
    } else {
      this.internalClose();
    }
  }

  close(): void {
    if (!this.pageForm.dirty) {
      this.internalClose();
    } else {
      this.translateService.get('COMMON.CANCELEDIT').subscribe(translate => {
        let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          width: '50%'
        });
        dialogRef.componentInstance.confirmMessage = translate;
        dialogRef.afterClosed().subscribe(confirm => {
          if (confirm === true) {
            this.internalClose();
          }
        });
      });
    }
  }

  private internalClose(result?: boolean): void {
    this.dialogRef.close(result);
  }

  private onFormValuesChanged(): void {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }
}
