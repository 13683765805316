<div class="container">
  <mat-card>
    <div fxLayout="row" fxLayout="row" fxLayoutAlign="end start">
      <mat-icon (click)="submitChanges(selectedSkin ? selectedSkin.id : null)">save</mat-icon>
    </div>
    <form [formGroup]="form">
      <mat-form-field [style.width.%]="100">
        <mat-label>Skin</mat-label>
        <mat-select (selectionChange)="selectSkin($event)" [value]="defaultSkin">
          <mat-option *ngFor="let skin of allSkins" [value]="skin">{{
            skin.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [style.width.%]="100">
        <mat-label>Maximum Session Load Amount</mat-label>
        <input matInput type="string" formControlName="maximumSessionLoadAmount" />
        <span matSuffix>€</span>
        <mat-error>Incorrect format</mat-error>
      </mat-form-field>
    </form>
  </mat-card>
</div>