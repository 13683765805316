import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { ApisService } from "src/app/shared/apis.service";
import { TranslateService } from "@ngx-translate/core";
import { NotifyService } from "src/app/shared/notify.service";
import { AppConfigService } from "src/app/shared/appconfig.service";
import { BaseEditorComponent } from "../../components/baseeditor.component";
import {
  PageModel,
  LayoutModel,
  LayoutItemContent,
  FrontendModel,
  MetaItem,
  BaseModel,
  UserAccess,
} from "../../models/DataModels";
import { LanguageModel } from "../../models/LanguageModel";
import { FuseConfirmDialogComponent } from "../../core/components/confirm-dialog/confirm-dialog.component";
import { SavePageRequest } from "../../models/DataModels/Cms/Request";
import { fuseAnimations } from "../../core/animations";
import {
  LayoutConfig,
  LayoutItem,
  LayoutColumn,
  LayoutCell,
} from "../../layout/layout.component";
import { LayoutSelectorDialogComponent } from "../layoutselectordialog/layoutselectordialog.component";

@Component({
  selector: "app-pageeditor",
  templateUrl: "./pageeditor.component.html",
  styleUrls: ["./pageeditor.component.scss"],
  animations: fuseAnimations,
})
export class PageEditorComponent
  extends BaseEditorComponent
  implements OnInit, OnDestroy
{
  skins: BaseModel[];
  frontends: FrontendModel[];
  frontend: FrontendModel;
  layoutData: LayoutItemContent[];
  enabledAddSection = true;
  layouts = new Array<LayoutModel>();
  languages = new Array<LanguageModel>();
  isLoading = false;
  formErrors: any;
  isNew = false;
  layoutConfig: LayoutConfig;
  isPublished: boolean;
  metaTags: FormArray;
  metaLinks: FormArray;
  layoutName: string;
  skinId: string;
  meta: string;
  links: string;
  isSystem: boolean;

  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true,
  };

  constructor(
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    @Inject(NotifyService) protected notifyService,
    @Inject(AppConfigService) private appConfig,
    private location: Location,
    private apisService: ApisService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    super();
    this.formErrors = {
      name: {},
      title: {},
      link: {},
      layoutId: {},
      frontend: {},
      skinId: {},
      isFullScreen: {},
      isLandingPage: {},
    };
  }

  ngOnInit(): void {
    this.createForm();
    this.subscribers.push(
      this.route.params.subscribe((params) => {
        this.id = params.id;
        this.isNew = this.id === undefined;
        if (!this.isNew) {
          this.pageForm.controls.skinId.disable();
        }
        this.loadPage(this.id);
      })
    );
  }

  ngOnDestroy(): void {
    this.clear();
  }

  loadPage(id: string): void {
    this.pageForm.reset();
    this.metaLinks.controls.splice(0);
    this.metaTags.controls.splice(0);
    this.isLoading = true;
    this.subscribers.push(
      this.apisService.getPage(id).subscribe((response) => {
        if (response.isError) {
          this.isLoading = false;
        }
        this.notifyService.handleServerResponse(response, (result) => {
          // this.userAccess = response.userAccess;
          this.clear();
          this.skins = result.skins;
          this.languages = result.languages;
          this.frontends = result.frontends;
          this.layouts = result.layouts;
          this.pageForm.patchValue(result.page);
          this.layoutData = result.page.layoutData;
          this.isPublished = result.page.published;
          this.isSystem = result.page.isSystem;
          for (const key in this.formErrors) {
            if (this.formErrors.hasOwnProperty(key)) {
              const element = this.formErrors[key];
              element.difference = undefined;
            }
          }
          if (result.page.publishedDiff) {
            result.page.publishedDiff.forEach((element) => {
              let key = this.formErrors[element];
              if (key) {
                key.difference = true;
              }
            });
          }
          let layoutFound = this.layouts.find(
            (x) => x.id === this.pageForm.value.layoutId
          );
          this.layoutName = layoutFound === undefined ? "" : layoutFound.name;
          this.loadMeta(result.page);
          this.updateLayout();
          this.updatePageLink();
          let timer = window.setTimeout(() => {
            this.attachCellChanged();
            this.pageForm.markAsPristine();
            this.isLoading = false;
            window.clearTimeout(timer);
          }, 800);
          this.isLoading = false;
        });
      })
    );
  }

  save(publish?: boolean, overwrite?: boolean): void {
    if (!this.userAccess.canEdit) return;
    if (this.pageForm.dirty || this.layoutData.some((x) => x.isDirty)) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new SavePageRequest();
        Object.assign(request, this.pageForm.getRawValue());
        request.layoutData = this.layoutData.filter(
          (x) => x.authWidgetName || x.widgetName
        );
        request.meta = this.pageForm.value.metaTags
          .map((x) => x.key + "=" + x.value)
          .join(";");
        request.tags = this.pageForm.value.metaLinks
          .map((x) => x.key + "=" + x.value)
          .join(";");
        request.publish = publish;
        request.overwrite = overwrite;
        request.id = this.id;
        this.subscribers.push(
          this.apisService.savePage(request).subscribe((response) => {
            this.isLoading = false;
            this.handleServerResponse(
              response,
              (result) => {
                this.pageForm.reset();
                if (this.id === result) {
                  this.loadPage(this.id);
                } else {
                  this.router.navigate(["/cms/pages/page", result]);
                }
              },
              publish
            );
          })
        );
      } else {
        this.notifyService.warning("COMMON.INVALIDDATA");
      }
    }
  }

  preview() {
    let selectedFrontend = this.frontends.find(
      (x) => x.id === this.pageForm.value.frontend
    );
    let location =
      this.appConfig.data.frontEndUrl +
      selectedFrontend.relativeUrl +
      "/" +
      this.pageForm.get("link").value;
    window.open(location, "_blank");
  }

  publish(): void {
    if (this.pageForm.invalid) {
      this.notifyService.error("COMMON.INVALIDPUBLISH");
    } else {
      this.pageForm.markAsDirty();
      this.save(true);
    }
  }

  onLayoutChanged(): void {
    this.pageForm.markAsDirty();
    if (this.layoutData) {
      this.layoutData.splice(0);
    } else {
      this.layoutData = new Array<LayoutItemContent>();
    }
    this.updateLayout();
  }

  resetFromPublished(): void {
    if (this.id === undefined) return;
    this.translateService
      .get("PAGE.RESET_PUBLISHED_CONFIRM")
      .subscribe((translate) => {
        let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          width: "50%",
        });
        dialogRef.componentInstance.confirmMessage = translate;
        dialogRef.afterClosed().subscribe((confirm) => {
          if (confirm === true) {
            this.apisService.resetPage(this.id).subscribe((result) => {
              this.notifyService.handleServerResponse(result, (res) => {
                this.loadPage(this.id);
              });
            });
          }
        });
      });
  }

  updatePageLink() {
    if (this.frontends) {
      let selectedFrontend = this.frontends.find(
        (x) => x.id === this.pageForm.value.frontend
      );
      if (selectedFrontend) {
        let location = "";
        if (this.isSystem) {
          location =
            this.appConfig.data.frontEndUrl +
            selectedFrontend.relativeUrl +
            "/" +
            this.pageForm.get("link").value;
        } else {
          let linkId = this.pageForm.get("link").value;
          if (linkId && linkId.startsWith("pages/")) {
            location =
              this.appConfig.data.frontEndUrl +
              selectedFrontend.relativeUrl +
              "/" +
              this.pageForm.get("link").value;
          } else {
            this.pageForm.controls["link"].setValue("pages/" + linkId);
            location =
              this.appConfig.data.frontEndUrl +
              selectedFrontend.relativeUrl +
              "/" +
              this.pageForm.get("link").value;
          }
        }
        this.pageForm.patchValue({
          linkUrl: location,
        });
      } else {
        this.pageForm.patchValue({
          linkUrl: "",
        });
      }
    }
  }

  protected internalClose(): void {
    let data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(["cms/pages"]);
    }
  }

  addMediaTagItem(): void {
    const control = <FormArray>this.pageForm.controls["metaTags"];
    const addrCtrl = this.createMetaItem(
      new MetaItem(["", Validators.required], ["", Validators.required])
    );
    control.push(addrCtrl);
    this.pageForm.markAsDirty();
    this.pageForm.markAsTouched();
  }

  removeMediaTagItem(i: number): void {
    const control = <FormArray>this.pageForm.controls["metaTags"];
    control.removeAt(i);
    let meta = "";
    this.pageForm.value.metaTags.forEach((metaTag) => {
      let value = metaTag.key + "=" + metaTag.value + ";";
      meta += value === "=;" ? "" : value;
    });
    this.meta = meta;
    this.pageForm.markAsDirty();
    this.pageForm.markAsTouched();
  }

  addMediaLinkItem(): void {
    const control = <FormArray>this.pageForm.controls["metaLinks"];
    const addrCtrl = this.createMetaItem(
      new MetaItem(["", Validators.required], ["", Validators.required])
    );
    control.push(addrCtrl);
    this.pageForm.markAsDirty();
    this.pageForm.markAsTouched();
  }

  removeMediaLinkItem(i: number): void {
    const control = <FormArray>this.pageForm.controls["metaLinks"];
    control.removeAt(i);
    let tags = "";
    this.pageForm.value.metaLinks.forEach((metaLink) => {
      let value = metaLink.key + "=" + metaLink.value + ";";
      tags += value === "=;" ? "" : value;
    });
    this.links = tags;
    this.pageForm.markAsDirty();
    this.pageForm.markAsTouched();
  }

  selectLayout(): void {
    let dialogRef = this.dialog.open(LayoutSelectorDialogComponent, {
      width: "80%",
      data: {},
      height: "80%",
    });
    dialogRef.afterClosed().subscribe((close) => {
      if (close && close.result) {
        if (this.pageForm.value.layoutId !== close.layoutId) {
          this.pageForm.controls.layoutId.setValue(close.layoutId);
          this.layoutName = close.layoutName;
          this.onLayoutChanged();
        }
      }
    });
  }

  onMetaTagChange(e, metaTag, prop, index): void {
    metaTag.value[prop] = e.target.value;
    let meta = "",
      change = metaTag.value.key + "=" + metaTag.value.value + ";";
    if (change === "=;") {
      change = "";
    }
    this.pageForm.value.metaTags.forEach((metaTag, i) => {
      if (index !== i) {
        meta += metaTag.key + "=" + metaTag.value + ";";
      }
    });
    this.meta = meta + change;
  }

  onMetaLinkChange(e, metaLink, prop, index): void {
    metaLink.value[prop] = e.target.value;
    let tags = "",
      change = metaLink.value.key + "=" + metaLink.value.value + ";";
    if (change === "=;") {
      change = "";
    }
    this.pageForm.value.metaLinks.forEach((metaLink, i) => {
      if (index !== i) {
        tags += metaLink.key + "=" + metaLink.value + ";";
      }
    });
    this.links = tags + change;
  }

  onSkinChanged(): void {
    this.loadFrontends();
  }

  private createForm(): void {
    this.metaLinks = this.formBuilder.array([]);
    this.metaTags = this.formBuilder.array([]);
    this.pageForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(255)]],
      title: ["", [Validators.required, Validators.maxLength(255)]],
      link: ["", [Validators.required, Validators.maxLength(255)]],
      layoutId: ["", Validators.required],
      frontend: ["", Validators.required],
      skinId: ["", Validators.required],
      isFullScreen: [],
      isLandingPage: [],
      version: [],
      metaTags: this.metaTags,
      metaLinks: this.metaLinks,
      isSystem: [{ value: false, disabled: true }],
      linkUrl: [{ value: "", disabled: true }],
    });
    this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    });
  }

  private updateLayoutCell(cell: LayoutCell): void {
    if (!cell.isLocked) {
      let context = this.layoutData.find((x) => x.cellId === cell.id);
      if (context === undefined) {
        context = new LayoutItemContent();
        context.cellId = cell.id;
        this.layoutData.push(context);
      }
      context.frontend = this.pageForm.value.frontend;
      cell.content = {
        context: context,
        type: "widgetselector",
      };
    }
  }

  private updateLayoutColumn(element: LayoutColumn): void {
    if (element.rows && element.rows.length > 0) {
      element.rows.forEach((row) => {
        if (row.cells) {
          row.cells.forEach((cell) => {
            if (cell.isContainer) {
              cell.columns.forEach((element) => {
                this.updateLayoutColumn(element);
              });
            } else {
              this.updateLayoutCell(cell);
            }
          });
        }
      });
    }
  }

  private updateLayout(): void {
    this.layoutConfig = undefined;
    let selected = this.layouts.find(
      (x) => x.id === this.pageForm.value.layoutId
    );
    if (selected) {
      this.layoutConfig = selected.config;
      if (this.layoutData === undefined) {
        this.layoutData = [];
      }
      if (this.layoutConfig) {
        this.layoutConfig.columns.forEach((element) => {
          this.updateLayoutColumn(element);
        });
      }
    }
  }

  private attachCellChanged(): void {
    if (this.layoutConfig) {
      this.layoutConfig.columns.forEach((column) => {
        column.rows.forEach((row) => {
          row.cells.forEach((cell) => {
            if (cell.content && cell.content.context) {
              let ctx = cell.content.context as LayoutItemContent;
              ctx.changed = (sender) => {
                this.pageForm.markAsDirty();
              };
            }
          });
        });
      });
    }
  }

  private createMetaItem(item: MetaItem): FormGroup {
    return this.formBuilder.group(item);
  }

  private onFormValuesChanged(data): void {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      let difference = this.formErrors[field].difference;
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
      if (difference) {
        this.formErrors[field].difference = difference;
      }
    }
    if (this.layoutConfig && this.layoutConfig.columns) {
      this.layoutConfig.columns.forEach((column) => {
        if (column.rows) {
          column.rows.forEach((row) => {
            if (row.cells) {
              row.cells.forEach((cell) => {
                if (cell.content && cell.content.context) {
                  cell.content.context.frontend = this.pageForm.value.frontend;
                }
              });
            }
          });
        }
      });
    }
  }

  private loadMeta(pageContent: PageModel): void {
    let metaTags = pageContent.meta;
    let metaLinks = pageContent.tags;
    if (metaTags) {
      metaTags.split(";").forEach((metaTag) => {
        let metaItem = metaTag.split("=");
        let key = metaItem[0];
        let value = metaItem[1];
        if (key && value) {
          this.metaTags.push(
            this.createMetaItem(
              new MetaItem(
                [key, Validators.required],
                [value, Validators.required]
              )
            )
          );
        }
      });
    }
    if (metaLinks) {
      metaLinks.split(";").forEach((metaLink) => {
        let metaItem = metaLink.split("=");
        let key = metaItem[0];
        let value = metaItem[1];
        if (key && value) {
          this.metaLinks.push(
            this.createMetaItem(
              new MetaItem(
                [key, Validators.required],
                [value, Validators.required]
              )
            )
          );
        }
      });
    }
    this.meta = "";
    this.links = "";
    // this.pageForm.setControl('metaTags', this.formBuilder.array(this.metaTags));
    // this.pageForm.setControl('metaLinks', this.formBuilder.array(this.metaLinks));
    // this.metaTags.forEach(x => this.meta += x.value.key + '=' + x.value.value + ';');
    // this.metaLinks.forEach(x => this.links += x.value.key + '=' + x.value.value + ';');
  }

  private loadFrontends(): void {
    this.frontends.splice(0);
    this.pageForm.controls.frontend.setValue(undefined);
    let skin = this.pageForm.controls.skinId;
    if (skin.value) {
      this.isLoading = true;
      this.subscribers.push(
        this.apisService.getFrontends(skin.value).subscribe((response) => {
          this.isLoading = false;
          this.notifyService.handleServerResponse(response, (result) => {
            this.clear();
            this.frontends = result;
          });
        })
      );
    }
  }
}
