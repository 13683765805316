<div class="navbar-header">
  <div class="logo">
    <a [routerLink]="['']">
      <img class="logo-icon" src="./assets/images/logos/new-pritech-logo.png" />
      <!-- <span class="logo-text">ADMIRAL</span> -->
    </a>
  </div>

  <button
    mat-button
    class="toggle-button-navbar mat-icon-button"
    fuseNavbarVertical="toggleFold"
    fxHide.lt-lg
  >
    <mat-icon *ngIf="isFoldedActive">menu</mat-icon>
    <mat-icon *ngIf="!isFoldedActive">close</mat-icon>
  </button>
  <button
    mat-button
    class="toggle-button-navbar mat-icon-button"
    fuseNavbarVertical="closeBar"
    fxHide.gt-md
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>

<div class="navbar-content" fusePerfectScrollbar>
  <fuse-navigation layout="vertical"></fuse-navigation>
</div>
