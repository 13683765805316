import { Injectable } from '@angular/core';
import { includes, head, last } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  currencyToString(_number: number): string {
    if (_number || _number == 0) {
      _number = _number / 100;

      let _string = String(_number.toFixed(2));
      if (includes(_string, '.')) {
        _string = _string.replace(/\./g, ',');
      }
      if (!includes(_string, ',')) {
        _string = _string + ',00';
      }

      let integer = head(_string.split(','));
      // let integer = head(_string.split(','))?.replace(
      //   /\B(?=(\d{3})+(?!\d))/g,
      //   '.'
      // );
      let decimal = last(_string.split(','));

      return String(integer + ',' + decimal);
    } else return null;
  }

  currencyToStringWithEuroSymbol(_number: number): string {
    if (_number || _number == 0) {
      _number = _number / 100;

      let _string = String(_number.toFixed(2));
      if (includes(_string, '.')) {
        _string = _string.replace(/\./g, ',');
      }
      if (!includes(_string, ',')) {
        _string = _string + ',00';
      }

      let integer = head(_string.split(','))?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        '.'
      );
      let decimal = last(_string.split(','));

      return String(integer + ',' + decimal) + ' €';
    } else return String(0) + ' €';
  }

  stringToCurrency(_string: string): number {
    if (!_string) return null;
    if (typeof _string === 'string') {
      _string = _string.replace(/\./g, '.').replace(/\,/g, '.');
    } else {
      _string = Number(_string).toFixed(2);
    }
    return Number(_string) * 100;
  }
}
