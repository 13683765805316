<div class="move-modal" (click)="selectItem(undefined)">
    <div class="modal-title">
        <svg viewBox="0 0 24 24">
            <path d="M14,18V15H10V11H14V8L19,13M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z"
            />
        </svg>
        <h3 [innerHTML]="title | safeHtml"></h3>
    </div>
    <div class="toolbar" fxLayout="row" fxLayoutAlign="end center" *ngIf="userAccess.canEdit">
        <h3>{{'MEDIAFOLDERS.TITLE' | translate}}</h3>
        <div class="options-icon isVisible">
            <button mat-button class="mat-icon-button" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" (click)="edit()" *fuseIfOnDom
                aria-label="add" matTooltip="{{'COMMON.ADDITEM_LABEL' | translate}}">
                <mat-icon>create_new_folder</mat-icon>
            </button>
        </div>
    </div>
    <div class="treehost">
        <li>
            <div class="nav-link mat-ripple" [ngClass]="{'selected': root.selected}">
                <button *ngIf="root.expanded === undefined || root.expanded === false" mat-button class="mat-icon-button" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                    (click)="toggleItem(root)" aria-label="expand">
                    <mat-icon>chevron_right</mat-icon>
                </button>
                <button *ngIf="root.expanded" mat-button class="mat-icon-button" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                    (click)="toggleItem(root)" aria-label="expand">
                    <mat-icon>expand_more</mat-icon>
                </button>
                <button mat-button class="mat-icon-button" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    <mat-icon>image</mat-icon>
                </button>
                <span class="title" (click)="selectItem(root)">{{root.value}}</span>
            </div>
            <ul *ngIf="root.children && root.children.length > 0">
                <ng-container *ngTemplateOutlet="treenode; context:{ $implicit: root.children }"></ng-container>
            </ul>
        </li>
    </div>
    <div class="actions">
        <button mat-raised-button [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" (click)="cancel()" aria-label="cancel">
            Annulla
        </button>
        <button mat-raised-button color="accent" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" (click)="move()" aria-label="move">
            Sposta
        </button>
    </div>
    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>

<ng-template #treenode let-list>
    <li *ngFor="let item of list">
        <div class="nav-link mat-ripple" [ngClass]="{'selected': item.selected}">
            <button *ngIf="item.expanded === undefined || item.expanded === false" mat-button class="mat-icon-button" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                (click)="toggleItem(item)" aria-label="add">
                <mat-icon>folder</mat-icon>
            </button>
            <button *ngIf="item.expanded" mat-button class="mat-icon-button" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                (click)="toggleItem(item)" aria-label="add">
                <mat-icon>folder_open</mat-icon>
            </button>
            <span class="title" (click)="selectItem(item)">{{item.value}}</span>
        </div>
        <ul *ngIf="item.children && item.children.length > 0">
            <ng-container *ngTemplateOutlet="treenode; context:{ $implicit: item.children }"></ng-container>
        </ul>
    </li>
</ng-template>