<div class="outcome dialog-container">
    <div class="page-layout carded fullwidth ps dialog" fuseperfectscrollbar>
        <div class="dialog-modal">
            <div class="header" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button class="mat-icon-button cmd-buttons" (click)="close()">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{'BETTINGGROUPS.CREATEBETOUTCOME_TITLE'
                            | translate}}</span>
                        <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
                            <button mat-button class="mat-icon-button cmd-buttons" (click)="save()" [disabled]="pageForm.invalid">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-container">
            <div name="pageForm" [formGroup]="pageForm" fxLayout="row" fxLayoutAlign="start stretch">
                <mat-form-field fxFlex="50">
                    <mat-select matInput placeholder="{{'BETTINGSPORTS.GROUP_SELECT' | translate}}" formControlName="groupId">
                        <mat-option *ngFor="let item of allItems" [value]="item.id">
                            {{ item.description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-slide-toggle matInput formControlName="enabled">{{'BETTINGSPORTS.BET_GROUPENABLED' | translate}}</mat-slide-toggle>
            </div>
        </div>
    </div>
</div>