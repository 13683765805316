import { Routes } from "@angular/router";
import { UnauthorizedComponent } from "./components/unauthorized.component";
import { AuthCallbackComponent } from "./components/auth-callback/auth-callback.component";
import { LoginAuthGuard } from "./components/login-auth.guard";
import { LoginComponent } from "./components/login/login.component";
import { SkinsSettingsComponent } from "./afriplay/skins-settings/skins-settings.component";

export const routes: Routes = [
  {
    path: "cms",
    loadChildren: () => import("./cms/cms.module").then((m) => m.CmsModule),
    pathMatch: 'prefix',
    canLoad: [LoginAuthGuard],
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'auth/login',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'auth-callback',
    pathMatch: 'full',
    component: AuthCallbackComponent
  },
  {
    path: "help",
    loadChildren: () => import("./help/help.module").then(m => m.HelpModule)
  },
  {
    path: "gbet",
    loadChildren: "./gbet/gbet.module#GbetModule",
  },
  {
    path: "betting",
    loadChildren: "./betting/betting.module#BettingModule",
  },
  {
    path: "promo",
    loadChildren: "./promotions/promotion.module#PromotionModule",
  },
  {
    path: 'game-pritech',
    canLoad: [LoginAuthGuard],
    loadChildren: () =>
      import('./afriplay/afriplay-game.module').then(
          (m) => m.AfriplayGameModule
      ),
      /*


      */
  },
  {path: 'unauthorized', component: UnauthorizedComponent},
  //temporary:
  {path: "skins-settings", component: SkinsSettingsComponent, pathMatch: "full"},
  {path: '**', redirectTo: 'cms'},
  {path: '', redirectTo: 'cms', pathMatch: 'full'},
];
