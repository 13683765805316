import { DataBaseRequest } from "../TableDataSource/DataBaseRequest";
// tslint:disable-next-line:max-line-length
import {
  AdmiralPlatform,
  BettingBonusDetail,
  FrontendModel,
  GameAttributeLinkModel,
  LayoutItemContent,
  OutcomeModel,
  SportBetGroupModel,
} from "../../DataModels";
import { LayoutConfig } from "../../../layout/layout.component";

export class GetWidgetRequest extends DataBaseRequest {
  name: string;
  type: string;
  showAnonymous?: boolean;
  showAuthenticated?: boolean;
  startDate?: Date;
  endDate?: Date;
  frontend?: string;
  exclude?: Array<string>;
  excludeTypes?: Array<string>;
  fixedWidget: boolean;
  isSelector: boolean;

  constructor() {
    super();
    this.name = "";
    this.type = "";
    this.startDate = undefined;
    this.endDate = undefined;
    this.showAnonymous = undefined;
    this.showAuthenticated = undefined;
    this.page = 0;
    this.includeAccess = true;
    this.fixedWidget = false;
    this.isSelector = false;
  }

  reset(): void {
    this.name = undefined;
    this.type = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.showAnonymous = undefined;
    this.showAuthenticated = undefined;
    this.page = 0;
    this.includeAccess = true;
    this.fixedWidget = false;
  }
}

export class GetMediaRequest extends DataBaseRequest {
  name: string;
  description: string;
  created?: Date;
  frontend?: string;
  folder?: string;
  allFolders: boolean;
  isSearch: boolean;

  constructor() {
    super();
    this.name = "";
    this.description = "";
    this.created = undefined;
    this.frontend = undefined;
    this.page = 0;
    this.includeAccess = true;
    this.folder = undefined;
    this.allFolders = true;
    this.isSearch = false;
  }

  reset(): void {
    this.name = undefined;
    this.description = undefined;
    this.created = undefined;
    this.frontend = undefined;
    this.page = 0;
    this.includeAccess = true;
    this.allFolders = true;
    this.isSearch = false;
  }
}

export enum MediaRequestType {
  None,
  Inserted,
  Removed,
  Updated,
}

export class UploadMediaRequest {
  width: number;
  height: number;
  frontend: string;
  folder?: string;
  isDocument: boolean;

  constructor(
    public id?: string,
    public name?: string,
    public description?: string,
    public items: UploadMediaRequestItem[] = new Array<UploadMediaRequestItem>(),
    public alt?: string
  ) {}
}

export class UploadMediaRequestItem {
  fileUrl: string;
  changed: boolean;
  languageCode: string;
  languageId: string;
  publicUrl: string;

  constructor(
    public action: MediaRequestType = MediaRequestType.None,
    public language?: string,
    public file?: File
  ) {}

  isNew(): boolean {
    return this.fileUrl === undefined;
  }
}

export class MediaContentResponse {
  id: string;
  name: string;
  description: string;
  alt: string;
  width: number;
  height: number;
  frontend: string;
  folder?: string;
  isDocument: boolean;
  cdnUrl: string;
  publicUrl: string;
  items: MediaContentResponseItem[];
  frontends: FrontendModel[];
}

export class MediaContentResponseItem {
  path: string;
  language: string;
  publicUrl: string;
}

export class GetLayoutsRequest extends DataBaseRequest {
  name: string;

  constructor() {
    super();
    this.name = undefined;
    this.includeAccess = true;
  }

  reset(): void {
    this.name = undefined;
    this.includeAccess = true;
  }
}

export class GetPagesRequest extends DataBaseRequest {
  name: string;
  skin: string;
  frontend: string;
  title: string;

  constructor() {
    super();
    this.name = undefined;
    this.skin = undefined;
    this.frontend = undefined;
    this.title = undefined;
    this.includeAccess = true;
    this.page = 0;
  }

  reset(): void {
    this.name = undefined;
    this.skin = undefined;
    this.frontend = undefined;
    this.title = undefined;
    this.page = 0;
    this.includeAccess = true;
  }
}

export class BaseSaveRequest {
  id?: string;
  version?: any;
  overwrite?: boolean;
  publish?: boolean;
  copy?: boolean;
}

export class SavePageRequest extends BaseSaveRequest {
  name: string;
  title: string;
  link: string;
  meta: string;
  tags: string;
  layoutId: string;
  skinId: string;
  layoutData?: LayoutItemContent[];
  publish?: boolean;
  copy?: boolean;

  constructor(
    name?: string,
    title?: string,
    link?: string,
    meta?: string,
    tags?: string,
    layoutId?: string,
    skinId?: string,
    layoutData?: LayoutItemContent[],
    publish?: boolean,
    isFullScreen?: boolean,
    isLandingPage?: boolean,
    copy?: boolean
  ) {
    super();
  }
}

export class SaveLayoutRequest extends BaseSaveRequest {
  name: string;
  config: LayoutConfig;
  copy?: boolean;
}

export class SaveZoneRequest {
  pageId: string;
  id: string;
  name: string;
  authWidgetId: string;
  anonymousWidgetId: string;
  remoteId: string;
}

export class SaveWidgetRequest extends BaseSaveRequest {
  name: string;
  type: string;
  startDate: Date;
  endDate?: Date;
  showAnonymous: boolean;
  showAuthenticated: boolean;
  hideOnApp: boolean;
  content: any;
  link: string;
  frontend: string;
  dynamicComponent: string;
  notes: string;
  visible: boolean;
  copy?: boolean;
  publish?: boolean;
  showShadow?: boolean;
}

export class GetExternalMediaRequest extends DataBaseRequest {
  searchById?: boolean;
  mediaIds?: Array<string>;
  name?: string;
  frontend?: string;
  languageCode?: string;
  exclude?: Array<string>;
  folder?: string;
  ignoreFolder: boolean;
  documents: boolean;

  constructor() {
    super();
    this.mediaIds = undefined;
    this.name = "";
    this.languageCode = "";
    this.frontend = undefined;
    this.folder = undefined;
  }

  reset(): void {
    this.name = undefined;
    this.languageCode = undefined;
    this.mediaIds = undefined;
    this.frontend = undefined;
    this.folder = undefined;
  }
}

export class SearchItemModel {
  id: string;
  name: string;
  type: number;
  typeLabel: string;
}

export class GetSearchRequest extends DataBaseRequest {
  name: string = "";
  startDate?: Date;
  endDate?: Date;

  constructor() {
    super();
    this.name = "";
    this.startDate = undefined;
    this.endDate = undefined;
    this.page = 0;
  }

  reset(): void {
    this.name = "";
    this.startDate = undefined;
    this.endDate = undefined;
    this.page = 0;
  }
}

export class GetContentsRequest extends DataBaseRequest {
  id?: string;
  name: string;
  findById?: boolean;

  constructor() {
    super();
    this.id = undefined;
    this.name = "";
    this.findById = undefined;
    this.page = 0;
    this.includeAccess = true;
  }

  reset(): void {
    this.name = undefined;
    this.findById = undefined;
    this.page = 0;
    this.includeAccess = true;
  }
}

export class SaveContentRequest extends BaseSaveRequest {
  name: string;
  copy?: boolean;
  widgetId?: string;
  widgetName?: string;
}

export class SaveCmhHtmlRequest extends BaseSaveRequest {
  style?: string;
  script?: string;
  publish?: boolean;
}

export class GetPromotionsRequest extends DataBaseRequest {
  name: string;
  platform: string;
  frontend: string;
  skin: string;
  startDate?: Date;
  endDate?: Date;

  constructor() {
    super();
    this.name = undefined;
    this.platform = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.skin = undefined;
    this.frontend = undefined;
    this.page = 0;
    this.includeAccess = true;
  }

  reset(): void {
    this.name = undefined;
    this.platform = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.skin = undefined;
    this.frontend = undefined;
    this.page = 0;
    this.includeAccess = true;
  }
}

export class SavePromotionRequest extends BaseSaveRequest {
  frontends: FrontendModel[];
  name: string;
  sort?: number;
  link: string;
  shortDetail?: string;
  isVisible: boolean;
  startDate: Date;
  endDate?: Date;
  backColor?: string;
  buttons: string;
  comingSoonButtons: string;
  enableDate: boolean;
  enableTimer: boolean;
  enableStartTimer: boolean;
  showData: boolean;
  html: string;
  jackpot?: number;
  notes?: string;
  platforms: AdmiralPlatform[];
  showIncoming: boolean;
  desktopMediaId: string;
  desktopSmallMediaId: string;
  mobileMediaId: string;
  moileSmallMediaId: string;
  showAfterRegister: boolean;
  badgeIcon: string;
  promotionId: string;
  isWelcome: boolean;
  landscape: boolean;
}

export class GetSectionsRequest extends DataBaseRequest {
  name: string;
  meta: string;
  tags: string;
  title: string;

  constructor() {
    super();
    this.name = "";
    this.meta = "";
    this.tags = "";
    this.page = 0;
  }

  reset(): void {
    this.name = undefined;
    this.meta = undefined;
    this.tags = undefined;
    this.page = 0;
  }
}

export class SaveSectionRequest extends BaseSaveRequest {
  skinId: string;
  menuId: string;
  name: string;
  title: string;
  link: string;
  layoutId: string;
  layoutData?: LayoutItemContent[];
  publish?: boolean;
  copy?: boolean;
  isPopup: boolean;
}

export class GetMenusRequest extends DataBaseRequest {
  skin: string;
  display: string;
  link: string;
  enabled?: boolean;

  constructor() {
    super();
    this.skin = undefined;
    this.display = undefined;
    this.link = undefined;
    this.enabled = undefined;
    this.page = 0;
    this.includeAccess = true;
  }

  reset(): void {
    this.skin = undefined;
    this.display = undefined;
    this.link = undefined;
    this.enabled = undefined;
    this.page = 0;
    this.includeAccess = true;
  }
}

export class SaveMenuRequest extends BaseSaveRequest {
  name: string;
  link: string;
  skinId: string;
  parentId: string;
  sort: number;
  isRight: boolean;
  authenticated: boolean;
  icon: string;
  enabled: boolean;

  constructor(
    name?: string,
    link?: string,
    skinId?: string,
    parentId?: string,
    sort?: number,
    isRight?: boolean,
    authenticated?: boolean,
    icon?: string,
    userType?: number,
    enabled?: boolean
  ) {
    super();
  }
}

export class SaveMediaFolderRequest extends BaseSaveRequest {
  name: string;
  parentFolder: string;
}

export class MoveMediaFolderRequest {
  sourceFolderId: string;
  destinationFolderId?: string;
}

export class MoveMediaItemsRequest {
  ids: string[];
  destinationFolderId: string;
}

export class SaveSkinRequest extends BaseSaveRequest {
  name: string;
  googleSearchConsole: string;
  googleTagManager: string;
  googleTagManagerHead: string;
  googleTagManagerBody: string;
  googleSearchConsoleMobile: string;
  googleTagManagerMobile: string;
  isMaintenance: boolean;
  isMobileMaintenance: boolean;

  constructor(
    name?: string,
    googleSearchConsole?: string,
    googleTagManager?: string,
    copy?: boolean
  ) {
    super();
  }
}

export class GetMobileContentRequest extends DataBaseRequest {
  name: string;
  skin: string;

  constructor() {
    super();
    this.name = undefined;
    this.skin = undefined;
    this.includeAccess = true;
    this.page = 0;
  }

  reset(): void {
    this.name = undefined;
    this.skin = undefined;
    this.page = 0;
    this.includeAccess = true;
  }
}

export class SaveMobileRequest extends BaseSaveRequest {
  name: string;
  skinId: string;
  pageId: string;
  startDate?: Date;
  endDate?: Date;
  enabled: boolean;
  authenticated: boolean;
  sort: number;
}

export class GetGamesRequest extends DataBaseRequest {
  name?: string;
  provider?: string;
  gameId?: string;
  adm?: string;
  channel?: string;
  jackpot?: string;
  visible?: string;
  enabled?: string;
  isNew?: string;
  room?: string;

  constructor() {
    super();
    this.channel = "-1";
    this.jackpot = "-1";
    this.visible = "1";
    this.enabled = "1";
    this.isNew = "-1";
    this.room = "-1";
    this.name = undefined;
    this.adm = undefined;
    this.gameId = undefined;
    this.provider = undefined;
    this.includeAccess = true;
    this.column = "systemDate";
    this.order = 1;
    this.page = 0;
  }

  reset(): void {
    this.channel = "-1";
    this.jackpot = "-1";
    this.visible = "1";
    this.enabled = "1";
    this.isNew = "-1";
    this.room = "-1";
    this.name = undefined;
    this.adm = undefined;
    this.gameId = undefined;
    this.provider = undefined;
    this.includeAccess = true;
    this.column = "systemDate";
    this.order = 1;
    this.page = 0;
  }
}

export class BaseGbetSaveRequest {
  id?: number;
}

export class SaveGameRequest extends BaseGbetSaveRequest {
  gameId: string;
  idFornitore: number;
  name: string;
  fullName: string;
  nomeUnivoco: string;
  title: string;
  nameSEO: string;
  aamsCode: string;
  aamsTipoGioco: string;
  isEnabled: boolean;
  isVisible: boolean;
  isJackpot: boolean;
  isMobile: boolean;
  isDesktop: boolean;
  isDemo: boolean;
  order: number;
  fpp: number;
  coefficiente: number;
  isMultybet: boolean;
  height: number;
  width: number;
  rpt: number;
  imageBackground: string;
  imageMini: string;
  imageSplashMobile: string;
  imageSplashDesktop: string;
  imageMiniPreview: string;
  imageIconMobile: string;
  help: string;
  shortDescription: string;
  longDescription: string;
  isNew: boolean;
  netentGameType: number | null;
  netentGameFamily: number | null;
  capecodeGameType: number | null;
  capeCodeClientType: string;
  capecodeIsFun: boolean | null;
  capecodeCashAvailable: boolean | null;
  greenTubeGameType: number | null;
  greenTubeIsFun: boolean | null;
  greenTubeCashAvailable: boolean | null;
  novomaticGameType: number | null;
  tagDescription: string | null;
  tagKeywords: string | null;
  tagTitle: string | null;
  attributes: GameAttributeLinkModel[];
  deletedAttributes: number[];
  public IsForeground: boolean;
  public GoLiveDate?: Date;
}

export class GetGamesLiveRequest extends DataBaseRequest {
  name: string;
  provider: string;
  gameId: string;

  constructor() {
    super();
    this.name = undefined;
    this.gameId = undefined;
    this.provider = undefined;
    this.includeAccess = true;
    this.column = "systemDate";
    this.order = 1;
    this.page = 0;
  }

  reset(): void {
    this.name = undefined;
    this.gameId = undefined;
    this.page = 0;
    this.provider = undefined;
    this.includeAccess = true;
    this.column = "systemDate";
    this.order = 1;
  }
}

export class SaveGameLiveRequest extends BaseGbetSaveRequest {
  idFornitore: number;
  tableName: string;
  tableId: string;
  tableType: string;
  tableVirtual: string;
  tableTypeName: string;
  urlSeo: string;
  tablePiattaforma: string;
  tableOpen: number;
  tableClose: number;
  ordine: number | null;
  isOpen: number | null;
  isEnabled: number;
  tipoGioco: string;
  codiceAAMS: string;
  tableImg: string;
  fpp: number | null;
  rpt: number | null;
  pageId: string;
  lobby: string;
  displayName: string;
  coefficente: number | null;
  tagDescription: string | null;
  tagKeywords: string | null;
  tagTitle: string | null;
  public IsForeground: boolean;
  public GoLiveDate?: Date;
  groupGame: string;
}

export class GetBettingGroupsRequest extends DataBaseRequest {
  name: string;
  sport: number;

  constructor() {
    super();
    this.name = undefined;
    this.includeAccess = true;
    this.sport = undefined;
    this.column = "systemDate";
    this.order = 1;
    this.page = 0;
  }

  reset(): void {
    this.name = undefined;
    this.page = 0;
    this.includeAccess = true;
    this.sport = undefined;
    this.column = "systemDate";
    this.order = 1;
  }
}

export class SaveBettingGroupRequest extends BaseGbetSaveRequest {
  description: string;
  sportId: number;
  outcomes: OutcomeModel[];
  removedOutcomes: number[];
}

export class GetBettingSportsRequest extends DataBaseRequest {
  name: string;
  sport: number;

  constructor() {
    super();
    this.name = undefined;
    this.includeAccess = true;
    this.sport = undefined;
    this.column = "systemDate";
    this.order = 1;
    this.page = 0;
  }

  reset(): void {
    this.name = undefined;
    this.page = 0;
    this.includeAccess = true;
    this.sport = undefined;
    this.column = "systemDate";
    this.order = 1;
  }
}

export class SaveBettingSportSettingsRequest extends BaseGbetSaveRequest {
  sportId: number;
  description: string;
  desktopImage: string;
  mobileImage: string;
  removedGroups: number[];
  betGroups: SportBetGroupModel[];
}

export class UploadNativeAppSettingRequest {
  public id: string;
  public file: File;
  public releaseVersion: string;
  isMaintenance: boolean;
  isAppDisabledOutsideOfItaly: boolean;
}

export class GetBonusRequest extends DataBaseRequest {
  name: string;
  bonusType: string;
  fakeMoney?: boolean;
  inConfiguration?: boolean;
  startDate?: Date;
  endDate?: Date;

  constructor() {
    super();
    this.name = undefined;
    this.bonusType = undefined;
    this.fakeMoney = undefined;
    this.inConfiguration = undefined;
    this.includeAccess = true;
    this.startDate = undefined;
    this.endDate = undefined;
    this.column = "systemDate";
    this.order = 1;
    this.page = 0;
  }

  reset(): void {
    this.name = undefined;
    this.bonusType = undefined;
    this.page = 0;
    this.fakeMoney = undefined;
    this.inConfiguration = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.includeAccess = true;
    this.column = "systemDate";
    this.order = 1;
  }
}

export class GetPromoRequest extends DataBaseRequest {
  promoCode: string;
  startDate?: Date;
  endDate?: Date;

  constructor() {
    super();
    this.promoCode = undefined;
    this.includeAccess = true;
    this.startDate = undefined;
    this.endDate = undefined;
    this.column = "systemDate";
    this.order = 1;
    this.page = 0;
  }

  reset(): void {
    this.promoCode = undefined;
    this.page = 0;
    this.startDate = undefined;
    this.endDate = undefined;
    this.includeAccess = true;
    this.column = "systemDate";
    this.order = 1;
  }
}

export class SaveBonusRequest extends BaseSaveRequest {
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  idBonusType: number;
  minRefill: number;
  maxBonus: number;
  active: boolean;
  fakeMoney: boolean;
  specialBonus: boolean;
  notes: string;
  cumulative: boolean;
  gameCode: number;
  isTranche: boolean;
  bonus: number;
  pointCode: number;
  wagering: number;
  durataGG: number;
  cap: number;
  comment: string;
  week: number;
  rulesTranche: string;
  isPercentage: boolean;
  percentage: number;
  detailFakeMoney: string;
  detailBonus: string;
  version: any;
  contoVerificato: boolean;
  betting: BettingBonusDetail;
}

export class SavePromoRequest extends BaseSaveRequest {
  pointCode: number;
  promoCode: string;
  attivo: boolean;
  multipleBonus: boolean;
  startDate: Date;
  endDate: Date;
  multipleCode: boolean;
  insertCounter: number;
  idBonus: number;
  maxNumberInsert: number;
}
