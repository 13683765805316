import { Component, OnInit } from "@angular/core";
import { DynamicComponent } from '../../layout/layoutcontent.component';

@Component({
  selector: "app-layoutimage",
  templateUrl: "./layoutimage.component.html",
  styleUrls: ["./layoutimage.component.scss"]
})

export class LayoutImageComponent extends DynamicComponent implements OnInit {
  public isDirty() {
    return false;
  }

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
