import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {NotifyService} from 'src/app/shared/notify.service';
import {ApisService} from 'src/app/shared/apis.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MediaFolderModel} from '../../models/DataModels/TableDataSource/ModelRequest';
import {fuseAnimations} from '../../core/animations';
import {UserAccess} from '../../models/DataModels';
import {FuseConfirmDialogComponent} from '../../core/components/confirm-dialog/confirm-dialog.component';
import {EditMediaFolderComponent} from './editmediafolder.component';
import {SaveMediaFolderRequest} from '../../models/DataModels/Cms/Request';
import {MoveMediaFolderComponent} from './movemediafolder.component';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'app-mediafolder',
    templateUrl: './mediafolder.component.html',
    styleUrls: ['./mediafolder.component.scss'],
    animations: fuseAnimations
})

export class MediaFolderComponent implements OnInit, OnDestroy {
    // tslint:disable-next-line:no-output-rename
    @Output('currentChanged') currentChanged = new EventEmitter<string>();

    isLoading = false;
    showActions = true;
    root = <MediaFolderModel> {
        value: 'Media&Documenti'
    };
    userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    private currentFolder: MediaFolderModel;

    constructor(
        @Inject(NotifyService) private notifyService,
        private apisService: ApisService,
        public dialog: MatDialog,
        private translateService: TranslateService
    ) {

    }

    ngOnInit() {
        this.toggleItem(this.root);
    }

    ngOnDestroy() {

    }

    selectItem(item: MediaFolderModel): void {
        if (item === this.currentFolder) {
            return;
        }
        if (this.currentFolder !== undefined) {
            this.currentFolder.selected = false;
        }
        this.currentFolder = item;
        if (this.currentFolder !== undefined) {
            this.currentChanged.emit(this.currentFolder.id);
            this.toggleItem(item);
            this.currentFolder.selected = true;
        } else {
            this.currentChanged.emit(undefined);
        }
    }

    toggleItem(item: MediaFolderModel): void {
        if (item.expanded) {
            item.expanded = false;
            item.children = new Array<MediaFolderModel>();
        } else {
            item.expanded = true;
            this.loadFolder(item);
        }
    }

    deleteItem(item: MediaFolderModel): void {
        if (item === undefined || !this.userAccess.canDelete) {
            return;
        }
        if (this.isLoading) {
            return;
        }
        this.translateService.get('COMMON.DELETEMESSAGE').subscribe(translate => {
            translate += `${item.value}?`;
            let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {
                    this.isLoading = true;
                    let loader = this.apisService.deleteMediaFolder(item.id).subscribe(response => {
                        this.isLoading = false;
                        loader.unsubscribe();
                        loader = undefined;
                        this.notifyService.handleServerResponse(response, result => {
                            this.root.children = undefined;
                            this.loadFolder(this.root);
                        });
                    });
                }
            });
        });
    }

    edit(item?: MediaFolderModel) {
        let dialogRef = this.dialog.open(EditMediaFolderComponent, {
            width: '40%',
            data: {
                model: item
            },
            height: '40%'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.item) {
                    let request = <SaveMediaFolderRequest> {
                        id: result.item.id,
                        name: result.item.value,
                        parentFolder: isNullOrUndefined(item) ? (this.currentFolder ? this.currentFolder.id : undefined) : item.parentId
                    };
                    let loader = this.apisService.saveMediaFolder(request).subscribe(response => {
                        loader.unsubscribe();
                        loader = undefined;
                        this.isLoading = false;
                        this.notifyService.handleServerResponse(response, result => {
                            this.root.children.splice(0);
                            this.loadFolder(this.root);
                        });
                    });
                }
            }
        });
    }

    move(item: MediaFolderModel) {
        let dialogRef = this.dialog.open(MoveMediaFolderComponent, {
            width: '60%',
            data: {
                model: item
            },
            height: '60%'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.request) {
                    let loader = this.apisService.moveMediaFolder(result.request).subscribe(response => {
                        loader.unsubscribe();
                        loader = undefined;
                        this.isLoading = false;
                        this.notifyService.handleServerResponse(response, result => {
                            this.root.children.splice(0);
                            this.loadFolder(this.root);
                        });
                    });
                }
            }
        });
    }

    public isGameMedia(folder: string): boolean {
        const gamesMediaFolder = this.root.children.find(x => x.value.toLocaleLowerCase() === 'games');
        const flatNodes = this.flat(gamesMediaFolder);
        if (flatNodes) {

            return flatNodes.find(x => x.id === folder) !== undefined;
        } else {
            return false;
        }

    }

    private flat(folder: MediaFolderModel, result?: MediaFolderModel[]): MediaFolderModel[] {
        result = result || [];
        if (folder) {

            result.push(folder);
            if (folder.children) {
                for (let index = 0; index < folder.children.length; index++) {
                    const element = folder.children[index];
                    this.flat(element, result);
                }
            }
        }

        return result;
    }

    private loadFolder(item?: MediaFolderModel): void {
        if (this.isLoading || item === undefined) {
            return;
        }
        this.isLoading = true;
        let path = undefined;
        if (item.id !== undefined) {
            path = item.id;
        }
        let loader = this.apisService.getMediaFolder(path).subscribe(response => {
            if (response.userAccess) {
                // this.userAccess = response.userAccess;
            }
            loader.unsubscribe();
            loader = undefined;
            this.notifyService.handleServerResponse(response, result => {
                item.children = result;
            });
            this.isLoading = false;
        });
    }

}
