import { Inject, Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { NotifyMessage, NotifyType } from '../../models/NotifyMessage';
import { NotifyService } from 'src/app/shared/notify.service';
import { FuseConfigService } from '../../core/services/config.service';

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"]
})

export class NotificationComponent implements OnInit, OnDestroy {
  private subscriber: Subscription;
  private autoclose: number = 2000;
  messages = new Array<NotifyMessage>();
  show = false;

  constructor(
    @Inject(NotifyService) private notifyService,
    private configService: FuseConfigService,
  ) {
    this.autoclose = this.configService.settings.notificationClose
  }

  ngOnInit() {
    this.subscriber = this.notifyService.subscribe((msg: NotifyMessage) => {
      if (msg == undefined) {
        this.messages = new Array<NotifyMessage>();
        this.show = false;
        return;
      }
      this.messages.push(msg);
      this.show = this.messages.length > 0;
      if (this.show) {
        let clear = setTimeout(() => {
          this.onRemoveClick(msg);
          clearInterval(clear);
        }, this.autoclose);
      }
    });
  }


  ngOnDestroy(): void {
    if (this.subscriber != undefined) {
      this.subscriber.unsubscribe();
    }
  }

  onRemoveClick(message: NotifyMessage) {
    this.messages = this.messages.filter(x => x !== message);
    this.show = this.messages.length > 0;
  }

  cssClass(msg: NotifyMessage) {
    if (!msg) {
      return 'alert-dismissable';
    }
    switch (msg.type) {
      case NotifyType.Success:
        return 'msg-success';
      case NotifyType.Error:
        return 'msg-error';
      case NotifyType.Warning:
        return 'msg-warning';
    }
  }
}
