<div class="page-layout carded fullwidth ps">
    <generic-search-filter [requestModel]="model" [panelTitle]="panelTitle" [panelIcon]="'insert_drive_file'"
                           (onSubmit)="onSubmit()"
                           (onAddItem)="add()"></generic-search-filter>

    <div class="custom-center" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
        <div class="content-card" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
            <div *ngIf="dataSource?.isEmpty || dataSource?.isError">
                <div class="table-error-message" *ngIf="dataSource?.isEmpty">
                    <span>{{'COMMON.NO_ITEMS' | translate}}</span>
                </div>
                <div class="table-error-message" *ngIf="dataSource?.isError">
                    <span>{{'COMMON.ERROR_LOADING' | translate}}</span>
                </div>
            </div>
            <mat-table [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}"
                       [dataSource]="dataSource"
                       class="table-items mat-table" [@animateStagger]="{value:'50'}" matSort matSortDisableClear
                       matSortActive="systemDate"
                       matSortDirection="desc" fuseperfectscrollbar>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'MEDIA.ACTION' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div fxLayoutAlign="start">
                            <button mat-icon-button *ngIf="userAccess.canEdit" (click)="edit(row, $event)"
                                    name="editButton"
                                    matTooltip="{{'COMMON.EDIT' | translate}}">
                                <mat-icon class="edit">edit</mat-icon>
                            </button>

                            <button mat-icon-button *ngIf="userAccess.canDelete" (click)="delete(row, $event)"
                                    name="deleteButton"
                                    matTooltip="{{'COMMON.DELETE' | translate}}">
                                <mat-icon class="delete-icon">delete</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="promoCode">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{'PROMOTIONSPOINTCODE.PROMO_CODE' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.promoCode}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="pointCode">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{'PROMOTIONSPOINTCODE.POINT_CODE' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.pointCodeDescr}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="attivo">
                    <mat-header-cell *matHeaderCellDef>{{'PROMOTIONSPOINTCODE.ACTIVE' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-icon [ngClass]="{'check': row.attivo, 'clear': !row.attivo}">
                            {{row.attivo ? "check" : "clear"}}
                        </mat-icon>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="startDate">
                    <mat-header-cell
                            *matHeaderCellDef>{{'PROMOTIONSPOINTCODE.STARTDATE_COLUMN' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.startDate}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <mat-header-cell
                            *matHeaderCellDef>{{'PROMOTIONSPOINTCODE.ENDDATE_COLUMN' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.endDate}}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="systemDate">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{'PROMOTIONSPOINTCODE.SYSTEMDATE_COLUMN' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.systemDate}}
                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></mat-row>

            </mat-table>
            <div class="media-loading-shade" *ngIf="dataSource?.isLoadingResults">
                <mat-spinner [color]="'accent'"></mat-spinner>
            </div>
            <mat-paginator [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}"
                           [length]="dataSource?.resultsLength"
                           [pageSize]="dataSource?.pageSize">
            </mat-paginator>
        </div>
    </div>

</div>
