<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{
                            'NATIVEAPP.TITLE' | translate}}</span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="!pageForm.touched && pageForm.invalid"
                                *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.SAVE' | translate}}" (click)="save()">
                                <mat-icon>backup</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div fxLayout="row" fxLayoutAlign="start stretch">
                <mat-form-field fxFlex="50">
                    <mat-select matInput placeholder="{{ 'NATIVEAPP.NAME' | translate}}" [(ngModel)]="selectedItem"
                        (selectionChange)="onPlatformChanged()">
                        <mat-option *ngFor="let appSetting of nativeAppSettings" [value]="appSetting.id">
                            {{ appSetting.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div name="pageForm" [formGroup]="pageForm" class="page-form" *ngIf="selectedItem">
                <div fxLayout="row" fxLayoutAlign="start stretch">
                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{ 'NATIVEAPP.RELEASEVERSION' | translate}}" formControlName="releaseVersion">
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{ 'NATIVEAPP.CURRENTFILE' | translate}}" formControlName="link">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start stretch">
                    <mat-form-field class="media-item-row" fxFlex="50">
                        <input id="fileInput" name="fileInput" type="file" #fileInput (change)="fileChange($event, fileSelected)">

                        <input id="filePicker" matInput placeholder="{{ (fileSelected.value ? 'MEDIA.FILE_INPUT_SELECTED' : 'MEDIA.FILE_INPUT') | translate }}"
                            name="fileSelected" disabled value="" #fileSelected>
                        <button mat-button fxFlex="0 0 10%" class="mat-icon-button" name="filePickerOpen" (click)="fileInput.click()">
                            <mat-icon class="material-icons mat-icon" role="img" aria-hidden="true">settings_applications</mat-icon>
                            <div class="mat-button-ripple mat-ripple mat-button-ripple-round"></div>
                            <div class="mat-button-focus-overlay"></div>
                        </button>
                        <!-- <button mat-button fxFlex="0 0 10%" class="mat-icon-button" name="delete" (click)="removeItem()">
                            <span class="custom-icon-container">
                                <mat-icon class="material-icons mat-icon" role="img" aria-hidden="true">delete</mat-icon>
                            </span>
                        </button> -->
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start stretch">
                    <div fxFlex="50" class="mat-form-field">
                        <mat-checkbox formControlName="isMaintenance">{{'NATIVEAPP.MAINTENANCE' | translate}}
                        </mat-checkbox>
                    </div>
                    <div fxFlex="50" class="mat-form-field">
                        <mat-checkbox formControlName="isAppDisabledOutsideOfItaly">{{'NATIVEAPP.DISABLE_OUT_OF_ITALY' | translate}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>
