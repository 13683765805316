import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GetBonusRequest} from '../../models/DataModels/Cms/Request';
import {Observable, Subscription} from 'rxjs';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {ApisService} from 'src/app/shared/apis.service';
import {NotifyService} from 'src/app/shared/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {BonusModel, GamesModel, GetItemsBaseResponse, UserAccess} from '../../models/DataModels';
import {DataBaseRequestField, ModelRequest, SelectItem} from '../../models/DataModels/TableDataSource/ModelRequest';
import {HttpDao} from '../../models/DataModels/TableDataSource/HttpDao';
import {FuseConfirmDialogComponent} from '../../core/components/confirm-dialog/confirm-dialog.component';
import {fuseAnimations} from '../../core/animations';
import {map} from 'rxjs/operators';

@Component({
    selector: 'bonus',
    templateUrl: './bonus.component.html',
    styleUrls: ['./bonus.component.scss'],
    animations: fuseAnimations
})
export class BonusComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    panelTitle = 'BONUS.TITLE';
    dataSource: TableDataSource<GetBonusRequest, GetItemsBaseResponse<BonusModel>>;
    displayedColumns = ['action', 'name', 'bonusType', 'attivo', 'fakeMoney', 'startDate', 'endDate', 'systemDate'];
    model: ModelRequest<GetBonusRequest>;

    protected request: GetBonusRequest = new GetBonusRequest();
    private bonusTypes = new DataBaseRequestField('bonusType', 'BONUS.BONUSTYPE', 'select', false, new Array<SelectItem>());

    private httpDao: BonusHttpDao;
    private firstLoad: Subscription;

    constructor(
        @Inject(AppConfigService) private appConfig: AppConfigService,
        @Inject(NotifyService) private notifyService,
        private translateService: TranslateService,
        public dialog: MatDialog,
        private apisService: ApisService,
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        // const internalData = this.appConfig.temporaryData;
        // if (internalData && internalData.internal && internalData.data) {
        //   let request = internalData.data as GetGamesRequest;
        //   if (request) {
        //     this.request = request;
        //   }
        // }
        this.request.includeAccess = true;
        this.bonusTypes.selectItems = new Array<SelectItem>();
        this.bonusTypes.selectItems.push(<SelectItem> {
            id: undefined,
            value: ''
        });
    }

    ngOnInit() {
        this.httpDao = new BonusHttpDao(this.apisService);
        this.firstLoad = this.httpDao.firstLoad.subscribe((userAccess: UserAccess) => {
            this.request.includeAccess = false;
            // this.userAccess = userAccess;
            this.model.updateShowAdd(this.userAccess.canInsert);
        });
        this.model = new ModelRequest<GetBonusRequest>(this.request);
        this.model.showAddItem = true;

        let tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(
            new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string'),
            this.bonusTypes,
            new DataBaseRequestField('startDate', 'BONUS.STARTDATE_FILTER', 'date'),
            new DataBaseRequestField('endDate', 'BONUS.ENDDATE_FILTER', 'date'),
            new DataBaseRequestField('inConfiguration', 'BONUS.IN_CONFIGURATION', 'boolean'),
            new DataBaseRequestField('fakeMoney', 'BONUS.FAKE_MONEY', 'boolean')
        );
        this.model.items = tmpItems;

        const bonusTypesLoader = this.apisService.getBonusType().subscribe(response => {
            if (!response.isError) {
                this.bonusTypes.selectItems.push(...response.data);
            }
            bonusTypesLoader.unsubscribe();
        });


    }

    ngAfterViewInit() {
        this.dataSource = new TableDataSource<GetBonusRequest, GetItemsBaseResponse<BonusModel>>(this.httpDao!, this.paginator, this.sort, this.model);
        this.cd.detectChanges();
    }


    ngOnDestroy() {
        this.dataSource.disconnect();
        if (this.firstLoad) {
            this.firstLoad.unsubscribe();
            this.firstLoad = undefined;
        }
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    edit(item?: BonusModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canEdit) {
            return;
        }
        this.appConfig.temporaryData = {'internal': true};
        if (item !== undefined) {
            this.appConfig._temporaryData = {
                internal: true,
                data: this.request
            };
            this.router.navigate(['item', item.id], {
                relativeTo: this.route
            });
        }

    }

    add(): void {
        if (!this.userAccess.canInsert) {
            return;
        }
        this.router.navigate(['add'], {
            relativeTo: this.route
        });
    }

    delete(item?: GamesModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canDelete || item === undefined) {
            return;
        }
        this.translateService.get('COMMON.DELETEMESSAGE').subscribe(translate => {
            translate += item.name + '?';
            let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {

                    this.dataSource.isLoadingResults = true;
                    this.apisService.deleteBonus(item.id).subscribe(result => {
                        this.dataSource.isLoadingResults = false;
                        this.notifyService.handleServerResponse(result, res => {
                            this.model.reset();
                        });
                    });
                }
            });
        });
    }
}


export class BonusHttpDao extends HttpDao<GetBonusRequest, GetItemsBaseResponse<BonusModel>> {
    constructor(public apisService: ApisService) {
        super(apisService);
    }

    public getData(request: GetBonusRequest): Observable<GetItemsBaseResponse<BonusModel>> {
        return this.apisService.getBonus(request)
            .pipe(map(res => {
                if (res.isError) {
                    return <GetItemsBaseResponse<BonusModel>> {
                        total: 0,
                        items: []
                    };
                } else {
                    if (request.includeAccess && res.data.userAccess) {
                        this.firstLoad.next(res.data.userAccess);
                    }
                    return res.data;
                }
            }));
    }
}
