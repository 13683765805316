import { Injectable, Inject } from '@angular/core';
import { ORIGIN_URL } from './constants/baseurl.constants';

@Injectable()
export class UrlHelper {
    constructor(
        @Inject(ORIGIN_URL) private baseUrl: string) {
    }

    public getUrl(operation: string): string {
        return this.baseUrl + '/api' + operation;
    }

    public isRedirectToLogin(url: string): string {
        const loginSegment = '/Account/Login';
        const loginUrl = this.baseUrl + loginSegment;
        return url.indexOf(loginUrl) >= 0 ? loginUrl : undefined;
    }

    public getLoginUrl(): string {
        const loginSegment = '/Account/Login';
        return `${this.baseUrl}${loginSegment}`;
    }

    public isAccessDenied(url: string): string {
        const segment = '/Account/AccessDenied';
        const segmenturl = this.baseUrl + segment;
        return url.indexOf(segmenturl) >= 0 ? '/unauthorized' : undefined;
    }
}
