import { DataSource } from '@angular/cdk/collections';
import { Observable, merge, Subscription, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { HttpDao } from './HttpDao';
import { DataBaseRequest } from './DataBaseRequest';
import { ModelRequest } from './ModelRequest';
import { EventEmitter } from '@angular/core';
import { startWith, map, switchMap, catchError } from 'rxjs/operators';

export class TableDataSource<R extends DataBaseRequest, T extends any> extends DataSource<T> {
  modelSubscription: Subscription;
  resultsLength = 0;
  isLoadingResults = false;
  isEmpty = false;
  isError = false;
  pageSize = 20;
  loaded: EventEmitter<T[]> = new EventEmitter();

  constructor(private database: HttpDao<R, T>, private paginator: MatPaginator, private sort: MatSort, private request: ModelRequest<R>) {
    super();
    this.modelSubscription = this.request.resetChaged.subscribe(res => {
      console.log('TableDataSource -> modelSubscription');
      this.paginator.pageIndex = 0;
      if (this.sort !== undefined) {
        this.sort.direction = 'asc';
        this.sort.active = this.request.request.column;
      }
    });
  }

  connect(): Observable<T[]> {

    console.log('TableDataSource -> connect');
    const displayDataChanges = this.sort !== undefined
      ? [this.paginator.page, this.sort.sortChange, this.request.changed]
      : [this.paginator.page, this.request.changed];
    if (this.sort !== undefined) {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    }

    return merge(...displayDataChanges)
      .pipe(startWith(undefined),
        switchMap(() => {
          if (this.sort !== undefined) {
            this.request.request.column = this.sort.active;
            this.request.request.order = this.sort.direction==='asc' ? 0 : 1;
          } else {
            this.request.request.column = '';
            this.request.request.order = 0;
          }
          this.request.request.page = this.paginator.pageIndex;
          const loaderTimer = setTimeout(() => {
            this.isLoadingResults = true;
            clearTimeout(loaderTimer);
          });
          return this.database.getData(this.request.request);
        }),
        map(data => {
          const loaderTimer = setTimeout(() => {
            this.isLoadingResults = false;
            clearTimeout(loaderTimer);
          }, 500);
          this.resultsLength = data['total'];
          this.isError = false;
          this.isEmpty = data['items'].length === 0;
          this.loaded.emit(data['items']);
          return data['items'];
        },
          catchError(() => {
            this.isEmpty = false;
            this.isError = true;
            const loaderTimer = setTimeout(() => {
              this.isLoadingResults = false;
              clearTimeout(loaderTimer);
            }, 500);
            return of([]);
          })
        ));
  }

  disconnect() {
    if (this.modelSubscription) {
      this.modelSubscription.unsubscribe();
    }
  }

}
