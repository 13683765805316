<div class="dialog-container">
    <div class="page-layout carded fullwidth ps dialog" fuseperfectscrollbar>
        <div class="dialog-modal">
            <div id="header">
                <div class="header p-sm-24">
                    <div class="header-content" fxLayoutAlign="space-between">
                        <div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
                            <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon class="logo-icon" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">content_paste</mat-icon>
                                <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{
                                    (isNew ? 'CONTENT.NEW_TITLE' : 'CONTENT.EDIT_TITLE') | translate}}</span>
                                <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                                    <div class="options-icon isVisible">
                                        <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }"
                                            [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="pageForm.touched && pageForm.invalid"
                                            (click)="save()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.SAVE' | translate}}">
                                            <mat-icon>save</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div name="pageForm" [formGroup]="pageForm">
                <div class="content-container">
                    <div class="page-layout simple no-scroll">
                        <div fxLayout="row" fxLayoutAlign="start stretch" fxFlex="0 0 auto">
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'CONTENT.NAME' | translate}}" formControlName="name"
                                    (change)="onNameChanged()" />
                                <mat-error *ngIf="formErrors.name.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                                <mat-error *ngIf="formErrors.name.maxLength">
                                    {{'COMMON.REQUIRED_MAXLEN' | translate}}
                                </mat-error>
                                <mat-error *ngIf="formErrors.name.invalidLink">
                                    {{'COMMON.INVALID_LINK' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'CONTENT.TITLE' | translate}}" formControlName="title" />
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start stretch" fxFlex="0 0 auto">
                            <mat-form-field fxFlex="100">
                                <input matInput placeholder="{{'CONTENT.URL' | translate}}" [(ngModel)]="url"
                                    formControlName="url" />
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start stretch" fxFlex="0 0 auto">
                            <mat-form-field fxFlex="50" class="content-selection-input">
                                <input hidden formControlName="widgetId">
                                <input disabled matInput placeholder="{{'PAGE.CELL_WIDGET' | translate}}" [(ngModel)]="widgetName"
                                    [ngModelOptions]="{standalone: true}">
                                <button mat-button class="mat-icon-button" (click)="selectWidget()" *fuseIfOnDom
                                    aria-label="Select widget" matTooltip="{{'WIDGET.SELECT' | translate}}" fxFlex="0 0 10%">
                                    <mat-icon>settings_applications</mat-icon>
                                </button>
                                <button mat-button class="mat-icon-button" (click)="editAnonymousWidget()" *fuseIfOnDom
                                    aria-label="Select widget" matTooltip="{{'COMMON.EDIT' | translate}}" fxFlex="0 0 10%"
                                    [ngClass]="{'disabled': widgetId === undefined || widgetId === null}">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </mat-form-field>

                            <mat-form-field fxFlex="30">
                                <mat-select matInput placeholder="{{'CONTENT.SKIN' | translate}}" [(ngModel)]="skinId"
                                    formControlName="skinId">
                                    <mat-option *ngFor="let skin of skins" [value]="skin.id">
                                        {{ skin.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formErrors.skinId.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>