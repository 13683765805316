<div class="outcome dialog-container">
    <div class="page-layout carded fullwidth ps dialog">
        <div class="dialog-modal">
            <div class="header" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button class="mat-icon-button cmd-buttons" (click)="close()">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{'BONUS.SPORTSELECTOR'
                            | translate}}</span>
                        <button mat-button class="mat-icon-button cmd-buttons" (click)="save()">
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="content-container">
                <div fxLayout="column">
                    <mat-table [dataSource]="currentPage" class="table-items mat-table" [@animateStagger]="{value:'50'}"
                        fuseperfectscrollbar fxFlex="0 1 100%">
                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef>
                                <span>{{'MEDIA.ACTION' | translate}}</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <mat-checkbox disabled [(ngModel)]="row.selected"></mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="value">
                            <mat-header-cell *matHeaderCellDef>{{'BONUS.SPORTSELECTOR_NAMECOLUMN' | translate}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">{{row.value}}</mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></mat-row>
                    </mat-table>
                    <mat-paginator [length]="sports.length" [pageSize]="20" (page)="pageChange($event)" style="align-self: flex-end;">
                    </mat-paginator>
                </div>
            </div>
            <div class="media-loading-shade" *ngIf="isBusy">
                <mat-spinner [color]="'accent'"></mat-spinner>
            </div>
        </div>
    </div>
</div>