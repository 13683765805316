import { Component, OnInit, Input } from '@angular/core';
import { LayoutColumn, LayoutRow, LayoutCell } from './layout.component';

@Component({
  selector: 'app-layoutcolumn',
  templateUrl: './layoutcolumn.component.html',
  styleUrls: ['./layoutcolumn.component.scss']
})

export class LayoutColumnComponent implements OnInit {
  @Input('columns') columns: LayoutColumn[];

  private _column: LayoutColumn;

  constructor() {

  }

  ngOnInit() {

  }


  get width() {
    return this._column.width;
  }

  @Input('width')
  set width(value: string) {
    if (this._column) {
      this._column.width = value;
    }
  }

  get column(): LayoutColumn {
    return this._column;
  }

  @Input('column')
  set column(value: LayoutColumn) {
    this._column = value;
  }

  addColumn() {
    let colum = new LayoutColumn();
    colum.width = '100';
    colum.rows = [];
    let row = new LayoutRow();
    row.width = '100';
    row.cells = [];
    let cell = new LayoutCell();
    cell.id = 'cell' + Math.round(Math.random() * 1000000).toString();
    cell.width = '100';
    row.cells.push(cell);
    colum.rows.push(row);
    colum.isEdit = true;
    row.isEdit = true;
    cell.isEdit = true;
    this.columns.push(colum);
  }

  reset() {
    this._column.rows.splice(0);
  }

  delete() {
    this.columns.splice(this.columns.findIndex(x => x === this._column), 1);
    if (this.columns.length === 0) {
      this.addColumn();
    }
  }

}
