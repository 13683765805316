<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button close" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                *fuseIfOnDom aria-label="Close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                            {{ (isNew ? 'BETTINGSPORTS.NEW_TITLE' : 'BETTINGSPORTS.EDIT_TITLE') | translate}}
                        </span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" [disabled]="pageForm.invalid"
                                (click)="save()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.SAVE' | translate}}">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <div class="options-icon isVisible" *ngIf="userAccess.canEdit">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }"
                                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" (click)="addBetGroup()"
                                *fuseIfOnDom aria-label="Add" [disabled]="pageForm.invalid" matTooltip="{{'BETTINGGROUPS.ADDOUTCOME' | translate}}">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div name="pageForm" [formGroup]="pageForm" fxLayout="column">
                <div class="form-editor-host">
                    <mat-form-field fxFlex="100">
                        <mat-select matInput placeholder="{{'BETTINGSPORTS.SPORT' | translate}}" formControlName="sportId">
                            <mat-option *ngFor="let sport of sports" [value]="sport.id">
                                {{ sport.value }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.sportId.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <div fxFlex="50" class="game-image-host">
                        <div>
                            <h3 class="description-subtitle" fxFlex="100">{{'BETTINGSPORTS.DESKTOPIMAGE' | translate}}</h3>
                        </div>

                        <figure class="game-image-figure" (click)="showMediaSelector('desktopImage')">
                            <img *ngIf="pageForm.value.desktopImage" class="game-image" [(src)]="pageForm.value.desktopImage">
                            <img *ngIf="!pageForm.value.desktopImage" class="game-noimage">
                            <figcaption *ngIf="canEdit" [ngClass]="{'': pageForm.value.desktopImage, 'no-content-box': !pageForm.value.desktopImage}">
                                <div class="media-buttons-container">
                                    <button mat-button class="mat-icon-button" *fuseIfOnDom aria-label="Add Media"
                                        matTooltip="{{'COMMON.ADD_MEDIA' | translate}}">
                                        <mat-icon>image</mat-icon>
                                    </button>
                                </div>
                            </figcaption>
                        </figure>
                    </div>


                    <div fxFlex="50" class="game-image-host">
                        <div>
                            <h3 class="description-subtitle" fxFlex="100">{{'BETTINGSPORTS.MOBILEIMAGE' | translate}}</h3>
                        </div>

                        <figure class="game-image-figure" (click)="showMediaSelector('mobileImage')">
                            <img *ngIf="pageForm.value.mobileImage" class="game-image" [(src)]="pageForm.value.mobileImage">
                            <img *ngIf="!pageForm.value.mobileImage" class="game-noimage">
                            <figcaption *ngIf="canEdit" [ngClass]="{'': pageForm.value.mobileImage, 'no-content-box': !pageForm.value.mobileImage}">
                                <div class="media-buttons-container">
                                    <button mat-button class="mat-icon-button" *fuseIfOnDom aria-label="Add Media"
                                        matTooltip="{{'COMMON.ADD_MEDIA' | translate}}">
                                        <mat-icon>image</mat-icon>
                                    </button>
                                </div>
                            </figcaption>
                        </figure>
                    </div>

                    <div class="description-title-host" fxFlex="100">
                        <span class="description-title">{{'BETTINGSPORTS.BETGROUPS_TITLE' | translate}}</span>
                    </div>
                    <div formArrayName="betGroups" fxFlex="100">
                        <div class="form-editor-host">
                            <div *ngFor="let betGrup of betGroups.controls; let i=index;" fxFlex="50">
                                <div [formGroupName]="i" fxLayout="row" fxLayoutAlign="start stretch">
                                    <button mat-button class="mat-icon-button fixed" (click)="deletebetGroup(i)"
                                        aria-label="Delete" matTooltip="{{'COMMON.DELETE' | translate}}" *ngIf="canEdit">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <mat-form-field fxFlex="50">
                                        <input matInput formControlName="description">
                                    </mat-form-field>
                                    <mat-slide-toggle formControlName="enabled">{{'BETTINGSPORTS.BET_GROUPENABLED'
                                        | translate}}</mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>