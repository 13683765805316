import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AppInitializerService {
  private _token: any;
  set token(token: any) {
    this._token = token;
  }

  get token(): any {
    return this._token;
  }
  
  static settings: any;

  constructor(
    // Handler che viene ignorato dall'interceptor
    private handler: HttpBackend,
    private httpBackend: HttpClient
  ) {
    this.httpBackend = new HttpClient(this.handler);
  }

  loadConfigJson(): Promise<any> {
    const jsonFile = "assets/config.json";
    return this.httpBackend
      .get<any>(jsonFile)
      .pipe(
        map((config: any) => {
          AppInitializerService.settings = config;
          environment.apiUrl = config.apiUrl;
          environment.boHomeUrl = config.boHomeUrl;
        })
      )
      .toPromise()
      .then()
      .catch((error: any) => Promise.resolve());
  }
}
