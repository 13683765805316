<div class="notification-wrapper mat-fuse-dark-700-bg" *ngIf="show">
    <div fxLayout="row" fxLayoutAlign="start stretch" fxFlex="1 0 auto" *ngFor="let msg of messages" class="notification">
        <div flex="80" [ngClass]="cssClass(msg)">
            <p class="notify-text col-md-11 row">
                <span>{{msg.message}}</span>
            </p>
        </div>
        <div flex="20">
            <button mat-icon-button matTooltip="{{'COMMON.HIDE' | translate}}" (click)="onRemoveClick(msg)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>