import {LanguageModel} from './LanguageModel';
import {LayoutConfig} from '../layout/layout.component';
import {SelectItem, TreeItem} from './DataModels/TableDataSource/ModelRequest';

export interface BaseEntityModel {
    id: string;
    version: any;
    created?: Date;
    changedAt?: Date;
    changedFormated?: string;
    changedBy: string;
}

export interface PageModel extends BaseEntityModel {
    name: string;
    tags: string;
    meta: string;
    title: string;
    link: string;
    frontend: string;
    layout: string;
    layoutId: string;
    skinId: string;
    isFullScreen?: boolean;
    isLandingPage?: boolean;
    skinName: string;
    layoutData: LayoutItemContent[];
    published?: boolean;
    publishedDate?: Date;
    publishedDiff?: Array<string>;
    canDelete?: boolean;
    isSystem?: boolean;
}

export class LayoutItemContent {
    cellId?: string;
    widgetType?: string;
    frontend?: string;
    widgetId?: string;
    widgetName?: string;
    authWidgetId?: string;
    authWidgetName?: string;
    isDirty?: boolean;
    height?: string;
    padding?: string;
    color?: string;
    changed?: any;
}

export interface FrontendModel extends BaseEntityModel {
    skinId: string;
    name: string;
    relativeUrl: string;
    selected?: boolean;
}

export interface LayoutModel extends BaseEntityModel {
    name: string;
    image: string;
    isDefault: boolean;
    config: LayoutConfig;
    isPublished: boolean;
}

export interface PageContentModel {
    page: PageModel;
    languages: LanguageModel[];
    skins: BaseModel[];
    layouts: LayoutModel[];
    frontened: FrontendModel[];
}

export interface ContentsModel extends BaseEntityModel {
    name: string;
    published?: boolean;
    publishedDate?: Date;
    widgetName?: string;
    widgetId?: string;
    skinId?: string;
    url: string;
}

export interface CmsHtmlModel extends BaseEntityModel {
    skins: BaseModel[];
    skinId: string;
    style?: string;
    script?: string;
}

export enum WidgetType {
    Text,
    Banner,
    Slider,
    Html,
    Dynamic
}

export class BaseModel {
    id: string;
    name: string;
}

export class WidgetModel extends BaseModel {
    type: WidgetType;
    startDate: Date;
    endDate?: Date;
    showAnonymous: boolean;
    showAuthenticated: boolean;
    hideOnApp: boolean;
    published?: boolean;
    frontend: string;
    dynamicComponent: string;
    notes?: string;
    visible?: boolean;
    showShadow?: boolean;
    canDelete?: boolean;
    isFixedWidget: boolean;

    constructor() {
        super();
        this.id = undefined;
        this.name = '';
        this.type = WidgetType.Text;
        this.startDate = new Date();
        this.endDate = undefined;
        this.showAnonymous = false;
        this.showAuthenticated = true;
    }
}

export class GetMediaResponse {
    items: GetMediaResponseItem[];
    total: number;
}

export abstract class BaseGetMediaItem {
    id: string;
    version: any;
    created?: Date;
    changedAt?: Date;
    changedFormated?: string;
    changedBy: string;
}

export class GetExternalMediaResponseItem extends BaseGetMediaItem {
    name: string;
    url: string;
}

export class BannerWidgetModel extends BaseGetMediaItem {
    BackId?: string;
    BackImage?: string;
    FrontId?: string;
    FrontImage?: string;
    BackAnimation?: string;
    FrontAnimation?: string;
    Title: string;
    TitleColor: string;
    TitleBackColor: string;
    Subtitle: string;
    SubtitleColor: string;
    SubtitleBackColor: string;
    TitleAnimation?: boolean;
    Timer?: boolean;
    Timerstart?: boolean;
    Buttons: BannerButtonModel[];
    Text?: string;
    End?: Date;
    Start?: Date;
    Ratio?: string;

    Notes?: string;

}

export class BannerButtonModel {
    type: number;
    text: string;
    link: string;
    contentName?: string;
    contentId?: string;
    pageLink?: string;
    customFunction?: string;
    isPopup?: boolean;
    isAuthenticated: boolean;
    isAnonymous: boolean;
}

export class GetMediaResponseItem extends BaseGetMediaItem {
    name: string;
    description: string;
    extension: string;
    width: number;
    height: number;
    link: string;
    folder: string;
    isDocument: boolean;
    randomLink?: string;
}

export class GetItemsBaseResponse<T> {
    items: T[];
    total: number;
    userAccess?: UserAccess;
}

export interface UserAccess {
    canEdit: boolean;
    canDelete: boolean;
    canInsert: boolean;
}

export class GetWidgetResponse {
    fontends?: FrontendModel[];
    widget: WidgetModel;
    types: string[];
    content: string;
    link: string;
}


export class GetMediaRequest {

    constructor(
        public name: string = '',
        public description: string = '',
        public created: Date = undefined,
        public page: number = 0) {
    }

    reset(): void {
        this.name = undefined;
        this.description = undefined;
        this.created = undefined;
        this.page = 0;
    }
}


export interface PromotionsModel extends BaseEntityModel {
    name: string;
    thumbnailUrl: string;
    isVisible: boolean;
    frontends: string;
    startDate: Date;
    startDateStr: string;
    endDate?: Date;
    endDateStr?: string;
    notes: string;
    skinName: string;
    published: boolean;
    sort: number;
}

export interface SectionModel extends BaseEntityModel {
    name: string;
    title: string;
    link: string;
    pageId: string;
    parentId?: string;
    contentId?: string;
    content?: string;
    isVisible: boolean;
    isPopup: boolean;
    skinId?: string;
    skinName: string;
    pageName: string;
}

export interface PromotionModel extends BaseEntityModel {
    name: string;
    isVisible: boolean;
    startDate: Date;
    endDate: Date;
    desktopMediaId?: string;
    desktopMediaName?: string;
    desktopSmallMediaId?: string;
    desktopSmallMediaName?: string;
    mobileMediaId?: string;
    mobileMediaName?: string;
    mobileSmallMediaId?: string;
    mobileSmallMediaName?: string;
    backColor?: string;
    buttons: string;
    comingSoonButtons: string;
    enableTimer: boolean;
    enableStartTimer: boolean;
    showData: boolean;
    showIncoming: boolean;
    html: string;
    jackpot: number;
    link: string;
    notes: string;
    platform: AdmiralPlatform;
    published?: boolean;
    publishedDate?: Date;
    publishedDiff?: Array<string>;
    showAfterRegister: boolean;
    badgeIcon: string;
    sort: number;
    landscape: boolean;
}

export interface PromotionContentModel {
    promotion: PromotionModel;
    languages: LanguageModel[];
    frontened: FrontendModel[];
    platforms: BaseModel[];
    skins: BaseModel[];
}

export enum AdmiralPlatform {
    Desktop = 1,
    Mobile = 2,
    Android = 4,
    Ios = 8,
    Windows = 16,
    All = 32,
    None = 64
}

export class MetaItem {
    constructor(public key: Array<any>, public value: Array<any>) {
    }
}

export interface SectionEditorModel {
    // frontends: FrontendModel[];
    // skins: BaseModel[];
    items: SectionItemModel[];
}

export interface SectionItemModel {
    id: string;
    value: string;
    selected: boolean;
    parentId: string;
    expanded: boolean;
    children: SectionItemModel[];
}


export interface GetContentsResponse extends GetItemsBaseResponse<ContentsModel> {
    skins: BaseModel[];
}


export interface MenuModel extends BaseEntityModel {
    parentId: string;
    name: string;
    link: string;
    sort: number;
    isRight: boolean;
    authenticated: boolean;
    icon: string;
    enabled: boolean;
    skinId: string;
}


export interface SkinDataResponse {
    skins: Array<SelectItem>;
    frontends: Array<SelectItem>;
}


export interface MenuContentModel {
    menu: MenuModel;
    skins: BaseModel[];
    icons: SelectItem[];
}

export interface SkinSettingsModel extends BaseEntityModel {
    name: string;
    googleSearchConsole: string;
    googleTagManager: string;
    googleTagManagerHead: string;
    googleTagManagerBody: string;
    googleSearchConsoleMobile: string;
    googleTagManagerMobile: string;
    isMaintenance: boolean;
    isMobileMaintenance: boolean;
}

export interface MobileContentModel extends BaseEntityModel {
    name: string;
    skin: string;
    page: string;
    pageId: string;
    skinId: string;
    sort: number;
    startDate?: Date;
    endDate?: Date;
    enabled: boolean;
    authenticated: boolean;
}

export interface MobileContentEditorModel {
    item: MobileContentModel;
    skins: SelectItem[];
    pages: SelectItem[];
}

export interface GamesModel {
    id: number;
    name: string;
    fornitore: string;
    isEnabled?: boolean;
    isVisible?: boolean;
    isJackpot?: boolean;
    isMobile?: boolean;
    isDesktop?: boolean;
    isDemo?: boolean;
    imageMini?: string;
    order?: number;
    systemDate: string;
    adm?: string;
}

export interface GbetBaseModel {
    id: number;
}

export interface GbetSelectItem extends GbetBaseModel {
    value: string;
}

export interface GbetSelectItemWithParent extends GbetBaseModel {
    value: string;
    parentId: string;
}

export interface GameModel extends GbetBaseModel {
    gameId: string;
    idFornitore: number;
    name: string;
    fullName: string;
    nomeUnivoco: string;
    title: string;
    nameSEO: string;
    aamsCode: string;
    aamsTipoGioco: string;
    isEnabled: boolean;
    isVisible: boolean;
    isJackpot: boolean | null;
    isMobile: boolean | null;
    isDesktop: boolean | null;
    isDemo: boolean;
    order: number | null;
    fpp: number | null;
    coefficiente: number | null;
    isMultybet: boolean;
    height: number | null;
    width: number | null;
    imageBackground: string;
    imageMini: string;
    imageSplashMobile: string;
    imageSplashDesktop: string;
    imageMiniPreview: string;
    imageIconMobile: string;
    help: string;
    shortDescription: string;
    longDescription: string;
    isNew: boolean;
    rpt: number;
    netentGameType: number | null;
    netentGameFamily: number | null;
    capecodeGameType: number | null;
    capecodeIsFun: boolean | null;
    capecodeCashAvailable: boolean | null;
    greenTubeGameType: number | null;
    greenTubeIsFun: boolean | null;
    greenTubeCashAvailable: boolean | null;
    novomaticGameType: number | null;
    tagDescription: string | null;
    tagKeywords: string | null;
    tagTitle: string | null;
    isForeground: boolean;
    goLiveDate?: Date;
}

export interface GameAttributeLinkModel {
    id: number;
    gameId: number;
    attributeId: number;
}

export interface AttributeFamilyModel {
    id: number;
    famiglia: string;
}

export interface GameAttributeModel {
    id: number;
    descrizione: string;
    idFamigliaAttributi: number;
}

export interface GetGameModel {
    netEntId: number;
    capecodeId: number;
    novomaticId: number;
    greenTubeId: number;
    item: GameModel;
    providers: GbetSelectItem[];
    links: GameAttributeLinkModel[];
    attributeFamilies: AttributeFamilyModel[];
    attributes: GameAttributeModel[];
    netentGameTypes: GbetSelectItem[];
    netentGameFamilies: GbetSelectItem[];
    capecodeGameTypes: GbetSelectItem[];
    greenTubeGameTypes: GbetSelectItem[];
    novomaticGameTypes: GbetSelectItem[];
}

export interface GamesLiveModel {
    id: number;
    name: string;
    tableTypeName: string;
    idFornitore: number;
    fornitore: string;
    order?: number;
    systemDate: string;
}

export interface GameLiveModel extends GbetBaseModel {
    idFornitore: number;
    tableName: string;
    tableId: string;
    tableType: string;
    tableVirtual: string;
    tableTypeName: string;
    urlSeo: string;
    tablePiattaforma: string;
    tableOpen: string;
    tableClose: string;
    ordine: number | null;
    isOpen: boolean;
    isEnabled: boolean;
    tipoGioco: string;
    codiceAAMS: string;
    tableImg: string;
    fpp: number;
    rpt: number;
    pageId: string;
    lobby: string;
    displayName: string;
    coefficente: number | null;
    tagDescription: string | null;
    tagKeywords: string | null;
    tagTitle: string | null;
    isForeground: boolean;
    goLiveDate?: Date;
    isita: string;
    groupGame: string;
}

export interface GetGameLiveModel {
    evolutionProviderId: number;
    extremeProviderId: number;
    item: GameLiveModel;
    providers: GbetSelectItem[];
    evolutionGameTypes: GbetSelectItemWithParent[];
    extremeGameTypes: GbetSelectItemWithParent[];
    evolutionTablePiattaforma: GbetSelectItem[];
    extremeTablePiattaforma: GbetSelectItem[];
}


export interface BettingGroupsModel {
    id: number;
    description: string;
    sportId: number;
    sportDescription: string;
    order: number;
    systemDate: string;
}

export interface GetBettingGroupModel {
    item: BetingGroupModel;
    children: OutcomeModel[];
    sports: SelectItem[];
    betOutcomes: TreeItem[];
}

export interface BetingGroupModel extends GbetBaseModel {
    id: number;
    description: string;
    sportId: number;
    sportDescription: string;
    order: number;
    systemDate: string;
}

export class OutcomeModel implements GbetBaseModel {
    id: number;
    betId: number;
    outcomeId: number;
    betDescription: string;
    outcomeDescription: string;
    order: number;
}

export interface SportSettingsModel {
    id: number;
    description: string;
    sportId: string;
    desktopImage: string;
    mobileImage: string;
    systemDate: string;
}

export interface GetBettingSportSettingsModel {
    sports: SelectItem[];
    item: SportSettingsModel;
    betGroups: SportBetGroupModel[];
    allBetGroups: BetGroupModel[];
}

export interface SportBetGroupModel {
    groupId: number;
    description: string;
    enabled: boolean;
    isNew: boolean;
}

export interface BetGroupModel extends GbetBaseModel {
    description: string;
}

export interface NativeAppSetting extends BaseEntityModel {
    name: string;
    link: string;
    releaseVersion: string;
    appType: string;
    isMaintenance: boolean;
    isAppDisabledOutsideOfItaly: boolean;
}

export interface BonusModel {
    id: number;
    starDate: string;
    endDate: string;
    attivo: boolean;
    name: string;
    cumulative: boolean;
    minRefill: number;
    maxBonus: number;
    idBonusType: number;
    description: string;
    idDominio: number;
    gameCode: number;
    fakeMoney: boolean;
    pointCode: number;
    specialBonus: boolean;
    notes: string;
    linkUrlSeo: string;
    pathImage: string;
    sort: number;
    bonusType: string;
    systemDate: string;
    idBonus: number;
    rulesTrance: string;
    detailFakeMoney: string;
    detailBonus: string;
    version: any;
    betting: BettingBonusDetail;
    contoVerificato: boolean;
}

export interface GetBonusModel {
    item: BonusModel;
    bonusType: GbetSelectItem[];
    gameCodes: GbetSelectItem[];
    pointCodes: GbetSelectItem[];
    FirstDepositBonusTypeId: number;
    ReloadDepositBonusTypeId: number;
    RegistrationBonusTypeId: number;
    PromoBonusTypeId: number;
    VerifyAccountBonusTypeId: number;
    BonusFriendsBonusTypeId: number;
    RegistrationHallBonusTypeId: number;
    VerifyAccountPVRBonusTypeId: number;
    ReloadPVRBonusTypeId: number;
    BettingBonusTypeId: number;
}

export class BettingBonusRule {
    minimumEvent: number;
    minimumOddValue: number;
    totalOddValue: number;
    type: BettingBonusRuleType;
}

export enum BettingBonusRuleType {
    All = 0,
    Simple = 1,
    Multiple = 2,
    System = 4
}

export enum NativeAppType {
    Android,
    IOS
}

export interface BettingBonusDetail {
    minimumEvents: number;
    minimumOddValue: number;
    totalOddValue: number;
    type: BettingBonusType;
    validWithBonusAmount: boolean;
    minimumAmount: number;
    winOnly: boolean;
    includedSports: SelectItem[];
    excludedSports: SelectItem[];
    includedTournaments: SelectItem[];
    excludedTournaments: SelectItem[];
    includedEvents: SelectItem[];
    excludedEvents: SelectItem[];

}

export enum BettingBonusType {
    All = 0,
    Simple = 1,
    Multiple = 2,
    SimpleMultiple = 4,
    System = 8
}

export interface PromotionsPointCodeModel {
    id: number;
    promoCode: number;
    startDate: string;
    endDate: string;
    attivo: boolean;
    multipleCode: boolean;
    multipleBonus: boolean;
    idBonusType: number;
    idDominio: number;
    pointCode: number;
    systemDate: string;
    maxNumberInsert: number;
    insertCounter: number;
    pointCodeDescr: string;
}

export interface GetPromoModel {
    item: PromotionsPointCodeModel;
    pointCodes: GbetSelectItem[];
    bonus: GbetSelectItem[];
}

export interface GetMaxOrderGamesModel {
    desktop: number;
    mobile: number;
}
