import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from "@angular/common/http";
import { AppInitializerService } from "../core/services/app-initializer.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public appInitializerService: AppInitializerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.appInitializerService.token)
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.appInitializerService.token}`,
        },
      });

    return next.handle(request);
  }
}
