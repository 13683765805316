import { Input, Injectable, PLATFORM_ID, Inject, EventEmitter } from '@angular/core';
import { DOCUMENT, isPlatformServer, isPlatformBrowser } from '@angular/common';
import { ORIGIN_URL } from './constants/baseurl.constants';
import { DATA } from './constants/request';
import { ITransferData } from '../models/TransferDataModel';
// import { LanguageModel } from '../../models/LanguageModel';

@Injectable()
export class AppConfigService {

  _temporaryData: any;

  isServer = isPlatformServer(this.platformId);
  isBrowser = isPlatformBrowser(this.platformId);
  notifyAutoClose = 2000;
  tempZoneEditor: any;
  onSearchRequest: EventEmitter<string> = new EventEmitter();
  private _isBackNavigation?: boolean;


  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(ORIGIN_URL) public baseOrigin,
    @Inject(DATA) public data: ITransferData
  ) {
  }

  isAuthenticated() {
    return this.data.isAuthenticated;
  }

  setBackNavigation(isBack?: boolean) {
    if (isBack) {
      this._isBackNavigation = true;
    } else {
      this._isBackNavigation = undefined;
    }
  }

  isBackNavigation() {
    const result = this._isBackNavigation;
    this._isBackNavigation = undefined;
    return result;
  }

  get temporaryData(): any {
    const data = this._temporaryData;
    this._temporaryData = undefined;
    return data;
  }

  @Input()
  set temporaryData(value: any) {
    this._temporaryData = value;
  }

  showSearchDialog(input: string) {
    if (input === undefined) { return; }
    this.onSearchRequest.emit(input);
  }

}
