import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { ApisService } from 'src/app/shared/apis.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fuseAnimations } from '../../core/animations';
import { MediaFolderModel } from '../../models/DataModels/TableDataSource/ModelRequest';
import { UserAccess, GetMediaResponseItem } from '../../models/DataModels';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/shared/notify.service';
import { SaveMediaFolderRequest, MoveMediaFolderRequest, MoveMediaItemsRequest } from '../../models/DataModels/Cms/Request';
import { EditMediaFolderComponent } from './editmediafolder.component';

@Component({
  selector: 'app-movemediafolder',
  templateUrl: './movemediafolder.component.html',
  styleUrls: ['./movemediafolder.component.scss'],
  animations: fuseAnimations
})

export class MoveMediaFolderComponent implements OnInit {

  @Output() currentChanged = new EventEmitter<string>();

  model: MediaFolderModel;
  models: string[];
  isLoading = false;
  title: string;
  root = <MediaFolderModel>{
    value: 'Media'
  };
  userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };

  private subscribers = new Array<Subscription>();
  private currentFolder: MediaFolderModel;

  constructor(
    @Inject(NotifyService) private notifyService,
    private apisService: ApisService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public input: any,
    public dialogRef: MatDialogRef<MoveMediaFolderComponent>,
    private translateService: TranslateService
  ) {
    this.model = input.model;
    this.models = input.models;
    let translation = undefined;
    let isMovingFolder = false;
    if (this.model) {
      isMovingFolder = true;
      translation = 'MEDIAFOLDERS.ACTIONS_MOVE_FOLDER';
    } else {
      translation = 'MEDIAFOLDERS.ACTIONS_MOVE_ITEMS';
    }
    this.translateService.get(translation)
      .subscribe(translate => {
        this.title = translate;
        if (isMovingFolder) return;
        this.title = translate.replace(/\*/g, this.models.length.toString());
      });
  }

  ngOnInit() {
    this.toggleItem(this.root);
  }

  selectItem(item: MediaFolderModel): void {
    if (item === undefined) return;
    if (item === this.currentFolder) return;
    if (this.currentFolder !== undefined) {
      this.currentFolder.selected = false;
    }
    this.currentFolder = item;
    if (this.currentFolder !== undefined) {
      this.currentChanged.emit(this.currentFolder.id);
      this.currentFolder.selected = true;
    } else {
      this.currentChanged.emit(undefined);
    }
  }

  toggleItem(item: MediaFolderModel): void {
    if (item.expanded) {
      item.expanded = false;
      item.children = new Array<MediaFolderModel>();
    } else {
      item.expanded = true;
      this.loadFolder(item);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  move() {
    let moveItems = false;
    let request = {};
    if (this.model) {
      request = <MoveMediaFolderRequest>{
        sourceFolderId: this.model.id,
        destinationFolderId: this.currentFolder.id ? this.currentFolder.id : undefined
      };
    } else {
      moveItems = true;
      request = <MoveMediaItemsRequest>{
        ids: this.models,
        destinationFolderId: this.currentFolder.id ? this.currentFolder.id : undefined
      };
    }
    this.dialogRef.close({
      request: request,
      moveItems: moveItems
    });
  }

  edit(item?: MediaFolderModel) {
    let dialogRef = this.dialog.open(EditMediaFolderComponent, {
      width: '40%',
      data: {
        model: item
      },
      height: '40%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.item) {
          let request = <SaveMediaFolderRequest>{
            id: result.item.id,
            name: result.item.value,
            parentFolder: this.currentFolder ? this.currentFolder.id : undefined
          };
          let loader = this.apisService.saveMediaFolder(request).subscribe(response => {
            loader.unsubscribe();
            loader = undefined;
            this.isLoading = false;
            this.notifyService.handleServerResponse(response, result => {
              this.root.children.splice(0);
              this.loadFolder(this.root);
            });
          });
        }
      }
    });
  }

  private loadFolder(item?: MediaFolderModel): void {
    if (this.isLoading || item === undefined) return;
    this.isLoading = true;
    let path = undefined;
    if (item.id !== undefined) {
      path = item.id;
    }
    let loader = this.apisService.getMediaFolder(path).subscribe(response => {
      if (response.userAccess) {
        // this.userAccess = response.userAccess;
      }
      loader.unsubscribe();
      loader = undefined;
      this.notifyService.handleServerResponse(response, result => {
        item.children = result;
      });
      this.isLoading = false;
    });
  }
}
