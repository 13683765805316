import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseConfigService } from '../../core/services/config.service';
import { ApisService } from 'src/app/shared/apis.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from 'src/app/shared/notify.service';
import { AppConfigService } from 'src/app/shared/appconfig.service';
import { UserAccess } from '../../models/DataModels';
import { FuseConfirmDialogComponent } from '../../core/components/confirm-dialog/confirm-dialog.component';
import { SaveLayoutRequest } from '../../models/DataModels/Cms/Request';
import { fuseAnimations } from '../../core/animations';
import { LayoutConfig, LayoutColumn, LayoutCell, LayoutRow } from '../../layout/layout.component';
import { BaseEditorComponent } from '../../components/baseeditor.component';

@Component({
  selector: 'app-layouteditor',
  templateUrl: './layouteditor.component.html',
  styleUrls: ['./layouteditor.component.scss'],
  animations: fuseAnimations
})

export class LayoutEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  id: string;
  formErrors: any;
  isNew: boolean = false;
  isPublished: boolean = false;
  layoutConfig: LayoutConfig;
  subscribers: Subscription[];
  isLoading = false;
  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };

  constructor(
    protected translateService: TranslateService,
    @Inject(NotifyService) protected notifyService,
    protected dialog: MatDialog,
    @Inject(AppConfigService) private appConfig,
    private location: Location,
    private apisService: ApisService,
    private fuseConfig: FuseConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    super();
    this.subscribers = new Array<Subscription>();
    this.formErrors = {
      name: {}
    };
  }

  ngOnInit() {
    this.createForm();

    this.subscribers.push(this.route.params.subscribe(params => {
      this.id = params.id;
      this.isNew = this.id === undefined;
      this.loadPage(this.id);
    }));
  }

  ngOnDestroy() {
    this.subscribers.forEach(t => t.unsubscribe());
  }

  loadPage(id: string): void {
    this.isLoading = true;
    this.subscribers.push(this.apisService.getLayout(id).subscribe(response => {
      if (response.isError) {
        this.isLoading = false;
      }
      this.notifyService.handleServerResponse(response, result => {
        // this.userAccess = response.userAccess;
        this.pageForm.patchValue(result);
        this.isPublished = result.isPublished;
        this.layoutConfig = result.config as LayoutConfig;
        this.updateLayout();
        this.isLoading = false;
      });
    }));
  }

  onFormValuesChanged(data) {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  save(copy?: boolean) {
    if (!this.userAccess.canEdit) return;
    if (copy) {
      this.internalSave(copy);
    } else {
      if (this.isPublished) {
        this.translateService.get('LAYOUT.CONFIRMSAVE').subscribe(translate => {
          let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            width: '50%'
          });
          dialogRef.componentInstance.confirmMessage = translate;
          dialogRef.afterClosed().subscribe(confirm => {
            if (confirm === true) {
              this.internalSave(copy);
            }
          });
        });
      } else {
        this.internalSave(copy);
      }
    }
  }

  reset() {
    this.layoutConfig = undefined;
    this.updateLayout();
  }

  copy() {
    this.save(true);
  }

  onLayoutChanged() {
    this.pageForm.markAsDirty({ onlySelf: true });
    this.updateLayout();
  }

  protected internalClose() {
    let data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(['cms/layouts']);
    }
  }

  private internalSave(copy?: boolean) {
    this.pageForm.markAsDirty();
    if (this.pageForm.dirty) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new SaveLayoutRequest();
        Object.assign(request, this.pageForm.value);
        request.id = this.id;
        request.copy = copy;
        request.config = this.getLayoutConfig(this.layoutConfig);
        this.subscribers.push(this.apisService.saveLayout(request).subscribe(response => {
          this.isLoading = false;
          this.notifyService.handleServerResponse(response, result => {
            this.pageForm.reset();
            if (this.id === result) {
              this.loadPage(this.id);
            } else {
              this.router.navigate(['/cms/layouts/layout/', result]);
            }
          });
        }));
      } else {
        this.notifyService.warning('COMMON.INVALIDDATA');
      }
    }
  }

  private updateLayout() {
    if (this.layoutConfig === undefined) {
      this.layoutConfig = new LayoutConfig();
    }
    if (this.layoutConfig.columns === undefined) {
      this.layoutConfig.columns = new Array<LayoutColumn>();
    }
    if (this.layoutConfig.columns.length === 0) {
      let column = new LayoutColumn();
      column.width = '100';
      column.rows = [];
      let row = new LayoutRow();
      row.width = '100';
      row.cells = [];
      let cell = new LayoutCell();
      cell.id = 'cell' + Math.round(Math.random() * 1000000).toString();
      cell.width = '100';
      row.cells.push(cell);
      column.rows.push(row);
      this.layoutConfig.columns.push(column);
    }
    try {
      this.layoutConfig.columns.forEach(element => {
        this.updateLayoutColumn(element);
      });
    } catch (error) {
      this.reset();
    }

  }

  private updateLayoutColumn(element: LayoutColumn) {
    element.isEdit = true;
    element.content = {
      context: {
        id: element.id,
        width: element.width
      },
      type: 'layouteditor'
    };
    element.rows.forEach(x => this.updateLayoutRow(x));
  }

  private updateLayoutRow(element: LayoutRow) {
    element.isEdit = true;
    element.content = {
      context: {
        id: element.id,
        width: element.width
      },
      type: 'layouteditor'
    };
    element.cells.forEach(x => this.updateLayoutCell(x));
  }

  private updateLayoutCell(element: LayoutCell) {
    element.isEdit = true;
    if (!element.id) {
      element.id = 'cell' + Math.round(Math.random() * 1000000).toString();
    }
    element.content = {
      context: {
        id: element.id,
        width: element.width,
        padding: element.padding
      },
      type: 'layouteditor'
    };
    if (element.isContainer) {
      if (element.columns) {
        element.columns.forEach(x => this.updateLayoutColumn(x));
      }
    } else {
      let context = {
        id: element.id,
        width: element.width
      };
      element.content = {
        context: context,
        type: 'layouteditor'
      };
    }
  }

  private getLayoutConfig(config: LayoutConfig): LayoutConfig {
    let result = new LayoutConfig();
    result.columns = [];
    config.columns.forEach(x => {
      let col = this.getLayoutColumn(x);
      result.columns.push(col);
    });
    return result;
  }

  private getLayoutColumn(element: LayoutColumn): LayoutColumn {
    let col = new LayoutColumn();
    col.id = element.id;
    col.width = element.width;
    if (element.rows) {
      col.rows = [];
      element.rows.forEach(y => {
        let row = this.getLayoutRow(y);
        col.rows.push(row);
      });
    }
    return col;
  }

  private getLayoutRow(element: LayoutRow): LayoutRow {
    let row = new LayoutRow();
    row.id = element.id;
    row.width = element.width;
    if (element.cells) {
      row.cells = [];
      element.cells.forEach(x => {
        let cel = this.getLayoutCell(x);
        row.cells.push(cel);
      });
    }
    return row;
  }

  private getLayoutCell(element: LayoutCell): LayoutCell {
    let cel = new LayoutCell();
    cel.id = element.id;
    if (!cel.id) {
      cel.id = 'cell' + Math.round(Math.random() * 1000000).toString();
    }
    cel.width = element.width;
    cel.padding = element.padding;
    cel.isContainer = element.isContainer;
    cel.isLocked = element.isLocked;
    if (cel.isContainer) {
      cel.columns = [];
      if (element.columns) {
        element.columns.forEach(x => {
          let col = this.getLayoutColumn(x);
          cel.columns.push(col);
        });
      }
    }
    return cel;
  }

  private createForm() {
    this.pageForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255)]]
    });

    this.subscribers.push(this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    }));
  }
}
