import { Component, OnInit } from "@angular/core";
import { DynamicComponent } from "../../layout/layoutcontent.component";

@Component({
  selector: "app-lockedwidget",
  templateUrl: "./lockedwidget.component.html",
  styleUrls: ["./lockedwidget.component.scss"]
})

export class LockedWidgetComponent extends DynamicComponent implements OnInit {
  public isDirty() {
    return false;
  }

  constructor() {
    super();
  }

  ngOnInit() {

  }
}
