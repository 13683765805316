import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GetGamesLiveRequest} from '../../models/DataModels/Cms/Request';
import {Observable, Subscription} from 'rxjs';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {ApisService} from 'src/app/shared/apis.service';
import {NotifyService} from 'src/app/shared/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {GamesLiveModel, GetItemsBaseResponse, UserAccess} from '../../models/DataModels';
import {DataBaseRequestField, ModelRequest, SelectItem} from '../../models/DataModels/TableDataSource/ModelRequest';
import {HttpDao} from '../../models/DataModels/TableDataSource/HttpDao';
import {FuseConfirmDialogComponent} from '../../core/components/confirm-dialog/confirm-dialog.component';
import {fuseAnimations} from '../../core/animations';
import {map} from 'rxjs/operators';

@Component({
    selector: 'gameslive',
    templateUrl: './gameslive.component.html',
    styleUrls: ['./gameslive.component.scss'],
    animations: fuseAnimations
})
export class GamesLiveComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    panelTitle = 'GAMESLIVE.TITLE';
    dataSource: TableDataSource<GetGamesLiveRequest, GetItemsBaseResponse<GamesLiveModel>>;
    displayedColumns = ['action', 'name', 'tableTypeName', 'fornitore', 'order', 'systemDate'];
    model: ModelRequest<GetGamesLiveRequest>;

    protected request: GetGamesLiveRequest = new GetGamesLiveRequest();
    private providers = new DataBaseRequestField('provider', 'GAMESLIVE.SEARCH_PROVIDER_LABEL', 'select', false, new Array<SelectItem>());

    private httpDao: GamesLiveHttpDao;
    private firstLoad: Subscription;

    constructor(
        @Inject(AppConfigService) private appConfig: AppConfigService,
        @Inject(NotifyService) private notifyService,
        private translateService: TranslateService,
        public dialog: MatDialog,
        private apisService: ApisService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.request.includeAccess = true;
        this.providers.selectItems = new Array<SelectItem>();
    }

    ngOnInit() {
        this.httpDao = new GamesLiveHttpDao(this.apisService);
        this.firstLoad = this.httpDao.firstLoad.subscribe((userAccess: UserAccess) => {
            this.request.includeAccess = false;
            // this.userAccess = userAccess;
            this.model.updateShowAdd(this.userAccess.canInsert);
        });
        this.model = new ModelRequest<GetGamesLiveRequest>(this.request);
        this.model.showAddItem = true;

        let tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string'),
            this.providers);
        this.model.items = tmpItems;
        this.dataSource = new TableDataSource<GetGamesLiveRequest, GetItemsBaseResponse<GamesLiveModel>>(this.httpDao!, this.paginator, this.sort, this.model);
        const providersLoader = this.apisService.getGameProviders(true).subscribe(response => {
            if (!response.isError) {
                response.data.forEach(x => this.providers.selectItems.push(x));
                if (this.providers.selectItems.length > 0) {
                    this.model.request.provider = this.providers.selectItems[0].id;
                }
            }
            providersLoader.unsubscribe();
        });
    }

    ngOnDestroy() {
        this.dataSource.disconnect();
        if (this.firstLoad) {
            this.firstLoad.unsubscribe();
            this.firstLoad = undefined;
        }
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    edit(item?: GamesLiveModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canEdit) return;
        this.appConfig.temporaryData = {'internal': true};
        if (item !== undefined) {
            this.appConfig._temporaryData = {
                internal: true,
                data: this.request
            };
            this.router.navigate(['gioco', item.id, item.idFornitore], {
                relativeTo: this.route
            });
        }
    }

    add(): void {
        if (!this.userAccess.canInsert) return;
        this.router.navigate(['add'], {
            relativeTo: this.route
        });
    }

    delete(item?: GamesLiveModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canDelete || item === undefined) return;
        this.translateService.get('COMMON.DELETEMESSAGE').subscribe(translate => {
            translate += item.name + '?';
            let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {
                    this.dataSource.isLoadingResults = true;
                    this.apisService.deleteGameLive(item.id, item.idFornitore).subscribe(result => {
                        this.dataSource.isLoadingResults = false;
                        this.notifyService.handleServerResponse(result, res => {
                            this.model.reset();
                        });
                    });
                }
            });
        });
    }
}


export class GamesLiveHttpDao extends HttpDao<GetGamesLiveRequest, GetItemsBaseResponse<GamesLiveModel>> {
    constructor(public apisService: ApisService) {
        super(apisService);
    }

    public getData(request: GetGamesLiveRequest): Observable<GetItemsBaseResponse<GamesLiveModel>> {
        return this.apisService.getGamesLive(request)
            .pipe(map(res => {
                if (res.isError) {
                    return <GetItemsBaseResponse<GamesLiveModel>>{
                        total: 0,
                        items: []
                    };
                } else {
                    if (request.includeAccess && res.data.userAccess) {
                        this.firstLoad.next(res.data.userAccess);
                    }
                    return res.data;
                }
            }));
    }
}
