import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ApisService} from 'src/app/shared/apis.service';
import {GetWidgetRequest} from '../../models/DataModels/Cms/Request';
import {GetItemsBaseResponse, WidgetModel} from '../../models/DataModels';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {DataBaseRequestField, ModelRequest} from '../../models/DataModels/TableDataSource/ModelRequest';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from 'src/app/shared/notify.service';
import {WidgetHttpDao} from '../widgets/widgets.component';

@Component({
    selector: 'app-widgets',
    templateUrl: './widgetselectordialog.component.html',
    styleUrls: ['./widgetselectordialog.component.scss']
})

export class WidgetSelectorDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    protected request = new GetWidgetRequest();
    public dataSource: TableDataSource<GetWidgetRequest, GetItemsBaseResponse<WidgetModel>>;
    public displayedColumns = ['name', 'type', 'notes'];
    public panelTitle = 'WIDGETSELECTOR.TITLE';
    public model: ModelRequest<GetWidgetRequest>;

    private httpDao: WidgetHttpDao;

    constructor(
        @Inject(NotifyService) private notifyService,
        @Inject(MAT_DIALOG_DATA) public input: any,
        public dialogRef: MatDialogRef<WidgetSelectorDialogComponent>,
        private apisService: ApisService,
        private cd : ChangeDetectorRef
    ) {

    }

    ngOnInit() {
        this.request.frontend = this.input.frontend;
        this.request.type = this.input.type;
        this.request.exclude = this.input.exclude;
        this.request.excludeTypes = this.input.excludeTypes;
        this.request.isSelector = true;
        this.httpDao = new WidgetHttpDao(this.apisService);
        this.model = new ModelRequest<GetWidgetRequest>(this.request);
        this.model.columns = 2;
        this.model.showAddItem = true;

        let tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(
            new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string'),
            new DataBaseRequestField('startDate', 'COMMON.SEARCH_START_LABEL', 'date'),
            new DataBaseRequestField('endDate', 'COMMON.SEARCH_END_LABEL', 'date'),
            new DataBaseRequestField('showAnonymous', 'COMMON.SEARCH_ANONYMOUS_LABEL', 'boolean'),
            new DataBaseRequestField('showAuthenticated', 'COMMON.SEARCH_AUTHENTICATED_LABEL', 'boolean')
        );
        this.model.items = tmpItems;
        this.model.changed.emit();
    }

    ngAfterViewInit(){

        this.dataSource = new TableDataSource<GetWidgetRequest, GetItemsBaseResponse<WidgetModel>>(this.httpDao!, this.paginator, this.sort, this.model);
        this.paginator.pageIndex = 0;

        this.cd.detectChanges();
    }

    ngOnDestroy() {
        this.dataSource.disconnect();
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    select(item: WidgetModel) {
        this.dialogRef.close({
            result: true,
            widgetId: item.id,
            widgetName: item.name
        });
    }
}
