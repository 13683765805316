<div class="page-layout carded fullwidth ps">
  <generic-search-filter [requestModel]="model" [panelTitle]="panelTitle" [panelIcon]="'insert_drive_file'" (onSubmit)="onSubmit()"
    (onAddItem)="add()"></generic-search-filter>

  <div class="custom-center" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
    <div class="content-card" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
      <div *ngIf="dataSource?.isEmpty || dataSource?.isError">
        <div class="table-error-message" *ngIf="dataSource?.isEmpty">
          <span>{{'COMMON.NO_ITEMS' | translate}}</span>
        </div>
        <div class="table-error-message" *ngIf="dataSource?.isError">
          <span>{{'COMMON.ERROR_LOADING' | translate}}</span>
        </div>
      </div>
      <mat-table [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [dataSource]="dataSource" class="table-items mat-table"
        [@animateStagger]="{value:'50'}" matSort matSortDisableClear matSortActive="systemDate" matSortDirection="desc" fuseperfectscrollbar>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>
            <span>{{'MEDIA.ACTION' | translate}}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div fxLayoutAlign="start">
              <button mat-icon-button *ngIf="userAccess.canEdit" (click)="edit(row, $event)" name="editButton" matTooltip="{{'PAGES.EDIT' | translate}}">
                <mat-icon class="edit">edit</mat-icon>
              </button>
              <button mat-icon-button *ngIf="userAccess.canDelete" (click)="delete(row, $event)" name="deleteButton" matTooltip="{{'COMMON.DELETE' | translate}}">
                <mat-icon class="delete-icon">delete</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        
        <ng-container matColumnDef="imageMini">
          <mat-header-cell *matHeaderCellDef>{{'GAMES.THUMNBNAIL_COLUMN' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <img class="table-image-column" [src]="row.imageMini" *ngIf="row.imageMini != null">
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'GAMES.NAME_COLUMN' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="fornitore">
          <mat-header-cell *matHeaderCellDef>{{'GAMES.PROVIDER_COLUMN' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.fornitore}}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="isEnabled">
          <mat-header-cell *matHeaderCellDef>{{'GAMES.ISENABLED_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon [ngClass]="{'check': row.isEnabled, 'clear': !row.isEnabled}">
              {{row.isEnabled ? "check": "clear"}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isVisible">
          <mat-header-cell *matHeaderCellDef>{{'GAMES.ISVISIBLE_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon [ngClass]="{'check': row.isVisible, 'clear': !row.isVisible}">
              {{row.isVisible ? "check": "clear"}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isJackpot">
          <mat-header-cell *matHeaderCellDef>{{'GAMES.ISJACKPOT_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon [ngClass]="{'check': row.isJackpot, 'clear': !row.isJackpot}">
              {{row.isJackpot ? "check": "clear"}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isMobile">
          <mat-header-cell *matHeaderCellDef>{{'GAMES.ISMOBILE_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon [ngClass]="{'check': row.isMobile, 'clear': !row.isMobile}">
              {{row.isMobile ? "check": "clear"}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isDesktop">
          <mat-header-cell *matHeaderCellDef>{{'GAMES.ISDESKTOP_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon [ngClass]="{'check': row.isDesktop, 'clear': !row.isDesktop}">
              {{row.isDesktop ? "check": "clear"}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isDemo">
          <mat-header-cell *matHeaderCellDef>{{'GAMES.ISDEMO_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon [ngClass]="{'check': row.isDemo, 'clear': !row.isDemo}">
              {{row.isDemo ? "check": "clear"}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="order">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'GAMES.ORDER_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.order}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="systemDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'GAMES.SYSTEMDATE_COLUMN' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.systemDate}}
            </mat-cell>
          </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></mat-row>

      </mat-table>
      <div class="media-loading-shade" *ngIf="dataSource?.isLoadingResults">
        <mat-spinner [color]="'accent'"></mat-spinner>
      </div>
      <mat-paginator [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [length]="dataSource?.resultsLength"
        [pageSize]="dataSource?.pageSize">
      </mat-paginator>
    </div>
  </div>

</div>
