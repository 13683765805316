import { SelectItem } from "../TableDataSource/ModelRequest";
import { BaseModel } from "../../DataModels";

export class ModelSelector {
    selected = false;
}
export class HelpTreeResponse {
    items: HelpTreeItem[];
    skins: BaseModel[];
}

export class HelpTreeItem extends ModelSelector {
    id: number;
    parentId?: number;
    display: string;
    sort?: number;
    skinId?: string;
    items: HelpTreeItem[];

    constructor(items: HelpTreeItem[]) {
        super();
        this.id = undefined;
        this.parentId = undefined;
        this.display = 'Help';
        this.items = items;
    }
}

export class HelpItemResponse {
    result: string;
}
