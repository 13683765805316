import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FuseConfigService } from '../../core/services/config.service';
import { ApisService } from 'src/app/shared/apis.service';
import { NotifyService } from 'src/app/shared/notify.service';
import { Subscription } from 'rxjs';
import { SaveContentRequest, GetWidgetRequest, GetContentsRequest } from '../../models/DataModels/Cms/Request';
import { fuseAnimations } from '../../core/animations';
import { WidgetType, BaseModel, UserAccess } from '../../models/DataModels';
import { IBaseResponse } from '../../models/BaseResponse';
import { FuseConfirmDialogComponent } from '../../core/components/confirm-dialog/confirm-dialog.component';
import { WidgetSelectorDialogComponent } from '../widgetselectordialog/widgetselectordialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contenteditor',
  templateUrl: './contenteditor.component.html',
  styleUrls: ['./contenteditor.component.scss'],
  animations: fuseAnimations
})

export class ContentEditorComponent implements OnInit, OnDestroy {
  pageForm: FormGroup;
  formErrors: any;
  isNew: boolean = false;
  skins: BaseModel[];
  widgetId: string;
  widgetName: string;
  url: string;
  userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };
  skinId: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  protected request = new GetWidgetRequest();
  protected displayedColumns = ['name', 'action'];

  private subscribers: Subscription[];
  private isLoading = false;

  constructor(@Inject(NotifyService) private notifyService,
    @Inject(MAT_DIALOG_DATA) public input: any,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    public dialogRef: MatDialogRef<ContentEditorComponent>,
    private apisService: ApisService,
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.isNew = this.input.id === undefined || this.input.id === null || this.input.id === '';
    this.subscribers = new Array<Subscription>();
    this.formErrors = {
      name: {},
      title: {},
      widgetId: {},
      skinId: {}
    };
    this.createForm();
    this.request.type = WidgetType[WidgetType.Html];
  }

  ngOnInit() {
    this.loadPage(this.input.id);
  }

  ngOnDestroy() {
    this.subscribers.forEach(s => s.unsubscribe());
  }

  selectWidget() {
    let dialogRef = this.dialog.open(WidgetSelectorDialogComponent, {
      width: '80%',
      data: {
        type: 'html'
      },
      height: '80%'
    });

    dialogRef.afterClosed().subscribe(close => {
      if (close && close.result) {
        this.widgetId = close.widgetId;
        this.widgetName = close.widgetName;
        this.pageForm.patchValue({
          widgetId: this.widgetId,
          widgetName: this.widgetName
        });
      }
    });
  }

  editAnonymousWidget() {
    if (this.widgetId !== undefined && this.widgetId !== '') {
      this.close(true);
      this.router.navigate(['/cms/widgets/widget', this.widgetId]);
    }
  }

  save(overwrite?: boolean) {
    if (this.pageForm.valid) {
      this.isLoading = true;
      let request = <SaveContentRequest>{
        id: this.input.id,
        overwrite: overwrite
      };
      Object.assign(request, this.pageForm.value);
      request.widgetId = this.widgetId;
      request.widgetName = this.widgetName;
      this.apisService.saveContent(request).subscribe(response => {
        this.isLoading = false;
        this.handleServerResponse(response, () => {
          this.close(true);
        });
      });
    } else {
      this.notifyService.error('NOTIFICATION.INVALID_DATA');
    }
  }

  onNameChanged() {
    if (this.isNew) {
      let contentName = this.pageForm.value.name;
      if (contentName) {
        this.pageForm.patchValue({ 'url': 'contents/' + contentName });
      } else {
        this.pageForm.patchValue({ 'url': 'contents/' });
      }
    }
  }

  protected handleServerResponse(response: IBaseResponse<any>, success: (result) => void) {
    this.notifyService.handleServerResponse(response, result => {
      success(result);
    }, undefined, () => {
      this.translateService.get('NOTIFICATION.CONFLICT').subscribe(msg => {
        let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          width: '50%'
        });
        dialogRef.componentInstance.confirmMessage = msg;
        dialogRef.afterClosed().subscribe(confirm => {
          if (confirm === true) {
            this.save(true);
          } else {
            this.loadPage(this.input.id);
          }
        });
      }).unsubscribe();
    });
  }

  private loadPage(id: string) {
    this.isLoading = true;
    let request = <GetContentsRequest>{
      id: this.input.id,
      findById: true
    };
    this.apisService.getContents(request).subscribe(response => {
      if (response.isError) {
        this.isLoading = false;
      }
      this.notifyService.handleServerResponse(response, result => {
        // this.userAccess = response.userAccess;
        this.skins = result.skins;
        if (result.items && result.items.length > 0) {
          this.pageForm.patchValue(result.items[0]);
          this.input.id = result.items[0].id;
          this.widgetId = result.items[0].widgetId;
          this.widgetName = result.items[0].widgetName;
          this.url = result.items[0].url;
        }
        this.isLoading = false;
      });
    });
  }

  private close(result?: boolean) {
    this.dialogRef.close(result);
  }

  private createForm() {
    this.pageForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255), contentNameValidator(/^[a-zA-Z0-9_-]*$/)]],
      title: [''],
      widgetId: ['', Validators.required],
      skinId: ['', Validators.required],
      widgetName: ['', Validators.required],
      url: [{ value: '', disabled: true }],
      version: []
    });
    this.pageForm.valueChanges.subscribe((changes) => {
      this.onFormValuesChanged();
    });
  }

  private onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }
}

export function contentNameValidator(pattern: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const valid = pattern.test(control.value);
    return valid ? undefined : { 'invalidLink': { value: control.value } };
  };
}
