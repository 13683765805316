import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseBonusDetailComponent } from './basedetail.component';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SelectItem } from '../../models/DataModels/TableDataSource/ModelRequest';
import { SportSelectorComponent } from './sportselector.component';
import { TournamentSelectorComponent } from './tournamentselector.component';
import { EventSelectorComponent } from './eventselector.component';
import { BettingBonusDetail, BonusModel } from '../../models/DataModels';
import { SaveBonusRequest } from '../../models/DataModels/Cms/Request';

@Component({
    selector: 'bonus-betting',
    templateUrl: './bettingdetail.component.html'
})
export class BettingDetailComponent extends BaseBonusDetailComponent {


    bonusTypes: SelectItem[] = [{
        id: '0',
        value: 'Tutti'
    }, {
        id: '1',
        value: 'Semplice'
    }, {
        id: '2',
        value: 'Multipla'
    }, {
        id: '4',
        value: 'Semplice/Multipla'
    }, {
        id: '8',
        value: 'Sistema'
    }];

    canEdit: boolean = true;

    public detailForm: FormGroup;
    includedSports: FormArray;
    excludedSports: FormArray;
    includedTournaments: FormArray;
    excludedTournaments: FormArray;
    includedEvents: FormArray;
    excludedEvents: FormArray;


    constructor(
        private formBuilder: FormBuilder,
        protected dialog: MatDialog

    ) {
        super();
        this.includedSports = formBuilder.array([]);
        this.excludedSports = formBuilder.array([]);
        this.includedTournaments = formBuilder.array([]);
        this.excludedTournaments = formBuilder.array([]);
        this.includedEvents = formBuilder.array([]);
        this.excludedEvents = formBuilder.array([]);

        this.detailForm = this.formBuilder.group({
            minimumEvents: [0, [Validators.min(0), Validators.required]],
            minimumOddValue: [0, [Validators.min(0), Validators.required]],
            totalOddValue: [0, [Validators.min(0), Validators.required]],
            amount: [0, [Validators.min(0), Validators.required]],
            minimumAmount: [0, [Validators.min(0), Validators.required]],
            type: [0, Validators.required],
            winOnly: [false, Validators.required],
            validWithBonusAmount: [false, Validators.required],
            includedSports: this.includedSports,
            excludedSports: this.excludedSports,
            includedTournaments: this.includedTournaments,
            excludedTournaments: this.excludedTournaments,
            includedEvents: this.includedEvents,
            excludedEvents: this.excludedEvents
        });

        this.detailForm.valueChanges.subscribe(() => {
            this.valueChanges.emit({
                data: this.detailForm.getRawValue(),
                invalid: this.detailForm.invalid
            });
        });
    }

    addSport(exclude?: boolean) {
        if (!this.canEdit) return;
        let excluded = this.includedSports.getRawValue().map(x => x.id);
        excluded.push(...this.excludedSports.getRawValue().map(x => x.id));
        let dialogRef = this.dialog.open(SportSelectorComponent, {
            width: '40%',
            data: {
                excluded: excluded
            },
            height: '70%'
        });

        const dialogSub = dialogRef.afterClosed().subscribe(result => {
            if (result && result.items) {
                result.items.forEach(item => {
                    this.patchArray([{ id: item.id, value: item.value }], exclude ? this.excludedSports : this.includedSports);
                });

            }
            dialogSub.unsubscribe();
        });
    }

    addTournament(exclude?: boolean) {
        if (!this.canEdit) return;
        let includedSports = this.includedSports.getRawValue().map(x => x.id);
        includedSports.push(...this.excludedSports.getRawValue().map(x => x.id));
        let excluded = this.includedTournaments.getRawValue().map(x => x.id);
        excluded.push(...this.excludedTournaments.getRawValue().map(x => x.id));
        let dialogRef = this.dialog.open(TournamentSelectorComponent, {
            width: '40%',
            data: {
                includedSports: includedSports,
                excluded: excluded
            },
            height: '70%'
        });

        const dialogSub = dialogRef.afterClosed().subscribe(result => {
            if (result && result.items) {
                result.items.forEach(item => {
                    this.patchArray([{ id: item.id, value: item.value }], exclude ? this.excludedTournaments : this.includedTournaments);
                });
            }
            dialogSub.unsubscribe();
        });
    }

    addEvent(exclude?: boolean) {
        if (!this.canEdit) return;
        let included = this.includedTournaments.getRawValue().map(x => x.id);
        included.push(...this.excludedTournaments.getRawValue().map(x => x.id));
        let excluded = this.includedEvents.getRawValue().map(x => x.id);
        excluded.push(...this.excludedEvents.getRawValue().map(x => x.id));
        let dialogRef = this.dialog.open(EventSelectorComponent, {
            width: '40%',
            data: {
                included: included,
                excluded: excluded
            },
            height: '70%'
        });

        const dialogSub = dialogRef.afterClosed().subscribe(result => {
            if (result && result.items) {
                result.items.forEach(item => {
                    this.patchArray([{ id: item.id, value: item.value }], exclude ? this.excludedEvents : this.includedEvents);
                });
            }
            dialogSub.unsubscribe();
        });
    }

    removeItem(item: any, list: FormArray) {
        const index = list.controls.indexOf(item);
        if (index >= 0) {
            list.removeAt(index);
        }
        if (this.includedSports.length === 0 && this.excludedSports.length === 0) {
            this.includedTournaments.controls.splice(0);
            this.excludedTournaments.controls.splice(0);
        }
        if (this.includedTournaments.length === 0 && this.excludedTournaments.length === 0) {
            this.includedEvents.controls.splice(0);
            this.excludedEvents.controls.splice(0);
        }
    }

    public load(input: BonusModel, canEdit: boolean) {
        this.canEdit = canEdit;
        if (input && input.betting) {
            let betting = input.betting as BettingBonusDetail;
            this.detailForm.patchValue(betting);
            this.patchArray(betting.includedSports, this.includedSports);
            this.patchArray(betting.excludedSports, this.excludedSports);
            this.patchArray(betting.includedTournaments, this.includedTournaments);
            this.patchArray(betting.excludedTournaments, this.excludedTournaments);
            this.patchArray(betting.includedEvents, this.includedEvents);
            this.patchArray(betting.excludedEvents, this.excludedEvents);
        }
        if (!this.canEdit) {
            for (const key in this.detailForm.controls) {
                if (this.detailForm.controls.hasOwnProperty(key)) {
                    const ctrl = this.detailForm.controls[key];
                    if (ctrl) {
                        ctrl.disable();
                    }
                }
            }
        }
    }

    protected init(): void {

    }

    public save(request: SaveBonusRequest) {
        request.betting = this.detailForm.getRawValue() as BettingBonusDetail;
    }

    private patchArray(values: any, list: FormArray) {
        if (Array.isArray(values)) {
            let items = values as SelectItem[];
            if (items) {
                items.forEach(x => {
                    list.push(this.formBuilder.group({
                        id: x.id,
                        value: x.value
                    }));
                });
            }
        }
    }
}
