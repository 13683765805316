import {Component, ViewChild, Inject, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApisService } from 'src/app/shared/apis.service';
import { GetMediaRequest } from '../../models/DataModels/Cms/Request';
import { GetMediaResponse, GetMediaResponseItem, GetItemsBaseResponse, UserAccess } from '../../models/DataModels';
import { TableDataSource } from '../../models/DataModels/TableDataSource/TableDataSource';
import { EditMediaComponent } from './editmedia.component';
import { HttpDao } from '../../models/DataModels/TableDataSource/HttpDao';
import { Observable } from 'rxjs';
import { ModelRequest, DataBaseRequestField, SelectItem } from '../../models/DataModels/TableDataSource/ModelRequest';
import { FuseConfirmDialogComponent } from '../../core/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from 'src/app/shared/notify.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MediaService } from 'src/app/shared/media.service';
import { MoveMediaFolderComponent } from './movemediafolder.component';
import { MediaFolderComponent } from './mediafolder.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})

export class MediaComponent implements OnInit, OnDestroy ,AfterViewInit{
  shouldEditMedia: boolean;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  subscribers: Subscription[] = new Array<Subscription>();
  selection = new SelectionModel<Element>(true, []);
  userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };

  protected request = new GetMediaRequest();
  public dataSource: TableDataSource<GetMediaRequest, GetItemsBaseResponse<GetMediaResponse>>;
  // tslint:disable-next-line:max-line-length
  public displayedColumns = ['select', 'link', 'name', 'extension', 'frontend', 'description', 'width', 'height', 'changedAt', 'changedBy', 'action'];
  public panelTitle = 'MEDIA.TITLE';
  public model: ModelRequest<GetMediaRequest>;
  private frontendFilter = new DataBaseRequestField('frontend', 'COMMON.SEARCH_FRONTEND_LABEL', 'select', false, []);
  private mediaHttpDao: MediaHttpDao;
  private firstLoad: Subscription;

  constructor(
    @Inject(NotifyService) private notifyService,
    private apisService: ApisService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private mediaService: MediaService
  ) {
    this.mediaHttpDao = new MediaHttpDao(this.apisService);
    this.model = new ModelRequest<GetMediaRequest>(this.request);
    this.model.showAddItem = true;

    this.model.showMoveItem = false;

    const tmpItems = new Array<DataBaseRequestField>();
    tmpItems.push(
      new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string'),
      new DataBaseRequestField('description', 'MEDIA.FILE_DESCRIPTION', 'string', true),
      new DataBaseRequestField('created', 'COMMON.SEARCH_CREATION_DATE', 'date'),
      this.frontendFilter,
      new DataBaseRequestField('allFolders', 'COMMON.SEARCH_ALLFOLDERS', 'toggle')
    );
    this.model.items = tmpItems;
    let paramLoader = this.route.params.subscribe(params => {
      this.model.request.name = params.id;
      this.shouldEditMedia = true;
    });
    paramLoader.unsubscribe();
    paramLoader = undefined;
  }

  @ViewChild('mediaFolder', { static: false }) mediaFolderElement: MediaFolderComponent;

  ngOnInit() {
    this.firstLoad = this.mediaHttpDao.firstLoad.subscribe((userAccess: UserAccess) => {
      this.request.includeAccess = false;
      // this.userAccess = userAccess;
      this.model.updateShowAdd(this.userAccess.canInsert);
    });
    this.model.request.reset();
    // tslint:disable-next-line:max-line-length


    let frontendLoader = this.apisService.getFrontends().subscribe(response => {
      if (!response.isError) {
        this.frontendFilter.selectItems = response.data.map(x => <SelectItem>{
          id: x.id,
          value: x.name
        });
      }
      frontendLoader.unsubscribe();
      frontendLoader = undefined;
    });
  }

  ngAfterViewInit() {
    this.dataSource = new TableDataSource<GetMediaRequest, GetItemsBaseResponse<GetMediaResponse>>(this.mediaHttpDao!, this.paginator, this.sort, this.model);
    this.subscribers.push(this.dataSource.loaded.subscribe(items => {
      this.shouldEditMedia = false;
      this.subscribers.forEach(x => x.unsubscribe);
      /* @fix-by-migration
      const selected = items.find(x => x.name === this.model.request.name);
      if (selected) {
        this.edit(selected);
      }*/
    }));
  }

  ngOnDestroy() {
    this.subscribers.forEach(x => x.unsubscribe());
    if (this.firstLoad) {
      this.firstLoad.unsubscribe();
      this.firstLoad = undefined;
    }
  }

  onSubmit() {
    this.paginator.pageIndex = 0;
    this.model.request.isSearch = true;
    this.model.changed.emit();
  }

  onMove() {
    if (this.mediaService.items && this.mediaService.items.length > 0) {
      const dialogRef = this.dialog.open(MoveMediaFolderComponent, {
        width: '60%',
        data: {
          models: this.mediaService.items
        },
        height: '60%'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.request) {
            let loader = this.apisService.moveMediaItems(result.request).subscribe(response => {
              loader.unsubscribe();
              loader = undefined;
              this.mediaService.clearItems();
              this.model.updateShowMove(false);
              this.notifyService.handleServerResponse(response, () => {
              });
            });
          }
        }
      });
    }
  }

  move(item, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    this.mediaService.clearItems();
    this.addItem(item);
    this.onMove();
  }

  addItem(item: Element) {
    if (item === undefined) { return; }
    this.selection.toggle(item);
    this.mediaService.addItem(item.id);
    this.model.updateShowMove(this.mediaService.items.length > 1);
  }

  edit(selectedMedia?: GetMediaResponseItem, event?: any): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.userAccess.canEdit) { return; }
    // tslint:disable-next-line:no-null-keyword
    const id = selectedMedia ? selectedMedia.id : null;
    let isGameMedia = false;
    if (this.mediaFolderElement) {
      isGameMedia = this.mediaFolderElement.isGameMedia(this.model.request.folder);
    }

    const dialogRef = this.dialog.open(EditMediaComponent, {
      width: '70%',
      data: {
        id: id,
        folder: this.model.request.folder,
        gameMedia: isGameMedia
      },
      height: '70%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.model.reset();
      }
    });
  }

  delete(item: GetMediaResponseItem, event?: any): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.userAccess.canDelete) { return; }
    if (item === undefined) { return; }
    this.translateService.get('COMMON.DELETEMESSAGE').subscribe(translate => {
      translate += `${item.name}?`;
      const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        width: '50%'
      });
      dialogRef.componentInstance.confirmMessage = translate;
      dialogRef.afterClosed().subscribe(confirm => {
        if (confirm === true) {
          this.dataSource.isLoadingResults = true;
          this.apisService.deleteMedia(item.id).subscribe(result => {
            this.dataSource.isLoadingResults = false;
            this.notifyService.handleServerResponse(result, res => {
              this.model.reset();
            });
          });
        }
      });
    });
  }

  folderChanged($event: string) {
    this.model.request.folder = $event;
    this.model.request.isSearch = false;
    this.model.reset();
  }

  getImageLink(item: GetMediaResponseItem) {
    if (item.randomLink) { return item.randomLink; }
    item.randomLink = item.link + '?v=' + Math.random();
    return item.randomLink;
  }
}

export class MediaHttpDao extends HttpDao<GetMediaRequest, GetItemsBaseResponse<GetMediaResponse>> {
  constructor(public apisService: ApisService) {
    super(apisService);
  }

  public getData(request: GetMediaRequest): Observable<GetItemsBaseResponse<GetMediaResponse>> {
    return this.apisService.getMedia(request)
      .pipe(map(res => {
        if (res.isError) {
          return {
            total: 0,
            items: []
          } as GetItemsBaseResponse<GetMediaResponse>;
        } else {
          if (request.includeAccess && res.userAccess) {
            this.firstLoad.next(res.userAccess);
          }
          this.itemsChangedAt(res.data);
          return res.data;
        }
      }));
  }
}
