import { Inject, Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { User, UserManager } from "oidc-client";
import { AppConfigService } from "./appconfig.service";
import {
  APP_ROUTE_LOGIN_PATH,
  APP_ROUTE_UNAUTH_PATH,
  TokenValidityState,
} from "./constants/auth.constants";
import { IdentityConfig } from "./identityconfig";

@Injectable()
export class AuthService {
  private manager: UserManager;
  user: User = null;

  constructor(
    @Inject(AppConfigService) private appConfig: AppConfigService,
    @Inject(IdentityConfig) identityConfig: IdentityConfig,
    private router: Router
  ) {
    console.log(identityConfig.getStsConfiguration());
    this.manager = new UserManager(identityConfig.getStsConfiguration());
    this.manager.clearStaleState().then(() => {
      //  todo clear storage
    });

    this.manager.events.addUserLoaded((user) => {
      this.user = user;
      if (this.user) {
        this.manager.storeUser(this.user);
        // this.getShortToken(true);
      }
    });

    this.manager.getUser().then((user) => {
      console.log(user);
      this.user = user;
      if (this.user) {
        // this.notifyChange();
      } else {
        // this.localStorageService.removeItem(
        //     environment.common.storageKeys.authData
        // );
      }
    });

    this.manager.events.addAccessTokenExpiring(() => {
      // this.SetUserData(this.user);
      // this.notifyChange();
      this.silentRenew();
    });

    this.manager.events.addSilentRenewError((error) => {
      console.log("------addSilentRenewError-------", error);
    });
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  // TODO: CALL IT from auth-callback.component
  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then((user) => {
      this.user = user;
      if (this.user) {
        this.manager.storeUser(this.user);
        this.router.navigate([""]);
        // this.SetUserData(this.user);
        // this.router.navigate(['']);
      }
    });
  }

  silentRenew() {
    // this.manager.startSilentRenew();
    this.manager.signinSilent().then((res) => {
      this.user = res;
      this.manager.storeUser(this.user);
      // this.SetUserData(this.user);
      // this.notifyChange();
    });
  }

  hasRole(role: string): boolean {
    const roles = this.appConfig.data.roles || new Array<string>();
    const result = roles.find((x) => role.indexOf(x) >= 0);
    // @fix-to-go-ahead
    // return result !== undefined;
    return true;
  }

  /**
   * Check whether there's an active user session.
   * If authentication fails, either resolve to false or to login route.
   */
  public async isAuthenticated(
    redirectToLogin: boolean = true
  ): Promise<boolean | UrlTree> {
    let isAuthenticatedFn: () => Promise<boolean> = null;
    isAuthenticatedFn = () => this._isAuthenticated();

    const authFailedFn = (resolve: (UrlTree) => void, reject: () => void) => {
      if (redirectToLogin) {
        resolve(this.router.parseUrl(APP_ROUTE_LOGIN_PATH));
      } else {
        reject();
      }
    };

    return new Promise<boolean>((resolve, reject) => {
      if (isAuthenticatedFn != null) {
        isAuthenticatedFn()
          .then((value) => {
            if (value) {
              resolve(true);
            } else {
              //authFailedFn(resolve, () => reject('Not authenticated'));
              this.router.navigate([APP_ROUTE_LOGIN_PATH]);
            }
          })
          .catch((error) => {
            authFailedFn(resolve, () => reject(error));
            this.router.navigate([APP_ROUTE_UNAUTH_PATH]);
          });
      } else {
        console.warn("AuthService", "No authentication provider");
        authFailedFn(resolve, () => reject("No authentication provider"));
        return false;
      }
    });
  }

  _isAuthenticated(): Promise<boolean> {
    console.log("Check if User is authenticated");

    return new Promise<boolean>((resolve) => {
      setTimeout(() => {
        this.manager.getUser().then((user) => {
          console.log("USER: ", user);
          if (user && user.access_token) {
            this.user = user;
            const tokenState = this.checkTokenValidity();
            console.log("TOKEN VALIDITY STATE", tokenState);
            //resolve(true)
            switch (tokenState) {
              case TokenValidityState.VALID:
                console.log("User is authenticated");
                resolve(true);
                break;

              case TokenValidityState.EXPIRED:
                resolve(false);
                break;

              case TokenValidityState.EXPIRING:
                resolve(false);
                break;
            }
          } else {
            console.log("User IS NOT authenticated");
            resolve(false);
          }
        });
      }, 200);
    });
  }

  private checkTokenValidity() {
    const validityTimeLeft = Date.now() / 1000 - this.user.expires_at;
    console.log("TOKEN VALIDITY TIME:", validityTimeLeft);
    let res;
    switch (true) {
      case validityTimeLeft < 0:
        console.log("Valid Token");
        res = TokenValidityState.VALID;
        break;

      case validityTimeLeft > 0:
        console.log("EXPIRED TOKEN");
        res = TokenValidityState.EXPIRED;
        break;

      case validityTimeLeft < 0 && validityTimeLeft > -5 * 60 * 1000:
        console.log("EXPIRING TOKEN");
        res = TokenValidityState.EXPIRING;
        break;
    }

    return res;
  }
}
