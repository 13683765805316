import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { fuseAnimations } from '../../core/animations';
import { NotifyService } from 'src/app/shared/notify.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SportBetGroupModel, BetGroupModel } from '../../models/DataModels';


@Component({
    selector: 'app-sportbetgroup',
    templateUrl: './sportbetgroup.component.html',
    styleUrls: ['./sportbetgroup.component.scss'],
    animations: fuseAnimations
})
export class SportBetgroupComponent implements OnInit, OnDestroy {
    allItems: BetGroupModel[];
    pageForm: FormGroup;
    formErrors: any;
    private subscribers: Subscription[] = [];

    constructor(
        @Inject(NotifyService) protected notifyService: NotifyService,
        @Inject(MAT_DIALOG_DATA) public input: any,
        public dialogRef: MatDialogRef<SportBetgroupComponent>,
        private formBuilder: FormBuilder
    ) {
        this.allItems = input.items || [];
        this.formErrors = {
            betDescription: {},
            outcomeId: {}
        };
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.pageForm = this.formBuilder.group({
            groupId: [undefined, [Validators.required]],
            enabled: [true, [Validators.required]]
        });

        this.subscribers.push(this.pageForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        }));
    }


    save() {
        if (this.pageForm.valid) {
            const value = this.pageForm.getRawValue();
            const selected = this.allItems.find(x => x.id === value.groupId);
            this.dialogRef.close({
                item: <SportBetGroupModel>{
                    groupId: +value.groupId,
                    description: selected.description,
                    enabled: value.enabled,
                    isNew: true
                }
            });
        }
    }

    close() {
        this.dialogRef.close();
    }

    private onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.pageForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
                console.log(field, 'not valid');
            }
        }
    }
}
