<div class="layout-container-horizontal">
    <div class="layout-editor" *ngIf="column.isEdit" [ngStyle]="{'flex': '0 0 ' + column.width + '%'}">
        <div>
            <label>Column</label>
            <button mat-button class="mat-icon-button" (click)="addColumn()" value="Add Column" matTooltip="{{'LAYOUT.ADD_COLUMN' | translate}}">
                <svg viewBox="0 0 24 24">
                    <path d="M11,2A2,2 0 0,1 13,4V20A2,2 0 0,1 11,22H2V2H11M4,10V14H11V10H4M4,16V20H11V16H4M4,4V8H11V4H4M15,11H18V8H20V11H23V13H20V16H18V13H15V11Z"
                    />
                </svg>
            </button>
            <button mat-button class="mat-icon-button" (click)="delete()" value="Delete" matTooltip="{{'LAYOUT.DELETE' | translate}}">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-button class="mat-icon-button" (click)="reset()" value="Reset" matTooltip="{{'LAYOUT.REFRESH_ROWS' | translate}}">
                <mat-icon>refresh</mat-icon>
            </button>
            <mat-form-field>
                <input class="layout-width" matInput placeholder="WIDTH" [(ngModel)]="column.width">
                <label>%</label>
            </mat-form-field>
        </div>
    </div>
    <app-layoutrow [rows]="column.rows" [ngStyle]="{'flex': '0 0 ' + row.width + '%'}" [row]="row" *ngFor="let row of column.rows"></app-layoutrow>
</div>