import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ILayoutContentMapper, LayoutContentMapper} from './layoutcontentmapper';

@Component({
    selector: 'layout-cell-content',
    template: `
        <div>
            <div #container></div>
        </div>
    `
})
export class LayoutContentComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('container', {read: ViewContainerRef, static: false})
    container: ViewContainerRef;

    @Input()
    type: string;

    @Input()
    context: any;

    private componentRef: ComponentRef<{}>;

    constructor(
        @Inject(LayoutContentMapper) private mapper: ILayoutContentMapper,
        private componentFactoryResolver: ComponentFactoryResolver) {
    }


    ngOnInit() {

    }

    ngAfterViewInit() {
        if (!this.mapper) {
            let factory = this.componentFactoryResolver.resolveComponentFactory(UnknownDynamicComponent);
            this.componentRef = this.container.createComponent(factory);
        } else {

            if (this.type) {
                let componentType = this.mapper.getComponentType(this.type);
                let factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
                let component = this.container.createComponent(factory);
                let instance = <DynamicComponent> component.instance;
                instance.context = this.context;
                this.componentRef = component;
            }
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = undefined;
        }
    }
}

export abstract class DynamicComponent {
    context?: any;
    type: string;
}

@Component({
    selector: 'unknown-component',
    template: `
        <div>Unknown component {{context?.text}}</div>`
})
export class UnknownDynamicComponent extends DynamicComponent {
    public isDirty() {
        return false;
    }
}
