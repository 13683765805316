<div class="page-layout carded fullwidth ps container">
    <div class="help-container">
        <div class="help-breadcrumb">
            <p class="help-step" *ngFor="let itemSelected of itemsSelected; let i = index" (click)="selectItem(itemSelected)">
                <span class="display">{{itemSelected.display}}</span>
                <span class="icon" [hidden]="i === itemsSelected.length - 1">
                    <mat-icon class="mat-icon material-icons">chevron_right</mat-icon>
                </span>
            </p>
        </div>
        <p class="selection-warning">
            {{'HELP.SELECTION_WARNING' | translate}}
        </p>
        <button mat-fab class="add-event-button jelp-sort add-button" *ngIf="userAccess.canInsert && itemsSelected.length > 0" (click)="sort()" aria-label="Sort item" matTooltip="{{'HELP.SORT'| translate}}" matTooltipPosition="left" [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
            <mat-icon>sort_by_alpha</mat-icon>
        </button>
        <button mat-fab class="add-event-button add-button" *ngIf="userAccess.canInsert && itemsSelected.length > 0" (click)="add()" aria-label="Add item" matTooltip="{{('HELP.ADDITEM_LABEL'| translate) + ' ' + itemsSelected[itemsSelected.length - 1].display}}" matTooltipPosition="left"
            [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-fab class="add-event-button" [ngClass]="{'save-button-hide': content == null, 'save-button': content != null}" *ngIf="userAccess.canEdit && itemSelected" (click)="save()" aria-label="Edit item"
            matTooltip="{{('HELP.EDITITEM_LABEL' | translate) + ' ' + itemsSelected[itemsSelected.length - 1].display}}" matTooltipPosition="left" [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
            <mat-icon>save</mat-icon>
        </button>
    </div>

    <div class="flex-container">
        <div class="content ps items-container" fuseperfectscrollbar>
            <div class="nav">
                <div aria-label="inbox" class="nav-item" *ngFor="let item of items">
                    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="gappx">
                        <a class="nav-link mat-ripple help-item" [ngClass]="{'selected': item.selected}" matripple (click)="getDescription(item)">
                            <span class="title">{{item.display}}</span>
                        </a>
                        <button mat-button class="tree-button">
                            <mat-icon (click)="delete(item.id, $event)">delete</mat-icon>
                        </button>
                        <button mat-button class="tree-button">
                            <mat-icon (click)="moveup(item.id, $event)">arrow_upward</mat-icon>
                        </button>
                        <button mat-button class="tree-button">
                            <mat-icon (click)="movedown(item.id, $event)">arrow_downward</mat-icon>
                        </button>
                        <button mat-button class="tree-button">
                            <mat-icon (click)="move(item.id, $event)">call_missed_outgoing
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="description-container" name="pageForm" [formGroup]="pageForm">

            <div class="description-container-display" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="50">
                    <input matInput placeholder="{{'HELP.DISPLAY' | translate}}" matTooltip="{{'HELP.DISPLAY' | translate}}" formControlName="display">
                    <mat-error *ngIf="formErrors.display.required">
                        {{'COMMON.REQUIRED_ERROR' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="50">
                    <mat-select matInput placeholder="{{'HELP.SKIN' | translate}}" matTooltip="{{'HELP.TOOLTIP_SKIN' | translate}}" formControlName="skinId" [(ngModel)]="skinId">
                        <mat-option *ngFor="let skin of skins" [value]="skin.id">
                            {{ skin.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formErrors.skinId.required">
                        {{'COMMON.REQUIRED_ERROR' | translate}}
                    </mat-error>
                </mat-form-field>

            </div>
            <!--            <app-tinymce [(ngModel)]="content" [config]="editorConfig" name="editor" formControlName="content">-->
            <!--            </app-tinymce>-->
            <angular-editor class="custom-editor-toolbar" formControlName="content" [config]="editConfig"></angular-editor>
        </div>
    </div>

    <div class="media-loading-shade" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>