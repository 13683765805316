import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { GetExternalMediaRequest } from "../../models/DataModels/Cms/Request";
import {
  DataBaseRequestField,
  FilterCommand,
  ModelRequest,
} from "../../models/DataModels/TableDataSource/ModelRequest";
import { ApisService } from "src/app/shared/apis.service";
import { GenericGalleryComponent } from "../../components/genericGallery/generic.gallery.component";
import {
  GetExternalMediaResponseItem,
  GetItemsBaseResponse,
} from "../../models/DataModels";
import { HttpDao } from "../../models/DataModels/TableDataSource/HttpDao";
import { Observable, Subscription } from "rxjs";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { map } from "rxjs/operators";
import { AddMediaModalComponent } from "../add-media-modal/add-media-modal.component";
import { CasinoService } from "src/app/shared/casino.service";

@Component({
  selector: "app-mediaselector",
  templateUrl: "./mediaselector.component.html",
  styleUrls: ["./mediaselector.component.scss"],
})
export class MediaSelectorComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  model: ModelRequest<GetExternalMediaRequest>;
  isMultiSelect: boolean = false;
  panelTitle = "MEDIASELECTOR.TITLE";
  // tslint:disable-next-line:max-line-length
  @ViewChild("genericGallery", { static: false })
  private _gallery: GenericGalleryComponent<
    GetExternalMediaRequest,
    GetItemsBaseResponse<GetExternalMediaResponseItem>
  >;

  private _httpDao: BannerWidgetHttpDao;
  private contentSubsription: Subscription;
  private _request = new GetExternalMediaRequest();
  addingToGame: boolean;
  addingToCategory: boolean;
  addingToGroups: boolean;

  constructor(
    private apisService: ApisService,
    public casinoService: CasinoService,
    @Inject(MAT_DIALOG_DATA) public input: any,
    public dialogRef: MatDialogRef<MediaSelectorComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.model = new ModelRequest<GetExternalMediaRequest>(this._request);
    this.model.showAddItem = true;

    this.model.commands = [];
    const saveBtn = new FilterCommand("save", "COMMON.SAVE", "save");
    saveBtn.click.subscribe((x) => this.select());
    this.model.commands.push(saveBtn);
    let tmpItems = new Array<DataBaseRequestField>();
    tmpItems.push(
      new DataBaseRequestField("name", "COMMON.SEARCH_NAME_LABEL", "string"),
      new DataBaseRequestField(
        "folder",
        "COMMON.SEARCH_CREATION_FOLDER",
        "string"
      ),
      new DataBaseRequestField("frontend", "COMMON.SEARCH_FRONTEND", "string"),
      new DataBaseRequestField(
        "created",
        "COMMON.SEARCH_CREATION_DATE",
        "date"
      ),
      new DataBaseRequestField("width", "COMMON.SEARCH_WIDTH", "number"),
      new DataBaseRequestField("height", "COMMON.SEARCH_HEIGHT", "number")
    );
    this.model.items = tmpItems;
    this._httpDao = new BannerWidgetHttpDao(this.apisService);
    this.isMultiSelect = this.input.isMultiSelect;
    this._request.exclude = this.input.exclude;
    this._request.folder = this.input.folder;
    this.addingToGame = this.input.addingToGame;
    this.addingToCategory = this.input.addingToCategory;
    this.addingToGroups = this.input.addingToGroups;
    console.log("GROUPS: ", this.addingToGroups);
    if (this.input.folder !== undefined) {
      this._request.ignoreFolder = this.input.ignoreFolder;
    } else {
      this._request.ignoreFolder = true;
    }
  }

  ngAfterViewInit() {
    this._gallery.create(this._httpDao, this.model);
    this.contentSubsription = this._gallery.selectedChanged.subscribe((res) => {
      // this.contentChanged.emit(res);
    });
  }

  ngOnDestroy() {
    if (this.contentSubsription !== undefined) {
      this.contentSubsription.unsubscribe();
    }
  }
  closeDialog(value: boolean) {
    this.dialogRef.close(value);
    console.log("evento chiusura ricevuto", value);
  }
  select() {
    let selectedItems = this._gallery.getSelectedItems();
    if (!this.isMultiSelect) {
      selectedItems = selectedItems.slice(0, 1);
    }
    this.dialogRef.close({
      items: selectedItems,
    });
  }

  onSubmit() {
    this.model.changed.emit();
  }

  openMediaLoader() {
    const dialogRef = this.dialog.open(AddMediaModalComponent, {
      width: "1280px",
      height: "90vh",
    });
  }
}

export class BannerWidgetHttpDao extends HttpDao<
  GetExternalMediaRequest,
  GetItemsBaseResponse<GetExternalMediaResponseItem>
> {
  constructor(public apisService: ApisService) {
    super(apisService);
  }

  public getData(
    request: GetExternalMediaRequest
  ): Observable<GetItemsBaseResponse<GetExternalMediaResponseItem>> {
    return this.apisService.getExternalMedia(request).pipe(
      map((res) => {
        if (res.isError) {
          return <GetItemsBaseResponse<GetExternalMediaResponseItem>>{
            total: 0,
            items: [],
          };
        } else {
          return res.data;
        }
      })
    );
  }
}
