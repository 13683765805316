import {Component, Inject, OnInit, OnDestroy, ViewChild, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApisService } from 'src/app/shared/apis.service';
import { GetLayoutsRequest } from '../../models/DataModels/Cms/Request';
import { GetItemsBaseResponse, LayoutModel } from '../../models/DataModels';
import { TableDataSource } from '../../models/DataModels/TableDataSource/TableDataSource';
import { ModelRequest, DataBaseRequestField } from '../../models/DataModels/TableDataSource/ModelRequest';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from 'src/app/shared/notify.service';
import { LayoutHttpDao } from '../layout/layout.component';

@Component({
  selector: 'app-layouts',
  templateUrl: './layoutselectordialog.component.html',
  styleUrls: ['./layoutselectordialog.component.scss']
})

export class LayoutSelectorDialogComponent implements OnInit, OnDestroy,AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  protected request = new GetLayoutsRequest();
  public dataSource: TableDataSource<GetLayoutsRequest, GetItemsBaseResponse<LayoutModel>>;
  public displayedColumns = ['name'];
  public panelTitle = 'LAYOUTSELECTOR.TITLE';
  public model: ModelRequest<GetLayoutsRequest>;

  private httpDao: LayoutHttpDao;

  constructor(
    @Inject(NotifyService) private notifyService,
    @Inject(MAT_DIALOG_DATA) public input: any,
    public dialogRef: MatDialogRef<LayoutSelectorDialogComponent>,
    private apisService: ApisService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.request.name = this.input.name;
    this.httpDao = new LayoutHttpDao(this.apisService);
    this.model = new ModelRequest<GetLayoutsRequest>(this.request);
    this.model.columns = 2;
    this.model.showAddItem = true;

    let tmpItems = new Array<DataBaseRequestField>();
    tmpItems.push(new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string')
    );
    this.model.items = tmpItems;
  }

  ngAfterViewInit() {
    this.dataSource = new TableDataSource<GetLayoutsRequest, GetItemsBaseResponse<LayoutModel>>(this.httpDao!, this.paginator, this.sort, this.model);
    this.paginator.pageIndex = 0;
    this.model.changed.emit();
  }

  ngOnDestroy() {
    this.dataSource.disconnect();
  }

  onSubmit() {
    this.paginator.pageIndex = 0;
    this.model.changed.emit();
  }

  select(item: LayoutModel) {
    this.dialogRef.close({
      result: true,
      layoutId: item.id,
      layoutName: item.name
    });
  }
}
