import {Inject, Injectable} from '@angular/core';
import {UrlHelper} from './urlhelper';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {NotifyService} from './notify.service';
/*custom import*/
import {IBaseResponse, IResponse} from '../models/BaseResponse';
import {
    BettingGroupsModel,
    BonusModel,
    CmsHtmlModel,
    FrontendModel,
    GamesLiveModel,
    GamesModel,
    GetBettingGroupModel,
    GetBettingSportSettingsModel,
    GetBonusModel,
    GetContentsResponse,
    GetExternalMediaResponseItem,
    GetGameLiveModel,
    GetGameModel,
    GetItemsBaseResponse,
    GetMaxOrderGamesModel,
    GetMediaResponse,
    GetPromoModel,
    GetWidgetResponse,
    LayoutModel,
    MenuContentModel,
    MenuModel,
    MobileContentEditorModel,
    MobileContentModel,
    NativeAppSetting,
    PageContentModel,
    PageModel,
    PromotionContentModel,
    PromotionsModel,
    PromotionsPointCodeModel,
    SectionEditorModel,
    SectionModel,
    SkinDataResponse,
    SkinSettingsModel,
    SportSettingsModel,
    WidgetModel,
} from '../models/DataModels';
import {LanguageModel} from '../models/LanguageModel';
import {
    BaseSaveRequest,
    GetBettingGroupsRequest,
    GetBettingSportsRequest,
    GetBonusRequest,
    GetContentsRequest,
    GetExternalMediaRequest,
    GetGamesLiveRequest,
    GetGamesRequest,
    GetLayoutsRequest,
    GetMediaRequest,
    GetMenusRequest,
    GetMobileContentRequest,
    GetPagesRequest,
    GetPromoRequest,
    GetPromotionsRequest,
    GetSearchRequest,
    GetWidgetRequest,
    MediaContentResponse,
    MoveMediaFolderRequest,
    MoveMediaItemsRequest,
    SaveBettingGroupRequest,
    SaveBettingSportSettingsRequest,
    SaveBonusRequest,
    SaveCmhHtmlRequest,
    SaveContentRequest,
    SaveGameLiveRequest,
    SaveGameRequest,
    SaveLayoutRequest,
    SaveMediaFolderRequest,
    SaveMenuRequest,
    SaveMobileRequest,
    SavePageRequest,
    SavePromoRequest,
    SavePromotionRequest,
    SaveSectionRequest,
    SaveSkinRequest,
    SaveWidgetRequest,
    SaveZoneRequest,
    SearchItemModel,
    UploadMediaRequest,
    UploadNativeAppSettingRequest,
} from '../models/DataModels/Cms/Request';
import {HelpItem, HelpModelItemEdit} from '../models/DataModels/Help/Request';
import {HelpItemResponse, HelpTreeResponse,} from '../models/DataModels/Help/Response';
import {FuseNavigationService} from '../core/components/navigation/navigation.service';
import {MediaFolderModel, SelectItem, TreeItem,} from '../models/DataModels/TableDataSource/ModelRequest';

@Injectable()
export class ApisService {
    private busyCount = 0;

    constructor(
        private http: HttpClient,
        private urlHelper: UrlHelper,
        @Inject(NotifyService) private notifiService,
        @Inject(FuseNavigationService) private navigation: FuseNavigationService
    ) {
    }

    getSkinSettings(): Observable<IBaseResponse<SkinSettingsModel[]>> {
        const url = this.urlHelper.getUrl('/DataServices/GetSkinsSettings');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<SkinSettingsModel[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SkinSettingsModel[]>>;
            })
        );
    }

    saveSkin(request: SaveSkinRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveSkin');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    getSkinData(): Observable<IBaseResponse<SkinDataResponse>> {
        const url = this.urlHelper.getUrl('/DataServices/GetSkinData');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<SkinDataResponse>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SkinDataResponse>>;
            })
        );
    }

    getSkinPages(id: string): Observable<IBaseResponse<SelectItem[]>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetSkinPages') + '?id=' + id;
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<SelectItem[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SelectItem[]>>;
            })
        );
    }

    getPages(
        request: GetPagesRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<PageModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetPages');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<PageModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<PageModel>>>;
            })
        );
    }

    getLayoutsData(
        request: GetLayoutsRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<LayoutModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetLayoutsData');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<LayoutModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<LayoutModel>>>;
            })
        );
    }

    getPage(id: string): Observable<IBaseResponse<PageContentModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetPage?id=') +
            (id === '0' ? undefined : id);
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<PageContentModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<PageContentModel>>;
            })
        );
    }

    getLayout(id?: string): Observable<IBaseResponse<LayoutModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetLayout?id=') +
            (id === '0' ? undefined : id);
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<LayoutModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<LayoutModel>>;
            })
        );
    }

    getStyle(skinId: string): Observable<IBaseResponse<CmsHtmlModel>> {
        let url = this.urlHelper.getUrl('/DataServices/GetStyle');
        if (skinId) {
            url += '?skinId=' + skinId;
        }
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<CmsHtmlModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<CmsHtmlModel>>;
            })
        );
    }

    getWidget(id: string): Observable<IBaseResponse<GetWidgetResponse>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetWidget?id=') +
            (id === undefined ? '' : id);
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<GetWidgetResponse>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetWidgetResponse>>;
            })
        );
    }

    getLanguages(): Observable<IBaseResponse<LanguageModel[]>> {
        const url = this.urlHelper.getUrl('/DataServices/GetLanguages');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<LanguageModel[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<LanguageModel[]>>;
            })
        );
    }

    getLayouts(): Observable<IBaseResponse<LayoutModel[]>> {
        const url = this.urlHelper.getUrl('/DataServices/GetLayouts');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<LayoutModel[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<LayoutModel[]>>;
            })
        );
    }

    getWidgets(
        request: GetWidgetRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<WidgetModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetWidgets');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<WidgetModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<WidgetModel>>>;
            })
        );
    }

    uploadFile(request: UploadMediaRequest): Observable<IResponse> {
        const input = new FormData();
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < request.items.length; i++) {
            const item = request.items[i];
            if (item.file !== undefined) {
                input.append('files', item.file);
            }
        }

        const data = request.items.map((x) => {
            return {
                action: x.action,
                language: 'it',
            };
        });

        const requestData = {
            id: request.id || null,
            name: request.name,
            description: request.description,
            frontendId: request.frontend || '',
            items: data,
            alt: request.alt,
            folder: request.folder,
        };

        input.append('jsonRequest', JSON.stringify(requestData));
        const url = this.urlHelper.getUrl('/MediaService/UploadMedia');
        return this.http.post(url, input).pipe(
            map((res) => {
                return this.handleSuccess(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetWidgetResponse>>;
            })
        );
    }

    getExternalMedia(
        request: GetExternalMediaRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<GetExternalMediaResponseItem>>> {
        const url = this.urlHelper.getUrl('/ExternalService/GetMedia');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<GetExternalMediaResponseItem>>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<GetExternalMediaResponseItem>>>;
            })
        );
    }

    getMedia(
        request: GetMediaRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<GetMediaResponse>>> {
        const url = this.urlHelper.getUrl('/MediaService/GetMedia');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<GetMediaResponse>>(
                    res as Response,
                    false
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<GetMediaResponse>>>;
            })
        );
    }

    getMediaFolder(id: string): Observable<IBaseResponse<MediaFolderModel[]>> {
        let url = this.urlHelper.getUrl('/MediaService/GetMediaFolder?parent=');
        if (id) {
            url += id;
        }
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<MediaFolderModel[]>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<MediaFolderModel[]>>;
            })
        );
        // .catch((error: any) => this.handleError(error, false)) as Observable<IBaseResponse<MediaFolderModel[]>>;
    }

    deleteMediaFolder(id: string): Observable<IBaseResponse<boolean>> {
        const url =
            this.urlHelper.getUrl('/MediaService/DeleteMediaFolder?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    saveMediaFolder(
        request: SaveMediaFolderRequest
    ): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/MediaService/SaveMediaFolder');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    moveMediaFolder(
        request: MoveMediaFolderRequest
    ): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/MediaService/MoveMediaFolder');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    moveMediaItems(
        request: MoveMediaItemsRequest
    ): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/MediaService/MoveMediaItems');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    getMediaContent(
        mediaId: string
    ): Observable<IBaseResponse<MediaContentResponse>> {
        const url =
            this.urlHelper.getUrl('/MediaService/GetMediaContent?id=') + mediaId;
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<MediaContentResponse>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<MediaContentResponse>>;
            })
        );
    }

    deleteMedia(mediaId: string): Observable<IBaseResponse<boolean>> {
        const url =
            this.urlHelper.getUrl('/MediaService/DeleteMedia?id=') + mediaId;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    deleteWidget(id: string): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeleteWidget?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    deletePage(id: string): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeletePage?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    deleteLayout(id: string): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeleteLayout?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    resetPage(id: string): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/ResetPage?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    deleteZone(id: string): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeleteZone?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    savePage(request: SavePageRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SavePage');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    saveLayout(request: SaveLayoutRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveLayout');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    saveStyle(request: SaveCmhHtmlRequest): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveStyle');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    saveZone(request: SaveZoneRequest): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveZone');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    saveWidget(request: SaveWidgetRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveWidget');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    searchData(
        request: GetSearchRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<SearchItemModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/Search');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<SearchItemModel>>(
                    res as Response,
                    false
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<SearchItemModel>>>;
            })
        );
    }

    getTree(): Observable<IBaseResponse<HelpTreeResponse>> {
        const url = this.urlHelper.getUrl('/HelpService/GetTree');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<HelpTreeResponse>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<HelpTreeResponse>>;
            })
        );
    }

    getTreeItem(id: number): Observable<IBaseResponse<HelpItemResponse>> {
        const url =
            this.urlHelper.getUrl('/HelpService/GetItemValue?id=') +
            (id === undefined ? undefined : id);
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess(res as Response);
            })
        );
        // .catch((error: any) => this.handleError(error)) as Observable<IBaseResponse<HelpItemResponse>>;
    }

    editTreeItemValue(
        request: HelpModelItemEdit
    ): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/HelpService/EditItemValue');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    addTreeItem(request: HelpItem): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/HelpService/AddItem');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    deleteTreeItem(id: number): Observable<IBaseResponse<boolean>> {
        const url =
            this.urlHelper.getUrl('/HelpService/RemoveItem?id=') +
            (id === undefined ? undefined : id);
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    moveTreeItem(
        id: number,
        direction: number,
        parentId?: string
    ): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/HelpService/MoveItem');
        const request = {
            // tslint:disable-next-line:object-literal-key-quotes
            id: id,
            // tslint:disable-next-line:object-literal-key-quotes
            direction: direction,
            // tslint:disable-next-line:object-literal-key-quotes
            parentId: parentId,
        };
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    sortTreeItem(id?: number): Observable<IBaseResponse<boolean>> {
        const url =
            this.urlHelper.getUrl('/HelpService/SortItem?itemId=') + (id ? id : '');
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    getContents(
        request: GetContentsRequest
    ): Observable<IBaseResponse<GetContentsResponse>> {
        const url = this.urlHelper.getUrl('/DataServices/GetContents');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetContentsResponse>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetContentsResponse>>;
            })
        );
    }

    deleteContent(id: string): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeleteContent?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    saveContent(request: SaveContentRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveContent');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    getFrontends(skinId?: string): Observable<IBaseResponse<FrontendModel[]>> {
        let url = this.urlHelper.getUrl('/DataServices/GetFrontends');
        if (skinId) {
            url += '?skinId=' + skinId;
        }
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<FrontendModel[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<FrontendModel[]>>;
            })
        );
    }

    getSectionEditor(): Observable<IBaseResponse<SectionEditorModel>> {
        const url = this.urlHelper.getUrl('/DataServices/GetSectionEditor');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<SectionEditorModel>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SectionEditorModel>>;
            })
        );
    }

    getSection(id?: string): Observable<IBaseResponse<SectionModel>> {
        const url = this.urlHelper.getUrl('/DataServices/GetSection') + '?id=' + id;
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<SectionModel>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SectionModel>>;
            })
        );
    }

    saveSection(
        request: SaveSectionRequest
    ): Observable<IBaseResponse<SectionModel>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveSection');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<SectionModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SectionModel>>;
            })
        );
    }

    deleteSection(
        request: BaseSaveRequest
    ): Observable<IBaseResponse<SectionModel>> {
        const url = this.urlHelper.getUrl('/DataServices/DeleteSection');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<SectionModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SectionModel>>;
            })
        );
    }

    getPromotions(
        request: GetPromotionsRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<PromotionsModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetPromotions');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<PromotionsModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<PromotionsModel>>>;
            })
        );
    }

    getPromotion(id: string): Observable<IBaseResponse<PromotionContentModel>> {
        const url = this.urlHelper.getUrl('/DataServices/GetPromotion?id=') + id;
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<PromotionContentModel>(
                    res as Response,
                    false
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<PromotionContentModel>>;
            })
        );
    }

    savePromotion(
        request: SavePromotionRequest
    ): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SavePromotion');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    deletePromotion(id: string): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeletePromotion?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    getMenus(
        request: GetMenusRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<MenuModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetMenus');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<MenuModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<MenuModel>>>;
            })
        );
    }

    deleteMenu(id: string): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeleteMenu?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    saveMenu(request: SaveMenuRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveMenu');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    getMenu(id: string): Observable<IBaseResponse<MenuContentModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetMenu?id=') +
            (id === '0' ? undefined : id);
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<MenuContentModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<MenuContentModel>>;
            })
        );
    }

    getMobile(
        request: GetMobileContentRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<MobileContentModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetMobiles');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<MobileContentModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<MobileContentModel>>>;
            })
        );
    }

    saveMobile(request: SaveMobileRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveMobile');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    deleteMobile(id: string): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeleteMobile?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    geMobileContent(
        id: string
    ): Observable<IBaseResponse<MobileContentEditorModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetMobile?id=') +
            (id === '0' ? undefined : id);
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<MobileContentEditorModel>(
                    res as Response,
                    false
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<MobileContentEditorModel>>;
            })
        );
    }

    getGameProviders(isLive: boolean): Observable<IBaseResponse<SelectItem[]>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetProviders?isLive=') +
            (isLive ? 'true' : 'false');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<SelectItem[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SelectItem[]>>;
            })
        );
    }

    getGames(
        request: GetGamesRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<GamesModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetGames');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<GamesModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<GamesModel>>>;
            })
        );
    }

    getGame(id: string): Observable<IBaseResponse<GetGameModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetGame?id=') +
            (id !== undefined && id !== null ? id : '0');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess(res as Response, false);
            })
        );
        // .catch((error: any) => this.handleError(error, false)) as Observable<IBaseResponse<GetGameModel>>;
    }

    saveGame(request: SaveGameRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveGame');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    deleteGame(id: number): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeleteGame?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    getGamesLive(
        request: GetGamesLiveRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<GamesLiveModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetGamesLive');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<GamesLiveModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<GamesLiveModel>>>;
            })
        );
    }

    getGameLive(
        id: string,
        idFornitore: string
    ): Observable<IBaseResponse<GetGameLiveModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetGameLive?id=') +
            (id !== undefined && id !== null ? id : '0') +
            (idFornitore !== undefined ? '&provider=' + idFornitore : '&provider=0');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<GetGameLiveModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetGameLiveModel>>;
            })
        );
    }

    saveGameLive(
        request: SaveGameLiveRequest
    ): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveGameLive');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    deleteGameLive(
        id: number,
        idFornitore: number
    ): Observable<IBaseResponse<boolean>> {
        const url =
            this.urlHelper.getUrl('/DataServices/DeleteGameLive?id=') +
            id +
            '&provider=' +
            idFornitore;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    getBettingGroups(
        request: GetBettingGroupsRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<BettingGroupsModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetBettingGroups');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<BettingGroupsModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<BettingGroupsModel>>>;
            })
        );
    }

    getBettingGroup(id: string): Observable<IBaseResponse<GetBettingGroupModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetBettingGroup?id=') +
            (id !== undefined && id !== null ? id : '0');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<GetBettingGroupModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetBettingGroupModel>>;
            })
        );
    }

    saveBettingGroup(
        request: SaveBettingGroupRequest
    ): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveBettingGroup');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    deleteBettingGroup(id: number): Observable<IBaseResponse<boolean>> {
        const url =
            this.urlHelper.getUrl('/DataServices/DeleteBettingGroup?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    getBetOutcomeBySport(id: string): Observable<IBaseResponse<TreeItem[]>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetBetOutcomeBySport?id=') +
            (id !== undefined && id !== null ? id : '0');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<TreeItem[]>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<TreeItem[]>>;
            })
        );
    }

    getBettingSports(
        request: GetBettingSportsRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<SportSettingsModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetBettingSports');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<SportSettingsModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<SportSettingsModel>>>;
            })
        );
    }

    getBettingSport(
        id: string
    ): Observable<IBaseResponse<GetBettingSportSettingsModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetBettingSport?id=') +
            (id !== undefined && id !== null ? id : '0');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<GetBettingSportSettingsModel>(
                    res as Response,
                    false
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetBettingSportSettingsModel>>;
            })
        );
    }

    saveBettingSport(
        request: SaveBettingSportSettingsRequest
    ): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveBettingSport');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    deleteBettingSport(id: number): Observable<IBaseResponse<boolean>> {
        const url =
            this.urlHelper.getUrl('/DataServices/DeleteBettingSport?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    getSports(): Observable<IBaseResponse<SelectItem[]>> {
        const url = this.urlHelper.getUrl('/DataServices/BettingSports');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<SelectItem[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SelectItem[]>>;
            })
        );
    }

    getTournaments(sports: string[]): Observable<IBaseResponse<SelectItem[]>> {
        const url = this.urlHelper.getUrl('/DataServices/BettingTournaments');
        return this.http.post(url, sports).pipe(
            map((res) => {
                return this.handleSuccess<SelectItem[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SelectItem[]>>;
            })
        );
    }

    getEvents(tournaments: string[]): Observable<IBaseResponse<SelectItem[]>> {
        const url = this.urlHelper.getUrl('/DataServices/BettingEvents');
        return this.http.post(url, tournaments).pipe(
            map((res) => {
                return this.handleSuccess<SelectItem[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SelectItem[]>>;
            })
        );
    }

    getBonus(
        request: GetBonusRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<BonusModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetBonus');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<BonusModel>>(
                    res as Response
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<BonusModel>>>;
            })
        );
    }

    getSingleBonus(id: string): Observable<IBaseResponse<GetBonusModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetBonus?id=') +
            (id === undefined ? '' : id);
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<GetBonusModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetBonusModel>>;
            })
        );
    }

    getBonusType(): Observable<IBaseResponse<SelectItem[]>> {
        const url = this.urlHelper.getUrl('/DataServices/GetBonusType');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<SelectItem[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<SelectItem[]>>;
            })
        );
    }

    saveBonus(request: SaveBonusRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SaveConfigBonus');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    deleteBonus(id: number): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeleteBonus?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    getNativeAppSettings(): Observable<IBaseResponse<NativeAppSetting[]>> {
        const url = this.urlHelper.getUrl('/DataServices/GetNativeAppSettings');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<NativeAppSetting[]>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<NativeAppSetting[]>>;
            })
        );
    }

    uploadNativeAppSetting(
        request: UploadNativeAppSettingRequest
    ): Observable<IResponse> {
        const input = new FormData();
        input.append('files[]', request.file);

        const requestData = {
            id: request.id || '',
            releaseVersion: request.releaseVersion,
            isMaintenance: request.isMaintenance,
            isAppDisabledOutsideOfItaly: request.isAppDisabledOutsideOfItaly,
        };

        input.append('request', JSON.stringify(requestData));
        const url = this.urlHelper.getUrl('/MediaService/SaveNativeAppSetting');
        return this.http.post(url, input).pipe(
            map((res) => {
                return this.handleSuccess(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetWidgetResponse>>;
            })
        );
        // .catch((error: any) => this.handleError(error));
    }

    getPromos(
        request: GetPromoRequest
    ): Observable<IBaseResponse<GetItemsBaseResponse<PromotionsPointCodeModel>>> {
        const url = this.urlHelper.getUrl('/DataServices/GetPromos');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<GetItemsBaseResponse<PromotionsPointCodeModel>>(res as Response);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetItemsBaseResponse<PromotionsPointCodeModel>>>;
            })
        );
    }

    getPromo(id: string): Observable<IBaseResponse<GetPromoModel>> {
        const url =
            this.urlHelper.getUrl('/DataServices/GetPromo?id=') +
            (id === undefined ? '' : id);
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<GetPromoModel>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetPromoModel>>;
            })
        );
    }

    savePromo(request: SavePromoRequest): Observable<IBaseResponse<string>> {
        const url = this.urlHelper.getUrl('/DataServices/SavePromo');
        return this.http.post(url, request).pipe(
            map((res) => {
                return this.handleSuccess<string>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<string>>;
            })
        );
    }

    deletePromo(id: number): Observable<IBaseResponse<boolean>> {
        const url = this.urlHelper.getUrl('/DataServices/DeletePromo?id=') + id;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return this.handleSuccess<boolean>(res as Response, false);
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<boolean>>;
            })
        );
    }

    getMaxOrder(): Observable<IBaseResponse<GetMaxOrderGamesModel>> {
        const url = this.urlHelper.getUrl('/DataServices/GetMaxOrderGames');
        return this.http.get(url).pipe(
            map((res) => {
                return this.handleSuccess<GetMaxOrderGamesModel>(
                    res as Response,
                    false
                );
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<IBaseResponse<GetMaxOrderGamesModel>>;
            })
        );
    }

    private handleBusy(value: number) {
        this.busyCount += value;
        if (this.busyCount < 0) {
            this.busyCount = 0;
        }
        if (this.busyCount <= 0) {
            // this.splash.hide();
        } else {
            // this.splash.show();
        }
    }

    private handleError<T>(
        error: any,
        handleBusy: boolean = true
    ): Observable<IBaseResponse<T>> {
        if (handleBusy) {
            this.handleBusy(-1);
        }
        const login = this.urlHelper.isRedirectToLogin(error.url);
        if (login) {
            window.location.href = login;
            return undefined;
        } else {
            const denied = this.urlHelper.isAccessDenied(error.url);
            if (denied) {
                this.navigation.navigate(denied);
                return undefined;
            }
            const result = {
                isError: true,
                errorMessage: error.statusText || 'GENERIC_SERVER_ERROR',
            } as IBaseResponse<T>;
            this.notifiService.error(result.errorMessage);
            return of(result);
        }
    }

    getNavigationMenu() {
        const url = this.urlHelper.getUrl('/Menu');
        return this.http.get(url).pipe(
            map((res) => {
                // return this.handleSuccess<any>(res as Response, false);
                return res;
            }),
            catchError((error: any) => {
                return this.handleError(error) as Observable<any>;
            })
        );
    }

    private handleSuccess<T>(
        response: Response,
        handleBusy: boolean = true
    ): IBaseResponse<T> {
        if (handleBusy) {
            this.handleBusy(-1);
        }
        try {
            return response as unknown as IBaseResponse<T>;
        } catch (error) {
            const login = this.urlHelper.isRedirectToLogin(response.url);
            if (login) {
                window.location.href = login;
                return undefined;
            } else {
                const denied = this.urlHelper.isAccessDenied(response.url);
                if (denied) {
                    this.navigation.navigate(denied);
                    // window.location.href = denied;
                    return undefined;
                }
                const result = {
                    isError: true,
                    errorMessage: error.statusText || 'GENERIC_SERVER_ERROR',
                } as IBaseResponse<T>;
                this.notifiService.error(result.errorMessage);
                return result;
            }
        }
    }
}
