<mat-sidenav-container>
    <div id="fuse-main-content">
        <ng-container *ngIf="fuseSettings.layout.toolbar === 'above'">
            <fuse-toolbar class="above" [ngClass]="fuseSettings.colorClasses.toolbar"></fuse-toolbar>
        </ng-container>
        <fuse-navbar-horizontal class="top-navbar" fxHide fxShow.gt-md [ngClass]="fuseSettings.colorClasses.navbar" *ngIf="fuseSettings.layout.navigation === 'top'">
        </fuse-navbar-horizontal>
        <div id="wrapper">
            <fuse-navbar-vertical [folded]="fuseSettings.layout.navigationFolded" class="left-navbar" [ngClass]="fuseSettings.colorClasses.navbar"
                *ngIf="fuseSettings.layout.navigation === 'left' || fuseSettings.layout.navigation === 'top'">
            </fuse-navbar-vertical>
            <div class="content-wrapper">
                <ng-container *ngIf="fuseSettings.layout.toolbar === 'below'">
                    <fuse-toolbar class="below" [ngClass]="fuseSettings.colorClasses.toolbar"></fuse-toolbar>
                </ng-container>
                <fuse-content></fuse-content>
                <ng-container *ngIf="fuseSettings.layout.footer === 'below'">
                    <fuse-footer class="below" [ngClass]="fuseSettings.colorClasses.footer"></fuse-footer>
                </ng-container>
            </div>
            <fuse-navbar-vertical [folded]="fuseSettings.layout.navigationFolded" class="right-navbar" [ngClass]="fuseSettings.colorClasses.navbar"
                *ngIf="fuseSettings.layout.navigation === 'right'">
            </fuse-navbar-vertical>
        </div>
        <ng-container *ngIf="fuseSettings.layout.footer === 'above'">
            <fuse-footer class="above" [ngClass]="fuseSettings.colorClasses.footer"></fuse-footer>
        </ng-container>
    </div>
    <mat-sidenav fuseMatSidenavHelper="quick-panel" align="end">
        <fuse-quick-panel></fuse-quick-panel>
    </mat-sidenav>
</mat-sidenav-container>
<app-notification></app-notification>