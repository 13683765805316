import { BaseEditorComponent } from "./baseeditor.component";
import {
  CanDeactivate,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

export class PageEditorGuard implements CanDeactivate<BaseEditorComponent> {
  canDeactivate(
    component: BaseEditorComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return component.canDeactivate();
  }
}
