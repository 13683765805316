<div fxLayout="column" fxLayoutAlign="space-between center" class="container">
  <h1 matDialogTitle>{{ title }}</h1>
  <div mat-dialog-content>
    {{ confirmMessage ? confirmMessage : data.message }}
  </div>
  <div mat-dialog-actions class="pt-24" *ngIf="inverse">
    <button
      mat-button
      class="mat-accent mr-16"
      (click)="dialogRef.close(false)"
    >
      {{ noButton }}
    </button>
    <button mat-button (click)="dialogRef.close(true)">{{ yesButton }}</button>
  </div>
  <div mat-dialog-actions class="pt-24" *ngIf="!inverse">
    <button
      mat-raised-button
      class="mat-accent mr-16"
      mat-button
      (click)="dialogRef.close(true)"
    >
      {{ yesButton }}
    </button>
    <button mat-raised-button (click)="dialogRef.close(false)">
      {{ noButton }}
    </button>
  </div>
</div>
