import { Observable } from 'rxjs';
import { ApisService } from 'src/app/shared/apis.service';
import { EventEmitter } from '@angular/core';
import { UserAccess } from '../../DataModels';

export abstract class HttpDao<R, T extends any> {

  firstLoad = new EventEmitter<UserAccess>();

  constructor(public apisService: ApisService) { }

  abstract getData(request: R): Observable<T>;

  protected itemsChangedAt(data: T): void {
    /* @fix-by-migration (angular 10)
    if (data.items) {
        try {
            data.items.forEach(x => {
                if (x.changedAt) {
                    try {
                        let changedDate = new Date(x.changedAt);
                        x.changedFormated = changedDate.toLocaleDateString() + ' ' + changedDate.toLocaleTimeString();
                    } catch (error) {
                    }
                }
            });
        } catch (err) {
        }
    }*/
  }
}
