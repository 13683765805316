import {TinymceOptions} from './angular2-tinymce.config.interface';

export class TinymceDefaultOptions implements TinymceOptions {
    plugins = [
        'link',
        'paste',
        'table',
        'advlist',
        'autoresize',
        'lists',
        'code',
        'colorpicker',
        'textcolor'
    ];
    toolbar = [
        'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons',
    ];
    skin_url = './assets/tinymce/skins/lightgray';
    baseURL = './assets/tinymce';

    // baseURL = './assets';
    auto_focus = true;
    paste_as_text = true;
    extended_valid_elements = 'button[*],a[*],img[*]';
    allow_script_urls: true;
    remove_script_host = false;
    convert_urls = false;
}
