<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button close " [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" *fuseIfOnDom
                                aria-label="Close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{ (isNew ? 'PAGE.NEW_TITLE' : 'PAGE.EDIT_TITLE') | translate}}</span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button mat-elevation-z1" [ngClass]="{ 'disabled': pageForm.invalid }" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                [disabled]="!pageForm.touched && pageForm.invalid" (click)="save()" *fuseIfOnDom aria-label="Save"
                                matTooltip="{{'COMMON.SAVE' | translate}}">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <div class="options-icon isVisible" *ngIf="!isNew">
                            <button mat-button class="mat-icon-button mat-elevation-z1" [ngClass]="{ 'disabled': pageForm.invalid }" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                (click)="preview()" [disabled]="pageForm.invalid" *fuseIfOnDom aria-label="Preview" matTooltip="{{'PAGE.PREVIEW' | translate}}">
                                <mat-icon>personal_video</mat-icon>
                            </button>
                        </div>
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button mat-elevation-z1" [ngClass]="{ 'disabled': pageForm.invalid }" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                [disabled]="pageForm.invalid" (click)="publish()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.PUBLISH' | translate}}">
                                <mat-icon>publish</mat-icon>
                            </button>
                        </div>
                        <div class="options-icon isVisible" *ngIf="isPublished">
                            <button mat-button class="mat-icon-button mat-elevation-z1" [ngClass]="{ 'disabled': pageForm.invalid }" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                [disabled]="pageForm.invalid" (click)="resetFromPublished()" *fuseIfOnDom aria-label="Save" matTooltip="{{'PAGE.RESET_PUBLISHED' | translate}}">
                                <mat-icon>settings_backup_restore</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div name="pageForm" [formGroup]="pageForm" class="page-form">
                <mat-tab-group>
                    <mat-tab label="{{'PAGE.LAYOUT'| translate}}">
                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <h3 class="layout-selector description-title">{{'LAYOUTSELECTOR.TITLE' | translate}}</h3>
                            <mat-form-field fxFlex="100" class="content-selection-input">
                                <input fxFlex="0 0 90%" class="layout-selected" disabled matInput placeholder="{{'PAGE.CELL_LAYOUT' | translate}}" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="layoutName" (change)="onLayoutChanged()">
                                <input hidden fromControlName="layoutId">
                                <button mat-button class="mat-icon-button mat-elevation-z1" (click)="selectLayout()" *fuseIfOnDom aria-label="Select layout" matTooltip="{{'COMMON.EDIT' | translate}}">
                                    <mat-icon>border_all</mat-icon>
                                </button>
                                <mat-error *ngIf="formErrors.layoutId.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                                <mat-hint class="field-difference" *ngIf="formErrors.layoutId.difference">
                                    {{'COMMON.DIFFERENT_FIELD' | translate}}
                                </mat-hint>
                            </mat-form-field>
                            <div fxFlex="100">
                                <app-layout [config]="layoutConfig"></app-layout>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{'PAGE.DETAILS' | translate}}">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field fxFlex="30">
                                <mat-select matInput placeholder="{{'PAGE.SKIN' | translate}}" matTooltip="{{'PAGE.TOOLTIP_SKIN' | translate}}" [(ngModel)]="skinId"
                                    formControlName="skinId" (selectionChange)="onSkinChanged()">
                                    <mat-option *ngFor="let skin of skins" [value]="skin.id">
                                        {{ skin.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formErrors.skinId.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                                <mat-hint class="field-difference" *ngIf="formErrors.skinId.difference">
                                    {{'COMMON.DIFFERENT_FIELD' | translate}}
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex="30">
                                <mat-select matInput placeholder="{{'PAGE.FRONTEND' | translate}}" matTooltip="{{'PAGE.TOOLTIP_PRODUCT' | translate}}" [(ngModel)]="frontend"
                                    formControlName="frontend" (selectionChange)="updatePageLink()">
                                    <mat-option *ngFor="let frontend of frontends" [value]="frontend.id">
                                        {{ frontend.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formErrors.frontend.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                                <mat-hint class="field-difference" *ngIf="formErrors.frontend.difference">
                                    {{'COMMON.DIFFERENT_FIELD' | translate}}
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex="30">
                                <input matInput placeholder="{{'PAGE.PAGE_ID' | translate}}" matTooltip="{{'PAGE.TOOLTIP_ID' | translate}}" formControlName="link"
                                    (change)="updatePageLink()">
                                <mat-error *ngIf="formErrors.link.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                                <mat-error *ngIf="formErrors.link.maxlength">
                                    {{'COMMON.REQUIRED_MAXLEN' | translate}}
                                </mat-error>
                                <mat-hint class="field-difference" *ngIf="formErrors.link.difference">
                                    {{'COMMON.DIFFERENT_FIELD' | translate}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'PAGE.NAME' | translate}}" matTooltip="{{'PAGE.TOOLTIP_NAME' | translate}}" formControlName="name">
                                <mat-error *ngIf="formErrors.name.required">
                                    {{'COMMON.REQUIRED_ERROR' | translate}}
                                </mat-error>
                                <mat-error *ngIf="formErrors.name.maxlength">
                                    {{'COMMON.REQUIRED_MAXLEN' | translate}}
                                </mat-error>
                                <mat-hint class="field-difference" *ngIf="formErrors.name.difference">
                                    {{'COMMON.DIFFERENT_FIELD' | translate}}
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex="50">
                                <input matInput placeholder="{{'PAGE.TITLE' | translate}}" matTooltip="{{'PAGE.TOOLTIP_TITLE' | translate}}" formControlName="title">
                                <mat-hint class="field-difference" *ngIf="formErrors.title.difference">
                                    {{'COMMON.DIFFERENT_FIELD' | translate}}
                                </mat-hint>
                            </mat-form-field>
                            <div fxFlex="50" class="mat-form-field">
                                <mat-checkbox formControlName="isFullScreen">{{'PAGE.PROMOTIONAL_LANDING_PAGE' | translate}}
                                </mat-checkbox>
                                <mat-hint class="field-difference" *ngIf="formErrors.isFullScreen.difference">
                                    {{'COMMON.DIFFERENT_FIELD' | translate}}
                                </mat-hint>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field fxFlex="66.66">
                                <input matInput class="input-url" placeholder="{{'PAGE.LINKURL' | translate}}" matTooltip="{{'PAGE.TOOLTIP_ADDRESS' | translate}}"
                                    formControlName="linkUrl">
                            </mat-form-field>
                            <div fxFlex="33.33" class="mat-form-field">
                                <mat-checkbox formControlName="isSystem">{{'PAGE.ISSYSTEM' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="meta" fxLayout="column" fxLayoutAlign="start center">
                            <div class="meta-header" fxFlex="90">
                                <div class="description-container">
                                    <h3 class="description-title">{{'PAGE.META' | translate}}</h3>
                                </div>
                                <div class="button-container">
                                    <button mat-icon-button color="accent" (click)="addMediaTagItem()" matTooltip="{{'PAGE.ADD_META_TAG' | translate}}">
                                        <mat-icon class="mat-24" aria-label="Add">add_circle</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div fxFlex="90" class="meta-items" fxLayout="column" formArrayName="metaTags">
                                <mat-form-field class="meta-control" fxFlex="100">
                                    <textarea matInput disabled placeholder="{{'PAGE.META_ADDED' | translate}}" [(ngModel)]="meta" [ngModelOptions]="{standalone: true}"></textarea>
                                </mat-form-field>
                                <div *ngFor="let metaTag of metaTags.controls; let i = index" fxFlex="100">
                                    <div [formGroupName]="i">
                                        <mat-form-field fxFlex="45">
                                            <input matInput placeholder="{{'PAGE.KEY' | translate}}" matTooltip="{{'PAGE.TOOLTIP_METATAG_KEY' | translate}}" (keyup)="onMetaTagChange($event, metaTag, 'key', i)"
                                                formControlName="key">
                                        </mat-form-field>
                                        <mat-form-field fxFlex="45">
                                            <input matInput placeholder="{{'PAGE.VALUE' | translate}}" matTooltip="{{'PAGE.TOOLTIP_METATAG_VALUE' | translate}}" (keyup)="onMetaTagChange($event, metaTag, 'value', i)"
                                                formControlName="value">
                                        </mat-form-field>
                                        <div class="button-container">
                                            <button mat-icon-button color="warn" (click)="removeMediaTagItem(i)" matTooltip="{{'PAGE.REMOVE_META_TAG' | translate}}">
                                                <mat-icon class="mat-24 delete-icon" aria-label="Remove">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="meta" fxLayout="column" fxLayoutAlign="start center">
                            <div class="meta-header" fxFlex="90">
                                <div class="description-container">
                                    <h3 class="description-title">{{'PAGE.TAGS' | translate}}</h3>
                                </div>
                                <div class="button-container">
                                    <button mat-icon-button color="accent" (click)="addMediaLinkItem()" matTooltip="{{'PAGE.ADD_META_LINK' | translate}}">
                                        <mat-icon class="mat-24" aria-label="Add">add_circle</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div fxFlex="90" class="meta-items" fxLayout="column" formArrayName="metaLinks">
                                <mat-form-field class="link-control" fxFlex="100">
                                    <textarea matInput disabled placeholder="{{'PAGE.TAGS_ADDED' | translate}}" [(ngModel)]="links" [ngModelOptions]="{standalone: true}"></textarea>
                                </mat-form-field>
                                <div *ngFor="let metaLink of metaLinks.controls; let i = index" fxFlex="100">
                                    <div [formGroupName]="i">
                                        <mat-form-field fxFlex="45">
                                            <input matInput placeholder="{{'PAGE.KEY' | translate}}" matTooltip="{{'PAGE.TOOLTIP_METALINK_KEY' | translate}}" (keyup)="onMetaLinkChange($event, metaLink, 'key', i)"
                                                formControlName="key">
                                        </mat-form-field>
                                        <mat-form-field fxFlex="45">
                                            <input matInput placeholder="{{'PAGE.VALUE' | translate}}" matTooltip="{{'PAGE.TOOLTIP_METALINK_VALUE' | translate}}" (keyup)="onMetaLinkChange($event, metaLink, 'value', i)"
                                                formControlName="value">
                                        </mat-form-field>
                                        <div class="button-container">
                                            <button mat-icon-button color="warn" (click)="removeMediaLinkItem(i)" matTooltip="{{'PAGE.REMOVE_META_LINK' | translate}}">
                                                <mat-icon class="mat-24 delete-icon" aria-label="Remove">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>