export abstract class DataBaseRequest {
    page = 0;
    column: string;
    order = 1;
    includeAccess?: boolean;

    abstract reset(): void;

    clone(): any {
        let cloneObj = new (<any>this.constructor)();
        for (let attribute in this) {
            if (typeof this[attribute] === 'object' && !((<any>this[attribute]) instanceof Date)) {
                cloneObj[attribute] = this.clone();
            } else {
                cloneObj[attribute] = this[attribute];
            }
        }
        return cloneObj;
    }
}
