<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
  <div id="header">
    <div class="header p-16 p-sm-24">
      <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
        <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
          <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
            <div class="options-icon isVisible">
              <button mat-button class="mat-icon-button close" [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }" *fuseIfOnDom aria-label="Close" matTooltip="{{ 'COMMON.CLOSE' | translate }}" (click)="close()">
                <mat-icon>arrow_back</mat-icon>
              </button>
            </div>
            <span class="logo-text" *fuseIfOnDom [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' }
              }">{{
              (isNew ? "WIDGET.NEW_TITLE" : "WIDGET.EDIT_TITLE") | translate
              }}</span>
          </div>
          <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
            <div class="options-icon isVisible">
              <button mat-button class="mat-icon-button" [ngClass]="{ disabled: pageForm.invalid || contentInvalid }"
                [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }" [disabled]="!pageForm.touched && pageForm.invalid" (click)="save()" *fuseIfOnDom aria-label="Save"
                matTooltip="{{ 'COMMON.SAVE' | translate }}">
                <mat-icon>save</mat-icon>
              </button>
            </div>
            <div class="options-icon isVisible">
              <button mat-button class="mat-icon-button" [ngClass]="{ disabled: pageForm.invalid }" [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }" [disabled]="pageForm.invalid" (click)="publish()" *fuseIfOnDom aria-label="publish"
                matTooltip="{{ 'COMMON.PUBLISH' | translate }}">
                <mat-icon>publish</mat-icon>
              </button>
            </div>
            <div class="options-icon isVisible" *ngIf="!isFixedWidget">
              <button mat-button class="mat-icon-button" [ngClass]="{ disabled: pageForm.invalid }" [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }" [disabled]="pageForm.invalid" (click)="clone()" *fuseIfOnDom aria-label="Clone"
                matTooltip="{{ 'COMMON.CLONE' | translate }}">
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-center">
    <div class="content-card" [ngClass]="{ 'fixed-widget': isFixedWidget }">
      <div name="pageForm" [formGroup]="pageForm" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
          <!-- <mat-form-field fxFlex="50">
                        <mat-select matInput placeholder="{{'WIDGET.FRONTEND' | translate}}"
                                    matTooltip="{{'WIDGETS.TOOLTIP_FRONTEND' | translate}}"
                                    [(ngModel)]="frontend" formControlName="frontend">
                            <mat-option *ngFor="let frontend of frontends" [value]="frontend.id">
                                {{ frontend.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.component.invalid">
                            {{'COMMON.WIDGET_COMPONENT_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field> -->
          <mat-form-field fxFlex="50">
            <mat-select placeholder="{{ 'WIDGET.TYPE' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_TYPE' | translate }}" formControlName="type" [(ngModel)]="type"
              (selectionChange)="typeChanged($event)">
              <mat-option *ngFor="let type of types" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formErrors.type.required">
              {{ "COMMON.REQUIRED_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50">
            <input matInput placeholder="{{ 'WIDGET.NAME' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_NAME' | translate }}" formControlName="name" />
            <mat-error *ngIf="formErrors.name.required">
              {{ "COMMON.REQUIRED_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
          <mat-form-field fxFlex="50">
            <input matInput [matDatepicker]="startDatePicker" placeholder="{{ 'WIDGET.STARTDATE' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_START_DATE' | translate }}" formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="formErrors.startDate.required">
              {{ "COMMON.REQUIRED_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50">
            <input matInput [matDatepicker]="endDatePicker" placeholder="{{ 'WIDGET.ENDDATE' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_END_DATE' | translate }}" formControlName="endDate" />
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="formErrors.endDate">
              {{ "WIDGET.DATES_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50">
            <input matInput placeholder="{{ 'WIDGET.NOTES' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_NOTE' | translate }}" formControlName="notes" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
          <div fxFlex="25" class="mat-form-field">
            <mat-checkbox matInput formControlName="showAnonymous">{{
              "WIDGET.SHOWANONYMOUS" | translate
              }}</mat-checkbox>
            <mat-error *ngIf="formErrors.visibilityError">
              {{ "COMMON.WIDGET_VISIBILITY_ERROR" | translate }}
            </mat-error>
          </div>

          <div fxFlex="25" class="mat-form-field">
            <mat-checkbox formControlName="showAuthenticated">{{
              "WIDGET.SHOWAUTHENTICATED" | translate
              }}</mat-checkbox>
            <mat-error *ngIf="formErrors.visibilityError">
              {{ "COMMON.WIDGET_VISIBILITY_ERROR" | translate }}
            </mat-error>
          </div>
          <div fxFlex="25" class="mat-form-field">
            <mat-checkbox matInput formControlName="visible">{{
              "WIDGET.VISIBLE" | translate
              }}</mat-checkbox>
            <mat-error *ngIf="formErrors.visible.required">
              {{ "COMMON.REQUIRED_ERROR" | translate }}
            </mat-error>
          </div>
          <div fxFlex="25" class="mat-form-field">
            <mat-checkbox matInput formControlName="showShadow">{{
              "WIDGET.SHOW_SHADOW" | translate
              }}</mat-checkbox>
          </div>
          <div fxFlex="25" class="mat-form-field">
            <mat-checkbox matInput formControlName="hideOnApp">{{
              "WIDGET.HIDE_ON_APP" | translate
              }}</mat-checkbox>
          </div>
        </div>
        <div fxLayout="row" fxLayourAlign="start center" fxFlex="1 0 auto">
          <div class="options-icon isVisible" *ngIf="showAddItem" style="padding: 1%">
            <div class="description-container">
              <h3 class="description-title">
                {{ "WIDGETS.ADD_BANNER" | translate }}
              </h3>
            </div>
            <button mat-icon-button color="accent" [@animate]="{
                value: '*',
                params: { delay: '50ms', scale: '0.2' }
              }" (click)="addItem()" *fuseIfOnDom aria-label="Select Media"
              matTooltip="{{ 'WIDGETS.ADD_BANNER' | translate }}">
              <mat-icon class="mat-24" aria-label="Add">add_circle</mat-icon>
            </button>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="showLink">
          <mat-form-field fxFlex="50">
            <input matInput placeholder="{{ 'WIDGET.LINK' | translate }}" formControlName="link" />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
          <mat-form-field fxFlex="50" [ngClass]="{ hidden: !isDynamic }">
            <input matInput placeholder="{{ 'WIDGET.DYNAMIC_COMPONENT' | translate }}"
              formControlName="dynamicComponent" />
            <mat-error *ngIf="formErrors.component.invalid">
              {{ "COMMON.WIDGET_COMPONENT_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 0 auto" class="widget-host">
          <div fxFlex="100">
            <mat-error *ngIf="formErrors.content.required">
              {{ "COMMON.REQUIRED_ERROR" | translate }}
            </mat-error>
          </div>
          <div #contentViewContainer fxFlex="100"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="media-loading-shade full-height" *ngIf="isLoading">
  <mat-spinner [color]="'accent'"></mat-spinner>
</div>