<div class="page-layout carded fullwidth ps">
  <div id="header">
    <div class="header p-16 p-sm-24">
      <div
        class="header-content"
        fxLayout="column"
        fxLayoutAlign="space-between"
      >
        <div
          class="header-top"
          fxLayout="row"
          fxLayoutAlign="stretch"
          fxLayout.xs="column"
        >
          <div
            class="logo mb-16 mb-sm-0"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <span
              class="logo-text"
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' }
              }"
              >{{ "SKINS.TITLE" | translate }}</span
            >
          </div>
          <div
            class="toolbar"
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngIf="userAccess.canEdit"
          >
            <div class="options-icon isVisible">
              <button
                mat-button
                class="mat-icon-button"
                [ngClass]="{ disabled: pageForm.invalid }"
                [@animate]="{
                  value: '*',
                  params: { delay: '50ms', scale: '0.2' }
                }"
                [disabled]="!pageForm.touched && pageForm.invalid"
                (click)="save()"
                *fuseIfOnDom
                aria-label="Save"
                matTooltip="{{ 'COMMON.SAVE' | translate }}"
              >
                <mat-icon>save</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-center">
    <div class="content-card">
      <div fxLayout="row" fxLayoutAlign="start stretch">
        <mat-form-field fxFlex="50">
          <mat-select
            matInput
            placeholder="{{ 'SKINS.ITEMS' | translate }}"
            [(ngModel)]="selectedItem"
            (selectionChange)="onSkinChanged()"
          >
            <mat-option *ngFor="let skin of skins" [value]="skin.id">
              {{ skin.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        name="pageForm"
        [formGroup]="pageForm"
        class="page-form"
        *ngIf="selectedItem"
      >
        <div fxLayout="row" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="50">
            <input
              matInput
              placeholder="{{ 'SKINS.NAME' | translate }}"
              formControlName="name"
            />
            <mat-error *ngIf="formErrors.name.required">
              {{ "COMMON.REQUIRED_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="50">
            <input
              matInput
              placeholder="{{ 'SKINS.GOOGLESEARCH' | translate }}"
              formControlName="googleSearchConsole"
            />
          </mat-form-field>
          <mat-form-field fxFlex="50">
            <input
              matInput
              placeholder="{{ 'SKINS.GOOGLETAG' | translate }}"
              formControlName="googleTagManager"
            />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="50">
            <input
              matInput
              placeholder="{{ 'SKINS.GOOGLETAG_HEADSCRIPT' | translate }}"
              formControlName="googleTagManagerHead"
            />
          </mat-form-field>
          <mat-form-field fxFlex="50">
            <input
              matInput
              placeholder="{{ 'SKINS.GOOGLETAG_BODYSCRIPT' | translate }}"
              formControlName="googleTagManagerBody"
            />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="50">
            <input
              matInput
              placeholder="{{ 'SKINS.GOOGLESEARCHMOBILE' | translate }}"
              formControlName="googleSearchConsoleMobile"
            />
          </mat-form-field>
          <mat-form-field fxFlex="50">
            <input
              matInput
              placeholder="{{ 'SKINS.GOOGLETAGMOBILE' | translate }}"
              formControlName="googleTagManagerMobile"
            />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start stretch">
          <div fxFlex="50">
            <!-- <mat-checkbox matInput formControlName="isMaintenance">{{
              "SKINS.ISMAINTENANCE" | translate
            }}</mat-checkbox> -->
            <mat-checkbox matInput formControlName="isMaintenance"
              >Maintenance Mode</mat-checkbox
            >
          </div>
          <!-- <div fxFlex="50">
            <mat-checkbox matInput formControlName="isMobileMaintenance">{{
              "SKINS.ISMOBILEMAINTENANCE" | translate
            }}</mat-checkbox>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="media-loading-shade full-height" *ngIf="isLoading">
    <mat-spinner [color]="'accent'"></mat-spinner>
  </div>
</div>
