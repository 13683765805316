<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
  <div id="header">
    <div class="header p-16 p-sm-24">
      <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
        <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
          <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="logo-icon" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">import_contacts</mat-icon>
            <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{ 'SECTION.TITLE' | translate}}</span>

          </div>
          <div class="sections-breadcrumb">
            <p class="sections-step" *ngFor="let item of itemsSelected">
              <span class="icon">
                <mat-icon class="mat-icon material-icons">chevron_right</mat-icon>
              </span>
              <span class="display">{{item}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="custom-center" [ngClass]="{'hidden': !items}">
    <div class="content-card">
      <div class="page-form" name="pageForm" [formGroup]="pageForm">
        <div class="content ps items-container" fxFlex="0 0 25%" fuseperfectscrollbar>
          <div *ngIf="items === undefined || items.length == 0" class="error-message-black">
            <span>{{'COMMON.NO_ITEMS' | translate}}</span>
          </div>
          <div class="nav treehost" *ngIf="items !== undefined && items.length > 0">
            <li *ngFor="let item of items">
              <div class="nav-item">
                <div class="nav-link mat-ripple sections-item" matripple>
                  <div (click)="expandSkin(item)">
                    <mat-icon *ngIf="item.expanded">folder_open</mat-icon>
                    <mat-icon *ngIf="!item.expanded">folder</mat-icon>
                    <span class="title">{{item.value}}</span>
                  </div>
                </div>
              </div>
              <ul *ngIf="item.expanded && item.children && item.children.length > 0">
                <li *ngFor="let menu of item.children">
                  <div class="nav-item">
                    <div class="nav-link mat-ripple sections-item" matripple>
                      <div (click)="expandMenu(menu)" fxLayout="row">
                        <mat-icon *ngIf="menu.expanded" fxFlex="20">folder_open</mat-icon>
                        <mat-icon *ngIf="!menu.expanded" fxFlex="20">folder</mat-icon>
                        <span class="title" fxFlex="80">{{menu.value}}</span>
                        <!-- <mat-icon *ngIf="menu.expanded" (click)="deleteMenu(menu)" fxFlex="20">delete</mat-icon> -->
                      </div>
                    </div>
                  </div>
                  <ul *ngIf="menu.expanded && menu.children && menu.children.length > 0">
                    <li *ngFor="let submenu of menu.children">
                      <div class="nav-item" [ngClass]="{'selected': submenu.selected}">
                        <div class="nav-link mat-ripple sections-item" matripple (click)="selectSubmenu(submenu)">
                          <span class="title">{{submenu.value}}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </div>
        </div>
        <div class="form-items-message" *ngIf="selectedSubmenu === undefined" fxFlex="0 0 75%">
          <p>{{'SECTION.SELECT_ITEM' | translate}}</p>
        </div>

        <div class="form-items" *ngIf="selectedSubmenu !== undefined" fxFlex="0 0 75%" fxLayout="column">
          
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="0 0 auto">
            <mat-form-field fxFlex="50">
              <input matInput placeholder="{{'SECTION.NAME' | translate}}" formControlName="name">
              <mat-error *ngIf="formErrors.name.required">
                {{'COMMON.REQUIRED_ERROR' | translate}}
              </mat-error>
              <mat-error *ngIf="formErrors.name.maxlength">
                {{'COMMON.REQUIRED_MAXLEN' | translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="50">
              <input matInput placeholder="{{'SECTION.ITEM_TITLE' | translate}}" formControlName="title">
              <mat-error *ngIf="formErrors.title.required">
                {{'COMMON.REQUIRED_ERROR' | translate}}
              </mat-error>
              <mat-error *ngIf="formErrors.title.maxlength">
                {{'COMMON.REQUIRED_MAXLEN' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input matInput placeholder="{{'SECTION.ITEM_LINK' | translate}}" formControlName="link">
              <mat-error *ngIf="formErrors.link.required">
                {{'COMMON.REQUIRED_ERROR' | translate}}
              </mat-error>
              <mat-error *ngIf="formErrors.link.maxlength">
                {{'COMMON.REQUIRED_MAXLEN' | translate}}
              </mat-error>
              <mat-error *ngIf="formErrors.link.invalidLink">
                {{'SECTION.INVALID_LINK' | translate}}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="0 0 auto">
            <div fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start stretch">
                <mat-form-field fxFlex="80">
                  <input hidden formControlName="contentId">
                  <input matInput placeholder="{{'SECTION.CONTENT' | translate}}" formControlName="content">
                </mat-form-field>
                <button fxFlex="20" mat-button class="mat-icon-button" (click)="selectContent()" *fuseIfOnDom matTooltip="{{'COMMON.SELECT_CONTENT' | translate}}">
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
              </div>
            </div>

            <div fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start stretch">
                <mat-form-field fxFlex="80">
                  <input hidden formControlName="pageId">
                  <input matInput placeholder="{{'SECTION.PAGE' | translate}}" formControlName="pageName">
                </mat-form-field>
                <button fxFlex="20" mat-button class="mat-icon-button" (click)="selectPage()" *fuseIfOnDom matTooltip="{{'SECTION.SELECT_PAGE' | translate}}">
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="0 0 auto">
            <div fxFlex="50">
              <mat-checkbox matInput formControlName="isVisible">{{'SECTION.ISVISIBLE' | translate}}</mat-checkbox>
              <mat-error *ngIf="formErrors.isVisible.required">
                {{'COMMON.REQUIRED_ERROR' | translate}}
              </mat-error>
            </div>
            <div fxFlex="50">
              <mat-checkbox matInput formControlName="isPopup">{{'SECTION.ISPOPUP' | translate}}</mat-checkbox>
              <mat-error *ngIf="formErrors.isPopup.required">
                {{'COMMON.REQUIRED_ERROR' | translate}}
              </mat-error>
            </div>
            <mat-form-field fxFlex="50">
              <input matInput type="number" placeholder="{{'SECTION.SORT' | translate}}" formControlName="sort">
            </mat-form-field>
          </div>
        </div>
      </div>
      
      <div class="item-toolbar" fxLayout="row">
          <button mat-fab class="cancel-button" *ngIf="userAccess.canEdit && selectedSubmenu && pageForm.dirty" (click)="cancel()"
            aria-label="cancel item" matTooltipPosition="left" [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
            <mat-icon>close</mat-icon>
          </button>
          <button mat-fab class="delete-button" *ngIf="userAccess.canDelete && selectedSubmenu" (click)="delete()" aria-label="delete item"
            matTooltipPosition="left" [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-fab class="save-button" [disabled]="!pageForm.dirty || pageForm.invalid" *ngIf="userAccess.canEdit && selectedSubmenu"
            (click)="save()" aria-label="Save item" matTooltipPosition="left" [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
            <mat-icon>save</mat-icon>
          </button>
          <button mat-fab class="add-button" *ngIf="userAccess.canInsert && selectedMenu && !pageForm.dirty" (click)="add()" aria-label="Add item"
            matTooltipPosition="left" [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
            <mat-icon>add</mat-icon>
          </button>
        </div>

    </div>
  </div>

  <div class="media-loading-shade" *ngIf="isLoading">
    <mat-spinner [color]="'accent'"></mat-spinner>
  </div>
</div>