import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { DataBaseRequest } from "../../models/DataModels/TableDataSource/DataBaseRequest";
import {
  ModelRequest,
  DataBaseRequestField,
  FilterCommand,
} from "../../models/DataModels/TableDataSource/ModelRequest";
import { fuseAnimations } from "../../core/animations";
import { Subscription } from "rxjs";

@Component({
  selector: "generic-search-filter",
  templateUrl: "./generic.search.filter.component.html",
  styleUrls: ["./generic.search.filter.component.scss"],
  animations: fuseAnimations,
})

// tslint:disable-next-line:component-class-suffix
export class GenericSearchFilter<T extends DataBaseRequest>
  implements OnInit, OnDestroy
{
  @Input("panelIcon") panelIcon: string;
  @Input("panelTitle") panelTitle: string;
  @Output() onSubmit = new EventEmitter();
  @Output() onAddItem = new EventEmitter();
  @Output() onMoveItem = new EventEmitter();

  showSaveBtn: boolean;
  showMoveItem: boolean;
  showAddItem: boolean;
  columns = 2;
  rows: Array<Array<DataBaseRequestField>>;
  panelExpanded = true;

  private showAddChanged: Subscription;
  private showMoveChanged: Subscription;
  private _properties = new Map<string, string>();
  private _model: ModelRequest<T>;

  openOrClose(): void {
    this.panelExpanded = !this.panelExpanded;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.showAddChanged) {
      this.showAddChanged.unsubscribe();
      this.showAddChanged = undefined;
    }
    if (this.showMoveChanged) {
      this.showMoveChanged.unsubscribe();
      this.showMoveChanged = undefined;
    }
  }

  get model() {
    return this._model;
  }
  @Input() games: boolean;
  @Input("requestModel")
  set model(value: ModelRequest<T>) {
    if (value === this._model) return;
    if (this.showAddChanged) {
      this.showAddChanged.unsubscribe();
      this.showAddChanged = undefined;
    }
    if (this.showMoveChanged) {
      this.showMoveChanged.unsubscribe();
      this.showMoveChanged = undefined;
    }
    this._model = value;
    this.rows = undefined;
    if (this._model !== undefined) {
      this.columns = this._model.columns;
      this.showAddItem = this._model.showAddItem;
      this.showMoveItem = this._model.showMoveItem;
      this.showAddChanged = this._model.showAddItemChanged.subscribe((t) => {
        this.showAddItem = t;
      });
      this.showMoveChanged = this._model.showMoveItemChanged.subscribe((t) => {
        this.showMoveItem = t;
      });
    } else {
      this.columns = 2;
    }
    this.model.items = this.model.items.filter((x) => !x.excluded);
    if (this.games) {
      this.showAddItem = false;
      this.showSaveBtn = false;
    } else {
      this.showAddItem = true;
      this.showSaveBtn = true;
    }
  }

  reset(): void {
    // this.panelExpanded = false;
    this.model.reset();
  }

  submit(): void {
    // this.panelExpanded = false;
    this.onSubmit.emit();
  }

  commandClick(cmd: FilterCommand, parameter?: any): void {
    if (cmd) {
      cmd.click.emit(parameter);
    }
  }

  selectFilterChanged(property: string, event: any): void {
    this.model.request[property] = event.value;
  }

  onKeyPress(event): void {
    if (event.keyCode !== 13) return;
    this.submit();
  }

  move(): void {
    this.onMoveItem.emit();
  }

  addItem(): void {
    if (this.model.showAddItem) {
      this.onAddItem.emit();
    }
  }
}
