<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
  <generic-search-filter [requestModel]="model" [panelTitle]="panelTitle" [panelIcon]="'search'" (onSubmit)="onSubmit()"></generic-search-filter>

  <div class="custom-center" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
    <div class="content-card" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
      <div *ngIf="dataSource?.isEmpty || dataSource?.isError">
        <div class="example-rate-limit-reached table-error-message" *ngIf="dataSource?.isEmpty">
          <span>{{'COMMON.NO_ITEMS' | translate}}</span>
        </div>
        <div class="example-rate-limit-reached table-error-message" *ngIf="dataSource?.isError">
          <span>{{'COMMON.ERROR_LOADING' | translate}}</span>
        </div>
      </div>
      <mat-table [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : '', 'max-height': '75vh'}" [dataSource]="dataSource"
        class="table-items mat-table" [@animateStagger]="{value:'50'}" matSort matSortActive="name" matSortDisableClear matSortDirection="asc"
        fuseperfectscrollbar>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMMON.SEARCH_NAME_LABEL' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="typeLabel">
          <mat-header-cell *matHeaderCellDef>{{'SEARCH.TYPE' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.typeLabel}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>
            <span>{{'MEDIA.ACTION' | translate}}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div fxLayoutAlign="end">
              <button mat-icon-button (click)="edit(row)" name="editButton" matTooltip="{{'PAGES.EDIT' | translate}}">
                <mat-icon>edit</mat-icon>
              </button>
            </div>

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></mat-row>

      </mat-table>
      <div class="media-loading-shade" *ngIf="dataSource?.isLoadingResults">
        <mat-spinner [color]="'accent'"></mat-spinner>
      </div>
      <mat-paginator [ngStyle]="{'display': 'none'}" [length]="dataSource?.resultsLength" [pageSize]="dataSource?.pageSize">
      </mat-paginator>
    </div>
  </div>

</div>
