import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseConfigService } from "../../core/services/config.service";
import { ApisService } from "src/app/shared/apis.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { NotifyService } from "src/app/shared/notify.service";
import { AppConfigService } from "src/app/shared/appconfig.service";
import { BaseEditorComponent } from "../../components/baseeditor.component";
import {
  BannerButtonModel,
  BaseModel,
  FrontendModel,
  PromotionModel,
  UserAccess,
  WidgetType,
} from "../../models/DataModels";
import { SavePromotionRequest } from "../../models/DataModels/Cms/Request";
import { fuseAnimations } from "../../core/animations";
import { TinymceOptions } from "../../tinymce/angular2-tinymce.config.interface";
import { DATA } from "src/app/shared/constants/request";
import { MediaSelectorComponent } from "../mediaselector/mediaselector.component";
import {
  PlatformModel,
  SelectItem,
} from "../../models/DataModels/TableDataSource/ModelRequest";
import { ContentSelectorDialogComponent } from "../contents/contentselectordialog.component";
import { PageSelectorDialogComponent } from "../pages/pageselectordialog.component";
import { AmazingTimePickerService } from "amazing-time-picker";

@Component({
  selector: "app-promotioneditor",
  templateUrl: "./promotioneditor.component.html",
  styleUrls: ["./promotioneditor.component.scss"],
  animations: fuseAnimations,
})
export class PromotionEditorComponent
  extends BaseEditorComponent
  implements OnInit, OnDestroy
{
  isLoading = false;
  formErrors: any;
  isNew = false;
  buttons: BannerButtonModel[];
  comingSoonButtons: BannerButtonModel[];
  platforms: PlatformModel[];
  frontends: FrontendModel[];
  skins: BaseModel[];
  buttontypes: any[];
  icon: string;
  icons: SelectItem[];
  subscribers: Subscription[];
  startTime: string;
  endTime: string;
  userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true,
  };
  skinId: string;

  private isPublished: boolean;
  private arrowStyleTimePicker = {
    background: "#efad1a",
    color: "#fff",
  };

  @ViewChild("skinSelector", { static: false }) skinSelectorElement: MatSelect;

  constructor(
    protected translateService: TranslateService,
    @Inject(NotifyService) protected notifyService,
    @Inject(AppConfigService) private appConfig,
    @Inject("TINYMCE_CONFIG") public editorConfig: TinymceOptions,
    @Inject(DATA) private tinyMceGallery,
    protected dialog: MatDialog,
    private location: Location,
    private apisService: ApisService,
    private fuseConfig: FuseConfigService,
    private atp: AmazingTimePickerService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    super();
    this.icons = new Array<SelectItem>();
    this.buttons = new Array<BannerButtonModel>();
    this.comingSoonButtons = new Array<BannerButtonModel>();
    this.editorConfig.plugins.push("gallery");
    this.subscribers = new Array<Subscription>();
    this.formErrors = {
      name: {},
      startDate: {},
      endDate: {},
      link: {},
      platform: {},
      skinId: {},
      desktopMediaId: {},
      desktopSmallMediaId: {},
      mobileMediaId: {},
      mobileSmallMediaId: {},
    };
    this.buttontypes = [
      { value: 0, display: "PROMO.BUTTON_ISCONTENT" },
      { value: 2, display: "PROMO.BUTTON_ISLINK" },
      { value: 3, display: "PROMO.BUTTON_ISFUNCTION" },
    ];
    this.icons = [
      // {
      //     id: undefined,
      //     value: 'Nessuna',
      // } as SelectItem,
      // {
      //     id: 'admiral-cup',
      //     value: 'Torneo',
      // } as SelectItem,
      // {
      //     id: 'admiral-vip-club',
      //     value: 'Vip Club',
      // } as SelectItem,
      // {
      //     id: 'admiral-mobile',
      //     value: 'Mobile',
      // } as SelectItem,
      // // todo
      // {
      //     id: 'admiral-rechargeplus',
      //     value: 'Ricarica Plus',
      // } as SelectItem,
      // {
      //     id: 'admiral-insurance',
      //     value: 'Insurance',
      // } as SelectItem,
      // {
      //     id: 'admiral-social',
      //     value: 'Social',
      // } as SelectItem,
      // {
      //     id: 'admiral-flash',
      //     value: 'Flash',
      // } as SelectItem,
      // {
      //     id: 'admiral-extralarge',
      //     value: 'Extra Large',
      // } as SelectItem,
      // {
      //     id: 'admiral-promo-combo',
      //     value: 'Combo',
      // } as SelectItem,
    ];
  }

  get htmlText() {
    return this.pageForm.get("html").value;
  }

  @Input("htmlText") set htmlText(value: string) {
    this.pageForm.controls.html.setValue(value);
    this.emitChange();
  }

  ngOnInit() {
    this.createForm();
    this.subscribers.push(
      this.route.params.subscribe((params) => {
        this.id = params.id;
        this.isNew = this.id === undefined;
        this.loadPage(this.id);
      })
    );
  }

  ngOnDestroy() {
    this.subscribers.forEach((t) => t.unsubscribe());
  }

  loadPage(id: string): void {
    this.isLoading = true;
    this.subscribers.push(
      this.apisService.getPromotion(id).subscribe((response) => {
        if (response.isError) {
          this.isLoading = false;
        }
        this.notifyService.handleServerResponse(response, (result) => {
          // this.userAccess = response.userAccess;
          if (!result.promotion) {
            result.promotion = {} as PromotionModel;
          }
          if (result.promotion.buttons) {
            this.buttons =
              (JSON.parse(result.promotion.buttons) as BannerButtonModel[]) ||
              new Array<BannerButtonModel>();
          }
          if (result.promotion.comingSoonButtons) {
            this.comingSoonButtons =
              (JSON.parse(
                result.promotion.comingSoonButtons
              ) as BannerButtonModel[]) || new Array<BannerButtonModel>();
          }
          if (result.promotion.frontends) {
            this.frontends.forEach((item) => {
              item.selected =
                result.promotion.frontends.findIndex((x) => x.id === item.id) >=
                0;
            });
          }
          const startDate = new Date(result.promotion.startDate);
          const endDate = new Date(result.promotion.endDate);
          if (startDate) {
            this.startTime =
              this.addZero(startDate.getHours()) +
              ":" +
              this.addZero(startDate.getMinutes());
          }
          if (endDate) {
            this.endTime =
              this.addZero(endDate.getHours()) +
              ":" +
              this.addZero(endDate.getMinutes());
          }
          this.icon = result.promotion.badgeIcon;
          this.platforms = result.platforms;
          this.skins = result.skins;
          // tslint:disable-next-line:max-line-length
          this.frontends =
            result.promotion.skinId === undefined
              ? result.frontends
              : result.frontends.filter(
                  (x) => x.skinId === result.promotion.skinId
                );
          this.pageForm.patchValue(result.promotion);
          this.pageForm.controls.platforms.setValue(
            this.platforms.filter((x) => x.selected)
          );
          this.pageForm.controls.frontends.setValue(
            this.frontends.filter((x) => x.selected)
          );
          this.isPublished = result.promotion.published;
          for (const key in this.formErrors) {
            if (this.formErrors.hasOwnProperty(key)) {
              const element = this.formErrors[key];
              if (element) {
                element.difference = undefined;
              }
            }
          }
          if (result.promotion.publishedDiff) {
            result.promotion.publishedDiff.forEach((element) => {
              const key = this.formErrors[element];
              if (key) {
                key.difference = true;
              }
            });
          }
          this.pageForm.markAsPristine();
          this.pageForm.markAsUntouched();
          this.isLoading = false;
        });
      })
    );
  }

  public save(publish?: boolean, overwrite?: boolean) {
    if (!this.userAccess.canEdit) {
      return;
    }
    if (this.pageForm.dirty || this.pageForm.touched) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new SavePromotionRequest();
        this.buttons = this.buttons.filter((x) => x.text);
        this.comingSoonButtons = this.comingSoonButtons.filter((x) => x.text);
        this.pageForm.controls.frontends.setValue(
          this.frontends.filter((x) => x.selected)
        );
        this.pageForm.controls.platforms.setValue(
          this.platforms.filter((x) => x.selected)
        );
        this.pageForm.controls.buttons.setValue(JSON.stringify(this.buttons));
        this.pageForm.controls.comingSoonButtons.setValue(
          JSON.stringify(this.comingSoonButtons)
        );
        Object.assign(request, this.pageForm.value);
        request.id = this.id;
        request.overwrite = overwrite;
        request.publish = publish;
        request.isVisible = request.showIncoming;
        request.badgeIcon = this.icon;
        if (
          request.desktopMediaId === undefined &&
          request.desktopSmallMediaId === undefined &&
          request.mobileMediaId === undefined &&
          request.moileSmallMediaId === undefined
        ) {
          this.isLoading = false;
          return;
        }
        if (this.startTime) {
          const startDate = new Date(request.startDate);
          const startTime = this.startTime.split(":");
          // tslint:disable-next-line:radix
          startDate.setHours(parseInt(startTime[0]));
          // tslint:disable-next-line:radix
          startDate.setMinutes(parseInt(startTime[1]));
          request.startDate = startDate;
        }
        if (this.endTime) {
          const endDate = new Date(request.endDate);
          const endTime = this.endTime.split(":");
          // tslint:disable-next-line:radix
          endDate.setHours(parseInt(endTime[0]));
          // tslint:disable-next-line:radix
          endDate.setMinutes(parseInt(endTime[1]));
          request.endDate = endDate;
        }
        this.subscribers.push(
          this.apisService.savePromotion(request).subscribe((response) => {
            this.isLoading = false;
            this.handleServerResponse(
              response,
              (result) => {
                this.pageForm.reset();
                if (this.id === result) {
                  this.loadPage(this.id);
                } else {
                  this.router.navigate(["/cms/promotions/promotion", result]);
                }
              },
              publish
            );
          })
        );
      } else {
        this.notifyService.warning("COMMON.INVALIDDATA");
      }
    }
  }

  publish() {
    if (!this.userAccess.canEdit) {
      return;
    }
    if (this.pageForm.invalid) {
      this.notifyService.error("COMMON.INVALIDPUBLISH");
    } else {
      this.pageForm.markAsDirty();
      this.save(true);
    }
  }

  openStartTimePicker() {
    const amazingTimePicker = this.atp.open({
      time: this.startTime,
      arrowStyle: this.arrowStyleTimePicker,
    });
    amazingTimePicker.afterClose().subscribe((time) => {
      this.startTime = time;
    });
  }

  openEndTimePicker() {
    const amazingTimePicker = this.atp.open({
      time: this.endTime,
      arrowStyle: this.arrowStyleTimePicker,
    });
    amazingTimePicker.afterClose().subscribe((time) => {
      this.endTime = time;
    });
  }

  enableDateOrTime(action: number) {
    if (action === undefined) {
      return;
    }
    if (action === 0) {
      this.pageForm.controls.enableDate.setValue(false);
    } else {
      this.pageForm.controls.enableTimer.setValue(false);
      this.pageForm.controls.enableStartTimer.setValue(false);
    }
  }

  addButton() {
    if (this.buttons && this.buttons.length === 2) {
      return;
    }
    const btn = {
      isAnonymous: true,
      isAuthenticated: true,
    } as BannerButtonModel;
    btn.type = 0;
    this.buttons.push(btn);
    this.emitChange();
  }

  deleteButton(item?: BannerButtonModel) {
    if (this.buttons) {
      this.buttons.splice(this.buttons.indexOf(item), 1);
      this.emitChange();
    }
  }

  addComingSoonButton() {
    if (this.comingSoonButtons && this.comingSoonButtons.length === 2) {
      return;
    }
    const btn = {
      isAnonymous: true,
      isAuthenticated: true,
    } as BannerButtonModel;
    btn.type = 0;
    this.comingSoonButtons.push(btn);
    this.emitChange();
  }

  deleteComingSoonButton(item?: BannerButtonModel) {
    if (this.comingSoonButtons) {
      this.comingSoonButtons.splice(this.comingSoonButtons.indexOf(item), 1);
      this.emitChange();
    }
  }

  emitChange() {
    this.pageForm.controls.buttons.setValue(JSON.stringify(this.buttons));
    this.pageForm.markAsDirty();
    this.pageForm.markAsTouched();
  }

  emitComingSoonChange() {
    this.pageForm.controls.comingSoonButtons.setValue(
      JSON.stringify(this.comingSoonButtons)
    );
    this.pageForm.markAsDirty();
    this.pageForm.markAsTouched();
  }

  selectImage(isMobile: boolean, isSmall: boolean) {
    const dialogRef = this.dialog.open(MediaSelectorComponent, {
      width: "100%",
      data: {
        isMultiSelect: false,
      },
      height: "100%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.items && result.items.length > 0) {
        const item = result.items[0];
        if (isMobile) {
          if (isSmall) {
            this.pageForm.patchValue({
              mobileSmallMediaId: item.id,
              mobileSmallMediaName: item.url,
            });
          } else {
            this.pageForm.patchValue({
              mobileMediaId: item.id,
              mobileMediaName: item.url,
            });
          }
        } else {
          if (isSmall) {
            this.pageForm.patchValue({
              desktopSmallMediaId: item.id,
              desktopSmallMediaName: item.url,
            });
          } else {
            this.pageForm.patchValue({
              desktopMediaId: item.id,
              desktopMediaName: item.url,
            });
          }
        }
        this.emitChange();
      }
    });
  }

  removeImage(image) {
    if (image === undefined) {
      return;
    }
    image.value = undefined;
  }

  selectContent(item: BannerButtonModel) {
    const dialogRef = this.dialog.open(ContentSelectorDialogComponent, {
      width: "70%",
      data: {
        type: WidgetType[WidgetType.Html],
      },
      height: "70%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.result) {
        item.contentId = result.widgetId;
        item.contentName = result.widgetName;
        this.emitChange();
      }
    });
  }

  selectPage(item: BannerButtonModel) {
    const dialogRef = this.dialog.open(PageSelectorDialogComponent, {
      width: "70%",
      data: {
        type: WidgetType[WidgetType.Html],
      },
      height: "70%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.result) {
        item.pageLink = result.name;
        this.emitChange();
      }
    });
  }

  onPlatformChange(platform: PlatformModel, checked: boolean) {
    platform.selected = checked;
    this.pageForm.controls.platforms.setValue(
      this.platforms.filter((x) => x.selected)
    );
    this.emitChange();
  }

  onFrontendChange(frontend: FrontendModel, checked: boolean) {
    frontend.selected = checked;
    this.pageForm.controls.frontends.setValue(
      this.frontends.filter((x) => x.selected)
    );
    this.emitChange();
  }

  onSkinChanged() {
    this.frontends.splice(0);
    this.pageForm.controls.frontends.setValue(undefined);
    const skin = this.pageForm.controls.skinId;
    if (skin.value) {
      this.isLoading = true;
      this.subscribers.push(
        this.apisService.getFrontends(skin.value).subscribe((response) => {
          this.isLoading = false;
          this.notifyService.handleServerResponse(response, (result) => {
            this.clear();
            this.frontends = result;
          });
        })
      );
    }
  }

  onButtonTypeChanged(button: BannerButtonModel) {
    button.contentId =
      button.contentName =
      button.customFunction =
      button.link =
      button.pageLink =
      button.isPopup =
        undefined;
  }

  protected internalClose() {
    const data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(["cms/promotions"]);
    }
  }

  private addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  private createForm() {
    this.pageForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(255)]],
      link: ["", [Validators.required, Validators.maxLength(255)]],
      skinId: ["", [Validators.required, Validators.maxLength(255)]],
      shortDetails: [""],
      desktopMediaId: [""],
      desktopMediaName: [""],
      desktopSmallMediaId: [""],
      desktopSmallMediaName: [""],
      mobileMediaId: [""],
      mobileMediaName: [""],
      mobileSmallMediaId: [""],
      mobileSmallMediaName: [""],
      enableDate: [],
      enableTimer: [],
      enableStartTimer: [],
      startDate: [undefined, Validators.required],
      endDate: [undefined],
      startTime: [undefined],
      endTime: [undefined],
      showIncoming: [],
      isVisible: [],
      sort: [],
      jackpot: [],
      notes: [],
      html: [],
      showData: [],
      showAfterRegister: [],
      platforms: ["", Validators.required],
      buttons: [""],
      comingSoonButtons: [""],
      frontends: [],
      version: [],
      icon: [""],
      isWelcome: [false],
      promotionId: [undefined],
      landscape: [],
    });

    this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    });
  }

  private onFormValuesChanged(data) {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      const difference = this.formErrors[field].difference;
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
      if (difference) {
        this.formErrors[field].difference = difference;
      }
    }

    const desktopBack = this.pageForm.get("desktopMediaId");
    const desktopSmallBack = this.pageForm.get("desktopSmallMediaId");
    const mobileBack = this.pageForm.get("mobileMediaId");
    const mobileSmallBack = this.pageForm.get("mobileSmallMediaId");
    // tslint:disable-next-line:max-line-length
    if (
      this.isNull(desktopBack.value) &&
      this.isNull(desktopSmallBack.value) &&
      this.isNull(mobileBack.value) &&
      this.isNull(mobileSmallBack.value)
    ) {
      desktopBack.setErrors(["imageError", "Immagine non valida"]),
        desktopSmallBack.setErrors(["imageError", "Immagine non valida"]),
        mobileBack.setErrors(["imageError", "Immagine non valida"]),
        mobileSmallBack.setErrors(["imageError", "Immagine non valida"]),
        (this.formErrors.desktopMediaId = { imageError: "invalid" });
      this.formErrors.desktopSmallMediaId = { imageError: "invalid" };
      this.formErrors.mobileMediaId = { imageError: "invalid" };
      this.formErrors.mobileSmallMediaId = { imageError: "invalid" };
    } else {
      desktopBack.setErrors(undefined);
      desktopSmallBack.setErrors(undefined);
      mobileBack.setErrors(undefined);
      mobileSmallBack.setErrors(undefined);
      this.formErrors.desktopMediaId = {};
      this.formErrors.desktopSmallMediaId = {};
      this.formErrors.mobileMediaId = {};
      this.formErrors.mobileSmallMediaId = {};
    }
  }

  private isNull(input: any): boolean {
    return input === null || input === undefined;
  }
}
