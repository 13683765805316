import {EventEmitter, Inject, Injectable} from '@angular/core';
import {NavigationModel} from '../../../models/navigation.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {IBaseResponse} from '../../../models/BaseResponse';
import {catchError, map} from 'rxjs/operators';
import {UrlHelper} from '../../../shared/urlhelper';

@Injectable()
export class FuseNavigationService {
    onNavCollapseToggle = new EventEmitter<any>();
    onNavCollapseToggled = new EventEmitter<any>();
    onNavigationModelChange: BehaviorSubject<any> = new BehaviorSubject({});
    navigationModel: NavigationModel;
    flatNavigation: any[] = [];

    constructor(
        @Inject(AppConfigService) private appConfig: AppConfigService,
        private router: Router,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,

    ) {
        this.navigationModel = new NavigationModel();
        this.navigationModel.model = this.appConfig.data.navigation || [];
        this.updateMatIcons();
        this.onNavigationModelChange.next(this.navigationModel.model);
    }



    /**
     * Get navigation model
     *
     * @returns {any[]}
     */
    getNavigationModel() {
        return this.navigationModel.model;
    }

    /**
     * Set the navigation model
     *
     * @param model
     */
    setNavigationModel(model) {
        this.navigationModel = model;
        this.updateMatIcons();
        this.onNavigationModelChange.next(this.navigationModel.model);
    }

    /**
     * Add new navigation item
     * to the given location
     */
    addNavigationItem(location, item) {
        // Parse the location
        const locationArr = location.split('.');

        if (locationArr.length === 0) {
            return;
        }

        // Find the navigation item
        const navItem = this.findNavigationItemById(locationArr);

        // Act according to the item type
        switch (navItem.type) {
            case 'item':

                // Create a children array
                navItem.children = [];

                // Push the item
                navItem.children.push(item);

                // Change the item type to collapsable
                navItem.type = 'collapse';

                break;

            case 'collapse':

                // Push the item
                navItem.children.push(item);

                break;

            case 'group':

                // Push the item
                navItem.children.push(item);

                break;

            default:
                break;
        }
    }

    /**
     * Get navigation item from
     * given location
     *
     * @param location
     */
    getNavigationItem(location) {
        // Parse the location
        const locationArr = location.split('.');

        if (locationArr.length === 0) {
            return;
        }

        // Find and return the navigation item
        return this.findNavigationItemById(locationArr);
    }

    /**
     * Find navigation item by location
     *
     * @param location
     * @param navigation
     */
    findNavigationItemById(location, navigation?) {
        if (!navigation) {
            navigation = this.navigationModel.model;
        }

        // Iterate through the given navigation
        for (const navItem of navigation) {
            // If the nav item id equals the first location...
            if (navItem.id === location[0]) {
                // If there is more location to look at...
                if (location.length > 1) {
                    // Remove the first item of the location
                    location.splice(0, 1);

                    // Go nested...
                    return this.findNavigationItemById(location, navItem.children);
                } else {
                    // Otherwise just return the nav item
                    return navItem;
                }
            }
        }
    }

    /**
     * Get flattened navigation array
     * @param navigationItems
     * @returns {any[]}
     */
    getFlatNavigation(navigationItems?) {
        if (!navigationItems) {
            navigationItems = this.navigationModel.model;
        }

        for (const navItem of navigationItems) {
            if (navItem.type === 'subheader') {
                continue;
            }

            if (navItem.type === 'item') {
                this.flatNavigation.push({
                    title: navItem.title,
                    type: navItem.type,
                    icon: navItem.icon || false,
                    url: navItem.url,
                    svg: navItem.svg || false
                });

                continue;
            }

            if (navItem.type === 'collapse' || navItem.type === 'group') {
                this.getFlatNavigation(navItem.children);
            }
        }

        return this.flatNavigation;
    }

    public navigate(route: string): void {
        let delay = setTimeout(() => {
            this.appConfig.setBackNavigation(false);
            this.router.navigate([route]);
            clearTimeout(delay);
        }, 300);
    }

    private updateMatIcons() {
        const nav = this.getFlatNavigation().filter(x => x.svg);
        nav.forEach(x => {
            this.matIconRegistry.addSvgIcon(
                x.icon,
                this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/ico-' + x.icon + '.svg')
            );
        });
        this.matIconRegistry.addSvgIcon(
            'exclude',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/exclude.svg')
        );
    }
}
