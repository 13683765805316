import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { fuseAnimations } from '../../core/animations';
import { NotifyService } from 'src/app/shared/notify.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TreeItem } from '../../models/DataModels/TableDataSource/ModelRequest';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { OutcomeModel } from '../../models/DataModels';



@Component({
    selector: 'app-createoutcome',
    templateUrl: './createOutcome.component.html',
    styleUrls: ['./createOutcome.component.scss'],
    animations: fuseAnimations
})
export class CreateOutcomeComponent implements OnInit, OnDestroy {
    betOutcomes: TreeItem[];
    outcomes: TreeItem[];
    filteredBets: Observable<TreeItem[]>;
    pageForm: FormGroup;
    formErrors: any;
    private subscribers: Subscription[] = [];

    constructor(
        @Inject(NotifyService) protected notifyService: NotifyService,
        @Inject(MAT_DIALOG_DATA) public input: any,
        public dialogRef: MatDialogRef<CreateOutcomeComponent>,
        private formBuilder: FormBuilder
    ) {
        this.betOutcomes = input.betOutcomes;
        this.formErrors = {
            betDescription: {},
            outcomeId: {}
        };
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.pageForm = this.formBuilder.group({
            betDescription: [undefined, [Validators.required, Validators.maxLength(255)]],
            outcomeId: [undefined, [Validators.required, Validators.maxLength(255)]],
            betId: [undefined, [Validators.required, Validators.maxLength(255)]],
            order: [undefined]
        });

        this.subscribers.push(this.pageForm.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        }));
        this.subscribers.push(this.pageForm.get('betDescription').valueChanges.subscribe((data) => {
            if (this.betOutcomes) {
                const selected = this.betOutcomes.find(x => x.value === data);
                if (selected) {
                    this.pageForm.patchValue({
                        betId: selected.id
                    });
                    this.outcomes = selected.children;
                } else {
                    this.pageForm.patchValue({
                        betId: undefined
                    });
                    this.outcomes = [];
                }
            } else {
                this.pageForm.patchValue({
                    betId: undefined
                });
                this.outcomes = [];
            }
        }));
        this.filteredBets = this.pageForm.get('betDescription').valueChanges.pipe(
            startWith(''),
            map(value => this.filteredBet(value))
        );
    }


    save() {
        if (this.pageForm.valid) {
            const value = this.pageForm.getRawValue();
            this.dialogRef.close({
                item: <OutcomeModel>{
                    id: undefined,
                    betId: +value.betId,
                    betDescription: value.betDescription,
                    outcomeId: +value.outcomeId,
                    outcomeDescription: this.betOutcomes.find(x => x.id === value.betId).children.find(x => x.id === value.outcomeId).value,
                    order: value.order
                }
            });
        }
    }

    close() {
        this.dialogRef.close();
    }

    private filteredBet(value: string): TreeItem[] {
        if (!this.betOutcomes || !value) return [];
        const filterValue = value.toLowerCase();
        return this.betOutcomes.filter(option => option.value.toLowerCase().indexOf(filterValue) >= 0);
    }

    private onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};
            const control = this.pageForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
                console.log(field, 'not valid');
            }
        }
    }
}
