import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AppConfigService } from "src/app/shared/appconfig.service";
import { ApisService } from "src/app/shared/apis.service";
import { NotifyService } from "src/app/shared/notify.service";
import { FuseConfigService } from "./core/services/config.service";
import { FuseTranslationLoaderService } from "./core/services/translation-loader.service";
import { locale as english } from "./i18n/en-US";
import { locale as italian } from "./i18n/it-IT";
import { LocalStorageService } from "./shared/local-storage.service";
import { Location } from "@angular/common";
import { AppInitializerService } from "./core/services/app-initializer.service";
import { FuseSplashScreenService } from "./core/services/splash-screen.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "fuse-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  loaded = false;
  private subscribers: Subscription[] = new Array<Subscription>();

  constructor(
    private translate: TranslateService,
    private apisService: ApisService,
    private fuseConfig: FuseConfigService,
    @Inject(AppConfigService) private appConfig: AppConfigService,
    @Inject(NotifyService) private notifyService: NotifyService,
    private translationLoader: FuseTranslationLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private appInitializerService: AppInitializerService,
    private location: Location,
    private fuseSplashScreenService: FuseSplashScreenService,
    private storageService: LocalStorageService
  ) {
    this.translationLoader.loadTranslations(english, italian);
  }

  ngOnInit() {
    this.appInitializerService.token = this.storageService.getItem(environment.storageKeys.token);

    if (!this.appInitializerService.token) {
      this.route.queryParams.subscribe((params) => {
        if (params.token) {
          let token = params['token'];

          this.appInitializerService.token = token;
          this.storageService.setItem(
            environment.storageKeys.token,
            token
          );

          this.location.replaceState("cms/pages");
        }
      })
    }
    else this.location.replaceState("cms/pages");

    this.subscribers.push(
      this.apisService.getLanguages().subscribe((result) => {
        this.notifyService.handleServerResponse(
          result,
          (res) => {
            this.fuseConfig.settings.languages = [
              {
                code: "en-US",
                id: "52b88d21-d84b-45d0-97f4-4ca3ae2cca83",
                isSelected: true,
                name: "English",
              },
            ];
            const loadLang = this.fuseConfig.settings.languages.map(
              (x) => x.code
            );
            this.translate.addLangs(loadLang);
            const selected = this.fuseConfig.settings.languages.find(
              (x) => x.isSelected === true
            );
            if (selected !== undefined) {
              this.fuseConfig.settings.selectedLanguage = selected;
            } else {
              this.fuseConfig.settings.selectedLanguage =
                this.fuseConfig.settings.languages[0];
            }
            this.translate.setDefaultLang(
              this.fuseConfig.settings.selectedLanguage.code
            );
            this.translate.use(this.fuseConfig.settings.selectedLanguage.code);
            this.clean();
            this.loaded = true;
          },
          (e) => {
            this.translate.setDefaultLang("it-IT");
            this.translate.use("it-IT");
            this.clean();
            this.loaded = true;
          }
        );
        this.fuseSplashScreenService.hide();
      })
    );
    this.appConfig.onSearchRequest.subscribe((r) => this.navigateToSearch(r));
  }

  ngOnDestroy(): void {
    this.subscribers.forEach((t) => t.unsubscribe());
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.appConfig.setBackNavigation(true);
  }

  private clean() {
    this.subscribers.forEach((t) => t.unsubscribe());
  }

  private navigateToSearch(input: string): void {
    // let dialogRef = this.dialog.open(SearchComponent, {
    //     width: '70%',
    //     data: {
    //       input: input
    //     },
    //     height: '60%'
    //   });
    if (input !== undefined) {
      this.router.navigate(["/cms/search", input]);
    }
  }
}
