import { EventEmitter } from '@angular/core';
import { DataBaseRequest } from './DataBaseRequest';
import { AdmiralPlatform } from '../../DataModels';

export class ModelRequest<T extends DataBaseRequest>  {

  public columns = 2;
  public items = new Array<DataBaseRequestField>();
  public showAddItem = false;
  public showMoveItem = false;
  public showReset = true;
  public showSearch = true;
  public commands: FilterCommand[];
  public changed: EventEmitter<T> = new EventEmitter();
  public resetChaged: EventEmitter<boolean> = new EventEmitter();
  public showAddItemChanged: EventEmitter<boolean> = new EventEmitter();
  public showMoveItemChanged: EventEmitter<boolean> = new EventEmitter();

  public static getKeyType(requestKey: any): string {
    return requestKey instanceof Date ? 'Date' : typeof requestKey;
  }

  constructor(public request: T) {
  }

  public updateShowAdd(value: boolean): void {
    if (this.showAddItem === value) { return; }
    this.showAddItem = value;
    this.showAddItemChanged.next(value);
  }

  public updateShowMove(value: boolean): void {
    if (this.showMoveItem === value) { return; }
    this.showMoveItem = value;
    this.showMoveItemChanged.next(value);
  }

  public reset(): void {
    this.request.reset();
    this.resetChaged.emit();
    this.changed.emit();
  }
}

export class FilterCommand {

  label: string;
  tooltip: string;
  icon: string;
  click: EventEmitter<any> = new EventEmitter();
  constructor(label?: string, tooltip?: string, icon?: string) {
    this.label = label;
    this.tooltip = tooltip;
    this.icon = icon;
  }

}


export class DataBaseRequestField {

  excluded = false;
  placeholder: string;
  property: string;
  propertyType: string;
  selectItems: SelectItem[];
  constructor(property?: string, placeholder?: string, propertyType?: string, excluded: boolean = false, selectItems?: SelectItem[]) {
    this.excluded = excluded;
    this.property = property;
    this.propertyType = propertyType;
    this.placeholder = placeholder;
    this.selectItems = selectItems;
  }
}

export class SelectItem {
  id: string;
  value: string;
}

export class CheckItem extends SelectItem {
  selected: boolean;
}

export class TreeItem {
  id: string;
  value: string;
  children: TreeItem[];
}

export class PlatformModel extends CheckItem {
  platform: AdmiralPlatform;
}

export class MediaFolderModel extends CheckItem {
  parentId?: string;
  expanded: boolean;
  children?: MediaFolderModel[];
}
