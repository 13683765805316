<div class="layout-editor" *ngIf="row.isEdit" [ngStyle]="{'flex': '0 0 ' + row.width + '%'}">
    <div>
        <label>Row</label>
        <button mat-button class="mat-icon-button" (click)="addRow()" value="Add Row" matTooltip="{{'LAYOUT.ADD_ROW' | translate}}">
            <mat-icon>add_box</mat-icon>
        </button>
        <button mat-button class="mat-icon-button" (click)="delete()" value="Delete Row" matTooltip="{{'LAYOUT.DELETE' | translate}}">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-button class="mat-icon-button" (click)="reset()" value="Reset" matTooltip="{{'LAYOUT.REFRESH_CELLS' | translate}}">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>
<div class="layout-row">
    <app-layoutcell [cells]="row.cells" [ngStyle]="{'flex': '0 0 ' + cell.width + '%'}" [cell]="cell" *ngFor="let cell of row.cells"></app-layoutcell>
</div>