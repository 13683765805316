<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
    <generic-search-filter [requestModel]="model" [panelTitle]="panelTitle" [panelIcon]="'image'" (onSubmit)="onSubmit()" (onAddItem)="edit()"
        (onMoveItem)="onMove()">
    </generic-search-filter>

    <div class="custom-center">
        <div class="content-card">
            <div *ngIf="dataSource?.isEmpty || dataSource?.isError">
                <div class="example-rate-limit-reached table-error-message no-mat-black" *ngIf="dataSource?.isEmpty">
                    <span>{{'COMMON.NO_ITEMS' | translate}}</span>
                </div>
                <div class="example-rate-limit-reached table-error-message no-mat-black" *ngIf="dataSource?.isError">
                    <span>{{'COMMON.ERROR_LOADING' | translate}}</span>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start stretch">
                <div fxFlex="30" [ngClass]="{ 'no-mat-white-bg': true }">
                    <app-mediafolder #mediaFolder (currentChanged)="folderChanged($event)"></app-mediafolder>
                </div>
                <div fxFlex="70" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }" class="table-box">
                    <mat-table [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [dataSource]="dataSource" class="table-items mat-table"
                        matSort matSortActive="name" matSortDisableClear matSortDirection="asc" fuseperfectscrollbar>

                        <ng-container matColumnDef="select">
                            <mat-header-cell *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? addItem(row) : null" [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="link">
                            <mat-header-cell *matHeaderCellDef>{{'MEDIA.THUMNBNAIL_COLUMN' | translate}} </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <img class="table-image-column" [src]="getImageLink(row)" *ngIf="!row.isDocument">
                                <span *ngIf="row.isDocument">{{row.extension}}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MEDIA.NAME_COLUMN' | translate}}</mat-header-cell>
                            <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="extension">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MEDIA.EXT_COLUMN' | translate}}</mat-header-cell>
                            <mat-cell *matCellDef="let row">{{ row.extension }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="frontend">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MEDIA.FRONTEND_COLUMN' | translate}}</mat-header-cell>
                            <mat-cell *matCellDef="let row">{{ row.frontend }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="description">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MEDIA.DESCRIPTION_COLUMN' | translate}}</mat-header-cell>
                            <mat-cell *matCellDef="let row">{{ row.description }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="width">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MEDIA.WIDTH_COLUMN' | translate}}</mat-header-cell>
                            <mat-cell *matCellDef="let row">{{ row.width }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="height">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MEDIA.HEIGHT_COLUMN' | translate}}</mat-header-cell>
                            <mat-cell *matCellDef="let row">{{ row.height }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="changedAt">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMMON.CHANGED_COLUMN' | translate }} </mat-header-cell>
                            <mat-cell *matCellDef="let row">{{row.changedFormated}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="changedBy">
                            <mat-header-cell *matHeaderCellDef>{{'COMMON.CHANGEDBY_COLUMN' | translate }} </mat-header-cell>
                            <mat-cell *matCellDef="let row">{{row.changedBy}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef>
                                <span>{{'MEDIA.ACTION' | translate}}</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <div fxLayoutAlign="end">
                                    <button mat-icon-button *ngIf="userAccess.canEdit" class="icon-media" (click)="edit(row, $event)" name="editButton" matTooltip="{{'MEDIA.EDIT' | translate}}">
                                        <mat-icon class="edit">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="userAccess.canDelete" class="icon-media" (click)="delete(row, $event)" name="deleteButton"
                                        matTooltip="{{'COMMON.DELETE' | translate}}">
                                        <mat-icon class="delete-icon">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="userAccess.canEdit" class="icon-media" (click)="move(row, $event)" name="deleteButton" matTooltip="{{'COMMON.MOVE' | translate}}">
                                        <svg viewBox="0 0 24 24">
                                            <path d="M14,18V15H10V11H14V8L19,13M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)" class="hover-effect"></mat-row>

                    </mat-table>
                    <div class="media-loading-shade" *ngIf="dataSource?.isLoadingResults">
                        <mat-spinner [color]="'accent'"></mat-spinner>
                    </div>
                    <mat-paginator [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [length]="dataSource?.resultsLength"
                        [pageSize]="dataSource?.pageSize">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
