<div name="detail" [formGroup]="detailForm" fxLayout="column">
    <div class="description-title-host" fxFlex="100">
        <span class="description-title">{{'BONUS.BETTING_TITLE' | translate}}</span>
    </div>
    <div fxLayout="row wrap">
        <mat-form-field fxFlex="33.33">
            <input matInput type="number" min="0" step="1" placeholder="{{'BONUS.MINIMMUMEVENTS' | translate}}"
                matTooltip="{{'BONUS.MINIMMUMEVENTS' | translate}}" formControlName="minimumEvents" />
        </mat-form-field>
        <mat-form-field fxFlex="33.33">
            <input matInput type="number" step="0.01" min="0" placeholder="{{'BONUS.MINIMUMODDVALUE' | translate}}"
                matTooltip="{{'BONUS.MINIMUMODDVALUE' | translate}}" formControlName="minimumOddValue" />
        </mat-form-field>
        <mat-form-field fxFlex="33.33">
            <input matInput type="number" min="0" step="0.01" placeholder="{{'BONUS.TOTALODDVALUE' | translate}}"
                matTooltip="{{'BONUS.TOTALODDVALUE' | translate}}" formControlName="totalOddValue" />
        </mat-form-field>

        <mat-form-field fxFlex="25">
            <input matInput type="number" min="0" step="0.01" placeholder="{{'BONUS.MINIMUMAMOUNT' | translate}}"
                matTooltip="{{'BONUS.TOTALODDVALUE' | translate}}" formControlName="minimumAmount" />
        </mat-form-field>
        <mat-form-field fxFlex="25">
            <mat-select matInput placeholder="{{'BONUS.BETTING_BONUSTYPE' | translate}}" matTooltip="{{'BONUS.BETTING_BONUSTYPE' | translate}}"
                formControlName="type">
                <mat-option *ngFor="let bt of bonusTypes" [value]="bt.id">
                    {{ bt.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-slide-toggle matInput formControlName="validWithBonusAmount" fxFlex="25">
            {{'BONUS.BETTING_BONUSAMOUNT' | translate}}</mat-slide-toggle>
        <mat-slide-toggle matInput formControlName="winOnly" fxFlex="25">
            {{'BONUS.BETTING_WINONLY' | translate}}</mat-slide-toggle>

        <div fxFlex="100" fxLayout="row wrap">
            <mat-toolbar>
                <span>{{'BONUS.BETTING_SPORTS' | translate}}</span>
                <span class="spacer"></span>
                <button mat-button class="mat-icon-button" aria-label="Add" matTooltip="{{'BONUS.ADDSPORT' | translate}}"
                    (click)="addSport()" *ngIf="canEdit">
                    <mat-icon>add_to_photos</mat-icon>
                </button>
                <button mat-button class="mat-icon-button" aria-label="Add" matTooltip="{{'BONUS.EXCLUDESPORT' | translate}}"
                    (click)="addSport(true)" *ngIf="canEdit">
                    <mat-icon svgIcon="exclude"></mat-icon>
                </button>
            </mat-toolbar>
            <div class="description-title-host" fxFlex="50">
                <span class="description-title">{{'BONUS.INCLUDEDSPORTS' | translate}}</span>
            </div>
            <div class="description-title-host" fxFlex="50">
                <span class="description-title">{{'BONUS.EXCLUDEDSPORTS' | translate}}</span>
            </div>

            <div formArrayName="includedSports" fxFlex="50" fxLayout="row wrap">
                <div *ngIf="includedSports.length === 0">
                    <span>{{'COMMON.NO_ITEMS' | translate}}</span>
                </div>
                <div *ngFor="let item of includedSports.controls">
                    <button mat-button aria-label="Delete" (click)="removeItem(item, includedSports)" *ngIf="canEdit">
                        <span>{{item.value.value}}</span>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <span *ngIf="!canEdit">{{item.value.value}}</span>
                </div>
            </div>

            <div formArrayName="excludedSports" fxFlex="50" fxLayout="row wrap">
                <div *ngIf="excludedSports.length === 0">
                    <span>{{'COMMON.NO_ITEMS' | translate}}</span>
                </div>
                <div *ngFor="let item of excludedSports.controls">
                    <button mat-button aria-label="Delete" (click)="removeItem(item, excludedSports)" *ngIf="canEdit">
                        <span>{{item.value.value}}</span>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <span *ngIf="!canEdit">{{item.value.value}}</span>
                </div>
            </div>
        </div>

        <div fxFlex="100" fxLayout="row wrap" *ngIf="includedSports.length > 0 || excludedSports.length > 0">
            <mat-toolbar>
                <span>{{'BONUS.BETTING_TOURNAMENTS' | translate}}</span>
                <span class="spacer"></span>
                <button mat-button class="mat-icon-button" aria-label="Add" matTooltip="{{'BONUS.ADDTOURNAMENT' | translate}}"
                    (click)="addTournament()" *ngIf="canEdit">
                    <mat-icon>add_to_photos</mat-icon>
                </button>
                <button mat-button class="mat-icon-button" aria-label="Add" matTooltip="{{'BONUS.EXCLUDETOURNAMENT' | translate}}"
                    (click)="addTournament(true)" *ngIf="canEdit">
                    <mat-icon svgIcon="exclude"></mat-icon>
                </button>
            </mat-toolbar>

            <div class="description-title-host" fxFlex="50">
                <span class="description-title">{{'BONUS.INCLUDEDTOURMANETS' | translate}}</span>
            </div>
            <div class="description-title-host" fxFlex="50">
                <span class="description-title">{{'BONUS.EXCLUDEDTOURNAMENTS' | translate}}</span>
            </div>
            <div formArrayName="includedTournaments" fxFlex="50" fxLayout="row wrap">
                <div *ngIf="includedTournaments.length === 0">
                    <span>{{'COMMON.NO_ITEMS' | translate}}</span>
                </div>
                <div *ngFor="let item of includedTournaments.controls">
                    <button mat-button aria-label="Delete" (click)="removeItem(item, includedTournaments)" *ngIf="canEdit">
                        <span>{{item.value.value}}</span>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <span *ngIf="!canEdit">{{item.value.value}}</span>
                </div>
            </div>
            <div formArrayName="excludedTournaments" fxFlex="50" fxLayout="row wrap">
                <div *ngIf="excludedTournaments.length === 0">
                    <span>{{'COMMON.NO_ITEMS' | translate}}</span>
                </div>
                <div *ngFor="let item of excludedTournaments.controls">
                    <button mat-button aria-label="Delete" (click)="removeItem(item, excludedTournaments)" *ngIf="canEdit">
                        <span>{{item.value.value}}</span>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <span *ngIf="!canEdit">{{item.value.value}}</span>
                </div>
            </div>
        </div>

        <div fxFlex="100" fxLayout="row wrap" *ngIf="includedTournaments.length || excludedTournaments.length > 0">
            <mat-toolbar>
                <span>{{'BONUS.BETTING_EVENTS' | translate}}</span>
                <span class="spacer"></span>
                <button mat-button class="mat-icon-button" aria-label="Add" matTooltip="{{'BONUS.ADDEVENT' | translate}}"
                    (click)="addEvent()" *ngIf="canEdit">
                    <mat-icon>add_to_photos</mat-icon>
                </button>
                <button mat-button class="mat-icon-button" aria-label="Add" matTooltip="{{'BONUS.EXCLUDEEVENT' | translate}}"
                    (click)="addEvent(true)" *ngIf="canEdit">
                    <mat-icon svgIcon="exclude"></mat-icon>
                </button>
            </mat-toolbar>
            <div class="description-title-host" fxFlex="50">
                <span class="description-title">{{'BONUS.INCLUDEDEVENTS' | translate}}</span>
            </div>
            <div class="description-title-host" fxFlex="50">
                <span class="description-title">{{'BONUS.EXCLUDEDEVENTS' | translate}}</span>
            </div>
            <div formArrayName="includedEvents" fxFlex="50" fxLayout="row wrap">
                <div *ngIf="includedEvents.length === 0" fxFlex="50">
                    <span>{{'COMMON.NO_ITEMS' | translate}}</span>
                </div>
                <div *ngFor="let item of includedEvents.controls">
                    <button mat-button aria-label="Delete" (click)="removeItem(item, includedEvents)" *ngIf="canEdit">
                        <span>{{item.value.value}}</span>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <span *ngIf="!canEdit">{{item.value.value}}</span>
                </div>
            </div>
            <div formArrayName="excludedEvents" fxFlex="50" fxLayout="row wrap">
                <div *ngIf="excludedEvents.length === 0" fxFlex="50">
                    <span>{{'COMMON.NO_ITEMS' | translate}}</span>
                </div>
                <div *ngFor="let item of excludedEvents.controls">
                    <button mat-button aria-label="Delete" (click)="removeItem(item, excludedEvents)" *ngIf="canEdit">
                        <span>{{item.value.value}}</span>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <span *ngIf="!canEdit">{{item.value.value}}</span>
                </div>
            </div>
        </div>
    </div>
</div>