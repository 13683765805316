import {Component, Inject, OnInit} from '@angular/core';
import {DynamicComponent} from '../../layout/layoutcontent.component';
import {MatDialog} from '@angular/material/dialog';
import {WidgetSelectorDialogComponent} from '../widgetselectordialog/widgetselectordialog.component';
import {NotifyService} from 'src/app/shared/notify.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-widgetselector',
    templateUrl: './widgetselector.component.html',
    styleUrls: ['./widgetselector.component.scss']
})

export class WidgetSelectorComponent extends DynamicComponent implements OnInit {

    widgetTypes = [
        // {
        //   id: 'Text',
        //   name: 'Text'
        // },
        {
            id: 'Banner',
            name: 'Banner'
        },
        {
            id: 'Slider',
            name: 'Slider'
        },
        {
            id: 'Html',
            name: 'Html'
        },
        {
            id: 'Dynamic',
            name: 'Dynamic'
        }
    ];

    private _loaded: boolean = false;
    private _isDirty: boolean;

    public isDirty() {
        return this._isDirty;
    }


    constructor(
        private dialog: MatDialog,
        @Inject(NotifyService) private notifyService,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this._loaded = true;
    }

    selectWidget() {
        if (this.context.widgetType === undefined) {
            this.notifyService.error('PAGE.SELECT_WIDGET_TYPE');
            return;
        }

        let dialogRef = this.dialog.open(WidgetSelectorDialogComponent, {
            width: '80%',
            data: {
                type: this.context.widgetType,
                frontend: this.context.frontend
            },
            height: '80%'
        });

        dialogRef.afterClosed().subscribe(close => {
            if (close && close.result) {
                if (this.context.WidgetId !== close.widgetId) {
                    this.context.widgetId = close.widgetId;
                    this.context.widgetName = close.widgetName;
                    this.onValueChanged();
                }
            }
        });
    }

    selectAuthWidget() {
        if (this.context.widgetType === undefined) {
            this.notifyService.error('PAGE.SELECT_WIDGET_TYPE');
            return;
        }

        let dialogRef = this.dialog.open(WidgetSelectorDialogComponent, {
            width: '80%',
            data: {
                type: this.context.widgetType,
                frontend: this.context.frontend
            },
            height: '80%'
        });

        dialogRef.afterClosed().subscribe(close => {
            if (close && close.result) {
                if (this.context.authWidgetId !== close.widgetId) {
                    this.context.authWidgetId = close.widgetId;
                    this.context.authWidgetName = close.widgetName;
                    this.onValueChanged();
                }
            }
        });
    }

    onWidgetTypeChanged() {
        this.context.widgetId = undefined;
        this.context.widgetName = undefined;
        this.context.authWidgetId = undefined;
        this.context.authWidgetName = undefined;
        this.onValueChanged();
    }

    onValueChanged() {
        if (this._loaded) {
            if (this.context.changed) {
                this.context.changed(this);
            }
        }
    }

    onColorChanged() {
        this.onValueChanged();
    }

    editAnonymousWidget() {
        if (this.context.widgetId !== undefined && this.context.widgetId !== null && this.context.widgetId !== '') {
            this.router.navigate(['/cms/widgets/widget', this.context.widgetId]);
        }
    }

    editAuthWidget() {
        if (this.context.authWidgetId !== undefined && this.context.authWidgetId !== null && this.context.authWidgetId !== '') {
            this.router.navigate(['/cms/widgets/widget', this.context.authWidgetId]);
        }
    }
}
