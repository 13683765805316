<mat-toolbar class="p-0 mat-elevation-z1">
  <mat-progress-bar
    *ngIf="showLoadingBar"
    class="loading-bar"
    color="accent"
    mode="indeterminate"
  ></mat-progress-bar>
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-button
        class="toggle-button-navbar mat-icon-button"
        fuseNavbarVertical="toggleBar"
        fxHide.gt-md
      >
        <mat-icon>menu</mat-icon>
      </button>

      <div class="toolbar-separator" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNav">
        <div class="logo ml-16">
          <a [routerLink]="['']">
            <img
              class="logo-icon"
              src="./assets/images/logos/new-pritech-logo.png"
            />
          </a>
        </div>
      </div>

      <div class="px-8 px-mat-16">
        <div class="logo">
          <a [routerLink]="['']">
            <img
              class="logo-icon-big"
              src="./assets/images/logos/new-pritech-logo.png"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="toolbar-separator"></div>

    <fuse-search-bar (onInput)="search($event)"></fuse-search-bar>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <div class="toolbar-separator"></div>

      <a href="{{ env.boHomeUrl }}" class="home-link">
        <mat-icon class="home-icon">home</mat-icon></a
      >
      <div class="toolbar-separator"></div>

      <div class="toolbar-separator"></div>

      <!-- <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row" fxLayoutAlign="center center">
            <span class="iso text-uppercase">{{ selectedLanguage !== undefined ? selectedLanguage.name : ""}}</span>
        </div>
    </button>

    <mat-menu #languageMenu="matMenu">

        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
            <div fxLayout="row" fxLayoutAlign="start center">
                <span class="iso">{{lang.name}}</span>
            </div>
        </button>

    </mat-menu>

    <div class="toolbar-separator"></div>

    <div class="toolbar-separator"></div> -->

      <div class="toolbar-separator"></div>
    </div>
  </div>
</mat-toolbar>
