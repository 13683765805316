import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { ApisService } from "src/app/shared/apis.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { NotifyService } from "src/app/shared/notify.service";
import { AppConfigService } from "src/app/shared/appconfig.service";
import {
  UserAccess,
  GbetSelectItem,
  GbetSelectItemWithParent,
} from "../../models/DataModels";
import { SaveGameLiveRequest } from "../../models/DataModels/Cms/Request";
import { fuseAnimations } from "../../core/animations";
import { BaseEditorComponent } from "../../components/baseeditor.component";
import { MediaSelectorComponent } from "../../cms/mediaselector/mediaselector.component";
// import { AmazingTimePickerService } from 'amazing-time-picker';

@Component({
  selector: "app-gameeditorlive",
  templateUrl: "./gameeditorlive.component.html",
  styleUrls: ["./gameeditorlive.component.scss"],
  animations: fuseAnimations,
})
export class GameLiveEditorComponent
  extends BaseEditorComponent
  implements OnInit, OnDestroy
{
  id: string;
  formErrors: any;
  isNew = false;
  subscribers: Subscription[];
  isLoading = false;
  userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true,
  };
  providers: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  removedAttributes: Array<number> = new Array<number>();
  imageBackground: string;
  idFornitore: string;
  evolutionProviderId: number;
  extremeProviderId: number;
  isEvolution = false;
  isExtreme = false;
  evolutionTablePiattaforma: GbetSelectItem[] = new Array<GbetSelectItem>();
  extremeTablePiattaforma: GbetSelectItem[] = new Array<GbetSelectItem>();
  extremeGameTypes: Array<GbetSelectItemWithParent> =
    new Array<GbetSelectItemWithParent>();
  evolutionGameTypes: Array<GbetSelectItemWithParent> =
    new Array<GbetSelectItemWithParent>();

  currentTablePiattaforma: GbetSelectItem[];
  currentTableTypes: GbetSelectItem[];

  private imagesPath = [{ key: "imageBackground", value: "background" }];

  constructor(
    protected translateService: TranslateService,
    @Inject(NotifyService) protected notifyService: NotifyService,
    protected dialog: MatDialog,
    @Inject(AppConfigService) private appConfig: AppConfigService,
    private location: Location,
    private apisService: ApisService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder // private atp: AmazingTimePickerService
  ) {
    super();
    this.createForm();
    this.subscribers = new Array<Subscription>();
    this.formErrors = {
      idFornitore: {},
      tableName: {},
      tableId: {},
      tableType: {},
      tableTypeName: {},
      urlSeo: {},
      tablePiattaforma: {},
      tableOpen: {},
      tableClose: {},
      ordine: {},
      isOpen: {},
      isEnabled: {},
      tipoGioco: {},
      codiceAAMS: {},
      tableImg: {},
      fpp: {},
      rpt: {},
      pageId: {},
      lobby: {},
      displayName: {},
      coefficente: {},
      groupGame: {},
    };
  }

  ngOnInit() {
    this.subscribers.push(
      this.route.params.subscribe((params) => {
        this.id = params.id;
        this.idFornitore = params.provider;
        this.isNew = this.id === undefined;
        if (this.id) {
          this.pageForm.controls.idFornitore.disable();
          this.pageForm.controls.tableType.disable();
          this.pageForm.controls.tablePiattaforma.disable();
        } else {
          this.pageForm.controls.idFornitore.enable();
          this.pageForm.controls.tableType.enable();
          this.pageForm.controls.tablePiattaforma.enable();
        }
        this.loadPage(this.id);
      })
    );
  }

  ngOnDestroy() {
    this.subscribers.forEach((t) => t.unsubscribe());
  }

  loadPage(id: string): void {
    this.removedAttributes = new Array<number>();
    this.isLoading = true;
    this.subscribers.push(
      this.apisService
        .getGameLive(id, this.idFornitore)
        .subscribe((response) => {
          if (response.isError) {
            this.isLoading = false;
          }

          this.notifyService.handleServerResponse(response, (result) => {
            // this.userAccess = response.userAccess;
            this.providers = result.providers;
            this.evolutionProviderId = result.evolutionProviderId;
            this.extremeProviderId = result.extremeProviderId;
            this.extremeGameTypes = result.extremeGameTypes;
            this.evolutionGameTypes = result.evolutionGameTypes;
            this.extremeTablePiattaforma = result.extremeTablePiattaforma;
            this.evolutionTablePiattaforma = result.evolutionTablePiattaforma;
            if (result.item) {
              const tableType = result.item.tableType;
              this.pageForm.patchValue(result.item);
              this.updateProviderControls(result.item.idFornitore);
              this.pageForm.patchValue({
                tableType,
              });
              this.imageBackground = this.pageForm.value.tableImg;
            }
            this.pageForm.markAsPristine();
            this.pageForm.markAsUntouched();
            this.isLoading = false;
          });
        })
    );
  }

  save(copy?: boolean) {
    if (!this.userAccess.canEdit) {
      return;
    }
    if (this.pageForm.dirty) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new SaveGameLiveRequest();
        Object.assign(request, this.pageForm.getRawValue());
        request.id = +this.id;
        if (request.idFornitore === this.extremeProviderId) {
          if (request.pageId === undefined) {
            this.notifyService.error("7004");
            this.isLoading = false;
            return;
          }
        } else if (request.idFornitore === this.evolutionProviderId) {
          if (request.coefficente === undefined) {
            this.notifyService.error("7005");
            this.isLoading = false;
            return;
          }
        }
        const provider = request.idFornitore;
        this.subscribers.push(
          this.apisService.saveGameLive(request).subscribe((response) => {
            this.isLoading = false;
            this.notifyService.handleServerResponse(response, (result) => {
              this.pageForm.reset();
              if (this.id === String(result)) {
                this.loadPage(this.id);
              } else {
                this.router.navigate([
                  "/gbet/giochi-live/gioco/",
                  result,
                  provider,
                ]);
              }
            });
          })
        );
      } else {
        this.notifyService.warning("COMMON.INVALIDDATA");
      }
    }
  }

  showMediaSelector() {
    const dialogRef = this.dialog.open(MediaSelectorComponent, {
      width: "100%",
      data: {
        isMultiSelect: false,
        folder: this.getImageFolder("imageBackground"),
      },
      height: "100%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.items && result.items.length > 0) {
        const item = result.items[0];
        this.pageForm.patchValue({
          tableImg: item.url,
        });
        this.imageBackground = item.url;
        this.pageForm.markAsDirty();
      }
    });
  }

  openTimePicker(isOpen: boolean) {
    /*let amazingTimePicker = this.atp.open({
      time: isOpen ? this.pageForm.value.tableOpen : this.pageForm.value.tableClose,
      arrowStyle: {
        background: '#efad1a',
        color: '#fff'
      }
    });
    amazingTimePicker.afterClose().subscribe(time => {
      if (isOpen) {
        this.pageForm.patchValue({
          tableOpen: time
        });
      } else {
        this.pageForm.patchValue({
          tableClose: time
        });
      }
    });*/
  }

  protected internalClose() {
    const data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(["gbet/giochi-live"]);
    }
  }

  private createForm() {
    this.pageForm = this.formBuilder.group({
      displayName: [undefined, [Validators.required, Validators.maxLength(64)]],
      idFornitore: [undefined, [Validators.required]],
      tableName: [undefined, [Validators.required, Validators.maxLength(50)]],
      tableId: [undefined, [Validators.required, Validators.maxLength(50)]],
      tableType: [undefined, [Validators.required, Validators.maxLength(50)]],

      tableVirtual: [undefined],
      tableTypeName: [
        undefined,
        [Validators.required, Validators.maxLength(50)],
      ],
      urlSeo: [undefined, [Validators.required, Validators.maxLength(50)]],
      tablePiattaforma: [
        undefined,
        [Validators.required, Validators.maxLength(10)],
      ],
      tableOpen: [undefined],
      tableClose: [undefined],
      ordine: [undefined, [Validators.required]],
      isOpen: [true, [Validators.required]],
      isEnabled: [true, [Validators.required]],
      tipoGioco: [undefined, [Validators.required, Validators.maxLength(10)]],
      codiceAAMS: [undefined, [Validators.required, Validators.maxLength(10)]],
      tableImg: [undefined, [Validators.required, Validators.maxLength(255)]],
      fpp: [undefined, [Validators.required]],
      rpt: [undefined, [Validators.required]],
      pageId: [undefined, [Validators.required, Validators.maxLength(50)]],
      // lobby: [undefined, [Validators.required, Validators.maxLength(125)]],
      lobby: [],
      groupGame: [undefined, [Validators.required, Validators.maxLength(125)]],
      coefficente: [undefined],
      tagKeywords: [],
      tagDescription: [],
      tagTitle: [],
      isForeground: [],
      goLiveDate: [],
      isIta: [false, [Validators.required]],
    });
    this.pageForm.get("idFornitore").valueChanges.subscribe((val) => {
      this.updateProviderControls(val);
    });

    this.pageForm.get("tablePiattaforma").valueChanges.subscribe((val) => {
      this.updateTableTypes(val);
    });

    this.subscribers.push(
      this.pageForm.valueChanges.subscribe((data) => {
        this.onFormValuesChanged(data);
      })
    );
  }

  private onFormValuesChanged(data) {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  private updateProviderControls(idFornitore: number): void {
    this.isEvolution = idFornitore === this.evolutionProviderId;
    this.isExtreme = idFornitore === this.extremeProviderId;
    if (this.isEvolution) {
      this.pageForm.get("coefficente").enable();
      this.pageForm.get("pageId").disable();
      this.currentTablePiattaforma = this.evolutionTablePiattaforma;

      if (this.evolutionGameTypes) {
        this.currentTableTypes = this.evolutionGameTypes.filter(
          (x) => x.parentId === this.pageForm.getRawValue().tablePiattaforma
        );
      }
      if (!this.isLoading) {
        this.pageForm.patchValue({
          tablePiattaforma: undefined,
          tableTypeName: undefined,
          pageId: undefined,
        });
      }
    } else {
      this.pageForm.get("coefficente").disable();
      this.pageForm.get("pageId").enable();
      this.currentTablePiattaforma = this.extremeTablePiattaforma;
      if (this.extremeGameTypes) {
        this.currentTableTypes = this.extremeGameTypes.filter(
          (x) => x.parentId === this.pageForm.getRawValue().tablePiattaforma
        );
      }
      if (!this.isLoading) {
        this.pageForm.patchValue({
          tablePiattaforma: undefined,
          tableTypeName: undefined,
          coefficente: undefined,
        });
      }
    }
  }

  private updateTableTypes(tablePiattaforma: string): void {
    if (tablePiattaforma === undefined) {
      this.currentTableTypes = new Array<GbetSelectItemWithParent>();
    } else if (this.isEvolution) {
      this.currentTableTypes = this.evolutionGameTypes.filter(
        (x) => x.parentId === tablePiattaforma
      );
    } else if (this.isExtreme) {
      this.currentTableTypes = this.extremeGameTypes.filter(
        (x) => x.parentId === tablePiattaforma
      );
    }
  }

  private getImageFolder(imagePath: string): string {
    let result = "games";
    if (!imagePath) {
      return result;
    }
    const provider = this.pageForm.getRawValue().idFornitore;
    if (provider) {
      const selected = this.providers.find((x) => x.id === provider);
      if (selected) {
        result += "/" + selected.value;
      } else {
        result += "/" + provider;
      }
    }
    const path = this.imagesPath.find((x) => x.key === imagePath);
    result += "/" + path.value.toLowerCase();
    return result.toLowerCase();
  }
}
