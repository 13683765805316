<div *ngIf="!cell.isEdit">
    <div class="layout-panel-content" *ngIf="!cell.isContainer">
        <div class="layout-cell-content">
            <layout-cell-content *ngIf="cell.content" [type]="cell.content.type" [context]="cell.content.context"></layout-cell-content>
            <div *ngIf="cell.isLocked" class="layout-panel-locked">
                <p>{{cell.id}}</p>
                <mat-icon>lock</mat-icon>
            </div>
        </div>
    </div>
    <div class="layout-container-horizontal" *ngIf="cell.isContainer">
        <div [ngStyle]="{'flex': '0 0 ' + column.width + '%'}" *ngFor="let column of cell.columns">
            <app-layoutcolumn [column]="column"></app-layoutcolumn>
        </div>
    </div>
</div>

<div class="layout-editor" *ngIf="cell.isEdit">
    <div>
        <label>Cell</label>
        <button mat-button class="mat-icon-button" (click)="addCell()" value="Add cell" matTooltip="{{'LAYOUT.ADD_CELL' | translate}}">
            <mat-icon>border_all</mat-icon>
        </button>
        <button mat-button class="mat-icon-button" (click)="delete()" value="delete cell" matTooltip="{{'LAYOUT.DELETE' | translate}}">
            <mat-icon>delete</mat-icon>
        </button>
        <mat-form-field fxFlex="50" *ngIf="cell.isLocked">
            <input matInput placeholder="LAYOUT.ID" [(ngModel)]="cell.id">
        </mat-form-field>
        <mat-form-field fxFlex="50">
            <input class="layout-width" matInput placeholder="WIDTH" [(ngModel)]="cell.width">
            <label>%</label>
        </mat-form-field>
    </div>
    <div class="row" fxLayoutAlign="start center" fxFlex="100">
        <mat-checkbox [(ngModel)]="cell.isLocked" fxFlex="50">Locked</mat-checkbox>
    </div>
    <div class="layout-container-horizontal" *ngIf="cell.isContainer">
        <div [ngStyle]="{'flex': '0 0 ' + column.width + '%'}" *ngFor="let column of cell.columns">
            <app-layoutcolumn [column]="column"></app-layoutcolumn>
        </div>
    </div>
</div>