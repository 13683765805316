import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ApisService} from 'src/app/shared/apis.service';
import {GetContentsRequest} from '../../models/DataModels/Cms/Request';
import {ContentsModel, GetItemsBaseResponse} from '../../models/DataModels';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {DataBaseRequestField, ModelRequest} from '../../models/DataModels/TableDataSource/ModelRequest';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from 'src/app/shared/notify.service';
import {ContentsHttpDao} from './contents.component';

@Component({
    selector: 'app-contentselector',
    templateUrl: './contentselectordialog.component.html',
    styleUrls: ['./contentselectordialog.component.scss']
})

export class ContentSelectorDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    dataSource: TableDataSource<GetContentsRequest, GetItemsBaseResponse<ContentsModel>>;
    displayedColumns = ['name'];
    model: ModelRequest<GetContentsRequest>;
    panelTitle = 'CONTENTS.SELECTOR_TITLE';
    protected request = new GetContentsRequest();

    private httpDao: ContentsHttpDao;

    constructor(
        @Inject(NotifyService) private notifyService,
        @Inject(MAT_DIALOG_DATA) public input: any,
        public dialogRef: MatDialogRef<ContentSelectorDialogComponent>,
        private apisService: ApisService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router
    ) {

    }

    ngOnInit() {
        this.httpDao = new ContentsHttpDao(this.apisService);
        this.model = new ModelRequest<GetContentsRequest>(this.request);
        this.model.columns = 2;
        this.model.showAddItem = true;

        const tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(
            new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string')
        );
        this.model.items = tmpItems;
    }

    ngAfterViewInit() {
        this.dataSource = new TableDataSource<GetContentsRequest, GetItemsBaseResponse<ContentsModel>>(this.httpDao!, this.paginator, this.sort, this.model);
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    ngOnDestroy() {
        this.dataSource.disconnect();
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    select(item: ContentsModel) {
        this.dialogRef.close({
            result: true,
            widgetId: item.id,
            widgetName: item.name
        });
    }
}
