import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ApisService} from 'src/app/shared/apis.service';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from 'src/app/shared/notify.service';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {BaseEditorComponent} from '../../components/baseeditor.component';
import {GbetSelectItem, UserAccess} from '../../models/DataModels';
import {SavePromoRequest} from '../../models/DataModels/Cms/Request';
import {fuseAnimations} from '../../core/animations';
import {SelectItem} from '../../models/DataModels/TableDataSource/ModelRequest';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-promoeditor',
    templateUrl: './promoeditor.component.html',
    styleUrls: ['./promoeditor.component.scss'],
    animations: fuseAnimations
})

export class PromoEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
    isLoading = false;
    formErrors: any;
    isNew: boolean = false;
    icon: string;
    icons: SelectItem[];
    subscribers: Subscription[];

    rulesForm: FormArray;
    userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };


    pointCodes: Array<GbetSelectItem> = new Array<GbetSelectItem>();
    bonus: Array<GbetSelectItem> = new Array<GbetSelectItem>();
    filteredOptions: Observable<GbetSelectItem[]>;
    filteredBonus: Observable<GbetSelectItem[]>;

    isPublished: boolean = false;

    constructor(protected translateService: TranslateService,
                @Inject(NotifyService) protected notifyService,
                @Inject(AppConfigService) private appConfig,
                protected dialog: MatDialog,
                private location: Location,
                private apisService: ApisService,
                private route: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder
    ) {
        super();
        this.icons = new Array<SelectItem>();
        this.subscribers = new Array<Subscription>();

        this.formErrors = {
            promoCode: {},
            startDate: {},
            maxNumberInsert: {},
            insertCounter: {}
        };

    }

    ngOnInit() {
        this.createForm();
        this.subscribers.push(this.route.params.subscribe(params => {

            this.id = params.id;
            this.isNew = this.id === undefined;

            this.loadPage(this.id);
        }));

        this.filteredOptions = this.pageForm.get('pointCodeDescr')!.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterGroup(value))
            );

        this.filteredBonus = this.pageForm.get('bonus')!.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterBonus(value))
            );
    }

    ngOnDestroy() {
        this.subscribers.forEach(t => t.unsubscribe());
    }

    loadPage(id: string): void {

        this.isLoading = true;


        this.userAccess.canEdit = true;
        this.subscribers.push(this.apisService.getPromo(id).subscribe(response => {
            if (response.isError) {
                this.isLoading = false;
            }
            this.notifyService.handleServerResponse(response, result => {
                // this.userAccess = response.userAccess;

                if (this.isNew) {
                    result.item.startDate = new Date();
                } else {
                    if (result.item) {
                        if (result.item.startDate) {
                            result.item.startDate = new Date(result.item.startDate);
                        } else {
                            result.item.startDate = new Date();
                        }
                        if (result.item.endDate) {
                            result.item.endDate = new Date(result.item.endDate);
                        }
                    }
                }


                this.pageForm.patchValue(result.item);

                this.pointCodes = result.pointCodes;
                if (result.item.pointCode !== 0) {
                    let c = this.pointCodes.find(x => x.id === result.item.pointCode);
                    if (c !== undefined)
                        this.pageForm.get('pointCodeDescr').patchValue(c.value);
                }

                this.bonus = result.bonus;
                let b = this.bonus.find(x => x.id === result.item.idBonus);
                if (b !== undefined)
                    this.pageForm.get('bonus').patchValue(b.value);

                this.pageForm.markAsPristine();
                this.pageForm.markAsUntouched();
                this.isLoading = false;


            });
        }));
    }


    save(publish?: boolean, overwrite?: boolean) {
        if (this.pageForm.dirty || this.pageForm.touched) {
            if (!this.pageForm.invalid) {

                const request = new SavePromoRequest();
                Object.assign(request, this.pageForm.getRawValue());
                request.publish = publish;
                request.id = this.id;

                let pc = this.pointCodes.find(x => x.value === this.pageForm.get('pointCodeDescr').value);

                if (pc !== undefined)
                    request.pointCode = pc.id;
                    // else
                //   request.pointCode = 0;
                else {
                    this.notifyService.warning('PROMOTIONSPOINTCODE.POINT_CODE_SECTION_NOT_VALID');
                    return;
                }

                let bb = this.bonus.find(x => x.value === this.pageForm.get('bonus').value);
                if (bb !== undefined)
                    request.idBonus = bb.id;
                else
                    request.idBonus = 0;


                this.subscribers.push(this.apisService.savePromo(request).subscribe(response => {
                    this.isLoading = false;
                    this.handleServerResponse(response, result => {
                        this.pageForm.reset();
                        if (parseInt(this.id, 10) === result) {
                            this.loadPage(this.id);
                        } else {
                            this.router.navigate(['/promotions/promo', result]);
                        }
                    }, publish);
                }));

            } else {
                this.notifyService.warning('COMMON.INVALIDDATA');
            }
        }

    }

    publish() {
        if (!this.userAccess.canEdit) return;
        if (this.pageForm.invalid) {
            this.notifyService.error('COMMON.INVALIDPUBLISH');
        } else {
            this.pageForm.markAsDirty();
            this.save(true);
        }
    }

    enableDateOrTime(action: number) {
        if (action === undefined) return;
        if (action === 0) {
            this.pageForm.controls.enableDate.setValue(false);
        } else {
            this.pageForm.controls.enableTimer.setValue(false);
            this.pageForm.controls.enableStartTimer.setValue(false);
        }
    }


    protected internalClose() {
        let data = this.appConfig.temporaryData;
        if (data && data.internal) {
            this.location.back();
        } else {
            this.router.navigate(['promotions/promo']);
        }
    }

    private createForm() {

        this.pageForm = this.formBuilder.group({

            promoCode: ['', [Validators.required, Validators.maxLength(50)]],
            startDate: [undefined, Validators.required],
            endDate: [],
            attivo: [false],
            multipleCode: [false],
            multipleBonus: [false],
            idBonus: [],
            idDominio: [],
            pointCode: [],
            pointCodeDescr: [undefined, Validators.required],
            systemDate: [],
            maxNumberInsert: [0, [Validators.required, Validators.min(0)]],
            insertCounter: [0, [Validators.required, Validators.min(0)]],
            bonus: []
        });

        this.pageForm.valueChanges.subscribe((data) => {
            this.onFormValuesChanged(data);
        });


    }


    private onFormValuesChanged(data) {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            let difference = this.formErrors[field].difference;
            this.formErrors[field] = {};
            const control = this.pageForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
            if (difference) {
                this.formErrors[field].difference = difference;
            }
        }
    }


    private _filterGroup(value: string): any[] {
        if (value) {
            return this.pointCodes

                .filter(el => el.value.toLowerCase().includes(value.toLowerCase()));
        }

        return this.pointCodes;
    }

    private _filterBonus(value: string): any[] {
        if (value) {
            return this.bonus

                .filter(el => el.value.toLowerCase().includes(value.toLowerCase()));
        }

        return this.bonus;
    }

}
