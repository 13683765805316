/* tslint:disable:typedef */
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  appPrefix = environment.storageKeys.appPrefix;

  constructor() { }

  setItem(key: string, value: any) {
    localStorage.setItem(`${this.appPrefix}-${key}`, JSON.stringify(value));
  }

  getItem(key: string, isArray?: boolean) {
    if (isArray) {
      return JSON.parse(
        localStorage.getItem(`${this.appPrefix}-${key}`) || "[]"
      );
    } else {
      return JSON.parse(
        localStorage.getItem(`${this.appPrefix}-${key}`) || null
      );
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(`${this.appPrefix}-${key}`);
  }
}
