<div class="page-layout carded fullwidth ps">
    <div id="header">
        <div class="header p-16 p-sm-24">
            <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="stretch" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button close" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" *fuseIfOnDom
                                aria-label="Close" matTooltip="{{'COMMON.CLOSE' | translate}}" (click)="close()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                            {{ (isNew ? 'GAME.NEW_TITLE' : 'GAME.EDIT_TITLE') | translate}}
                        </span>
                    </div>
                    <div class="toolbar" fxLayout="row" fxLayoutAlign="start center" *ngIf="userAccess.canEdit">
                        <div class="options-icon isVisible">
                            <button mat-button class="mat-icon-button" [ngClass]="{ 'disabled': pageForm.invalid }" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                [disabled]="pageForm.invalid" (click)="save()" *fuseIfOnDom aria-label="Save" matTooltip="{{'COMMON.SAVE' | translate}}">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-center">
        <div class="content-card">
            <div name="pageForm" [formGroup]="pageForm" fxLayout="column">
                <div class="form-editor-host">
                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.DISPLAYNAME' | translate}}" formControlName="displayName">
                        <mat-error *ngIf="formErrors.displayName.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.displayName.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <mat-select matInput placeholder="{{'GAMELIVE.PROVIDER' | translate}}" matTooltip="{{'GAMELIVE.TOOLTIP_PROVIDER' | translate}}"
                                    formControlName="idFornitore">
                            <mat-option *ngFor="let provider of providers" [value]="provider.id">
                                {{ provider.value }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.idFornitore.invalid">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <mat-select matInput placeholder="{{'GAMELIVE.TABLEPIATTAFORMA' | translate}}" matTooltip="{{'GAMELIVE.TABLEPIATTAFORMA' | translate}}"
                                    formControlName="tablePiattaforma">
                            <mat-option *ngFor="let t of currentTablePiattaforma" [value]="t.id">
                                {{ t.value }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.tablePiattaforma.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.tablePiattaforma.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input type="text" formControlName="tableType"  matInput [matAutocomplete]="auto" placeholder="{{'GAMELIVE.TABLETYPENAME' | translate}}" matTooltip="{{'GAMELIVE.TABLETYPENAME' | translate}}">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let t of currentTableTypes" [value]="t.id">
                                {{ t.value }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="formErrors.tableType.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
 
                        <mat-error *ngIf="formErrors.tableType.maxlength">
                                {{'COMMON.REQUIRED_MAXLEN' | translate}}
                            </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.TABLENAME' | translate}}" formControlName="tableName">
                        <mat-error *ngIf="formErrors.tableName.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.tableName.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.TABLEID' | translate}}" formControlName="tableId">
                        <mat-error *ngIf="formErrors.tableId.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.tableId.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.TABLETYPENAME' | translate}}" formControlName="tableTypeName">
                        <mat-error *ngIf="formErrors.tableTypeName.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.tableTypeName.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.TABLEVIRTUAL' | translate}}" formControlName="tableVirtual">                    
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.URLSEO' | translate}}" formControlName="urlSeo">
                        <mat-error *ngIf="formErrors.urlSeo.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.urlSeo.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.LOBBY' | translate}}" formControlName="lobby">
                        <mat-error *ngIf="formErrors.lobby.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.lobby.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.GROUPGAME' | translate}}" formControlName="groupGame">
                        <mat-error *ngIf="formErrors.groupGame.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.groupGame.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.TAGTITLE' | translate}}" formControlName="tagTitle">
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.TAGTDESCRIPTION' | translate}}" formControlName="tagDescription">
                    </mat-form-field>

                    <mat-form-field fxFlex="100">
                        <input matInput placeholder="{{'GAMELIVE.TAGKEYWORDS' | translate}}" formControlName="tagKeywords">
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <div class="timepicker">
                            <div class="timepicker-value">
                                <input matInput type="time" matTooltip="{{'GAMELIVE.TABLEOPEN' | translate}}" formControlName="tableOpen">
                            </div>
                            <div class="timepicker-button">
                                <button mat-button class="mat-icon-button" (click)="openTimePicker(true)" *fuseIfOnDom aria-label="Open start time picker">
                                    <mat-icon>schedule</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <div class="timepicker">
                            <div class="timepicker-value">
                                <input matInput type="time" matTooltip="{{'GAMELIVE.TABLECOSE' | translate}}" formControlName="tableClose">
                            </div>
                            <div class="timepicker-button">
                                <button mat-button class="mat-icon-button" (click)="openTimePicker(false)" *fuseIfOnDom aria-label="Open start time picker">
                                    <mat-icon>schedule</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input type="number" matInput placeholder="{{'GAMELIVE.ORDER' | translate}}" formControlName="ordine">
                        <mat-error *ngIf="formErrors.ordine.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="{{'GAMELIVE.TIPOGIOCO' | translate}}" formControlName="tipoGioco">
                        <mat-error *ngIf="formErrors.tipoGioco.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.tipoGioco.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <div fxFlex="25" class="mat-form-field">
                        <mat-slide-toggle matInput formControlName="isOpen">{{'GAMELIVE.ISOPEN' | translate}}</mat-slide-toggle>
                    </div>

                    <div fxFlex="25" class="mat-form-field">
                        <mat-slide-toggle matInput formControlName="isEnabled">{{'GAMELIVE.ISENABLED' | translate}}</mat-slide-toggle>
                    </div>

                    <div fxFlex="25" class="mat-form-field">
                        <mat-slide-toggle matInput formControlName="isForeground">{{'GAMELIVE.ISFOREGROUND' | translate}}</mat-slide-toggle>
                    </div>
                    <div fxFlex="25" class="mat-form-field">
                        <mat-slide-toggle matInput formControlName="isIta">{{'GAMELIVE.ISITA' | translate}}</mat-slide-toggle>
                    </div>

                    <mat-form-field fxFlex="33.33">
                        <input matInput placeholder="{{'GAMELIVE.CODICEAAMS' | translate}}" formControlName="codiceAAMS">
                        <mat-error *ngIf="formErrors.codiceAAMS.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.codiceAAMS.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="33.33">
                        <input type="number" matInput placeholder="{{'GAMELIVE.FPP' | translate}}" formControlName="fpp">
                        <mat-error *ngIf="formErrors.fpp.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="33.33">
                        <input type="number" matInput placeholder="{{'GAMELIVE.RPT' | translate}}" formControlName="rpt">
                        <mat-error *ngIf="formErrors.rpt.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="33.33">
                        <input matInput [matDatepicker]="goLiveDatePicker" placeholder="{{'GAMELIVE.GOLIVEDATE' | translate}}"
                        matTooltip="{{'GAMELIVE.GOLIVEDATE' | translate}}" formControlName="goLiveDate" />
                        <mat-datepicker-toggle matSuffix [for]="goLiveDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #goLiveDatePicker></mat-datepicker>

                    </mat-form-field>

                    <mat-form-field fxFlex="33.33" *ngIf="isExtreme">
                        <input matInput placeholder="{{'GAMELIVE.PAGEID' | translate}}" formControlName="pageId">
                        <mat-error *ngIf="formErrors.pageId.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="33.33" *ngIf="isEvolution">
                        <input type="number" matInput placeholder="{{'GAMELIVE.COEFICINTE' | translate}}" formControlName="coefficente">
                        <mat-error *ngIf="formErrors.coefficente.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>



                    <div fxFlex="50" class="game-image-host">
                        <div>
                            <h3 class="description-subtitle" fxFlex="100">{{'GAMELIVE.TABLEIMAGE' | translate}}</h3>
                        </div>
                        <div>
                            <mat-form-field fxFlex="100">
                                <input matInput formControlName="tableImg" placeholder="{{'GAMELIVE.TABLEIMAGE' | translate}}">
                            </mat-form-field>
                        </div>

                        <figure class="game-image-figure" (click)="showMediaSelector()">
                            <img *ngIf="imageBackground" class="game-image" [(src)]="imageBackground">
                            <img *ngIf="!imageBackground" class="game-noimage">
                            <figcaption [ngClass]="{'': imageBackground, 'no-content-box': !imageBackground}">
                                <div class="media-buttons-container">
                                    <button mat-button class="mat-icon-button" *fuseIfOnDom aria-label="Add Media" matTooltip="{{'COMMON.ADD_MEDIA' | translate}}">
                                        <mat-icon>image</mat-icon>
                                    </button>
                                </div>
                            </figcaption>
                        </figure>
                        <mat-error *ngIf="formErrors.tableImg.required">
                            {{'COMMON.REQUIRED_ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formErrors.tableImg.maxlength">
                            {{'COMMON.REQUIRED_MAXLEN' | translate}}
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="media-loading-shade full-height" *ngIf="isLoading">
        <mat-spinner [color]="'accent'"></mat-spinner>
    </div>
</div>