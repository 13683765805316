<div class="generic-gallery-container" [hidden]="dataSource?.isEmpty || dataSource?.isError">
  <mat-grid-list cols="{{ galleryColumns }}" ratio="16:9">
    <mat-grid-tile *ngFor="let image of images" class="grid-image" (click)="addOrRemoveImage(image.id); getImageData(image)" [ngClass]="{ active: contains(image.id), '': !contains(image.id) }">
      <div class="gallery-item" [ngStyle]="{ 'background-image': 'url(' + image.url + ')' }">
        <span>{{ image.name }}</span>
        <div class="selected"></div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-paginator #paginator [pageSize]="dataSource?.pageSize" [length]="dataSource?.resultsLength"></mat-paginator>
</div>
<button *ngIf="gamesForm || categoriesForm || groupsForm" mat-button style="background-color: grey; color: white; margin: 2em" (click)="closeDialog()">
  SUBMIT SELECTION
</button>
<div class="media-loading-shade" *ngIf="dataSource?.isLoadingResults">
  <mat-spinner [color]="'accent'"></mat-spinner>
</div>
<div *ngIf="dataSource?.isEmpty || dataSource?.isError">
  <div class="example-rate-limit-reached align-center" *ngIf="dataSource?.isEmpty">
    <span>{{ "COMMON.NO_ITEMS" | translate }}</span>
  </div>
  <div class="example-rate-limit-reached align-center" *ngIf="dataSource?.isError">
    <span>{{ "COMMON.ERROR_LOADING" | translate }}</span>
  </div>
</div>