<a class="nav-link" *ngIf="item.url" [routerLink]="[item.url]" routerLinkActive="active"
   [routerLinkActiveOptions]="{exact: item.exactMatch || false}" matRipple>
    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
    <span class="nav-link-title">{{item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge"
          [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
</a>

<span class="nav-link" *ngIf="item.function" (click)="item.function()" matRipple>
    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
    <span class="nav-link-title">{{item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge"
          [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
</span>