import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  GetPagesRequest,
  SavePageRequest,
} from "../../models/DataModels/Cms/Request";
import { Observable } from "rxjs";
import { TableDataSource } from "../../models/DataModels/TableDataSource/TableDataSource";
import { ApisService } from "src/app/shared/apis.service";
import { Subscription } from "rxjs";
import { NotifyService } from "src/app/shared/notify.service";
import { TranslateService } from "@ngx-translate/core";
import { AppConfigService } from "src/app/shared/appconfig.service";
import {
  PageModel,
  GetItemsBaseResponse,
  UserAccess,
} from "../../models/DataModels";
import {
  ModelRequest,
  DataBaseRequestField,
  SelectItem,
} from "../../models/DataModels/TableDataSource/ModelRequest";
import { HttpDao } from "../../models/DataModels/TableDataSource/HttpDao";
import { FuseConfirmDialogComponent } from "../../core/components/confirm-dialog/confirm-dialog.component";
import { fuseAnimations } from "../../core/animations";
import { map } from "rxjs/operators";

@Component({
  selector: "pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  animations: fuseAnimations,
})
export class PagesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true,
  };

  public panelTitle = "PAGES.TITLE";
  protected request = new GetPagesRequest();
  public dataSource: TableDataSource<
    GetPagesRequest,
    GetItemsBaseResponse<PageModel>
  >;
  public displayedColumns = [
    "action",
    "name",
    "link",
    "title",
    "skinName",
    "frontendName",
    "layout",
    "published",
    "publishedDate",
    "changedAt",
    "changedBy",
  ];
  public model: ModelRequest<GetPagesRequest>;

  private httpDao: PagesHttpDao;
  private skins = new DataBaseRequestField(
    "skin",
    "COMMON.SEARCH_SKIN_LABEL",
    "select",
    false,
    new Array<SelectItem>()
  );
  private frontends = new DataBaseRequestField(
    "frontend",
    "COMMON.SEARCH_FRONTEND_LABEL",
    "select",
    false,
    new Array<SelectItem>()
  );
  private firstLoad: Subscription;

  constructor(
    @Inject(AppConfigService) private appConfig,
    @Inject(NotifyService) private notifyService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private apisService: ApisService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.httpDao = new PagesHttpDao(this.apisService);
    this.firstLoad = this.httpDao.firstLoad.subscribe(
      (userAccess: UserAccess) => {
        this.request.includeAccess = false;
        // this.userAccess = userAccess;
        this.model.updateShowAdd(this.userAccess.canInsert);
      }
    );
    this.model = new ModelRequest<GetPagesRequest>(this.request);
    this.model.showAddItem = true;

    let tmpItems = new Array<DataBaseRequestField>();
    tmpItems.push(
      new DataBaseRequestField("name", "COMMON.SEARCH_NAME_LABEL", "string"),
      this.skins,
      this.frontends,
      new DataBaseRequestField("title", "COMMON.SEARCH_TITLE_LABEL", "string")
    );
    this.model.items = tmpItems;
    console.log("pages.components -> ngOnInit");
    const loader = this.apisService.getSkinData().subscribe((response) => {
      if (!response.isError) {
        this.skins.selectItems = response.data.skins;
        this.frontends.selectItems = response.data.frontends;
      }
      loader.unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.dataSource = new TableDataSource<
      GetPagesRequest,
      GetItemsBaseResponse<PageModel>
    >(this.httpDao!, this.paginator, this.sort, this.model);
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.dataSource.disconnect();
    if (this.firstLoad) {
      this.firstLoad.unsubscribe();
      this.firstLoad = undefined;
    }
  }

  onSubmit() {
    this.paginator.pageIndex = 0;
    this.model.changed.emit();
  }

  edit(item?: PageModel, event?: any): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.userAccess.canEdit) {
      return;
    }
    this.appConfig.temporaryData = { internal: true };
    if (item !== undefined) {
      this.router.navigate(["page", item.id], {
        relativeTo: this.route,
      });
    }
  }

  add(): void {
    if (!this.userAccess.canInsert) {
      return;
    }
    this.router.navigate(["addpage"], {
      relativeTo: this.route,
    });
  }

  delete(item?: PageModel, event?: any): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.userAccess.canDelete || item === undefined || !item.canDelete) {
      return;
    }
    this.translateService.get("COMMON.DELETEMESSAGE").subscribe((translate) => {
      translate += `${item.name}?`;
      const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        width: "50%",
      });
      dialogRef.componentInstance.confirmMessage = translate;
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm === true) {
          this.dataSource.isLoadingResults = true;
          this.apisService.deletePage(item.id).subscribe((result) => {
            this.dataSource.isLoadingResults = false;
            this.notifyService.handleServerResponse(result, (res) => {
              this.model.reset();
            });
          });
        }
      });
    });
  }

  clone(item: PageModel, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.userAccess.canInsert) {
      return;
    }

    if (item === undefined) {
      return;
    }
    this.dataSource.isLoadingResults = true;
    const request = new SavePageRequest();
    request.id = item.id;
    request.copy = true;
    this.apisService.savePage(request).subscribe((response) => {
      this.dataSource.isLoadingResults = false;
      this.notifyService.handleServerResponse(response, (result) => {
        this.model.reset();
      });
    });
  }
}

export class PagesHttpDao extends HttpDao<
  GetPagesRequest,
  GetItemsBaseResponse<PageModel>
> {
  constructor(public apisService: ApisService) {
    super(apisService);
  }

  public getData(
    request: GetPagesRequest
  ): Observable<GetItemsBaseResponse<PageModel>> {
    return this.apisService.getPages(request).pipe(
      map((res) => {
        if (res.isError) {
          return {
            total: 0,
            items: [],
          } as GetItemsBaseResponse<PageModel>;
        } else {
          if (request.includeAccess && res.data.userAccess) {
            this.firstLoad.next(res.data.userAccess);
          }
          this.itemsChangedAt(res.data);
          return res.data;
        }
      })
    );
  }
}
