import { Component, OnInit, Inject } from '@angular/core';
import { ApisService } from 'src/app/shared/apis.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fuseAnimations } from '../../core/animations';
import { MediaFolderModel } from '../../models/DataModels/TableDataSource/ModelRequest';

@Component({
  selector: 'app-editmediafolder',
  templateUrl: './editmediafolder.component.html',
  styleUrls: ['./editmediafolder.component.scss'],
  animations: fuseAnimations
})

export class EditMediaFolderComponent implements OnInit {
  model: MediaFolderModel;

  constructor(
    private apisService: ApisService,
    @Inject(MAT_DIALOG_DATA) public input: any,
    public dialogRef: MatDialogRef<EditMediaFolderComponent>
  ) {
    this.model = input.model || {} as MediaFolderModel;
  }

  ngOnInit() {

  }

  save() {
    this.dialogRef.close({
      item: this.model
    });
  }
}
