import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApisService } from 'src/app/shared/apis.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from 'src/app/shared/notify.service';
import { AppConfigService } from 'src/app/shared/appconfig.service';
import { UserAccess, SportBetGroupModel, BetGroupModel } from '../../models/DataModels';
import { SaveBettingGroupRequest, SaveBettingSportSettingsRequest } from '../../models/DataModels/Cms/Request';
import { fuseAnimations } from '../../core/animations';
import { BaseEditorComponent } from '../../components/baseeditor.component';
import { SelectItem } from '../../models/DataModels/TableDataSource/ModelRequest';
import { MediaSelectorComponent } from '../../cms/mediaselector/mediaselector.component';
import { SportBetgroupComponent } from './sportbetgroup.component';

@Component({
  selector: 'app-sporteditor',
  templateUrl: './sporteditor.component.html',
  styleUrls: ['./sporteditor.component.scss'],
  animations: fuseAnimations
})

export class SportEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  id: string;
  formErrors: any;
  isNew: boolean = false;
  subscribers: Subscription[];
  isLoading = false;
  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };

  canEdit: boolean = true;
  sports: Array<SelectItem>;
  betGroups: FormArray;

  private iconsFolder = 'betting/icons';
  private removedBetGroups: number[];
  private sportBetGroups: BetGroupModel[];

  constructor(
    protected translateService: TranslateService,
    @Inject(NotifyService) protected notifyService: NotifyService,
    protected dialog: MatDialog,
    @Inject(AppConfigService) private appConfig: AppConfigService,
    private location: Location,
    private apisService: ApisService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    super();
    this.createForm();
    this.subscribers = new Array<Subscription>();
    this.formErrors = {
      id: {},
      description: {},
      sportId: {},
      desktopImage: {},
      mobileImage: {}
    };
  }

  ngOnInit() {
    this.subscribers.push(this.route.params.subscribe(params => {
      this.id = params.id;
      this.isNew = this.id === undefined;
      this.loadPage(this.id);
    }));
  }

  ngOnDestroy() {
    this.subscribers.forEach(t => t.unsubscribe());
  }

  loadPage(id: string): void {
    this.removedBetGroups = [];
    this.sportBetGroups = [];
    this.betGroups.controls.splice(0);
    this.isLoading = true;
    let requestLoader;
    requestLoader = this.apisService.getBettingSport(id).subscribe(response => {
      if (response.isError) {
        this.isLoading = false;
      }
      this.notifyService.handleServerResponse(response, result => {
        // this.userAccess = response.userAccess;
        this.sports = result.sports;
        if (result && result.item) {
          this.sportBetGroups = result.allBetGroups;
          this.pageForm.patchValue(result.item);
          this.pageForm.patchValue({
            sportId: String(result.item.sportId)
          });
          if (result.betGroups) {
            result.betGroups.forEach(o => {
              this.betGroups.push(this.patchLink(o));
            });
          }
          if (result.item && result.item.id && +result.item.id > 0) {
            this.pageForm.get('sportId').disable();
          } else {
            this.pageForm.get('sportId').enable();
          }
          this.pageForm.markAsPristine();
          this.pageForm.markAsUntouched();
        }
        this.isLoading = false;
        requestLoader.unsubscribe();
      });
    });
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
        console.log(field, 'not valid');
      }
    }
  }

  save(copy?: boolean) {
    if (!this.userAccess.canEdit) return;
    if (this.pageForm.dirty) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new SaveBettingSportSettingsRequest();
        Object.assign(request, this.pageForm.getRawValue());
        request.removedGroups = this.removedBetGroups;
        request.id = +this.id;
        let requestLoader;
        requestLoader = this.apisService.saveBettingSport(request).subscribe(response => {
          this.isLoading = false;
          this.notifyService.handleServerResponse(response, result => {
            this.pageForm.reset();
            if (this.id === String(result)) {
              this.loadPage(this.id);
            } else {
              this.router.navigate(['/betting/sports/sport/', result]);
            }
          });
          requestLoader.unsubscribe();
        });
      } else {
        this.notifyService.warning('COMMON.INVALIDDATA');
      }
    }
  }

  showMediaSelector(imageProp: string) {
    if (imageProp === undefined || imageProp === null || this.canEdit === false) return;

    let dialogRef = this.dialog.open(MediaSelectorComponent, {
      width: '100%',
      data: {
        isMultiSelect: false,
        folder: this.iconsFolder
      },
      height: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.items && result.items.length > 0) {
        let item = result.items[0];
        const control = this.pageForm.get(imageProp);
        if (control) {
          control.patchValue(item.url);
          this.pageForm.markAsDirty();
        }
      }
    });
  }

  deletebetGroup(i: number) {
    if (this.userAccess.canEdit && this.betGroups) {
      if (i >= 0 && i < this.betGroups.length) {
        const ctrl = this.betGroups.controls[i];
        if (!ctrl.value.isNew) {
          this.removedBetGroups.push(ctrl.value.groupId);
        }
        this.betGroups.removeAt(i);
        this.pageForm.markAsDirty();
      }
    }
  }

  addBetGroup() {
    if (!this.userAccess.canEdit) {
      return;
    }
    if (!this.pageForm.getRawValue().sportId) {
      this.notifyService.error('NOTIFICATION.9001');
      return;
    }
    const items = this.sportBetGroups.filter(x => this.betGroups.controls.find(y => y.value.groupId === x.id) === undefined);
    if (items.length === 0) {
      this.notifyService.error('NOTIFICATION.10003');
      return;
    }
    let dialogRef = this.dialog.open(SportBetgroupComponent, {
      width: '30%',
      data: {
        items: items
      },
      height: '30%'
    });

    const dialogSub = dialogRef.afterClosed().subscribe(result => {
      if (result && result.item) {
        const item = <SportBetGroupModel>{
          groupId: result.item.groupId,
          description: result.item.description,
          enabled: result.item.enabled,
          isNew: true
        };
        const old = this.betGroups.controls.map(x => x.value.groupId);
        if (old.find(x => x.groupId === item.groupId)) {
          this.notifyService.error('NOTIFICATION.10001');
        } else {
          this.betGroups.push(this.patchLink(item));
          this.pageForm.markAsDirty();
        }
      }
      dialogSub.unsubscribe();
    });
  }


  protected internalClose() {
    let data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(['betting/sports']);
    }
  }


  private createForm() {
    this.betGroups = this.formBuilder.array([]);

    this.pageForm = this.formBuilder.group({
      sportId: [undefined, [Validators.required]],
      desktopImage: [undefined],
      mobileImage: [undefined],
      betGroups: this.betGroups
    });

    this.subscribers.push(this.pageForm.get('sportId').valueChanges.subscribe((sporId) => {
      if (sporId) {
        const selected = this.sports.find(x => x.id === sporId);
        if (selected) {
          this.pageForm.patchValue({
            description: selected.value
          });
        } else {
          this.pageForm.patchValue({
            description: undefined
          });
        }
      } else {
        this.pageForm.patchValue({
          description: undefined
        });
      }
    }));

    this.subscribers.push(this.pageForm.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    }));
  }


  private patchLink(item: SportBetGroupModel) {
    const control = this.formBuilder.group({
      groupId: [item.groupId, [Validators.required]],
      description: [{
        value: item.description,
        disabled: true
      }],
      enabled: [item.enabled],
      isNew: [item.isNew]
    });
    // control.disable();
    return control;
  }

}
