import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IBaseResponse } from '../models/BaseResponse';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '../core/components/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable()
export abstract class BaseEditorComponent implements OnInit, OnDestroy {

  protected notifyService;
  protected translateService: TranslateService;
  protected dialog: MatDialog;
  protected id?: string;
  protected canClose: boolean;
  public pageForm: FormGroup;
  protected subscribers: Subscription[];

  constructor() {
    this.subscribers = new Array<Subscription>();
  }

  abstract ngOnInit();
  abstract ngOnDestroy();

  protected handleServerResponse(response: IBaseResponse<any>, success: (result) => void, publish: boolean) {
    this.notifyService.handleServerResponse(response, result => {
      success(result);
    }, undefined, () => {
      this.translateService.get('NOTIFICATION.CONFLICT').subscribe(msg => {
        let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          width: '50%'
        });
        dialogRef.componentInstance.confirmMessage = msg;
        dialogRef.afterClosed().subscribe(confirm => {
          if (confirm === true) {
            this.save(publish, true);
          } else {
            this.loadPage(this.id);
          }
        });
      }).unsubscribe();
    });
  }

  protected save(publish?: boolean, overwrite?: boolean) {

  }

  protected abstract loadPage(id?: string);

  protected internalClose() {

  }

  protected clear() {
    this.subscribers.forEach(t => {
      t.unsubscribe();
      t = undefined;
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.pageForm.dirty || this.canClose) {
      return true;
    }
    let result = new Subject<boolean>();
    this.subscribers.push(this.translateService.get('COMMON.CANCELEDIT').subscribe(translate => {
      let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        width: '50%',
        data: {
          inverse: true
        }
      });
      dialogRef.componentInstance.confirmMessage = translate;
      let diasub = dialogRef.afterClosed().subscribe(confirm => {
        diasub.unsubscribe();
        result.next(confirm === true);
      });
    }));
    return result;
  }


  close() {
    if (!this.pageForm.dirty) {
      this.internalClose();
    } else {
      this.translateService.get('COMMON.CANCELEDIT').subscribe(translate => {
        let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          width: '50%',
          data: {
            inverse: true
          }
        });
        dialogRef.componentInstance.confirmMessage = translate;
        dialogRef.afterClosed().subscribe(confirm => {
          if (confirm === true) {
            this.canClose = true;
            this.internalClose();
          }
        });
      });
    }
  }
}
