import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
} from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, Validators, FormArray, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { ApisService } from "src/app/shared/apis.service";
import { Subscription, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { NotifyService } from "src/app/shared/notify.service";
import { AppConfigService } from "src/app/shared/appconfig.service";
import { BaseEditorComponent } from "../../components/baseeditor.component";
import { UserAccess, GbetSelectItem } from "../../models/DataModels";
import { SaveBonusRequest } from "../../models/DataModels/Cms/Request";
import { fuseAnimations } from "../../core/animations";
import { SelectItem } from "../../models/DataModels/TableDataSource/ModelRequest";
import { BettingDetailComponent } from "./bettingdetail.component";
import {
  BaseBonusDetailComponent,
  BonusDetailResult,
} from "./basedetail.component";
import {
  BonusRules,
  BonusDetailModel,
  BonusFunCasinoModel,
  BonusTranche,
} from "../../models/DataModels/Bonus/BonusDataModel";
import { map, startWith } from "rxjs/operators";
import { AmazingTimePickerService } from "amazing-time-picker";

@Component({
  selector: "app-bonuseditor",
  templateUrl: "./bonuseditor.component.html",
  styleUrls: ["./bonuseditor.component.scss"],
  animations: fuseAnimations,
})
export class BonusEditorComponent
  extends BaseEditorComponent
  implements OnInit, OnDestroy
{
  isLoading = false;
  formErrors: any;
  isNew = false;
  icon: string;
  icons: SelectItem[];
  subscribers: Subscription[];

  rulesForm: FormArray;
  userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true,
  };
  skinId: string;
  bonusTypes: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  gameCodes: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  pointCodes: Array<GbetSelectItem> = new Array<GbetSelectItem>();
  filteredOptions: Observable<GbetSelectItem[]>;
  tranceSection = false;
  fakeMoneySection = false;
  isPercentage = false;
  isPublished = false;
  firstDepositBonusTypeId: number;
  reloadDepositBonusTypeId: number;
  registrationBonusTypeId: number;
  promoBonusTypeId: number;
  verifyAccountBonusTypeId: number;
  bonusFriendsBonusTypeId: number;
  registrationHallBonusTypeId: number;
  verifyAccountPVRBonusTypeId: number;
  reloadPVRBonusTypeId: number;
  bettingBonusTypeId: number;

  @ViewChild("bonusDetail", { read: ViewContainerRef, static: false })
  detailHost: ViewContainerRef;

  private enabledChanges = ["endDate", "bonusDetail"];
  private detailComponent: BaseBonusDetailComponent;
  private detailChangesSubs: Subscription;

  constructor(
    protected translateService: TranslateService,
    @Inject(NotifyService) protected notifyService,
    @Inject(AppConfigService) private appConfig,
    protected dialog: MatDialog,
    private location: Location,
    private apisService: ApisService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private atp: AmazingTimePickerService,
    private componentFactory: ComponentFactoryResolver
  ) {
    super();
    this.icons = new Array<SelectItem>();
    this.subscribers = new Array<Subscription>();

    this.formErrors = {
      name: {},
      startDate: {},
      endDate: {},
      description: {},
      idBonusType: {},
      gameCode: {},
    };
  }

  ngOnInit() {
    this.createForm();
    this.subscribers.push(
      this.route.params.subscribe((params) => {
        this.id = params.id;
        this.isNew = this.id === undefined;

        this.loadPage(this.id);
      })
    );

    // tslint:disable-next-line:no-non-null-assertion
    this.filteredOptions = this.pageForm.get("pointCode")!.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterGroup(value))
    );
  }

  ngOnDestroy() {
    this.subscribers.forEach((t) => t.unsubscribe());
  }

  loadPage(id: string): void {
    this.isLoading = true;

    this.userAccess.canEdit = true;
    this.subscribers.push(
      this.apisService.getSingleBonus(id).subscribe((response) => {
        if (response.isError) {
          this.isLoading = false;
        }
        this.notifyService.handleServerResponse(response, (result) => {
          // this.userAccess = response.userAccess;

          this.firstDepositBonusTypeId = result.firstDepositBonusTypeId;
          this.reloadDepositBonusTypeId = result.reloadDepositBonusTypeId;
          this.registrationBonusTypeId = result.registrationBonusTypeId;
          this.promoBonusTypeId = result.promoBonusTypeId;
          this.verifyAccountBonusTypeId = result.verifyAccountBonusTypeId;
          this.bonusFriendsBonusTypeId = result.bonusFriendsBonusTypeId;
          this.registrationHallBonusTypeId = result.registrationHallBonusTypeId;
          this.verifyAccountPVRBonusTypeId = result.verifyAccountPVRBonusTypeId;
          this.reloadPVRBonusTypeId = result.reloadPVRBonusTypeId;
          this.bettingBonusTypeId = result.bettingBonusTypeId;

          this.bonusTypes = result.bonusType;
          this.gameCodes = result.gameCodes;
          this.pointCodes = result.pointCodes;

          if (result.item) {
            if (result.item.startDate) {
              result.item.startDate = new Date(result.item.startDate);
            } else {
              result.item.startDate = new Date();
            }
            if (result.item.endDate) {
              result.item.endDate = new Date(result.item.endDate);
            }
          }

          this.isPublished =
            result.item.idBonus !== undefined &&
            result.item.idBonus !== null &&
            result.item.idBonus > 0;

          this.pageForm.patchValue(result.item);

          const m: BonusDetailModel = JSON.parse(result.item.detailBonus);
          if (m !== undefined && m !== null) {
            this.pageForm.patchValue(m);
            if (m.isPercentage) {
              this.isPercentage = true;
            } else {
              this.isPercentage = false;
            }
          }

          const c = this.pointCodes.find((x) => x.id === result.item.pointCode);
          if (c !== undefined) {
            this.pageForm.get("pointCode").patchValue(c.value);
          }

          if (
            result.item.fakeMoney &&
            result.item.detailFakeMoney !== undefined &&
            result.item.detailFakeMoney !== null
          ) {
            const f: BonusFunCasinoModel = JSON.parse(
              result.item.detailFakeMoney
            );
            this.fakeMoneySection = true;
            this.pageForm.patchValue(f);
          }

          const fr: BonusTranche = JSON.parse(result.item.rulesTranche);

          if (fr !== null && result.item.isTranche) {
            this.tranceSection = true;

            const arr = this.pageForm.controls.rulesForm as FormArray;
            arr.controls = [];

            this.loadRulesForm(fr.rulesTranche);
            this.pageForm.patchValue(fr);
          }

          if (this.detailComponent) {
            this.detailComponent.load(result.item, this.isPublished === false);
          }

          if (
            result.item.idBonus !== undefined &&
            result.item.idBonus &&
            result.item.idBonus > 0
          ) {
            for (const key in this.pageForm.controls) {
              if (this.enabledChanges.indexOf(key) < 0) {
                if (this.pageForm.controls.hasOwnProperty(key)) {
                  const ctrl = this.pageForm.controls[key];
                  if (ctrl) {
                    ctrl.disable();
                  }
                }
              }
            }
          }

          this.pageForm.markAsPristine();
          this.pageForm.markAsUntouched();
          this.isLoading = false;
        });
      })
    );
  }

  loadRulesForm(fr: BonusRules[]) {
    fr.forEach((el) => {
      this.rulesForm.push(
        this.createBonusRulesItem(
          new BonusRules(
            [el.from, [Validators.required, Validators.min(0)]],
            [el.at, [Validators.required, Validators.min(0)]],
            [el.bonus, [Validators.required, Validators.min(0)]]
          )
        )
      );
    });
  }

  save(publish?: boolean, overwrite?: boolean) {
    if (this.pageForm.dirty || this.pageForm.touched) {
      if (!this.pageForm.invalid) {
        const request = new SaveBonusRequest();
        Object.assign(request, this.pageForm.getRawValue());
        request.publish = publish;
        request.id = this.id;

        const detailBonus = new BonusDetailModel(
          request.isPercentage,
          request.bonus,
          request.percentage
        );

        request.detailBonus = JSON.stringify(detailBonus);

        const pc = this.pointCodes.find(
          (x) => x.value === this.pageForm.get("pointCode").value
        );

        request.pointCode = pc !== undefined ? pc.id : undefined;

        if (this.pageForm.value.fakeMoney) {
          const fmOk = this.checkFakeMoneySection(request);

          if (fmOk === false) {
            return;
          }

          const fakeMoneyDetail = new BonusFunCasinoModel(
            request.wagering,
            request.cap,
            request.comment,
            request.durataGG
          );
          request.detailFakeMoney = JSON.stringify(fakeMoneyDetail);
        }

        const rt = {
          week: request.week,
          rulesTranche: this.pageForm.value.rulesForm,
        };

        request.rulesTranche = JSON.stringify(rt);

        const bOk = this.checkBonusSection(request, rt);

        if (bOk === false) {
          return;
        }

        if (this.detailComponent !== undefined) {
          this.detailComponent.save(request);
        }

        this.subscribers.push(
          this.apisService.saveBonus(request).subscribe((response) => {
            this.isLoading = false;
            this.handleServerResponse(
              response,
              (result) => {
                this.pageForm.reset();
                this.pageForm.markAsPristine();
                this.pageForm.markAsUntouched();
                if (publish) {
                  this.router.navigate(["/promotions/bonus"]);
                } else {
                  if (parseInt(this.id, 10) === result) {
                    this.loadPage(this.id);
                  } else {
                    this.router.navigate(["/promotions/bonus/item", result]);
                  }
                }
              },
              publish
            );
          })
        );
      } else {
        this.notifyService.warning("COMMON.INVALIDDATA");
      }
    }
  }

  publish() {
    if (!this.userAccess.canEdit) {
      return;
    }
    if (this.pageForm.invalid) {
      this.notifyService.error("COMMON.INVALIDPUBLISH");
    } else {
      this.pageForm.markAsDirty();
      this.save(true);
    }
  }

  enableDateOrTime(action: number) {
    if (action === undefined) {
      return;
    }
    if (action === 0) {
      this.pageForm.controls.enableDate.setValue(false);
    } else {
      this.pageForm.controls.enableTimer.setValue(false);
      this.pageForm.controls.enableStartTimer.setValue(false);
    }
  }

  tranceChange(event: any) {
    if (event.checked) {
      this.tranceSection = true;
    } else {
      this.tranceSection = false;
    }
  }

  fakeMoneyChange(event: any) {
    if (event.checked) {
      this.fakeMoneySection = true;
    } else {
      this.fakeMoneySection = false;
    }
  }

  percentageChange(event: any) {
    if (event.checked) {
      this.isPercentage = true;
    } else {
      this.isPercentage = false;
    }
  }

  addRules() {
    // tslint:disable-next-line:no-string-literal
    const control = this.pageForm.controls["rulesForm"] as FormArray;
    const addrCtrl = this.createBonusRulesItem(
      new BonusRules(
        ["", [Validators.required, Validators.min(0)]],
        ["", [Validators.required, Validators.min(0)]],
        ["", [Validators.required, Validators.min(0)]]
      )
    );
    control.push(addrCtrl);
  }

  removeBonusRulesItem(i: number) {
    // tslint:disable-next-line:no-string-literal
    const control = this.pageForm.controls["rulesForm"] as FormArray;
    control.removeAt(i);
  }

  protected internalClose() {
    const data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(["promotions/bonus"]);
    }
  }

  private createForm() {
    this.rulesForm = this.formBuilder.array([]);
    this.pageForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(255)]],
      description: ["", [Validators.required, Validators.maxLength(255)]],
      startDate: [undefined, Validators.required],
      endDate: [undefined, Validators.required],
      idBonusType: [undefined, [Validators.required]],
      active: [],
      fakeMoney: [false],
      specialBonus: [],
      notes: [],
      cumulative: [],
      gameCode: [undefined, [Validators.required]],
      isTranche: [],
      bonus: [],
      pointCode: [undefined],
      wagering: [],
      durataGG: [],
      cap: [],
      comment: [],
      week: [undefined],
      rulesForm: this.rulesForm,
      isPercentage: [false],
      percentage: [],
      contoVerificato: [false],
      maxBonus: [0, Validators.min(0)],
      minRefill: [0, Validators.min(0)],
      version: [],
      detailValid: [undefined, Validators.required],
    });

    this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    });

    this.pageForm.get("idBonusType").valueChanges.subscribe((data) => {
      this.updateDetails(data);
    });
  }

  private createBonusRulesItem(item: BonusRules): FormGroup {
    return this.formBuilder.group(item);
  }

  private onFormValuesChanged(data) {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  private updateDetails(type: number) {
    if (this.detailComponent && this.detailComponent.type === type) {
      this.detailComponent.load(undefined, this.isPublished === false);
      return;
    }
    this.detailHost.clear();
    if (this.detailComponent) {
      this.detailComponent.valueChanges.unsubscribe();
      this.detailChangesSubs.unsubscribe();
      this.detailChangesSubs = undefined;
      this.detailComponent = undefined;
    }
    this.enableAllFlieds();
    if (type) {
      switch (type) {
        case this.registrationBonusTypeId:
        case this.registrationHallBonusTypeId:
          this.disablePercentage();
          break;
        case this.promoBonusTypeId:
        case this.verifyAccountBonusTypeId:
        case this.bonusFriendsBonusTypeId:
        case this.verifyAccountPVRBonusTypeId:
          this.disablePercentage();
          this.disableTranche();
          break;
        case this.bettingBonusTypeId:
          const factory = this.componentFactory.resolveComponentFactory(
            BettingDetailComponent
          );
          this.detailComponent = this.detailHost.createComponent(factory)
            .instance as BaseBonusDetailComponent;
          break;
        // todo add other types
        default:
          break;
      }
    }
    if (this.detailComponent) {
      this.detailComponent.type = type;
      this.detailComponent.load(undefined, this.isPublished === false);
      this.pageForm.patchValue({
        detailValid: undefined,
      });
      this.detailChangesSubs = this.detailComponent.valueChanges.subscribe(
        (data: BonusDetailResult) => {
          this.pageForm.patchValue({
            detailValid: data.invalid ? undefined : true,
          });
          this.pageForm.markAsDirty();
          this.pageForm.markAsTouched();
        }
      );
    } else {
      this.pageForm.patchValue({
        detailValid: true,
      });
    }
  }

  private enableAllFlieds() {
    this.pageForm.controls.isPercentage.enable();
    this.pageForm.controls.isTranche.enable();
  }

  private disablePercentage() {
    this.isPercentage = false;
    this.pageForm.controls.isPercentage.setValue(false);
    this.pageForm.controls.isPercentage.disable();
  }

  private disableTranche() {
    this.tranceSection = false;
    this.pageForm.controls.isTranche.setValue(false);
    this.pageForm.controls.isTranche.disable();
  }

  private isNull(input: any): boolean {
    return input === null || input === undefined;
  }

  private _filterGroup(value: string): any[] {
    if (value) {
      return (
        this.pointCodes

          // .filter(el => el.value.toLowerCase().includes(value.toLowerCase()));
          .filter((el) =>
            el.value.toLowerCase().includes(value.toString().toLowerCase())
          )
      );
    }

    return this.pointCodes;
  }

  private checkFakeMoneySection(request: SaveBonusRequest): boolean {
    if (
      request.wagering === null ||
      request.cap === null ||
      request.comment === null ||
      request.durataGG === null
    ) {
      this.notifyService.warning("BONUS.FAKE_MONEY_SECTION_NOT_VALID");
      return false;
    }

    return true;
  }

  private checkBonusSection(request: SaveBonusRequest, rt: any): boolean {
    if (request.isTranche) {
      if (request.week === null || rt.rulesTranche.length === 0) {
        this.notifyService.warning("BONUS.TRANCHE_SECTION_NOT_VALID");
        return false;
      }
    } else {
      if (request.isPercentage) {
        if (request.percentage <= 0) {
          this.notifyService.warning("BONUS.PERCENTAGE_SECTION_NOT_VALID");
          return false;
        }
      } else {
        if (request.bonus <= 0) {
          this.notifyService.warning("BONUS.BONUS_SECTION_NOT_VALID");
          return false;
        }
      }
    }

    return true;
  }
}
