<div class="dialog-container" [ngClass]="input.addingToGame ? 'no-margin' : ''">
  <div class="page-layout carded fullwidth ps dialog" fuseperfectscrollbar>
    <generic-search-filter
      [games]="addingToGame || addingToCategory || addingToGroups"
      [requestModel]="model"
      [panelTitle]="panelTitle"
      [panelIcon]="'image'"
      (onSubmit)="onSubmit()"
    ></generic-search-filter>
    <span fxLayout="row" fxLayoutAlign="center center" (click)="openMediaLoader()" class="add-icon"><span>Upload new media</span></span>

    <generic-gallery
      (closeDialogEmitter)="closeDialog($event)"
      #genericGallery
      [multiSelection]="isMultiSelect"
      [gamesForm]="addingToGame"
      [groupsForm]="addingToGroups"
      [categoriesForm]="addingToCategory"
    ></generic-gallery>
  </div>
</div>
