import { HttpClient, HttpRequest } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NotifyService } from "src/app/shared/notify.service";
import { CurrencyService } from "src/app/shared/services/currency.service";
import { UrlHelper } from "src/app/shared/urlhelper";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-skins-settings",
  templateUrl: "./skins-settings.component.html",
  styleUrls: ["./skins-settings.component.scss"],
})
export class SkinsSettingsComponent implements OnInit {
  selectedSkin: any;
  skins: any;
  allSkins: any;
  defaultSkin: any;
  postObject: any;
  form = new FormGroup({
    name: new FormControl(),
    maximumSessionLoadAmount: new FormControl(null, Validators.pattern(environment.regex.regexDecimals)),
    skin: new FormControl({ disabled: true }),
  });

  constructor(
    private urlHelper: UrlHelper,
    private http: HttpClient,
    public notifyService: NotifyService,
    private currencyService: CurrencyService
  ) { }

  getSkins() {
    const url = this.urlHelper.getUrl("/casino/skins/");
    return this.http.get<any>(url);
  }

  getSkinSettings() {
    const url = this.urlHelper.getUrl("/DataServices/GetSkinsSettings/");
    return this.http.get<any>(url);
  }

  saveSkinChanges(skinId?: string) {
    if (this.selectedSkin) {
      const url = this.urlHelper.getUrl("/casino/skins/" + skinId);
      return this.http.put<any>(url, {
        name: this.selectedSkin.name,
        settings: {
          maximumSessionLoadAmount: this.currencyService.stringToCurrency(this.form.value?.maximumSessionLoadAmount),
        },
        version: this.selectedSkin.version,
      });
    } else {
      const url = this.urlHelper.getUrl("/casino/skins/");
      return this.http.post<any>(url, {
        ...this.postObject,
        settings: {
          maximumSessionLoadAmount: this.currencyService.stringToCurrency(this.form.value.maximumSessionLoadAmount),
        },
      });
    }
  }

  submitChanges(skinId?: string) {
    this.saveSkinChanges(skinId).subscribe(
      () => {
        this.notifyService.success("Skin settings have been saved!");
      },
      () => {
        this.notifyService.error("Error");
      }
    );
  }

  selectSkin(evt) {
    console.log({ evt });
    this.selectedSkin = this.skins.find((skin) => {
      console.log(evt.value.id, skin.id);
      return evt.value.id == skin.id;
    });
    console.log({ selected: this.selectedSkin });
    if (this.selectedSkin) {
      this.form.patchValue({
        maximumSessionLoadAmount: this.currencyService.currencyToString(this.selectedSkin.setting.maximumSessionLoadAmount),
        name: this.selectedSkin.name,
      });
    } else {
      this.postObject = {
        name: evt.value.name,
        id: evt.value.id,
      };
      this.form.patchValue({
        maximumSessionLoadAmount: this.currencyService.currencyToString(10000),
        name: evt.value.name,
      });
    }
  }
  ngOnInit(): void {
    this.getSkins().subscribe((skinsData) => {
      this.skins = skinsData;
      this.selectedSkin = skinsData[0];
      this.form.patchValue({
        maximumSessionLoadAmount: this.currencyService.currencyToString(this.selectedSkin.setting.maximumSessionLoadAmount),
        name: this.selectedSkin.name,
      })
    });

    this.getSkinSettings().subscribe((skins) => {
      this.allSkins = skins.data;
      console.log({ skins });
      this.defaultSkin = skins.data[0];
    });
  }
}
