export const locale = {
  lang: "en-US",
  data: {
    NOTIFICATION: {
      UNAUTHORIZED: "Unauthorized",
      "-1000": "Invalid data. Please update data from server",
      "-1001": "Invalid request",
      "1000": "Element not found",
      "1001": "Unable to delete a published item",
      "2001": "Inexistent page address",
      "2002": "Unable to change skin",
      "4000": "Inexisted seo link",
      "4001": "Invalid seo link",
      "6000": "Invalid content name. Must be unique",
      "7000":
        "Invalid data for gico Netent. AAMS game type must be numeric, netent family and type must not be empty. Also the 'gameId' field must be unique",
      "7001":
        "Invalid data for game Capecod. AAMS game type and AAMS code must be numeric, type must not be empty. In addition, the 'gameId' field must be unique",
      "7002":
        "Invalid data for GreenTube game. AAMS game type and AAMS code must be numeric, type must not be empty. Also the 'gameId' field must be unique",
      "7003":
        "Invalid data for Novomatic game. AAMS game type, AAMS code and game id must be numeric. In addition, the 'gameId' field must be unique",
      "7004": "Invalid data for Extreme game",
      "7005": "Invalid data for Evolution game",
      "8000": "The folder contains items, they cannot be deleted",
      "8001": "Invalid folder name",
      "8002": "Media folder not found",
      "9000": "Bet already insertedia.",
      "9001": "Select the discipline",
      "9002": "Duplicate bet",
      "10001": "Group already present",
      "10002": "Existing regulation",
      "10003": "All groups are already configured",
      SUCCESS: "Operation done",
      ERRROR: "Error",
      CONFLICT: "Item was changed by another user. Overwrite the data?",
      NOT_IMPLEMENTED: "Not implemented",
      GENERICERROR: "Generic error",
      NULLREQUESTERROR: "Invalid Request",
      ITEM_NOT_FOUND_ERROR: "Item not found",
      INVALID_REQUEST: "Invalid Request",
      ERROR_WIDGET_SETAUTHORANONYM:
        "Set visibility anonymous or / and authenticated",
      INVALID_DATA: "Invalid data",
      MEDIA_NAME_ERROR: "Please enter name",
      MEDIA_FOLDER_DELETE_ERROR: "The folder contains items, cannot be deleted",
      MEDIA_FOLDER_SAVE_ERROR: "Error saving folder",
      MEDIA_FOLDER_MOVE_ERROR: "Error moving folder",
      MEDIA_ITEMS_MOVE_ERROR: "Error moving elements",
      ERROR_SAVING_DATA: "Error saving",
      MEDIA_FILE_NOTSUPPORTED_ERROR: "Null or unsupported file",
    },
    COMMON: {
      SEARCH_TITLE: "Search",
      SEARCH_FRONTEND_LABEL: "Product",
      SEARCH_CREATION_FOLDER: "Folder",
      SEARCH_WIDTH: "Width",
      SEARCH_HEIGHT: "Height",
      SEARCH_SKIN_LABEL: "Skin",
      SEARCH_NAME_LABEL: "Name",
      SEARCH_META_LABEL: "Meta",
      SEARCH_TAGS_LABEL: "Tags",
      SEARCH_TYPE_LABEL: "Type",
      SEARCH_PAGE_LABEL: "Name",
      SEARCH_TITLE_LABEL: "Title",
      SEARCH_CREATION_DATE: "Creation date",
      SEARCH_START_LABEL: "From",
      SEARCH_END_LABEL: "A",
      SEARCH_ANONYMOUS_LABEL: "Anonymous",
      SEARCH_AUTHENTICATED_LABEL: "Authenticated",
      SEARCH_FRONTEND: "Product",
      SEARCH_PLATFORM_LABEL: "Platform",
      SEARCH_STARTDATE_LABEL: "Start Date",
      SEARCH_ENDDATE_LABEL: "End date",
      SEARCH_NAME: "Search by name",
      SEARCH_ENABLED_LABEL: "enabled",
      SEARCH_ALLFOLDERS: "All folders",
      RESET_LABEL: "Reset",
      SUBMIT_LABEL: "Submit",
      ADDITEM_LABEL: "New",
      SAVE: "Save",
      CLOSE: "Close",
      EDIT: "Edit",
      MOVE: "Move",
      DELETE: "Delete",
      DELETE_BUTTON: "Remove",
      HIDE: "Delete",
      CONFIRM_TITLE: "Confirm",
      CONFIRM_YES_BUTTON: "Yes",
      CONFIRM_NO_BUTTON: "No",
      DELETEMESSAGE: "Delete the selected item?",
      DELETEMESSAGE_SECTION:
        "Delete the selected item? All children will also be deleted.",
      DELETE_PUBLISHED_MESSAGE:
        "Attention! The item selected and published. Continue with the cancellation?",
      NO_ITEMS: "No items found",
      ERROR_LOADING: "Error loading data",
      CANCELEDIT: "Changes not saved. Would you like to close anyway?",
      CONFIRM_DELETE:
        "Clicking yes will delete the following node and all the nodes below",
      INVALIDDATA: "Fill in all required fields",
      REQUIRED_ERROR: "Required field",
      REQUIRED_MAXLEN: "Maximum length",
      SEARCH_PLACEHOLDER: "Search",
      PUBLISH: "Publish",
      CREATE: "Create new",
      WIDGET_VISIBILITY_ERROR:
        "Set visibility anonymous or / and authenticated",
      FRONTEND: "Product",
      CLONE: "Copy",
      WIDGET_COMPONENT_ERROR:
        "Dynamic component requires both product and component name",
      SELECT_MEDIA: "Select media",
      ACTION: "Actions",
      RESET: "Reset",
      REMOVE_MEDIA: "Remove media",
      ADD_MEDIA: "Add / change media",
      ADD_FRONT_MEDIA: "Add / change front media",
      PUBLISHED: "Published",
      SELECT: "Select",
      DIFFERENT_FIELD: "Field other than publication",
      ADD_BUTTON: "Add button",
      ADD_ITEM: "Add item",
      SELECT_CONTENT: "Select content",
      ISVISIBLE: "Yes",
      ISNOTVISIBLE: "No",
      INVALID_LINK: "invalid value",
      MOVE_UP: "Move up",
      MOVE_DOWN: "Move down",
      CHANGED_COLUMN: "Last modified",
      CHANGEDBY_COLUMN: "Modified by",
      SEARCH_FIXEDWIDGET_LABEL: "Known Widgets",
      SUCCESS_COPY: "Copied successfully to clipboard",
    },
    SEARCH: {
      PAGES: "Pages",
      WIDGETS: "Widgets",
      MEDIA: "Pictures",
      TITLE: "Search",
      TYPE: "Type",
    },
    WIDGETS: {
      TITLE: "Widgets",
      FILTER: "Search",
      NAME_COLUMN: "Name",
      TYPE_COLUMN: "Type",
      NOTES_COLUMN: "Notes",
      PUBLISHED_COLUMN: "Published",
      FRONTEND_COLUMN: "Product",
      ADD_BANNER: "Add a banner",
      TOOLTIP_FRONTEND: "select which section of the site it should belong to",
      TOOLTIP_TYPE: "select widget type",
      TOOLTIP_NAME: "widget identification name",
      TOOLTIP_START_DATE: "widget start date",
      TOOLTIP_END_DATE: "widget expiration date",
      TOOLTIP_NOTE: "notes or additional information",
      TOOLTIP_BANNER_ANIM: "choose an animation",
      TOOLTIP_BANNER_TITLE: "Banner title",
      TOOLTIP_BANNER_BACK_COLOR_TITLE:
        "ability to set a background color for the title",
      TOOLTIP_BANNER_COLOR_TITLE: "ability to set a color for the title",
      TOOLTIP_BANNER_SUBTITLE: "Banner subtitle",
      TOOLTIP_BANNER_BACK_COLOR_SUBTITLE:
        "possibility to set a background color for the subtitle",
      TOOLTIP_BANNER_COLOR_SUBTITLE: "possibility to set a color for the title",
      TOOLTIP_BANNER_TEXT: "banner description",
      TOOLTIP_BANNER_BUTTON_TEXT: "test in the banner button",
      TOOLTIP_BANNER_BUTTON_CONTENT: "button content",
      TOOLTIP_BANNER_BUTTON_LINK: "link to set to the button",
      TOOLTIP_BANNER_BUTTON_FUNCTION:
        "possible js function from CMS (styles and scripts)",
      TOOLTIP_BANNER_RATIO: "Banner aspect ratio (if blank, default 16%)",
    },
    FILTER: {
      TITLE: "Filter",
    },

    PAGES: {
      FILTER: "Search",
      SELECT_PAGE_LABEL: "Select a page",
      TITLE: "Pages",
      NAME_COLUMN: "Name",
      META_COLUMN: "Meta Tags",
      TAGS_COLUMN: "Meta Links",
      LAYOUT_COLUMN: "Layout",
      FRONTEND_COLUMN: "Product",
      SKIN_COLUMN: "Skin",
      EDIT: "Edit",
      LINK: "Address",
      TITLE_COLUMN: "Title",
      PUBLISHED_COLUMN: "Published",
      PUBLISHED_DATE_COLUMN: "Published on",
      SELECTOR_TITLE: "Select page",
      SELECT: "Select",
    },
    MEDIA: {
      FILTER: "Search",
      TITLE: "Media",
      DESCRIPTION_COLUMN: "Description",
      EXT_COLUMN: "Type",
      FILE_LANGUAGE: "Language",
      FILE_DESCRIPTION: "Media Description",
      FILE_INPUT: "Select File",
      FILE_INPUT_SELECTED: "File Name",
      NAME_COLUMN: "Name",
      FRONTEND_COLUMN: "Product",
      EDIT: "Edit",
      FILE_NAME: "Media Name",
      ACTION: "Action",
      PREVIEW: "Preview",
      WIDTH_COLUMN: "Width",
      HEIGHT_COLUMN: "Height",
      FILE_WIDTH: "Width",
      FILE_HEIGHT: "Height",
      FILE_FRONTEND: "Product",
      LANGUAGE_SELECTOR: "Add Media in another language (optional)",
      DIALOG_EDIT: "Edit Media",
      DIALOG_ADD: "Add Media",
      ALT: "Alt Media",
      THUMNBNAIL_COLUMN: "",
      TOOLTIP_PRODUCT: "select which section to direct an image to",
      TOOLTIP_NAME: "identification name",
      TOOLTIP_DESCRIPTION: "small description (optional)",
      TOOLTIP_ALT_MEDIA: "alt for html tag",
      TOOLTIP_LANGUAGE: "type of language",
      FILE_CDNURL: "Absolute link",
      FILE_PUBLICURL: "Relative link",
    },

    PAGE: {
      NEW_TITLE: "New page",
      EDIT_TITLE: "Edit page",
      NAME: "Name",
      TAGS: "Add Meta Links",
      META: "Add Meta Tags",
      TAGS_ADDED: "Meta Links",
      META_ADDED: "Meta Tags",
      LAYOUT: "Layout",
      ISFULLSCREEN: "Full Screen",
      PROMOTIONAL_LANDING_PAGE: "Promotional Landing Pages",
      LANDING_PAGE: "Landing Page",
      LINK: "Address",
      PAGE_ID: "Page Id",
      ADDZONE: "New section",
      ZONES: "Sections",
      PREVIEW: "Preview",
      REMOTE_ID: "Remote Id",
      TITLE: "Title",
      DETAILS: "Details",
      WIDGET_TYPE: "Select widget type",
      FRONTEND: "Product",
      SELECT_WIDGET_TYPE: "Select widget type",
      SELECT_LAYOUT_TYPE: "Select layout type",
      RESET_PUBLISHED: "Restore from publication",
      RESET_PUBLISHED_CONFIRM:
        "Do you want to restore the page from the last publication?",
      SECTION: "Section",
      CELL_HEIGHT: "Height",
      CELL_WIDGET: "Widget",
      CELL_LAYOUT: "Layout",
      CELL_WIDGET_AUTH: "Widget authenticated",
      CELL_PADDING: "Padding",
      CELL_COLOR: "Background color",
      KEY: "Key",
      VALUE: "Value",
      ADD_META_TAG: "Add meta tag",
      REMOVE_META_TAG: "Remove meta tag",
      ADD_META_LINK: "Add meta link",
      REMOVE_META_LINK: "Remove meta link",
      SKIN: "Skin",
      ISSYSTEM: "System Page",
      LINKURL: "Address",
      TOOLTIP_SKIN: "select a skin for the page",
      TOOLTIP_PRODUCT: "select which section it is addressed to",
      TOOLTIP_ID:
        "insert the link of the page (if the page is not a system page it will always be preceded by the virtual directory pages /)",
      TOOLTIP_NAME: "identification name of the page",
      TOOLTIP_TITLE: "title displayed on html card",
      TOOLTIP_ADDRESS: "page url address",
      TOOLTIP_METATAG_KEY:
        "this field corresponds to the name value of the metatag",
      TOOLTIP_METATAG_VALUE:
        "this field corresponds to the content value of the metatag",
      TOOLTIP_METALINK_KEY:
        "this field corresponds to the name value of the metalink",
      TOOLTIP_METALINK_VALUE:
        "this field corresponds to the content value of the metalink",
      TOOLTIP_HEIGHT: "enter the height of the container",
      TOOLTIP_PADDING:
        "insert the padding of the container (the distance between the edges of the container and the internal content)",
      TOOLTIP_BACKGROUND: "choose a background color for the box",
    },

    WIDGET: {
      NEW_TITLE: "New Widget",
      EDIT_TITLE: "Edit Widget",
      NAME: "Name",
      TYPE: "Type",
      STARTDATE: "Start",
      ENDDATE: "End",
      SHOWANONYMOUS: "Anonymous",
      SHOWAUTHENTICATED: "Authenticated",
      SHOW_SHADOW: "Shadow",
      HIDE_ON_APP: "Hide on app",
      CONTENT: "Content",
      DETAILS: "Details",
      DATES_ERROR: "Invalid end date",
      LINK: "Link",
      WIDTH: "Width",
      HEIGHT: "Height",
      ANOUNYMOUS_WIDGETS: "Anonymous Widgets",
      AUTHENTICATED_WIDGETS: "Authenticated Widgets",
      FRONTEND: "Product",
      DYNAMIC_COMPONENT: "Dynamic component",
      SELECT_IMAGE_MESSAGE: "Select media",
      ANIMATION: "Animation",
      NOTES: "Notes",
      SELECT: "Select Widget",
      VISIBLE: "Visible",
      CONFIRMTYPECHANGED:
        "WARNING! If you change the type of the widget, the content will be lost. Continue?",
    },
    HELP: {
      SKIN: "Skin",
      EDITITEM_LABEL: "Edit",
      ADDITEM_LABEL: "Add to",
      DISPLAY: "Node name",
      NEW_NODE: "New node",
      DEFAULT_NODE_VALUE:
        '<h2 style = "color: # ff0000"> Add content to the following node </h2>',
      SELECTION_WARNING: "Select an item",
      TOOLTIP_SKIN: "Skin",
      SORT: "Sort items",
      CONFIRM_SORT: "Are you sure you want to sort the items alphabetically?",
    },
    STYLES: {
      EDIT_TITLE: "Edit styles",
      CONTENT: "Content",
      STYLE: "CSS Styles",
      SCRIPT: "Script",
    },
    LAYOUT: {
      NAME_COLUMN: "Name",
      EDIT_TITLE: "Edit layout",
      NEW_TITLE: "New layout",
      ADD_COLUMN: "Add Column",
      REFRESH_ROWS: "Delete lines",
      ADD_ROW: "Add Row",
      REFRESH_CELLS: "Delete cells",
      ADD_CELL: "Add Cell",
      DELETE: "Delete",
      ISPUBLISHED: "- Layout published",
      CONFIRMSAVE:
        "ATTENTION! The layout is linked to published pages. Changes can reset the contents of the page. Continue?",
    },
    LAYOUTS: {
      TITLE: "Layouts",
    },
    MEDIASELECTOR: {
      TITLE: "Select media",
    },
    LAYOUTSELECTOR: {
      TITLE: "Select layout",
    },
    WIDGETSELECTOR: {
      TITLE: "Select widget",
    },
    BANNER: {
      BACK_ANIMATION: "Select background animation",
      FRONT_ANIMATION: "Select Foreground Animation",
      LINK: "Link",
      TITLE: "Title",
      SUBTITLE: "Subtitle",
      BUTTON_TEXT: "Text",
      BUTTON_TYPE: "Type",
      IMAGES_FRONT_SUBTITLE: "Select Foreground Image",
      IMAGES_SECTION: "Select Images",
      IMAGES_SECTION_SUBTITLE: "Select Background Image",
      IMAGES_SECTION_RATIO: "Upload an image with min Proportion 1072x364",
      DETAILS: "Details",
      TIMER: "Enable timer",
      TITLE_COLOR: "Title color",
      SUBTITLE_COLOR: "Subtitle color",
      TITLE_BACK_COLOR: "Title background color",
      SUBTITLE_BACK_COLOR: "Subtitle background color",
      TEXT: "Text",
      TITLE_ANIMATION: "Animated title",
      TIMER_ONSTART: "Start date timer",
      ADD_BUTTON: "Add Button",
      BUTTON_ISCONTENT: "Content",
      BUTTON_ISPOPUP: "Open popup",
      BUTTON_CONTENT: "Content",
      BUTTON_ISPAGE: "Page",
      BUTTON_PAGE: "Page",
      BUTTON_ISLINK: "Link",
      BUTTON_LINK: "Link",
      BUTTON_ISFUNCTION: "JS function",
      BUTTON_FUNCTION: "JS Function",
      SELECT_CONTENT: "Select point con",
      SELECT_PAGE: "Select page",
      RATIO: "Proportions",
    },
    CONTENTS: {
      TITLE: "Contents",
      SELECTOR_TITLE: "Select content",
    },
    CONTENT: {
      NEW_TITLE: "Edit content",
      EDIT_TITLE: "Edit content",
      NAME: "Name",
      TITLE: "Title",
      WIDGET: "Widget",
      WIDGET_COLUMN: "Widget",
      SKIN: "Skin",
      URL: "Content Url",
    },
    SLIDER: {
      ITEM_NAME: "Widget",
    },
    SECTION: {
      TITLE: "Sub-menu",
      NAME: "Name",
      SKIN: "Skin",
      ITEM_TITLE: "Title",
      SORT: "Sorting",
      CONTENT: "Content",
      ISPOPUP: "Open popup",
      ISVISIBLE: "Visible",
      FRONTEND: "Product",
      SELECT_ITEM: "Select an item",
      ITEM_LINK: "Url Seo",
      INVALID_LINK: "Invalid seo link",
      PAGE: "Page",
      SELECT_PAGE: "Select page",
    },
    PROMOTIONS: {
      TITLE: "Promotions",
      NAME_COLUMN: "Title",
      THUMNBNAIL_COLUMN: "",
      SKIN_COLUMN: "Skin",
      ISVISIBLE: "Visible",
      FRONTENDS_COLUMN: "Product",
      STARTDATE_COLUMN: "Start date",
      ENDDATE_COLUMN: "End date",
      EDIT: "Edit",
      PUBLISHED_COLUMN: "Published",
      SORT: "Sorting",
    },
    PROMO: {
      SKIN: "Skin",
      NEW_TITLE: "New promotion",
      EDIT_TITLE: "Edit promotion",
      BACK_COLOR: "Background color",
      NAME: "Promotion Title",
      ADD_BUTTON: "Add button",
      SHORTDETAILS: "Short text",
      DESKTOPIMAGE: "Desktop Image",
      PORTRAIT_IMAGE: "Upload a 390x420 image",
      LANDSCAPE_IMAGE: "Upload a 720x400 image",
      BIGIMAGE: "Landscape",
      SMALLIMAGE: "Portrait",
      MOBILEIMAGE: "Mobile Image",
      SHOWDATA: "Date visible",
      BUTTON_IS_AUTHENTICATED: "Authenticated",
      BUTTON_IS_ANONYMOUS: "Anonymous",
      ENABLE_DATE: "Date enabled",
      ENABLE_TIMER: "Timer enabled",
      ENABLE_START_TIMER: "Start date timer",
      STARTDATE: "Start",
      ENDDATE: "End",
      NOTES: "Notes",
      HTML: "Html",
      SHOWINCOMING: "VISIBLE COMING SOON",
      ISVISIBLE: "Visible",
      JACKPOT: "Prize Pool",
      SORT: "Sorting",
      BUTTON_TEXT: "Text",
      BUTTON_TYPE: "Type",
      SELECT_BACKIMAGE: "Background",
      SELECT_FRONTIMAGE: "Image",
      PLATFORM: "Platform",
      PRODUCTS: "Products",
      LANDSCAPE: "Portrait / Landscape",
      BUTTON_ISCONTENT: "Content",
      BUTTON_ISPOPUP: "Open popup",
      BUTTON_CONTENT: "Content",
      BUTTON_ISPAGE: "Page",
      BUTTON_PAGE: "Page",
      BUTTON_ISLINK: "Link",
      BUTTON_LINK: "Link",
      BUTTON_ISFUNCTION: "JS function",
      BUTTON_FUNCTION: "JS Function",
      SELECT_CONTENT: "Select content",
      COMINGSOON_BUTTONS: "Buttons coming soon",
      BUTTONS: "Buttons",
      LINK: "Promotion Link",
      SHOW_AFTER_REGISTER: "View after registration",
      BADGE_ICON: "Badge Icon",
      TYPE: "Promotion type",
      SELECT_TYPE: "Select type",
      TOOLTIP_SKIN: "Select the type of skin",
      TOOLTIP_TITLE_PROMO: "Title displayed on the card",
      TOOLTIP_DESCRIPTION_PROMO:
        "Description displayed in upcoming and finished promos",
      TOOLTIP_LINK_PROMO:
        "link pointing to the promo both absolute and relative",
      TOOLTIP_ICON_PROMO: "Select the badge visible on the card",
      TOOLTIP_IMG_LANDSCAPE_DESKTOP:
        "Image visible on incoming and finished (desktop)",
      TOOLTIP_IMG_PORTRAIT_DESKTOP: "Image visible on active (desktop)",
      TOOLTIP_IMG_LANDSCAPE_MOBILE:
        "Image visible on incoming and finished (mobile)",
      TOOLTIP_IMG_PORTRAIT_MOBILE: "Image visible on active (mobile)",
      TOOLTIP_START_DATE: "Promotion start date",
      TOOLTIP_END_DATE: "Promotion end date",
      TOOLTIP_START_HOUR: "Promotion start time",
      TOOLTIP_END_HOUR: "Promotion end time",
      TOOLTIP_NOTE: "additional notes or information for promo",
      TOOLTIP_JACKPOT: "prize pool for the promo",
      TOOLTIP_BUTTON_TEXT: "button text",
      TOOLTIP_BUTTON_CONTENT:
        "type of content visible on the click of the button",
      TOOLTIP_BUTTON_LINK: "type of content visible on button click",
      TOOLTIP_BUTTON_FUNCTION: "js function of the CMS (styles and scripts)",
      PROMOID: "Promotion Id",
      TOOLTIP_PROMOID: "Promotion Id",
      ISWELCOME: "Welcome promotion",
      SORT_DESCRIPTION_PROMO: "Sort",
      LANDSCAPE_DESCRIPTION_PROMO: "Portrait / Landscape (default portrait)",
      LANDSCAPE_RATIO: "Horizontal",
      PORTRAIT_RATIO: "Vertical",
    },

    MENUS: {
      TITLE: "Menu",
      NAME_COLUMN: "Name",
      LINK: "Address",
      SORT: "Order",
      ISRIGHT: "To the right",
      AUTHENTICATED: "Authenticated",
      ICON: "Icon",
    },
    MENU: {
      NEW_TITLE: "New",
      EDIT_TITLE: "Edit",
      SKIN: "Skin",
      LINK: "Address",
      NAME: "Name",
      SORT: "Order",
      ISRIGHT: "To the right",
      AUTHENTICATED: "Authenticated",
      ICON: "Icon",
      PARENT: "Father",
      ENABLED: "Enabled",
    },
    MEDIAFOLDERS: {
      TITLE: "Create New Folder",
      NOITEMS: "No folder",
      EDITTITLE: "Edit Folder",
      NAME: "Folder name",
      ACTIONS_MOVE_FOLDER: "Move Folder to ...",
      ACTIONS_MOVE_ITEMS:
        'Move Media <span style = "color: # 039be5"> (*) </span> to ...',
    },

    SKINS: {
      TITLE: "Skins Editor",
      ITEMS: "Skins",
      NAME: "Name",
      GOOGLESEARCH: "Google Search Console Desktop",
      GOOGLETAG: "Google Tag Manager Desktop",
      GOOGLETAG_HEADSCRIPT: "Google Tag Manager Header Script",
      GOOGLETAG_BODYSCRIPT: "Google Tag Manager Body Script",
      GOOGLESEARCHMOBILE: "Google Search Console Mobile",
      GOOGLETAGMOBILE: "Google Tag Manager Mobile",
      ISMAINTENANCE: "Desktop Maintenance",
      ISMOBILEMAINTENANCE: "Mobile Maintenance",
    },
    MOBILE: {
      TITLE: "Mobile Content",
      NAME_COLUMN: "Name",
      NAME: "Name",
      SKIN: "Skin",
      PAGE: "Page",
      PUBLISHED_COLUMN: "Published",
      PUBLISHED_DATE_COLUMN: "Publication date",
      NEW_TITLE: "New",
      EDIT_TITLE: "Edit mobile content",
      SORT: "Sorting",
      ENABLED: "Enabled",
      STARTDATE: "Start",
      TOOLTIP_START_DATE: "Start",
      TOOLTIP_END_DATE: "Finish",
      ENDDATE: "End",
    },

    NATIVEAPP: {
      TITLE: "Native Applications",
      NAME: "Platform",
      LINK: "Link",
      RELEASEVERSION: "Version",
      CURRENTFILE: "Current file",
      MAINTENANCE: "Maintenance",
      DISABLE_OUT_OF_ITALY: "Block App outside Italy",
    },
    GAMES: {
      TITLE: "Games registry",
      NAME_COLUMN: "Name",
      SEARCH_PROVIDER_LABEL: "Supplier",
      PROVIDER_COLUMN: "Supplier",
      ISENABLED_COLUMN: "Enabled",
      ISVISIBLE_COLUMN: "Visible",
      ISJACKPOT_COLUMN: "Jackpot",
      ISMOBILE_COLUMN: "Mobile",
      ISDESKTOP_COLUMN: "Desktop",
      ISDEMO_COLUMN: "Demo",
      ORDER_COLUMN: "Sort",
      SYSTEMDATE_COLUMN: "Date modified",
      SEARCH_GAMEID_LABEL: "GameId",
      THUMNBNAIL_COLUMN: "Thumbnail",
      SEARCH_ADM_LABEL: "ADM Code",
      SEARCH_CHANNEL_LABEL: "Channel",
      SEARCH_JACKPOT_LABEL: "Jackpot",
      SEARCH_VISIBLE_LABEL: "Visible",
      SEARCH_ENABLED_LABEL: "Enabled",
      SEARCH_ISNEW_LABEL: "New",
      SEARCH_ROOM_LABEL: "Room",
    },

    GAMESLIVE: {
      TITLE: "Live games database",
      NAME_COLUMN: "Name",
      TABLETYPENAME_COLUMN: "Table",
      SEARCH_PROVIDER_LABEL: "Supplier",
      PROVIDER_COLUMN: "Supplier",
      ISENABLED_COLUMN: "Enabled",
      ISVISIBLE_COLUMN: "Visible",
      ISJACKPOT_COLUMN: "Jackpot",
      ISMOBILE_COLUMN: "Mobile",
      ISDESKTOP_COLUMN: "Desktop",
      ISDEMO_COLUMN: "Demo",
      ORDER_COLUMN: "Sort",
      SYSTEMDATE_COLUMN: "Date modified",
      SEARCH_GAMEID_LABEL: "GameId",
    },
    GAME: {
      DETAILS: "Details",
      ATTRIBUTES: "Attributes",
      NEW_TITLE: "New Game",
      EDIT_TITLE: "Edit Game",
      NAME: "Name",
      PROVIDER: "Supplier",
      TOOLTIP_PROVIDER: "Supplier",
      GAMEID: "Game id",
      FULLNAME: "Full name",
      UNIQUENAME: "Unique name",
      TITLE: "Title",
      NAMESEO: "SEO name",
      AAMSCODE: "AAMS Code",
      AAMSGAMETYPE: "AAMS game type",
      ISENABLED: "Enabled",
      ISVISIBLE: "Visible",
      ISJACKPOT: "Jackpot",
      ISDEMO: "Demo",
      ISDESKTOP: "Desktop",
      ISMOBILE: "Mobile",
      ORDER: "Sort",
      FFP: "FFP",
      COEFFICIENTE: "Coefficient",
      ISMULTIBET: "Multibet",
      HEIGHT: "Height",
      WIDTH: "Width",
      RPT: "RPT",
      IMAGEBACKGROUND: "Background image",
      IMAGEMINI: "Mini image",
      IMAGESPLASHDESKTOP: "Splash Desktop Image",
      IMAGEMINIPREVIEW: "Preview Image",
      IMAGEICONMOBILE: "Mobile Icon",
      IMAGESPLASHMOBILE: "Splash Mobile Image",
      HELP: "Help",
      ISNEW: "New",
      SHORTDESCRIPTION: "Short description",
      LONGDESCRIPTION: "Full Description",
      FAMILY: "Family",
      ATTRIBUTE: "Attribute",
      ADDATTRIBUTE: "New attribute",
      DESKTOPORMOBILE: "Desktop / Mobile",
      NETENTGAMETYPE: "Netent Game Type",
      TOOLTIP_GAMETYPE: "Netent Vendor Game Type",
      SELECTIMAGE: "Select image",
      NETENTGAMEFAMILY: "Netent game family",
      CAPECODEGAMETYPE: "Game type capecode",
      CAPECODECASH: "Capecode available RealMoney",
      CAPECODEISFUN: "Capecode is fun",
      GREENTUBEGAMETYPE: "GreenTube game type",
      GREENTUBECASH: "GreenTube available RealMoney",
      GREENTUBEISFUN: "GreenTube is fun",
      "SELECT-IMAGE": "Select image",
      NOVOMATICGAMETYPE: "Novomatic game type",
      TAGKEYWORDS: "Keyword Tag",
      TAGTDESCRIPTION: "Description tag",
      TAGTITLE: "Title tag",
      ISFOREGROUND: "First floor",
      GOLIVEDATE: "Launch date",
      ISITA: "Italian table",
      GROUPGAME: "Group",
    },

    GAMELIVE: {
      NEW_TITLE: "New Game",
      EDIT_TITLE: "Edit Game",
      DISPLAYNAME: "Name",
      PROVIDER: "Supplier",
      TOOLTIP_PROVIDER: "Supplier",
      TABLENAME: "Table Name",
      TABLEID: "Table Id",
      TABLETYPE: "Table Type",
      TABLEVIRTUAL: "Virtual Table",
      TABLETYPENAME: "Table Type Name",
      URLSEO: "SEO URL",
      TABLEPLATFORM: "Table Platform",
      TABLEOPEN: "Opening",
      TABLCLOSE: "Closing",
      ORDER: "Sort",
      ISOPEN: "Open",
      ISENABLED: "Enabled",
      TIPOGIOCO: "Game Type",
      CODICEAAMS: "AAMS Code",
      TABLEIMAGE: "Image",
      FPP: "FPP",
      RPT: "RPT",
      PAGEID: "Page Id",
      LOBBY: "Lobby",
      COEFICINTE: "Coefficient",
      TAGKEYWORDS: "Keyword Tag",
      TAGTDESCRIPTION: "Description tag",
      TAGTITLE: "Title tag",
      ISFOREGROUND: "First floor",
      GOLIVEDATE: "Launch date",
      ISITA: "Table in Italian",
      GROUPGAME: "Group",
    },
    BETTINGGROUPS: {
      TITLE: "Betting Groups",
      NEW_TITLE: "New bet group",
      EDIT_TITLE: "Edit bet group",
      NAME: "Group name",
      NAME_COLUMN: "Group name",
      SPORT_COLUMN: "Discipline",
      ORDER_COLUMN: "Sort",
      SYSTEMDATE_COLUMN: "Date modified",
      ADDOUTCOME: "Add bet",
      SPORT: "Discipline",
      SPORT_SELECT: "Select discilin",
      OUTCOMES_TITLE: "Bets",
      OUTCOME_BET: "Bet",
      OUTCOME_ID: "Outcome",
      SEARCH_SPORT_LABEL: "Discipline",
      CREATEBETOUTCOME_TITLE: "New outcome",
      BET_SELECT: "Select bet",
      ORDER: "Sort",
      BET_ORDER: "Sort",
    },

    BETTINGSPORTS: {
      TITLE: "Disciplines",
      NEW_TITLE: "New discipline",
      EDIT_TITLE: "Edit discipline",
      SPORT: "Discipline",
      DESCRIPTION: "Discipline name",
      DESKTOPIMAGE: "Desktop Image",
      MOBILEIMAGE: "Mobile Image",
      SEARCH_SPORT_LABEL: "Discipline",
      BETTINGSPORTS: "Betting Groups",
      BETGROUPS_TITLE: "Betting Model Groups",
      BET_GROUPENABLED: "Enabled",
      GROUP_SELECT: "Select Group",
    },
    BONUS: {
      TITLE: "Bonus",
      NAME: "Name",
      NAME_COLUMN: "Name",
      DESCRIPTION: "Description",
      SPECIAL_BONUS: "Special Bonus",
      CUMULATIVE: "Cumulative",
      STARTDATE: "Start",
      ENDDATE: "End",
      NOTES: "Notes",
      FAKE_MONEY: "Fake Money",
      BONUS_TYPE: "Bonus Type",
      GAME_CODE: "Game Code",
      TRANCHE: "Tranche",
      BONUS: "Bonus",
      POINT_CODE: "Point Code",
      WAGERING: "Bets",
      DAYS: "Duration Days",
      Postcode: "Postcode",
      COMMENT: "Comment",
      NEW_TITLE: "Add Bonus",
      EDIT_TITLE: "Edit Bonus",
      WEEK: "Number of weeks",
      ADD_RULES: "Add Rule",
      FROM: "From",
      AT: "At",
      PRECENTAGE: "Percentage",
      BETTING_TITLE: "Bonus Bets",
      MINIMMUMEVENTS: "Minimum number of events",
      MINIMUMODDVALUE: "Minimum quota value",
      TOTALODDVALUE: "Total unit value",
      BETTING_BONUSTYPE: "Bonus type",
      BETTING_BONUSAMOUNT: "Enabled with bonus amount",
      BETTING_SPORTS: "Disciplines",
      SPORTINCLUSIONTYPE: "Included / excluded",
      BETTING_TOURNAMENTS: "Events",
      BETTING_EVENTS: "Events",
      TOURNAMENTINCLUSIONTYPE: "Included / excluded",
      EVENTINCLUSIONTYPE: "Included / excluded",
      INCLUDEDSPORTS: "Disciplines included",
      EXCLUDEDSPORTS: "Disciplines excluded",
      INCLUDEDTOURMANETS: "Events included",
      EXCLUDEDTOURNAMENTS: "Excluded events",
      INCLUDEDEVENTS: "Included events",
      EXCLUDEDEVENTS: "Excluded events",
      ADDSPORT: "Include discipline",
      ADDTOURNAMENT: "Include manifestation",
      ADDEVENT: "Include event",
      EXCLUDESPORT: "Exclude discipline",
      EXCLUDETOURNAMENT: "Exclude event",
      EXCLUDEEVENT: "Exclude event",
      SPORTSELECTOR: "Select discipline",
      SPORTSELECTOR_NAMECOLUMN: "Discipline",
      TOURNAMENTTSELECTOR: "Select event",
      TOURNAMENTSELECTOR_NAMECOLUMN: "Event",
      EVENTSELECTOR: "Select event",
      EVENTSELECTOR_NAMECOLUMN: "Event",
      MAX_BONUS: "Maximum Bonus",
      MIN_REFILL: "Minimum Reload",
      ACTIVE: "Active",
      STARTDATE_COLUMN: "Start date",
      ENDDATE_COLUMN: "End date",
      SYSTEMDATE_COLUMN: "Date modified",
      TRANCHE_SECTION_NOT_VALID: "Fill in section Tranche correctly",
      IN_CONFIGURATION: "In Configuration",
      PERCENTAGE_SECTION_NOT_VALID: "Fill in the percentage fields correctly",
      BONUS_SECTION_NOT_VALID: "Fill in the bonus fields correctly",
      FAKE_MONEY_SECTION_NOT_VALID:
        "Fill in the fields relating to the Fake Money section correctly",
      VIEW_TITLE: "View Bonus",
      EDIT: "Edit",
      VIEW: "View",
      FILTER_TOURNAMENTS: "Search events",
      FILTER_EVENTS: "Search events",
      VERIFIED_ACCOUNT: "Verified Account",
      BONUSTYPE: "Bonus Type",
      STARTDATE_FILTER: "Start date",
      ENDDATE_FILTER: "End date",
      MINIMUMAMOUNT: "Minimum amount",
      BETTING_WINONLY: "Win only",
    },
    PROMOTIONSPOINTCODE: {
      TITLE: "Promotions",
      PROMO_CODE: "Promotion Code",
      POINT_CODE: "Point Code",
      ACTIVE: "Active",
      NEW_TITLE: "New Promotion",
      EDIT_TITLE: "Edit Promotion",
      MULTIPLE_CODES: "Multiple Codes",
      MULTIPLE_BONUS: "Multiple Bonuses",
      MAX_INSERT: "Maximum number of insertions",
      INSERT_COUNTER: "Insertion counter",
      STARTDATE_COLUMN: "Start date",
      ENDDATE_COLUMN: "End date",
      SYSTEMDATE_COLUMN: "Date modified",
      STARTDATE: "Start",
      ENDDATE: "End",
      BONUS: "Bonus",
      POINT_CODE_SECTION_NOT_VALID: "Please enter a valid point code",
    },
  },
};
