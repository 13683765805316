import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../core/modules/shared.module';
import {TinymceModule} from '../tinymce/angular2-tinymce.module';
import {TinymceDefaultOptions} from '../tinymce/angular2-tinymce.default';

import {routes} from './help.routes';
import {Sections} from '../help/sections/sections.component';
import {SectionsEditor} from './sections/sectionseditor.component';
import {SelectSections} from './sections/select.section.component';
import {AngularEditorModule} from '@kolkov/angular-editor';

const defaultTinyMceConfig = {
    plugins: [
        'link',
        'paste',
        'table',
        'advlist',
        'autoresize',
        'lists',
        'code',
        'colorpicker',
        'textcolor'
    ],
    toolbar: [
        'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify' +
        ' | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons'
    ],
    skin_url: './assets/tinymce/skins/lightgray',
    baseURL: './assets/tinymce',
    auto_focus: true,
    paste_as_text: true
};

@NgModule({
    declarations: [
        Sections,
        SectionsEditor,
        SelectSections
    ],
    entryComponents: [
        SectionsEditor,
        SelectSections
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        TinymceModule.withConfig(defaultTinyMceConfig),
        AngularEditorModule
    ],
    providers: [
        {provide: 'TINYMCE_CONFIG', useClass: TinymceDefaultOptions}
    ]
})

export class HelpModule {
}
