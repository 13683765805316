import {Component, OnInit, Inject, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GetContentsRequest, SaveContentRequest } from '../../models/DataModels/Cms/Request';
import { Observable } from 'rxjs';
import { TableDataSource } from '../../models/DataModels/TableDataSource/TableDataSource';
import { ApisService } from 'src/app/shared/apis.service';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/shared/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/shared/appconfig.service';
import { ContentsModel, GetItemsBaseResponse, UserAccess } from '../../models/DataModels';
import { ModelRequest, DataBaseRequestField, FilterCommand } from '../../models/DataModels/TableDataSource/ModelRequest';
import { HttpDao } from '../../models/DataModels/TableDataSource/HttpDao';
import { FuseConfirmDialogComponent } from '../../core/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '../../core/animations';
import { ContentEditorComponent } from './contenteditor.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.scss'],
  animations: fuseAnimations
})

export class ContentsComponent implements OnInit, OnDestroy,AfterViewInit {
  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public panelTitle = 'CONTENTS.TITLE';
  protected request = new GetContentsRequest();
  public dataSource: TableDataSource<GetContentsRequest, GetItemsBaseResponse<ContentsModel>>;
  public displayedColumns = ['action', 'name', 'changedAt', 'changedBy'];
  public model: ModelRequest<GetContentsRequest>;
  private httpDao: ContentsHttpDao;

  private firstLoad: Subscription;



  constructor(
    @Inject(AppConfigService) private appConfig,
    @Inject(NotifyService) private notifyService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private apisService: ApisService,
    private cd : ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.httpDao = new ContentsHttpDao(this.apisService);
    this.model = new ModelRequest<GetContentsRequest>(this.request);
    this.model.showAddItem = true;

    const tmpItems = new Array<DataBaseRequestField>();
    tmpItems.push(new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string')
    );
    this.model.items = tmpItems;

    this.firstLoad = this.httpDao.firstLoad.subscribe((userAccess: UserAccess) => {
      this.request.includeAccess = false;
      // this.userAccess = userAccess;
      this.model.updateShowAdd(this.userAccess.canInsert);
    });

  }

  ngAfterViewInit() {
    this.dataSource = new TableDataSource<GetContentsRequest, GetItemsBaseResponse<ContentsModel>>(this.httpDao!, this.paginator, this.sort, this.model);
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.dataSource.disconnect();
    if (this.firstLoad) {
      this.firstLoad.unsubscribe();
      this.firstLoad = undefined;
    }

  }

  onSubmit() {
    this.paginator.pageIndex = 0;
    this.model.changed.emit();
  }

  edit(item?: ContentsModel, event?: any): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.userAccess.canEdit) return;
    let id = item !== undefined ? item.id : undefined;
    let version = item !== undefined ? item.version : undefined;
    let dialogRef = this.dialog.open(ContentEditorComponent, {
      width: '80%',
      data: {
        id: id,
        version: version
      },
      height: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.model.reset();
      }
    });
  }

  add(): void {
    if (!this.userAccess.canInsert) return;
    this.edit(undefined);
  }

  delete(item?: ContentsModel, event?: any): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.userAccess.canDelete) return;
    if (item === undefined) return;
    this.translateService.get('COMMON.DELETEMESSAGE').subscribe(translate => {
      translate += `${item.name}?`;
      let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        width: '50%'
      });
      dialogRef.componentInstance.confirmMessage = translate;
      dialogRef.afterClosed().subscribe(confirm => {
        if (confirm === true) {
          this.dataSource.isLoadingResults = true;
          this.apisService.deleteContent(item.id).subscribe(result => {
            this.dataSource.isLoadingResults = false;
            this.notifyService.handleServerResponse(result, res => {
              this.model.reset();
            });
          });
        }
      });
    });
  }

  clone(item: ContentsModel, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    if (item === undefined) {
      return;
    }
    this.dataSource.isLoadingResults = true;
    const request = new SaveContentRequest();
    request.id = item.id;
    request.copy = true;
    const savesub = this.apisService.saveContent(request).subscribe(response => {
      this.dataSource.isLoadingResults = false;
      this.notifyService.handleServerResponse(response, result => {
        this.model.reset();
      });
    });
  }
}


export class ContentsHttpDao extends HttpDao<GetContentsRequest, GetItemsBaseResponse<ContentsModel>> {
  constructor(public apisService: ApisService) {
    super(apisService);
  }

  public getData(request: GetContentsRequest): Observable<GetItemsBaseResponse<ContentsModel>> {
    return this.apisService.getContents(request)
      .pipe(map(res => {
        if (res.isError) {
          return <GetItemsBaseResponse<ContentsModel>>{
            total: 0,
            items: []
          };
        } else {
          if (request.includeAccess && res.userAccess) {
            this.firstLoad.next(res.userAccess);
          }

          this.itemsChangedAt(res.data);
          return res.data;
        }
      }));
  }
}

