<div class="outcome dialog-container">
    <div class="page-layout carded fullwidth ps dialog" fuseperfectscrollbar>
        <div class="dialog-modal">
            <div class="header" fxLayout="column" fxLayoutAlign="space-between">
                <div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
                    <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button class="mat-icon-button cmd-buttons" (click)="close()">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                        <span class="logo-text" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{'BETTINGGROUPS.CREATEBETOUTCOME_TITLE'
                            | translate}}</span>
                        <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
                            <button mat-button class="mat-icon-button cmd-buttons" (click)="save()" [disabled]="pageForm.invalid">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-container">
            <div name="pageForm" [formGroup]="pageForm" fxLayout="row" fxLayoutAlign="start stretch">
                <input hidden formControlName="betId">
                <mat-form-field fxFlex="33.33">
                    <input matInput placeholder="{{'BETTINGGROUPS.BET_SELECT' | translate}}" formControlName="betDescription"
                        [matAutocomplete]="betOptions">
                    <mat-autocomplete #betOptions="matAutocomplete">
                        <mat-option *ngFor="let bet of filteredBets | async" [value]="bet.value">
                            {{ bet.value }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field fxFlex="33.33">
                    <mat-select matInput placeholder="{{'BETTINGGROUPS.OUTCOME_ID' | translate}}" formControlName="outcomeId">
                        <mat-option *ngFor="let o of outcomes" [value]="o.id">
                            {{ o.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="33.33">
                    <input matInput type="number" placeholder="{{'BETTINGGROUPS.BET_ORDER' | translate}}" formControlName="order">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>