<div class="page-layout carded fullwidth ps" fuseperfectscrollbar>
  <generic-search-filter [requestModel]="model" [panelTitle]="panelTitle" [panelIcon]="'widgets'" (onSubmit)="onSubmit()" (onAddItem)="edit()"></generic-search-filter>

  <div class="custom-center" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
    <div class="content-card" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
      <div *ngIf="dataSource?.isEmpty || dataSource?.isError">
        <div class="example-rate-limit-reached table-error-message" *ngIf="dataSource?.isEmpty">
          <span>{{'COMMON.NO_ITEMS' | translate}}</span>
        </div>
        <div class="example-rate-limit-reached table-error-message" *ngIf="dataSource?.isError">
          <span>{{'COMMON.ERROR_LOADING' | translate}}</span>
        </div>
      </div>
      <mat-table [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [dataSource]="dataSource" class="table-items mat-table"
        matSort matSortDisableClear matSortActive="changedAt" matSortDirection="desc" fuseperfectscrollbar>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'WIDGETS.NAME_COLUMN' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'WIDGETS.TYPE_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.type}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="frontend">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'WIDGETS.FRONTEND_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="widgets-frontend">{{row.frontend}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="notes">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'WIDGETS.NOTES_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="widgets-notes" [matTooltip]="row.notes">{{row.notes}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="published">
          <mat-header-cell *matHeaderCellDef>{{'WIDGETS.PUBLISHED_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon [ngClass]="{'check': row.published, 'clear': !row.published}">
              {{row.published ? "check": "clear"}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="changedAt">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMMON.CHANGED_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.changedFormated}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="changedBy">
          <mat-header-cell *matHeaderCellDef>{{'COMMON.CHANGEDBY_COLUMN' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.changedBy}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>
            <span>{{'MEDIA.ACTION' | translate}}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div fxLayoutAlign="end">
              <button mat-icon-button *ngIf="userAccess.canEdit" (click)="edit(row, $event)" name="dialogButton" matTooltip="{{'COMMON.EDIT' | translate}}">
                <mat-icon class="edit">edit</mat-icon>
              </button>
              <button mat-icon-button *ngIf="userAccess.canDelete && row.canDelete" (click)="delete(row, $event)" name="deleteMediaButton"
                matTooltip="{{'COMMON.DELETE' | translate}}">
                <mat-icon class="delete-icon">delete</mat-icon>
              </button>
              <button mat-icon-button *ngIf="userAccess.canInsert && row.canDelete" (click)="clone(row, $event)" name="cloneButton" matTooltip="{{'COMMON.CLONE' | translate}}">
                <mat-icon class="content-copy">content_copy</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)" [ngClass]="{'fixed-widget': row.isFixedWidget}"></mat-row>

      </mat-table>
      <div class="media-loading-shade" *ngIf="dataSource?.isLoadingResults">
        <mat-spinner [color]="'accent'"></mat-spinner>
      </div>
      <mat-paginator [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [length]="dataSource?.resultsLength"
        [pageSize]="dataSource?.pageSize">
      </mat-paginator>
    </div>
  </div>
</div>
