import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "fuse-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class FuseConfirmDialogComponent implements OnInit {
  public confirmMessage: string;
  title: string = "Conferma";
  noButton: string = "No";
  yesButton: string = "Si";

  inverse: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>
  ) {
    this.inverse = data && data.inverse;
  }

  ngOnInit() {}
}
