import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GetGamesRequest} from '../../models/DataModels/Cms/Request';
import {Observable, Subscription} from 'rxjs';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {ApisService} from 'src/app/shared/apis.service';
import {NotifyService} from 'src/app/shared/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {GamesModel, GetItemsBaseResponse, UserAccess} from '../../models/DataModels';
import {DataBaseRequestField, ModelRequest, SelectItem} from '../../models/DataModels/TableDataSource/ModelRequest';
import {HttpDao} from '../../models/DataModels/TableDataSource/HttpDao';
import {FuseConfirmDialogComponent} from '../../core/components/confirm-dialog/confirm-dialog.component';
import {fuseAnimations} from '../../core/animations';
import {map} from 'rxjs/operators';

@Component({
    selector: 'games',
    templateUrl: './games.component.html',
    styleUrls: ['./games.component.scss'],
    animations: fuseAnimations
})
export class GamesComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    panelTitle = 'GAMES.TITLE';
    dataSource: TableDataSource<GetGamesRequest, GetItemsBaseResponse<GamesModel>>;
    displayedColumns = ['action', 'imageMini', 'name', 'fornitore', 'order', 'isEnabled', 'isVisible', 'isJackpot', 'isMobile', 'isDesktop', 'isDemo', 'systemDate'];
    model: ModelRequest<GetGamesRequest>;

    protected request: GetGamesRequest = new GetGamesRequest();
    private providers = new DataBaseRequestField('provider', 'GAMES.SEARCH_PROVIDER_LABEL', 'select', false, new Array<SelectItem>());

    private httpDao: GamesHttpDao;
    private firstLoad: Subscription;

    static savedRequest: GetGamesRequest;

    constructor(
        @Inject(AppConfigService) private appConfig: AppConfigService,
        @Inject(NotifyService) private notifyService,
        private translateService: TranslateService,
        public dialog: MatDialog,
        private apisService: ApisService,
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        // const internalData = this.appConfig.temporaryData;
        // if (internalData && internalData.internal && internalData.data) {
        //   let request = internalData.data as GetGamesRequest;
        //   if (request) {
        //     this.request = request;
        //   }
        // }

        if (this.appConfig.isBackNavigation() && GamesComponent.savedRequest) {
            Object.assign(this.request, GamesComponent.savedRequest);
        }

        this.request.includeAccess = true;
        this.providers.selectItems = new Array<SelectItem>();
        this.providers.selectItems.push(<SelectItem> {
            id: undefined,
            value: ''
        });
    }

    ngOnInit() {
        this.httpDao = new GamesHttpDao(this.apisService);
        this.firstLoad = this.httpDao.firstLoad.subscribe((userAccess: UserAccess) => {
            this.request.includeAccess = false;
            // this.userAccess = userAccess;
            this.model.updateShowAdd(this.userAccess.canInsert);
        });
        this.model = new ModelRequest<GetGamesRequest>(this.request);
        this.model.columns = 5;
        this.model.showAddItem = true;

        let tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string'),
            new DataBaseRequestField('gameId', 'GAMES.SEARCH_GAMEID_LABEL', 'string'),
            new DataBaseRequestField('adm', 'GAMES.SEARCH_ADM_LABEL', 'string'),
            this.providers,
            new DataBaseRequestField('channel', 'GAMES.SEARCH_CHANNEL_LABEL', 'select', false, [{
                id: '1',
                value: 'Desktop'
            }, {
                id: '0',
                value: 'Mobile'
            }, {
                id: '-1',
                value: 'Tutti'
            }]),
            new DataBaseRequestField('jackpot', 'GAMES.SEARCH_JACKPOT_LABEL', 'select', false, [{
                id: '1',
                value: 'Si'
            }, {
                id: '0',
                value: 'No'
            }, {
                id: '-1',
                value: 'Tutti'
            }]),
            new DataBaseRequestField('visible', 'GAMES.SEARCH_VISIBLE_LABEL', 'select', false, [{
                id: '1',
                value: 'Si'
            }, {
                id: '0',
                value: 'No'
            }, {
                id: '-1',
                value: 'Tutti'
            }]),
            new DataBaseRequestField('enabled', 'GAMES.SEARCH_ENABLED_LABEL', 'select', false, [{
                id: '1',
                value: 'Si'
            }, {
                id: '0',
                value: 'No'
            }, {
                id: '-1',
                value: 'Tutti'
            }]),
            new DataBaseRequestField('isNew', 'GAMES.SEARCH_ISNEW_LABEL', 'select', false, [{
                id: '1',
                value: 'Si'
            }, {
                id: '0',
                value: 'No'
            }, {
                id: '-1',
                value: 'Tutti'
            }]),
            new DataBaseRequestField('room', 'GAMES.SEARCH_ROOM_LABEL', 'select', false, [{
                id: 'Admiral',
                value: 'Admiral'
            }, {
                id: 'Seven',
                value: 'Seven'
            }, {
                id: '-1',
                value: 'Tutti'
            }]));
        this.model.items = tmpItems;

        const providersLoader = this.apisService.getGameProviders(false).subscribe(response => {
            if (!response.isError) {
                this.providers.selectItems.push(...response.data);
            }
            providersLoader.unsubscribe();
        });
    }

    ngAfterViewInit() {
        this.dataSource = new TableDataSource<GetGamesRequest, GetItemsBaseResponse<GamesModel>>(this.httpDao!, this.paginator, this.sort, this.model);
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        GamesComponent.savedRequest = new GetGamesRequest();
        Object.assign(GamesComponent.savedRequest, this.model.request);
        this.dataSource.disconnect();
        if (this.firstLoad) {
            this.firstLoad.unsubscribe();
            this.firstLoad = undefined;
        }
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    edit(item?: GamesModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canEdit) {
            return;
        }
        this.appConfig.temporaryData = {'internal': true};
        if (item !== undefined) {
            this.appConfig._temporaryData = {
                internal: true,
                data: this.request
            };
            this.router.navigate(['gioco', item.id], {
                relativeTo: this.route
            });
        }
    }

    add(): void {
        if (!this.userAccess.canInsert) {
            return;
        }
        this.router.navigate(['add'], {
            relativeTo: this.route
        });
    }

    delete(item?: GamesModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (!this.userAccess.canDelete || item === undefined) {
            return;
        }
        this.translateService.get('COMMON.DELETEMESSAGE').subscribe(translate => {
            translate += item.name + '?';
            let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {
                    this.dataSource.isLoadingResults = true;
                    this.apisService.deleteGame(item.id).subscribe(result => {
                        this.dataSource.isLoadingResults = false;
                        this.notifyService.handleServerResponse(result, res => {
                            this.model.reset();
                        });
                    });
                }
            });
        });
    }
}


export class GamesHttpDao extends HttpDao<GetGamesRequest, GetItemsBaseResponse<GamesModel>> {
    constructor(public apisService: ApisService) {
        super(apisService);
    }

    public getData(request: GetGamesRequest): Observable<GetItemsBaseResponse<GamesModel>> {
        return this.apisService.getGames(request)
            .pipe(map(res => {
                if (res.isError) {
                    return <GetItemsBaseResponse<GamesModel>> {
                        total: 0,
                        items: []
                    };
                } else {
                    if (request.includeAccess && res.data.userAccess) {
                        this.firstLoad.next(res.data.userAccess);
                    }
                    return res.data;
                }
            }));
    }
}
