import { Component, Input, OnInit, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseConfigService } from '../../core/services/config.service';
import { ApisService } from 'src/app/shared/apis.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from 'src/app/shared/notify.service';
import { AppConfigService } from 'src/app/shared/appconfig.service';
import { BaseEditorComponent } from '../../components/baseeditor.component';
import { BaseModel, UserAccess } from '../../models/DataModels';
import { SaveMobileRequest } from '../../models/DataModels/Cms/Request';
import { fuseAnimations } from '../../core/animations';


@Component({
  selector: 'app-mobilecontenteditor',
  templateUrl: './mobilecontenteditor.component.html',
  styleUrls: ['./mobilecontenteditor.component.scss'],
  animations: fuseAnimations
})

export class MobileContentEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  skins: BaseModel[];
  isLoading = false;
  formErrors: any;
  isNew = false;
  skinId: string;
  pageId: string;
  pages: BaseModel[];

  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true
  };

  constructor(
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    @Inject(NotifyService) protected notifyService,
    @Inject(AppConfigService) private appConfig,
    private location: Location,
    private apisService: ApisService,
    private fuseConfig: FuseConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    super();
    this.formErrors = {
      name: {},
      skinId: {},
      pageId: {},
      authenticated: {},
      enabled: {},
      sort: {},
      startDate: {}
    };
  }

  ngOnInit(): void {
    this.createForm();
    this.subscribers.push(this.route.params.subscribe(params => {
      this.id = params.id;
      this.isNew = this.id === undefined;
      this.loadPage(this.id);
    }));
  }

  ngOnDestroy(): void {
    this.clear();
  }

  loadPage(id: string): void {
    this.isLoading = true;
    this.subscribers.push(this.apisService.geMobileContent(id).subscribe(response => {
      if (response.isError) {
        this.isLoading = false;
      }
      this.notifyService.handleServerResponse(response, result => {
        // this.userAccess = response.userAccess;
        this.clear();
        this.skins = result.skins;
        this.pages = result.pages;
        if (this.isNew) {
          this.pageForm.patchValue({
            startDate: new Date(),
            enabled: true,
            authenticated: false
          });
        } else {
          this.pageForm.patchValue(result.item);
          this.skinId = result.item.skinId;
          this.pageId = result.item.pageId;
        }
        const timer = window.setTimeout(() => {
          this.pageForm.markAsPristine();
          this.isLoading = false;
          if (!this.isNew) {
            this.pageForm.controls.skinId.disable();
          }
          window.clearTimeout(timer);
        }, 800);


        this.isLoading = false;
      });
    }));
  }

  save(publish?: boolean, overwrite?: boolean): void {
    if (!this.userAccess.canEdit || !this.pageForm.valid) { return; }
    if (this.pageForm.dirty) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = {} as SaveMobileRequest;
        Object.assign(request, this.pageForm.getRawValue());
        request.overwrite = overwrite;
        request.id = this.id;
        request.publish = publish;
        this.subscribers.push(this.apisService.saveMobile(request).subscribe(response => {
          this.isLoading = false;
          this.handleServerResponse(response, result => {
            this.pageForm.reset();
            if (this.id === result) {
              this.loadPage(this.id);
            } else {
              this.router.navigate(['/cms/mobile/edit', result]);
            }
          }, publish);
        }));
      } else {
        this.notifyService.warning('COMMON.INVALIDDATA');
      }
    }
  }

  publish(): void {
    if (this.pageForm.invalid) {
      this.notifyService.error('COMMON.INVALIDPUBLISH');
    } else {
      this.pageForm.markAsDirty();
      this.save(true);
    }
  }

  onSkinChanged() {
    this.pages.splice(0);
    this.pageForm.patchValue({
      pageId: undefined
    });
    this.pageId = undefined;
    if (this.skinId) {
      this.isLoading = true;
      this.apisService.getSkinPages(this.skinId).subscribe(response => {
        this.notifyService.handleServerResponse(response, result => {
          this.pages = result;
          this.isLoading = false;
        });
      });
    }
  }

  protected internalClose(): void {
    const data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(['cms/mobile']);
    }
  }

  private createForm(): void {
    this.pageForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      skinId: ['', Validators.required],
      authenticated: [false],
      sort: [''],
      version: [],
      pageId: ['', Validators.required],
      enabled: [true, Validators.required],
      startDate: [undefined, Validators.required],
      endDate: [undefined]
    });
    this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    });
  }

  private onFormValuesChanged(data): void {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      const difference = this.formErrors[field].difference;
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
      if (difference) {
        this.formErrors[field].difference = difference;
      }
    }
  }
}
