<div class="page-layout carded fullwidth ps">
    <generic-search-filter [requestModel]="model" [panelTitle]="panelTitle" [panelIcon]="'insert_drive_file'" (onSubmit)="onSubmit()"
      (onAddItem)="add()"></generic-search-filter>
  
    <div class="custom-center" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
      <div class="content-card" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
        <div *ngIf="dataSource?.isEmpty || dataSource?.isError">
          <div class="table-error-message" *ngIf="dataSource?.isEmpty">
            <span>{{'COMMON.NO_ITEMS' | translate}}</span>
          </div>
          <div class="table-error-message" *ngIf="dataSource?.isError">
            <span>{{'COMMON.ERROR_LOADING' | translate}}</span>
          </div>
        </div>
        <mat-table [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [dataSource]="dataSource" class="table-items mat-table"
          [@animateStagger]="{value:'50'}" matSort matSortDisableClear matSortActive="changedAt" matSortDirection="desc" fuseperfectscrollbar>
  
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MOBILE.NAME_COLUMN' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="skin">
            <mat-header-cell *matHeaderCellDef>{{'MOBILE.SKIN' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.skin}} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="page">
            <mat-header-cell *matHeaderCellDef>{{'MOBILE.PAGE' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.page}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sort">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MOBILE.SORT' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.sort}} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="published">
            <mat-header-cell *matHeaderCellDef>{{'PAGES.PUBLISHED_COLUMN' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-icon [ngClass]="{'check': row.published, 'clear': !row.published}">
                {{row.published ? "check": "clear"}}
              </mat-icon>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="publishedDate">
            <mat-header-cell *matHeaderCellDef>{{'PAGES.PUBLISHED_DATE_COLUMN' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.publishedDate}}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="changedAt">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMMON.CHANGED_COLUMN' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.changedFormated}}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="changedBy">
            <mat-header-cell *matHeaderCellDef>{{'COMMON.CHANGEDBY_COLUMN' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.changedBy}}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'MEDIA.ACTION' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div fxLayoutAlign="end">
                <button mat-icon-button *ngIf="userAccess.canEdit" (click)="edit(row, $event)" name="editButton" matTooltip="{{'PAGES.EDIT' | translate}}">
                  <mat-icon class="edit">edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="userAccess.canDelete" (click)="delete(row, $event)" name="deleteButton" matTooltip="{{'COMMON.DELETE' | translate}}">
                  <mat-icon class="delete-icon">delete</mat-icon>
                </button>
              </div>
  
            </mat-cell>
          </ng-container>
  
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></mat-row>
  
        </mat-table>
        <div class="media-loading-shade" *ngIf="dataSource?.isLoadingResults">
          <mat-spinner [color]="'accent'"></mat-spinner>
        </div>
        <mat-paginator [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [length]="dataSource?.resultsLength"
          [pageSize]="dataSource?.pageSize">
        </mat-paginator>
      </div>
    </div>
  
  </div>
