import { Inject, Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "../shared/auth.service";
import { FuseNavigationService } from "../core/components/navigation/navigation.service";

@Injectable()
export class PageCanActivateGuard implements CanActivate {
  constructor(
    @Inject(AuthService) private authService: AuthService,
    @Inject(FuseNavigationService) private navigation: FuseNavigationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const role = state.url;
    if (!this.authService.hasRole(role)) {
      this.navigation.navigate("/unauthorized");
      return false;
    }
    return true;
  }
}
