import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../core/modules/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AuthService } from '../shared/auth.service';
import { PageEditorGuard } from '../components/editorguard';
import { PageCanActivateGuard } from '../components/activateguard';
// guards

import { routes } from './promotion.routes';

import { BonusComponent } from './bonus/bonus.component';
import { BonusEditorComponent } from './bonus/bonuseditor.component';

import { PromoComponent } from './promotion/promo.component';
import { PromoEditorComponent } from './promotion/promoeditor.component';

import { BettingDetailComponent } from './bonus/bettingdetail.component';

import { SportSelectorComponent } from './bonus/sportselector.component';
import { TournamentSelectorComponent } from './bonus/tournamentselector.component';
import { EventSelectorComponent } from './bonus/eventselector.component';

@NgModule({
    declarations: [
        BonusComponent,
        BonusEditorComponent,
        BettingDetailComponent,
        SportSelectorComponent,
        TournamentSelectorComponent,
        EventSelectorComponent,
        PromoComponent,
        PromoEditorComponent
    ],
    entryComponents: [
        BettingDetailComponent,
        SportSelectorComponent,
        TournamentSelectorComponent,
        EventSelectorComponent
    ],
    imports: [
        BrowserAnimationsModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    providers: [
        AuthService,
        PageEditorGuard,
        PageCanActivateGuard
    ]
})

export class PromotionModule {
}
