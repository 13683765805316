<div class="dialog-container">
  <div class="page-layout carded fullwidth ps dialog" fuseperfectscrollbar>
    <generic-search-filter [requestModel]="model" [panelTitle]="panelTitle" [panelIcon]="'grid_on'" (onSubmit)="onSubmit()"></generic-search-filter>

    <div class="content-container" [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
      <div [ngClass]="{ 'no-mat-white-bg': dataSource?.isEmpty || dataSource?.isError }">
        <div *ngIf="dataSource?.isEmpty || dataSource?.isError">
          <div class="example-rate-limit-reached table-error-message" *ngIf="dataSource?.isEmpty">
            <span>{{'COMMON.NO_ITEMS' | translate}}</span>
          </div>
          <div class="example-rate-limit-reached table-error-message" *ngIf="dataSource?.isError">
            <span>{{'COMMON.ERROR_LOADING' | translate}}</span>
          </div>
        </div>
        <mat-table [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [dataSource]="dataSource" class="table-items mat-table"
          matSort matSortActive="name" matSortDisableClear matSortDirection="asc" fuseperfectscrollbar>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'LAYOUT.NAME_COLUMN' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
          </ng-container>

          <!-- <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'MEDIA.ACTION' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div fxLayoutAlign="end">
                <button mat-icon-button (click)="select(row)" name="dialogButton" matTooltip="{{'COMMON.SELECT' | translate}}">
                  <mat-icon>check</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container> -->
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></mat-row>

        </mat-table>
        <div class="media-loading-shade" *ngIf="dataSource?.isLoadingResults">
          <mat-spinner [color]="'accent'"></mat-spinner>
        </div>
        <mat-paginator [ngStyle]="{'display': dataSource?.isEmpty || dataSource?.isError ? 'none' : ''}" [length]="dataSource?.resultsLength"
          [pageSize]="dataSource?.pageSize">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
