import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GetPagesRequest} from '../../models/DataModels/Cms/Request';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {GetItemsBaseResponse, PageModel} from '../../models/DataModels';
import {DataBaseRequestField, ModelRequest} from '../../models/DataModels/TableDataSource/ModelRequest';
import {AppConfigService} from 'src/app/shared/appconfig.service';
import {NotifyService} from 'src/app/shared/notify.service';
import {ApisService} from 'src/app/shared/apis.service';
import {TranslateService} from '@ngx-translate/core';
import {PagesHttpDao} from './pages.component';
import {fuseAnimations} from '../../core/animations';

@Component({
    selector: 'app-pageselectordialog',
    templateUrl: './pageselectordialog.component.html',
    styleUrls: ['./pageselectordialog.component.scss'],
    animations: fuseAnimations
})

export class PageSelectorDialogComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    protected request = new GetPagesRequest();
    public dataSource: TableDataSource<GetPagesRequest, GetItemsBaseResponse<PageModel>>;
    public displayedColumns = ['name', 'action'];
    public model: ModelRequest<GetPagesRequest>;
    public panelTitle = 'PAGES.SELECTOR_TITLE';
    private httpDao: PagesHttpDao;

    constructor(@Inject(AppConfigService) private appConfig,
                @Inject(NotifyService) private notifyService,
                @Inject(MAT_DIALOG_DATA) public input: any,
                public dialogRef: MatDialogRef<PageSelectorDialogComponent>,
                private translateService: TranslateService,
                public dialog: MatDialog,
                private apisService: ApisService,
                private route: ActivatedRoute,
                private router: Router) {

    }

    ngOnInit() {
        this.httpDao = new PagesHttpDao(this.apisService);
        this.model = new ModelRequest<GetPagesRequest>(this.request);
        this.model.showAddItem = true;

        this.model.showReset = false;
        let tmpItems = new Array<DataBaseRequestField>();
        tmpItems.push(new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string'),
            new DataBaseRequestField('title', 'COMMON.SEARCH_TITLE_LABEL', 'string')
        );
        if (!this.input.hideSkin) {
            tmpItems.push(new DataBaseRequestField('skin', 'COMMON.SEARCH_SKIN_LABEL', 'string'));
        }
        if (!this.input.hideFrontend) {
            tmpItems.push(new DataBaseRequestField('frontend', 'COMMON.SEARCH_FRONTEND_LABEL', 'string'));
        }
        this.model.items = tmpItems;
        if (this.input.skin) {
            this.request.skin = this.input.skin;
        }

    }

    ngAfterViewInit() {
        this.dataSource = new TableDataSource<GetPagesRequest, GetItemsBaseResponse<PageModel>>(this.httpDao!, this.paginator, this.sort, this.model);

    }

    ngOnDestroy() {
        this.dataSource.disconnect();
    }

    select(item: PageModel) {
        this.dialogRef.close({
            result: true,
            id: item.id,
            name: item.link
        });
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    add() {

    }
}
