import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BaseWidgetContentComponent } from "./baseWidgetContent.component";
import { ApisService } from "src/app/shared/apis.service";
import { GetExternalMediaRequest } from "../../models/DataModels/Cms/Request";
import {
  BannerButtonModel,
  BannerWidgetModel,
  WidgetType,
} from "../../models/DataModels";
import { MatDialog } from "@angular/material/dialog";
import { MediaSelectorComponent } from "../mediaselector/mediaselector.component";
import { ContentSelectorDialogComponent } from "../contents/contentselectordialog.component";
import { PageSelectorDialogComponent } from "../pages/pageselectordialog.component";

@Component({
  selector: "app-bannerWidget",
  templateUrl: "./bannerWidget.component.html",
  styleUrls: ["./bannerWidget.component.scss"],
})
export class BannerWidgetComponent extends BaseWidgetContentComponent {
  buttons: BannerButtonModel[];
  backAnimations: Array<any>;
  frontAnimations: Array<any>;
  buttontypes: Array<any>;
  frontAnimation: string;
  backAnimation: string;
  titleColor: string;
  subtitleColor: string;
  titleBackColor: string;
  subtitleBackColor: string;

  private _request = new GetExternalMediaRequest();

  constructor(
    protected apisService: ApisService,
    protected dialog: MatDialog,
    protected formBuilder: FormBuilder
  ) {
    super(formBuilder);
    this.buttons = new Array<BannerButtonModel>();
    this._request.searchById = true;
    this._request.mediaIds = [];
    this.formErrors = {
      BackImage: {},
      BackAnimation: {},
      FrontImage: {},
      FrontAnimation: {},
      Timer: {},
    };
    this.backAnimations = [
      { id: "none", name: "none" },
      { id: "zoomIn", name: "zoomIn" },
      { id: "zoomOut", name: "zoomOut" },
    ];

    this.frontAnimations = [
      { id: "none", name: "none" },
      { id: "slideInLeft", name: "slideInLeft" },
      { id: "slideInRight", name: "slideInRight" },
      { id: "slideInTop", name: "slideInTop" },
      { id: "slideInBottom", name: "slideInBottom" },
      { id: "fade", name: "fade" },
    ];
    this.buttontypes = [
      { value: 0, display: "BANNER.BUTTON_ISCONTENT" },
      { value: 2, display: "BANNER.BUTTON_ISLINK" },
      { value: 3, display: "BANNER.BUTTON_ISFUNCTION" },
    ];
  }

  public getContent() {
    const result = this.contentForm.value as BannerWidgetModel;
    result.Buttons = this.buttons.filter((x) => x.text !== "");
    console.log("BTNs : ", result.Buttons);
    return JSON.stringify(result);
  }

  createForm(value: any) {
    this.contentForm = this.formBuilder.group({
      BackId: [undefined, Validators.required],
      BackImage: [undefined, Validators.required],
      BackAnimation: [],
      FrontImage: [undefined],
      FrontId: [undefined],
      FrontAnimation: [undefined],
      Title: [undefined],
      TitleBackColor: [undefined],
      TitleColor: [undefined],
      Subtitle: [undefined],
      SubtitleBackColor: [undefined],
      SubtitleColor: [undefined],
      TitleAnimation: [undefined],
      Timer: [false, Validators.required],
      Timerstart: [false],
      Ratio: [],
      Text: [],
    });
    if (value !== "" && value !== undefined) {
      try {
        const content = JSON.parse(value) || new BannerWidgetModel();
        console.log({ content });
        if (content.BackId) {
          if (!content.FrontAnimation) {
            content.FrontAnimation = this.frontAnimations[0].id;
          }
          if (!content.BackAnimation) {
            content.BackAnimation = this.backAnimations[0].id;
          }
          this.contentForm.patchValue(content);
          this.frontAnimation = this.contentForm.value.FrontAnimation;
          this.backAnimation = this.contentForm.value.BackAnimation;
          this.buttons = content.Buttons;
          this.titleColor = this.contentForm.value.TitleColor;
          this.subtitleColor = this.contentForm.value.SubtitleColor;
          this.titleBackColor = this.contentForm.value.TitleBackColor;
          this.subtitleBackColor = this.contentForm.value.SubtitleBackColor;

          console.log({ btns: this.buttons });
        }
      } catch (error) {}
    }
  }

  removeBackImage() {
    this.contentForm.patchValue({
      BackId: undefined,
      BackImage: undefined,
    });
  }

  removeFrontImage() {
    this.contentForm.patchValue({
      FrontId: undefined,
      FrontImage: undefined,
    });
  }

  showMediaSelector(isFront?: boolean) {
    this.contentChanged.emit(this.getContent());
    const dialogRef = this.dialog.open(MediaSelectorComponent, {
      width: "100%",
      data: {
        isMultiSelect: false,
      },
      height: "100%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.items && result.items.length > 0) {
        const item = result.items[0];
        if (isFront) {
          this.contentForm.patchValue({
            FrontId: item.id,
            FrontImage: item.url,
          });
        } else {
          this.contentForm.patchValue({
            BackId: item.id,
            BackImage: item.url,
          });
        }
      }
    });
  }

  changeFrontImage() {
    this.showMediaSelector(true);
  }
  changeBackImage() {
    this.showMediaSelector(false);
  }

  selectContent(item: BannerButtonModel) {
    const dialogRef = this.dialog.open(ContentSelectorDialogComponent, {
      width: "70%",
      data: {
        type: WidgetType[WidgetType.Html],
      },
      height: "70%",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.result) {
        item.contentId = result.widgetId;
        item.contentName = result.widgetName;
        this.contentChanged.emit(this.getContent());
      }
    });
  }

  selectPage(item: BannerButtonModel) {
    const dialogRef = this.dialog.open(PageSelectorDialogComponent, {
      width: "70%",
      data: {
        type: WidgetType[WidgetType.Html],
      },
      height: "70%",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.result) {
        item.pageLink = result.name;
        this.contentChanged.emit(this.getContent());
      }
    });
  }

  addButton() {
    const btn = new BannerButtonModel();
    btn.type = 0;
    this.buttons.push(btn);
    this.contentChanged.emit(this.getContent());
  }

  deleteButton(item?: BannerButtonModel) {
    this.buttons.splice(this.buttons.indexOf(item), 1);
    // if (this.buttons.length == 0) {
    //   this.addButton();
    // }
    this.contentChanged.emit(this.getContent());
  }

  emitChange() {
    this.contentChanged.emit(this.getContent());
  }

  firstLoad = {
    title: true,
    titleBg: true,
    sub: true,
    subBg: true,
  };

  onSubtitleColorChanged() {
    if (!this.firstLoad.sub) {
      console.log("exec");
      this.contentForm.patchValue({
        SubtitleColor: this.subtitleColor,
      });
    }
    this.firstLoad.sub = false;
  }

  onTitleColorChanged() {
    if (!this.firstLoad.title) {
      console.log("exec");
      this.contentForm.patchValue({
        TitleColor: this.titleColor,
      });
    }
    this.firstLoad.title = false;
  }

  onSubtitleBackColorChanged() {
    if (!this.firstLoad.subBg) {
      console.log("exec");
      this.contentForm.patchValue({
        SubtitleBackColor: this.subtitleBackColor,
      });
    }
    this.firstLoad.subBg = false;
  }

  onTitleBackColorChanged() {
    if (!this.firstLoad.titleBg) {
      console.log("exec");
      this.contentForm.patchValue({
        TitleBackColor: this.titleBackColor,
      });
    }
    this.firstLoad.titleBg = false;
  }

  onButtonTypeChanged(button: BannerButtonModel) {
    button.contentId =
      button.contentName =
      button.customFunction =
      button.link =
      button.pageLink =
        undefined;
  }
}
