import { Inject, Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseWidgetContentComponent } from './baseWidgetContent.component';
import { DATA } from 'src/app/shared/constants/request';
import { TinymceOptions } from '../../tinymce/angular2-tinymce.config.interface';

@Component({
  selector: 'app-textWidget',
  templateUrl: './textWidget.component.html',
  styleUrls: ['./textWidget.component.scss']
})
export class TextWidgetComponent extends BaseWidgetContentComponent {

  constructor(
    @Inject('TINYMCE_CONFIG') public editorConfig: TinymceOptions,
    @Inject(DATA) private tinyMceGallery,
    protected formBuilder: FormBuilder
  ) {
    super(formBuilder);
  }

  createForm(value: any) {
    this.contentForm = this.formBuilder.group({
      text: ['', Validators.required]
    });
    this.contentForm.patchValue({
      text: value
    });
    this.contentForm.markAsPristine();
  }

  public getContent(): any {
    return this.contentForm.value.text;
  }

  get text() {
    return this.contentForm.get('text').value;
  }

  @Input('text') set text(value: string) {
    this.contentForm.patchValue({
      text: value
    });
  }
}

