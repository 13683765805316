import { Route } from "@angular/compiler/src/core";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

@Injectable()
export class LoginAuthGuard implements CanActivate, CanLoad {
  /**
   * Guard checking for authenticated user.
   *
   * @param next Route snapshot.
   * @param state Route state snapshot.
   *
   * @returns Observable<boolean> | Promise<boolean> | boolean value that indicates if a route can be activated.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.guardLogic();
  }

  canLoad(route: Route): Promise<boolean | UrlTree> {
    return this.guardLogic();
  }

  guardLogic() {
    return new Promise<boolean>((resolve) => {
      resolve(true);
    });
    // if (environment.production) {
    //     return this.authService.isAuthenticated();
    // } else {
    //     return new Promise<boolean>((resolve) => {
    //         resolve(true);
    //     });
    // }
  }
}
