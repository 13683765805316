import { Component, OnInit, Input, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DynamicComponent } from './layoutcontent.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LayoutComponent implements OnInit {

  configChanged: EventEmitter<LayoutConfig> = new EventEmitter();
  columns: LayoutColumn[];

  private _config: LayoutConfig;


  constructor() {

  }

  ngOnInit() {

  }

  get config(): LayoutConfig {
    return this._config;
  }

  @Input('config')
  set config(value: LayoutConfig) {
    if (this._config === value) { return; }
    this._config = value;
    if (this._config !== undefined) {
      this.columns = this._config!.columns || [];
      this.configChanged.emit(this._config);
    } else {
      this._config = undefined;
      this.columns = [];
    }

  }


}

export class LayoutConfig {
  columns: LayoutColumn[];
}

export abstract class LayoutItem {
  id?: string;
  width: string;
  isLocked?: boolean = false;
  content?: DynamicComponent;
  isEdit?: boolean;
  // onDelete: EventEmitter<LayoutItem> = new EventEmitter();
}

export class LayoutColumn extends LayoutItem {
  rows?: LayoutRow[];
}

export class LayoutRow extends LayoutItem {
  cells?: LayoutCell[];
}

export class LayoutCell extends LayoutItem {

  columns?: LayoutColumn[];
  isContainer?: boolean = false;
  padding?: number;

  constructor() {
    super();
    this.isContainer = false;
  }

}

