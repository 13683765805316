import { Component, Input, OnInit, Inject, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { FuseConfigService } from "../../core/services/config.service";
import { ApisService } from "src/app/shared/apis.service";
import { TranslateService } from "@ngx-translate/core";
import { NotifyService } from "src/app/shared/notify.service";
import { AppConfigService } from "src/app/shared/appconfig.service";
import { BaseEditorComponent } from "../../components/baseeditor.component";
import { BaseModel, UserAccess } from "../../models/DataModels";
import { SaveMenuRequest } from "../../models/DataModels/Cms/Request";
import { fuseAnimations } from "../../core/animations";
import { SelectItem } from "../../models/DataModels/TableDataSource/ModelRequest";

@Component({
  selector: "app-menueditor",
  templateUrl: "./menueditor.component.html",
  styleUrls: ["./menueditor.component.scss"],
  animations: fuseAnimations,
})
export class MenuEditorComponent
  extends BaseEditorComponent
  implements OnInit, OnDestroy
{
  skins: BaseModel[];
  isLoading = false;
  formErrors: any;
  isNew = false;
  skinId: string;
  icons = new Array<SelectItem>();
  icon: string;

  public userAccess: UserAccess = {
    canDelete: true,
    canEdit: true,
    canInsert: true,
  };

  constructor(
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    @Inject(NotifyService) protected notifyService,
    @Inject(AppConfigService) private appConfig,
    private location: Location,
    private apisService: ApisService,
    private fuseConfig: FuseConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    super();
    this.formErrors = {
      name: {},
      link: {},
      skinId: {},
      icon: {},
      authenticated: {},
      isRight: {},
      parentId: {},
      enabled: {},
      sort: {},
      userType: {},
    };
  }

  ngOnInit(): void {
    this.createForm();
    this.subscribers.push(
      this.route.params.subscribe((params) => {
        this.id = params.id;
        this.isNew = this.id === undefined;
        this.loadPage(this.id);
      })
    );
  }

  ngOnDestroy(): void {
    this.clear();
  }

  loadPage(id: string): void {
    this.isLoading = true;
    this.subscribers.push(
      this.apisService.getMenu(id).subscribe((response) => {
        if (response.isError) {
          this.isLoading = false;
        }
        this.notifyService.handleServerResponse(response, (result) => {
          // this.userAccess = response.userAccess;
          this.clear();
          this.skins = result.skins;
          this.icons = result.icons || new Array<SelectItem>();
          this.icons.push({
            id: undefined,
            value: "nessuna",
          } as SelectItem);
          this.icons = this.icons.reverse();
          this.pageForm.patchValue(result.menu);
          if (this.isNew) {
            this.pageForm.patchValue({
              enabled: true,
              link: "/",
            });
          }
          const timer = window.setTimeout(() => {
            this.pageForm.markAsPristine();
            this.isLoading = false;
            window.clearTimeout(timer);
          }, 800);
          this.skinId = result.menu.skinId;
          if (!this.isNew) {
            this.pageForm.controls.skinId.disable();
          }
          this.isLoading = false;
        });
      })
    );
  }

  save(publish?: boolean, overwrite?: boolean): void {
    if (this.pageForm.dirty) {
      if (!this.pageForm.invalid) {
        this.isLoading = true;
        const request = new SaveMenuRequest();
        Object.assign(request, {
          ...this.pageForm.getRawValue(),
          userType: this.pageForm.value.userType ? 1 : 0,
        });
        request.overwrite = overwrite;
        request.id = this.id;
        this.subscribers.push(
          this.apisService.saveMenu(request).subscribe((response) => {
            this.isLoading = false;
            this.handleServerResponse(
              response,
              (result) => {
                this.pageForm.reset();
                if (this.id === result) {
                  this.loadPage(this.id);
                } else {
                  this.router.navigate(["/cms/menus/menu", result]);
                }
              },
              publish
            );
          })
        );
      } else {
        this.notifyService.warning("COMMON.INVALIDDATA");
      }
    }
  }

  publish(): void {
    if (this.pageForm.invalid) {
      this.notifyService.error("COMMON.INVALIDPUBLISH");
    } else {
      this.pageForm.markAsDirty();
      this.save(true);
    }
  }

  protected internalClose(): void {
    const data = this.appConfig.temporaryData;
    if (data && data.internal) {
      this.location.back();
    } else {
      this.router.navigate(["cms/menus"]);
    }
  }

  private createForm(): void {
    this.pageForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(255)]],
      link: ["", [Validators.required, Validators.maxLength(255)]],
      skinId: ["", Validators.required],
      icon: [""],
      authenticated: [""],
      isRight: [""],
      parentId: [""],
      enabled: [""],
      userType: [""],
      sort: [""],
      version: [],
    });
    this.pageForm.valueChanges.subscribe((data) => {
      this.onFormValuesChanged(data);
    });
  }

  private onFormValuesChanged(data): void {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      const difference = this.formErrors[field].difference;
      this.formErrors[field] = {};
      const control = this.pageForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
      if (difference) {
        this.formErrors[field].difference = difference;
      }
    }
  }
}
