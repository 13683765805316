import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ApisService} from 'src/app/shared/apis.service';
import {GetWidgetRequest, SaveWidgetRequest} from '../../models/DataModels/Cms/Request';
import {GetItemsBaseResponse, UserAccess, WidgetModel} from '../../models/DataModels';
import {TableDataSource} from '../../models/DataModels/TableDataSource/TableDataSource';
import {DataBaseRequestField, ModelRequest, SelectItem} from '../../models/DataModels/TableDataSource/ModelRequest';
import {HttpDao} from '../../models/DataModels/TableDataSource/HttpDao';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FuseConfirmDialogComponent} from '../../core/components/confirm-dialog/confirm-dialog.component';
import {NotifyService} from 'src/app/shared/notify.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-widgets',
    templateUrl: './widgets.component.html',
    styleUrls: ['./widgets.component.scss']
})

export class WidgetsComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    public userAccess: UserAccess = {
        canDelete: true,
        canEdit: true,
        canInsert: true
    };

    protected request = new GetWidgetRequest();
    public dataSource: TableDataSource<GetWidgetRequest, GetItemsBaseResponse<WidgetModel>>;
    public displayedColumns = ['action', 'name', 'type', 'frontend', 'notes', 'published', 'changedAt', 'changedBy'];
    public panelTitle = 'WIDGETS.TITLE';
    public model: ModelRequest<GetWidgetRequest>;
    protected types = new DataBaseRequestField('type', 'COMMON.SEARCH_TYPE_LABEL', 'select', false, [
        // <SelectItem>{ id: 'text', value: 'Text' },
        <SelectItem> {id: 'banner', value: 'Banner'},
        <SelectItem> {id: 'slider', value: 'Slider'},
        <SelectItem> {id: 'html', value: 'Html'},
        <SelectItem> {id: 'dynamic', value: 'Dynamic'}
    ]);

    private frontends = new DataBaseRequestField('frontend', 'COMMON.FRONTEND', 'select', false, new Array<SelectItem>());
    private httpDao: WidgetHttpDao;
    private firstLoad: Subscription;

    constructor(
        @Inject(NotifyService) private notifyService,
        private apisService: ApisService,
        private translateService: TranslateService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        this.httpDao = new WidgetHttpDao(this.apisService);
        this.model = new ModelRequest<GetWidgetRequest>(this.request);
        this.model.columns = 3;
        this.model.showAddItem = true;
        let tmpItems = new Array<DataBaseRequestField>();

        tmpItems.push(
            this.frontends,
            new DataBaseRequestField('name', 'COMMON.SEARCH_NAME_LABEL', 'string'),
            this.types,
            new DataBaseRequestField('startDate', 'COMMON.SEARCH_START_LABEL', 'date'),
            new DataBaseRequestField('endDate', 'COMMON.SEARCH_END_LABEL', 'date'),
            new DataBaseRequestField('showAnonymous', 'COMMON.SEARCH_ANONYMOUS_LABEL', 'boolean'),
            new DataBaseRequestField('showAuthenticated', 'COMMON.SEARCH_AUTHENTICATED_LABEL', 'boolean'),
            new DataBaseRequestField('fixedWidget', 'COMMON.SEARCH_FIXEDWIDGET_LABEL', 'boolean')
        );
        this.model.items = tmpItems;
    }

    ngOnInit() {
        this.firstLoad = this.httpDao.firstLoad.subscribe((userAccess: UserAccess) => {
            this.request.includeAccess = false;
            // this.userAccess = userAccess;
            this.model.updateShowAdd(this.userAccess.canInsert);
        });
        const loader = this.apisService.getSkinData().subscribe(response => {
            if (!response.isError) {
                this.frontends.selectItems = response.data.frontends;
            }
            loader.unsubscribe();
        });
        this.model.request.reset();

    }

    ngAfterViewInit() {
        this.dataSource = new TableDataSource<GetWidgetRequest, GetItemsBaseResponse<WidgetModel>>(this.httpDao!, this.paginator, this.sort, this.model);
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        this.dataSource.disconnect();
        if (this.firstLoad) {
            this.firstLoad.unsubscribe();
            this.firstLoad = undefined;
        }
    }

    onSubmit() {
        this.paginator.pageIndex = 0;
        this.model.changed.emit();
    }

    edit(item?: WidgetModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (item !== undefined) {
            this.router.navigate(['widget', item.id], {
                relativeTo: this.route
            });
        } else {
            this.router.navigate(['addwidget'], {
                relativeTo: this.route
            });
        }
    }

    delete(item?: WidgetModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        if (item === undefined || !item.canDelete) {
            return;
        }
        let msg = item.published ? 'COMMON.DELETE_PUBLISHED_MESSAGE' : 'COMMON.DELETEMESSAGE';
        this.translateService.get(msg).subscribe(translate => {
            let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                width: '50%'
            });
            dialogRef.componentInstance.confirmMessage = translate;
            dialogRef.afterClosed().subscribe(confirm => {
                if (confirm === true) {
                    this.dataSource.isLoadingResults = true;
                    this.apisService.deleteWidget(item.id).subscribe(result => {
                        this.dataSource.isLoadingResults = false;
                        this.notifyService.handleServerResponse(result, res => {
                            this.model.reset();
                        });
                    });
                }
            });
        });
    }

    clone(item: WidgetModel, event?: any) {
        if (event) {
            event.stopPropagation();
        }
        if (item === undefined || !item.canDelete) {
            return;
        }
        this.dataSource.isLoadingResults = true;
        const request = new SaveWidgetRequest();
        request.id = item.id;
        request.copy = true;
        let savesub = this.apisService.saveWidget(request).subscribe(response => {
            this.dataSource.isLoadingResults = false;
            this.notifyService.handleServerResponse(response, result => {
                this.model.reset();
            });
        });
    }

}

export class WidgetHttpDao extends HttpDao<GetWidgetRequest, GetItemsBaseResponse<WidgetModel>> {
    constructor(public apisService: ApisService) {
        super(apisService);
    }

    public getData(request: GetWidgetRequest): Observable<GetItemsBaseResponse<WidgetModel>> {
        return this.apisService.getWidgets(request)
            .pipe(map(res => {
                if (res.isError) {
                    return <GetItemsBaseResponse<WidgetModel>> {
                        total: 0,
                        items: []
                    };
                } else {
                    if (request.includeAccess && res.userAccess) {
                        this.firstLoad.next(res.userAccess);
                    }
                    this.itemsChangedAt(res.data);
                    return res.data;
                }
            }));
    }
}
