import { Injectable, Inject } from '@angular/core';

@Injectable()
export class MediaService {

    private _items: string[];

    get items() {
        return this._items;
    }

    constructor() {
        this._items = new Array<string>();
    }

    addItem(item: string): void {
        if (item === undefined) { return; }
        const itemFound = this._items.find(x => x === item);
        if (itemFound !== undefined) {
            this.removeItem(item);
            return;
        }
        this._items.push(item);
    }

    removeItem(item: string): void {
        if (item === undefined) { return; }
        this._items = this._items.filter(x => x !== item);
    }

    clearItems(): void {
        this._items = new Array<string>();
    }

}
