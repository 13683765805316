<div fxLayout="column" fxLayoutAlign="start stretch">
  <div name="contentForm" [formGroup]="contentForm">
    <div class="banner-form-item-row">
      <h3 class="description-title" fxFlex="100">
        {{ "BANNER.IMAGES_SECTION" | translate }}
      </h3>
      <div fxFlex="50">
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <h3 class="description-subtitle" fxFlex="100">
            {{ "BANNER.IMAGES_SECTION_SUBTITLE" | translate }}
          </h3>
          <h4 class="description-subtitle" fxFlex="100">
            {{ "BANNER.IMAGES_SECTION_RATIO" | translate }}
          </h4>
          <div>
            <input hidden formControlName="BackId" />
            <input hidden formControlName="BackImage" />
            <figure>
              <img
                *ngIf="contentForm.value.BackImage"
                class="banner-widget-image"
                [(src)]="contentForm.value.BackImage"
              />
              <img
                *ngIf="!contentForm.get('BackImage').value"
                class="banner-widget-image"
              />
              <figcaption
                [ngClass]="{
                  '': contentForm.get('BackImage').value,
                  'no-content-box': !contentForm.get('BackImage').value
                }"
              >
                <div class="media-buttons-container">
                  <button
                    mat-button
                    class="mat-icon-button"
                    (click)="changeBackImage()"
                    *fuseIfOnDom
                    aria-label="Add Media"
                    matTooltip="{{ 'COMMON.ADD_MEDIA' | translate }}"
                  >
                    <mat-icon>image</mat-icon>
                  </button>
                  <button
                    mat-button
                    [ngStyle]="{
                      display: !contentForm.get('BackImage').value ? 'none' : ''
                    }"
                    class="mat-icon-button"
                    (click)="removeBackImage()"
                    *fuseIfOnDom
                    aria-label="Remove Media"
                    matTooltip="{{ 'COMMON.REMOVE_MEDIA' | translate }}"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </figcaption>
            </figure>
          </div>
          <mat-form-field>
            <mat-select
              matInput
              placeholder="{{ 'BANNER.BACK_ANIMATION' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_BANNER_ANIM' | translate }}"
              [(ngModel)]="backAnimation"
              formControlName="BackAnimation"
            >
              <mat-option
                *ngFor="let banim of backAnimations"
                [value]="banim.id"
              >
                {{ banim.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="50">
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <h3 class="description-subtitle" fxFlex="100">
            {{ "BANNER.IMAGES_FRONT_SUBTITLE" | translate }}
          </h3>
          <h4 class="description-subtitle" fxFlex="100">
            {{ "BANNER.IMAGES_SECTION_RATIO" | translate }}
          </h4>
          <div>
            <input hidden formControlName="FrontId" />
            <input hidden formControlName="FrontImage" />
            <figure>
              <img
                *ngIf="contentForm.value.FrontImage"
                class="banner-widget-image"
                [(src)]="contentForm.value.FrontImage"
              />
              <img
                *ngIf="!contentForm.value.FrontImage"
                class="banner-widget-image"
              />
              <figcaption
                [ngClass]="{
                  '': contentForm.get('FrontImage').value,
                  'no-content-box': !contentForm.get('FrontImage').value
                }"
              >
                <div class="media-buttons-container">
                  <button
                    mat-button
                    class="mat-icon-button"
                    (click)="changeFrontImage()"
                    *fuseIfOnDom
                    aria-label="Add Media"
                    matTooltip="{{ 'COMMON.ADD_FRONT_MEDIA' | translate }}"
                  >
                    <mat-icon>image</mat-icon>
                  </button>
                  <button
                    mat-button
                    [ngStyle]="{
                      display: !contentForm.get('FrontImage').value
                        ? 'none'
                        : ''
                    }"
                    class="mat-icon-button"
                    (click)="removeFrontImage()"
                    *fuseIfOnDom
                    aria-label="Remove Media"
                    matTooltip="{{ 'COMMON.REMOVE_MEDIA' | translate }}"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </figcaption>
            </figure>
          </div>
          <mat-form-field>
            <mat-select
              matInput
              placeholder="{{ 'BANNER.FRONT_ANIMATION' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_BANNER_ANIM' | translate }}"
              [(ngModel)]="frontAnimation"
              formControlName="FrontAnimation"
            >
              <mat-option
                *ngFor="let fanim of frontAnimations"
                [value]="fanim.id"
              >
                {{ fanim.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="banner-form-item-row">
      <h3 class="description-title" fxFlex="100">
        {{ "BANNER.DETAILS" | translate }}
      </h3>
      <div fxFlex="100">
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'BANNER.TITLE' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_BANNER_TITLE' | translate }}"
              formControlName="Title"
            />
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="40">
            <input
              matInput
              placeholder="{{ 'BANNER.TITLE_BACK_COLOR' | translate }}"
              matTooltip="{{
                'WIDGETS.TOOLTIP_BANNER_BACK_COLOR_TITLE' | translate
              }}"
              formControlName="TitleBackColor"
            />
          </mat-form-field>
          <fuse-material-color-picker
            [(selectedBg)]="titleBackColor"
            fxFlex="10"
            (onValueChange)="onTitleBackColorChanged()"
          >
          </fuse-material-color-picker>
          <mat-form-field fxFlex="40">
            <input
              matInput
              placeholder="{{ 'BANNER.TITLE_COLOR' | translate }}"
              matTooltip="{{
                'WIDGETS.TOOLTIP_BANNER_COLOR_TITLE' | translate
              }}"
              formControlName="TitleColor"
            />
          </mat-form-field>
          <fuse-material-color-picker
            [(selectedBg)]="titleColor"
            fxFlex="10"
            (onValueChange)="onTitleColorChanged()"
          >
          </fuse-material-color-picker>
        </div>
      </div>
      <div fxFlex="100">
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'BANNER.SUBTITLE' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_BANNER_SUBTITLE' | translate }}"
              formControlName="Subtitle"
            />
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="40">
            <input
              matInput
              placeholder="{{ 'BANNER.SUBTITLE_BACK_COLOR' | translate }}"
              matTooltip="{{
                'WIDGETS.TOOLTIP_BANNER_BACK_COLOR_SUBTITLE' | translate
              }}"
              formControlName="SubtitleBackColor"
            />
          </mat-form-field>
          <fuse-material-color-picker
            [(selectedBg)]="subtitleBackColor"
            fxFlex="10"
            (onValueChange)="onSubtitleBackColorChanged()"
          >
          </fuse-material-color-picker>
          <mat-form-field fxFlex="40">
            <input
              matInput
              placeholder="{{ 'BANNER.SUBTITLE_COLOR' | translate }}"
              matTooltip="{{
                'WIDGETS.TOOLTIP_BANNER_COLOR_SUBTITLE' | translate
              }}"
              formControlName="SubtitleColor"
            />
          </mat-form-field>
          <fuse-material-color-picker
            [(selectedBg)]="subtitleColor"
            fxFlex="10"
            (onValueChange)="onSubtitleColorChanged()"
          >
          </fuse-material-color-picker>
        </div>
      </div>
      <div fxFlex="100">
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'BANNER.TEXT' | translate }}"
              matTooltip="{{ 'WIDGETS.TOOLTIP_BANNER_TEXT' | translate }}"
              formControlName="Text"
            />
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="100">
        <mat-checkbox matInput formControlName="TitleAnimation"
          >{{ "BANNER.TITLE_ANIMATION" | translate }}
        </mat-checkbox>
        <mat-checkbox matInput formControlName="Timer">{{
          "BANNER.TIMER" | translate
        }}</mat-checkbox>
        <mat-checkbox matInput formControlName="Timerstart"
          >{{ "BANNER.TIMER_ONSTART" | translate }}
        </mat-checkbox>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'BANNER.RATIO' | translate }}"
            matTooltip="{{ 'WIDGETS.TOOLTIP_BANNER_RATIO' | translate }}"
            formControlName="Ratio"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start stretch">
    <div class="banner-form-item-row">
      <div>
        <h3 class="description-title" fxFlex="100">
          {{ "BANNER.ADD_BUTTON" | translate }}
        </h3>
        <div class="button-action add">
          <button
            mat-button
            class="mat-icon-button"
            (click)="addButton()"
            *fuseIfOnDom
            aria-label="Add Media"
            matTooltip="{{ 'COMMON.ADD_BUTTON' | translate }}"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </div>
      <div class="banner-form-item-row" *ngFor="let button of buttons">
        <div fxFlex="100">
          <span class="button-type">{{
            "BANNER.BUTTON_TYPE" | translate
          }}</span>
          <mat-radio-group
            matInput
            [(ngModel)]="button.Type"
            (change)="onButtonTypeChanged(button)"
          >
            <mat-radio-button
              *ngFor="let item of buttontypes"
              [value]="item.value"
            >
              {{ item.display | translate }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div fxFlex="100">
          <div fxLayout="row" fxLayoutAlign="start stretch">
            <mat-form-field fxFlex="30">
              <input
                matInput
                placeholder="{{ 'BANNER.BUTTON_TEXT' | translate }}"
                matTooltip="{{
                  'WIDGETS.TOOLTIP_BANNER_BUTTON_TEXT' | translate
                }}"
                [(ngModel)]="button.Text"
                (change)="emitChange()"
              />
            </mat-form-field>
            <div class="button-action">
              <button
                mat-button
                class="mat-icon-button"
                (click)="deleteButton(button)"
                *fuseIfOnDom
                matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}"
              >
                <mat-icon>remove_circle</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div fxFlex="100" *ngIf="button.Type === 0">
          <div fxLayout="row" fxLayoutAlign="start stretch">
            <mat-form-field fxFlex="30" class="content-selection-input">
              <input
                matInput
                disabled
                placeholder="{{ 'BANNER.BUTTON_CONTENT' | translate }}"
                matTooltip="{{
                  'WIDGETS.TOOLTIP_BANNER_BUTTON_CONTENT' | translate
                }}"
                [(ngModel)]="button.ContentName"
                (change)="emitChange()"
              />
            </mat-form-field>
            <div class="button-action">
              <button
                mat-button
                class="mat-icon-button"
                (click)="selectContent(button)"
                *fuseIfOnDom
                aria-label="Select content"
                matTooltip="{{ 'BANNER.SELECT_CONTENT' | translate }}"
              >
                <mat-icon>settings_applications</mat-icon>
              </button>
            </div>
            <div class="button-action popup">
              <mat-checkbox
                matInput
                [(ngModel)]="button.isPopup"
                (change)="emitChange()"
              >
                {{ "BANNER.BUTTON_ISPOPUP" | translate }}</mat-checkbox
              >
            </div>
          </div>
        </div>
        <div fxFlex="100" *ngIf="button.Type === 1">
          <div fxLayout="row" fxLayoutAlign="start stretch">
            <mat-form-field fxFlex="30">
              <input
                matInput
                placeholder="{{ 'BANNER.BUTTON_PAGE' | translate }}"
                [(ngModel)]="button.pageLink"
                (change)="emitChange()"
              />
            </mat-form-field>
            <div class="button-action">
              <button
                mat-button
                class="mat-icon-button"
                (click)="selectPage(button)"
                *fuseIfOnDom
                aria-label="Select Page"
                matTooltip="{{ 'BANNER.SELECT_PAGE' | translate }}"
              >
                <mat-icon>find_in_page</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div fxFlex="100" *ngIf="button.Type === 2">
          <div fxLayout="row" fxLayoutAlign="start stretch">
            <mat-form-field fxFlex="30">
              <input
                matInput
                placeholder="{{ 'BANNER.BUTTON_LINK' | translate }}"
                matTooltip="{{
                  'WIDGETS.TOOLTIP_BANNER_BUTTON_LINK' | translate
                }}"
                [(ngModel)]="button.Link"
                (change)="emitChange()"
              />
            </mat-form-field>
          </div>
        </div>
        <div fxFlex="100" *ngIf="button.Type === 3">
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <mat-form-field>
              <input
                matInput
                placeholder="{{ 'BANNER.BUTTON_FUNCTION' | translate }}"
                matTooltip="{{
                  'WIDGETS.TOOLTIP_BANNER_BUTTON_FUNCTION' | translate
                }}"
                [(ngModel)]="button.CustomFunction"
                (change)="emitChange()"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
