import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../core/modules/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AuthService } from '../shared/auth.service';
import { PageEditorGuard } from '../components/editorguard';
import { PageCanActivateGuard } from '../components/activateguard';
// guards

import { routes } from './betting.routes';
import { GroupsComponent } from './groups/groups.component';
import { GroupsEditorComponent } from './groups/groupseditor.component';
import { CreateOutcomeComponent } from './groups/createOutcome.component';

import { SportsComponent } from './sports/sports.component';
import { SportEditorComponent } from './sports/sporteditor.component';
import { SportBetgroupComponent } from './sports/sportbetgroup.component';




@NgModule({
    declarations: [
        GroupsComponent,
        GroupsEditorComponent,
        SportsComponent,
        SportEditorComponent,
        CreateOutcomeComponent,
        SportBetgroupComponent
    ],
    entryComponents: [
        CreateOutcomeComponent,
        SportBetgroupComponent
    ],
    imports: [
        BrowserAnimationsModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    providers: [
        AuthService,
        PageEditorGuard,
        PageCanActivateGuard
    ]
})

export class BettingModule {
}
